import { msg, Store } from "plume2";
import Actor from "./actor/actor";
import { SUCCESS_CODE, cache } from "@utils";
import * as webapi from "./webapi";
import { message } from "antd";
import {fromJS} from "immutable";
export default class AppStore extends Store {
  bindActor() {
    return [new Actor()];
  }

  /**
   * 设值
   */
  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 改值
   */
  changeData = (field, value) => {
    this.dispatch("change:data", { field, value });
    if (field == "departmentId") {
      this.getDepartmentUser();
    }
    if(field == "selectYear" || field == "selectMonth"){
      this.getDepartmentUser();
    }
  };

  /**
   * 初始化
   */
  init = () => {
    const LOGIN_FUNCTIONS = JSON.parse(
      localStorage.getItem?.(cache.LOGIN_FUNCTIONS)
    );
    const isLeader = this.state().get("isLeader");
    if (
      LOGIN_FUNCTIONS.includes("f_resource_calendar_data_department_tree") ||
      isLeader
    ) {
      this._getDepartmentList();
    } else {
      this.search();
    }
  };

  /**
   * 部门下拉列表
   */
  _getDepartmentList = async () => {
    const res = await webapi.getDepartmentList();
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        belongDepartmentList: res.context,
        belongDepartmentInfo: res.context[0],
        departmentId: res.context?.[0]?.id,
        projectModldepartmentName: res.context[0]?.name,
        departmentName: res.context[0]?.name,
      });
      setTimeout(() => {
        this.search();
      }, 400);
    } else {
      message.error(res.message);
    }
  };

  /*
   * 搜索
   */
  search = async (isReset?) => {
    const year = this.state().get("selectYear");
    const month = this.state().get("selectMonth");
    // 获取员工列表
    this.getDepartmentUser();
    // 搜索设置scoreType初始值 0:全部 1:仅看异常
    this.mergeData({ scoreType: 0 });
    const departmentId = this.state().get("departmentId");
    const departmentName = isReset
      ? this.state().get("departmentName")
      : this.state().get("projectModldepartmentName");
    // 设置日历的时间
    this.mergeData({
      totalStartYear: year,
      totalStartMonth: month,
      detailStartYear: year,
      detailStartMonth: month,
      projectModlDepartmentId: departmentId,
      projectModldepartmentName: departmentName,
    });

    const params = {
      year: Number(year),
      month: Number(month),
      departmentId,
    };
    // 1、部门员工投入数据汇总列表
    this.getEffortSummaryPage(1, 10);
    // 2、 部门员工投入卡片数据汇总
    const totalCardRes = await webapi.getEffortSummaryCard(params);
    if ((totalCardRes.code = SUCCESS_CODE)) {
      this.mergeData({ effortSummaryCard: totalCardRes.summaryCardVO });
    } else {
      message.error(totalCardRes.message);
    }
    // 3、资源日历 ---- 投入汇总
    this.getCalendarData("total");
    //重置数据
    if (isReset) {
      this.mergeData({ tabType: "1", listUserId: null });
    }
  };

  /*
   * 部门员工投入数据汇总列表
   */

  getEffortSummaryPage = async (pageNum?, pageSize?) => {
    this.mergeData({ effortSummaryPageLoading: true });
    const year = this.state().get("selectYear");
    const month = this.state().get("selectMonth");
    const departmentId = this.state().get("departmentId");
    const effortPageNum = this.state().get("effortPageNum");
    const effortPageSize = this.state().get("effortPageSize");
    const userId = this.state().get("userId");
    const params = {
      year: Number(year),
      month: Number(month),
      departmentId: departmentId || null,
      pageNum: pageNum || 1,
      pageSize: effortPageSize,
      userId: userId
    };
    const scoreType = this.state().get("scoreType");
    const totalListRes = await webapi.getEffortSummaryPage({
      ...params,
      isOnlyAbnormal: scoreType == 0 ? false : true,
    });
    if ((totalListRes.code = SUCCESS_CODE)) {
      this.mergeData({ effortSummaryPage: totalListRes });
    } else {
      message.error(totalListRes.message);
    }
    setTimeout(() => {
      this.mergeData({ effortSummaryPageLoading: false });
    }, 0);
    //选择员工效果
    if(userId){
      const totalStartMonth = this.state().get("totalStartMonth");
      const totalStartYear = this.state().get("totalStartYear");
      const record = totalListRes?.page?.records?.filter(a => a.id == userId)[0];
      const data = {
        children: record.username,
        value: record.id
      }
      this.mergeData({
        departmentUserInfo: fromJS(data),
        tabType: '2',
        listUserId: record.id,
        calendarDetail: [],
        detailStartYear: totalStartYear,
        detailStartMonth: totalStartMonth,
      })
      this.getCalendarData('detail');
    }

  };

  /*
   * 项目弹窗的搜索
   */

  projectModalSearch = async (pageNum, pageSize) => {
    this.mergeData({ projectLoading: true });
    const year = this.state().get("projectModalYear");
    const month = this.state().get("projectModalMonth");
    const day = this.state().get("projectModalDay");
    const userName = this.state().get("userName");
    const departmentId = this.state().get("projectModlDepartmentId");
    const status = this.state().get("status");
    const detailPageNum = this.state().get("detailPageNum");
    const detailPageSize = this.state().get("detailPageSize");
    this.mergeData({ detailPageSize: pageSize || detailPageSize, effortPageNum: pageNum || detailPageNum });
    let params = {
      day,
      year,
      month,
      pageNum: pageNum || detailPageNum,
      pageSize: pageSize || detailPageSize,
      status,
      userName,
      departmentId,
    };
    const res = await webapi.getDepartmentDetailList(params);
    if ((res.code = SUCCESS_CODE)) {
      this.mergeData({ departmentDetailList: res?.page });
    } else {
      message.error(res.message);
    }
    setTimeout(() => {
      this.mergeData({ projectLoading: false });
    }, 0);
  };

  /*
   * 资源日历--部门详细弹窗列表
   */
  getDepartmentDetailList = async (pageNum, pageSize, data?) => {
    this.mergeData({ projectLoading: true });
    const year = this.state().get("projectModalYear");
    const month = this.state().get("projectModalMonth");
    const day = this.state().get("projectModalDay");
    const departmentId = this.state().get("projectModlDepartmentId");
    const status = this.state().get("status");
    const detailPageNum = this.state().get("detailPageNum");
    const detailPageSize = this.state().get("detailPageSize");
    const params = {
      day,
      year,
      month,
      pageNum: pageNum || 1,
      pageSize: pageSize || detailPageSize,
      status: status,
      userName: "",
      departmentId,
    };

    const res = await webapi.getDepartmentDetailList(params);
    if ((res.code = SUCCESS_CODE)) {
      this.mergeData({
        departmentDetailList: res?.page,
      });
    } else {
      message.error(res.message);
    }
    setTimeout(() => {
      this.mergeData({ projectLoading: false });
    }, 0);
  };

  /*
   * 资源日历--任务明细弹窗列表
   */
  getTaskDetailList = async (pageNum?, pageSize?) => {
    this.mergeData({ personLoading: true });
    const year = this.state().get("personModalYear");
    const month = this.state().get("personModalMonth");
    const day = this.state().get("personModalDay");
    const taskName = this.state().get("taskName");
    const userId = this.state().get("departmentUserInfo")?.toJS().value;
    const departmentId = this.state().get("departmentId");
    const params = {
      year,
      month,
      day,
      pageNum: pageNum || 1,
      pageSize: pageSize || 10,
      taskName,
      departmentId,
      userId,
    };
    const res = await webapi.getTaskDetailList(params);
    if ((res.code = SUCCESS_CODE)) {
      this.mergeData({ taskDetailList: res.page });
    } else {
      message.error(res.message);
    }
    setTimeout(() => {
      this.mergeData({ personLoading: false });
    }, 0);
  };

  /*
   * 资源日历--日历数据（汇总/明细）
   */
  getCalendarData = async (type) => {
    const year = type == "total" ? "totalStartYear" : "detailStartYear";
    const month = type == "total" ? "totalStartMonth" : "detailStartMonth";
    const selectYear = this.state().get(year);
    const selectYearMonth = this.state().get(month);
    const departmentId = this.state().get("departmentId");
    const userId = this.state().get("departmentUserInfo")?.toJS()?.value;
    const params = {
      year: selectYear,
      month: selectYearMonth,
      departmentId,
    };
    if (type == "total") {
      const res = await webapi.getCalendarTotal({ ...params, departmentId });
      if ((res.code = SUCCESS_CODE)) {
        this.mergeData({ calendarTotal: res.summaryVOS });
      } else {
        message.error(res.message);
      }
    } else {
      const res = await webapi.getCalendarDetail({ ...params, userId });
      if ((res.code = SUCCESS_CODE)) {
        this.mergeData({ calendarDetail: res.detailVOS });
      } else {
        message.error(res.message);
      }
    }
  };

  /*
   * 资源日历--部门员工下拉
   */
  getDepartmentUser = async () => {
    const deptId = this.state().get("departmentId");
    const year = this.state().get("selectYear");
    const month = this.state().get("selectMonth");
    const param = {
      deptId,
      year,
      month
    };
    const res = await webapi.getDepartmentUser(param);
    if ((res.code = SUCCESS_CODE)) {
      this.mergeData({
        departmentUserList: res.context,
        departmentUserInfo: res.context?.[0],
      });
    } else {
      message.error(res.message);
    }
  };
}
