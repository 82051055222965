import React from "react";
import { Relax } from "plume2";
import ReactEcharts from "echarts-for-react";
import { util } from '@utils';

@Relax
class TrendChart extends React.Component<any, any> {
  static relaxProps = {
    bugRepairsChartsVOS: "bugRepairsChartsVOS",
    bugRepairsWeeksVOS: 'bugRepairsWeeksVOS'
  };

  render() {
    const {
      bugRepairsChartsVOS,
      bugRepairsWeeksVOS
    } = this.props.relaxProps;


    let options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          const axisValue = params[0]?.axisValue
          const value1 = params[0]?.value ? params[0]?.value + "%" : "0%"
          const value2 = params[1]?.value ? params[1]?.value + "%" : "0%"
          const value3 = params[2]?.value ? params[2]?.value + "%" : "0%"
          return `<div><div style="font-size:10px;color: #000000;">${axisValue}</div><div style="display: flex;flex-direction: row;align-items: center;width:130px; justify-content: space-between;font-size:10px;"><div style="display:flex;flex-direction: row;align-items:center;"><div style="width:4px;height:4px;background:#FE6D46;border-radius:2px;margin-right:5px"></div><span>处理及时率</span></div><span style="font-weight:500;">${value1}</span></div><div style="display: flex;flex-direction: row;align-items: center;width:130px; justify-content: space-between;font-size:10px;"><div style="display:flex;flex-direction: row;align-items:center;"><div style="width:4px;height:4px;background:#3AD595;border-radius:2px;margin-right:5px"></div><span>修复质量</span></div><span style="font-weight:500;">${value2}</span></div><div style="display: flex;flex-direction: row;align-items: center;width:130px; justify-content: space-between;font-size:10px;"><div style="display: flex;flex-direction: row;align-items: center;"><div style="width:4px;height:4px;background:#FF9E4C;border-radius:2px;margin-right:5px"></div><span>回归及时率</span></div><span style="font-weight:500;">${value3}</span></div></div>`;
        }

      },
      // 标题样式
      legend: {
        //居左显示
        left: 'left',
        itemWidth: 8,
        itemHeight: 8,
        //间隔
        itemGap: 16
      },
      grid: {
        bottom: '8%',
      },
      xAxis: [
        {
          type: 'category',
          data: this.weeksData(bugRepairsWeeksVOS?.toJS())
        }
      ],
      yAxis: [
        {
          type: 'value',
          // 最小刻度值的计算
          min: function (value) {
            if (value.min == 0) {
              return 0;
            } else {
              let data = Math.floor(value.min / 10) * 10
              return data
            }
          },
          // 最大刻度值的计算
          max: function (value) {
            if (value.max > 100) {
              return 110;
            } else {
              return 100
            }
          },
        }
      ],
      series: [
        {
          name: '处理及时率',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.getRateData(bugRepairsChartsVOS?.toJS(), 'dealTimelyRate'),
          color: '#FE6D46',
        },

        {
          name: '修复质量',
          type: 'bar',
          data: this.getRateData(bugRepairsChartsVOS?.toJS(), 'repairQuality'),
          emphasis: {
            focus: 'series'
          },
          color: '#3AD595'
        },
        {
          name: '回归及时率',
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          data: this.getRateData(bugRepairsChartsVOS?.toJS(), 'returnTimelyRate'),
          color: '#FF9E4C'
        },
      ]
    };


    return (
      <div className="bug_fixes_trend_chart" >
        <div className="title">
          缺陷修复趋势柱状图
        </div>
        <div className="chart">
          <ReactEcharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
          />
        </div>

      </div>
    );
  }


  weeksData = (data) => {
    var weekArr = [];
    data?.forEach?.((item) => {
      weekArr?.push("第" + util.numToChinaNum(item?.week) + "周")
    })
    if (weekArr[0] === '第零周') {
      weekArr[0] = '全阶段'
    }
    return weekArr
  }

  getRateData = (data, param) => {
    var rateDataArr = []
    data?.forEach?.((item) => {
      rateDataArr.push(item[param])
    })
    return rateDataArr
  }
}
export default TrendChart;
