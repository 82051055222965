import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class SearchGroupActor extends Actor {
  defaultState() {
    return {
      bugRepairsSearch: {}, //搜索条件
      bugRepairsSearchData: {}, //搜索条件数据
      bugRepairsPageVOPage: {
        pageNum: 0,
        pageSize: 10,
      }, //数据汇总（定制项目列表数据）
      bugRepairsChartsVOS: {}, //趋势图数据
      repairsDetailPageVOPage: {
        pageNum: 0,
        pageSize: 10,
      }, //数据明细
      bugRepairsWeeksVOS: {}, //数据周
      bugRepairsCountVO: {}, // 明细数量
      tabType: 0, //明细的tab 0:开发侧 1:测试侧
      weekTabType: 0, //全阶段 0 1:第一周 2:第二周 以此类推
      detailSearch: {},
      detailSearchData: {},
      weeksData: {}, // 明细周的具体数据 {"beginTime": "2022-03-11T06:11:40.441Z","endTime": "2022-03-11T06:11:40.441Z", "week": 0}
      pageItem: {
        pageNum: 0,
        pageSize: 10,
      }, //数据汇总（基线列表数据）
      clickRowClassName: "", // 列表点击的样式名称 （转测中缺陷修复数据）
      rowId: "", // 列表id （转测中缺陷修复数据）
      totalInterfaceLoading: false, //数据汇总加载中
      repairsDetailLoading: false, //缺陷修复明细加载中
      pageNum: 1,
      pageSize: 10,
      detailPageNum: 1,
      detailPageSize: 10,
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
