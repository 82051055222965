import React from "react";
import { Relax } from "plume2";

// GitLab - icon
import GitLab_img from "./img/GitLab.png";
// WiKi - icon
import WiKi_img from "./img/WiKi.png";
// TAPD - icon
import TAPD_img from "./img/TAPD.png";
// Sonar - icon
import Sonar_img from "./img/Sonar.png";
// Jenkins - icon
import Jenkins_img from "./img/Jenkins.png";
// logo - icon
import logo_img from "./img/logo.png";
import "./index.less";

// 平台logo
const GET_IMG = {
  GitLab: GitLab_img,
  WiKi: WiKi_img,
  TAPD: TAPD_img,
  Sonar: Sonar_img,
  Jenkins: Jenkins_img,
  APP: logo_img,
  潘多拉: logo_img,
  开放平台: logo_img,
};

// 其他平台入口(如：gitLab、wiki、tapd等)
@Relax
class Platform extends React.Component<any, any> {
  static relaxProps = {
    platform_obj: "platform_obj",
  };

  render() {
    const { platform_obj } = this.props.relaxProps;
    if (!platform_obj?.get("records")?.size) return null;
    return (
      <div className="skyeye_pc_home_sider_platform">
        {platform_obj?.get("records")?.map?.((item, index) => {
          return (
            <a
              className="skyeye_pc_home_sider_platform__item"
              key={index}
              rel="noreferrer"
              href={item?.get("url")}
              target="_blank"
            >
              <img
                draggable={false}
                src={GET_IMG[item?.get("title")] || logo_img}
                alt="icon"
                width={50}
                height={50}
              />
              <span>{item?.get("title")}</span>
            </a>
          );
        })}
      </div>
    );
  }
}
export default Platform;
