import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      pageType: "0", //类型 0: 项目列表 1: 质量考核 2: 缺陷修复
      qualityInspectionConditions: {}, // 质量考核 搜索条件
      qualityInspectionConditionsData: {}, //质量考核 条件框数据
      qualityInspectionProjectData: {}, // 质量考核 项目信息
      qualityInspectionIterativeData: {}, // 质量考核 迭代信息
      projectType: 0, // 0:基线 1:定制
      qualityInspectionListInfo: {}, // 质量考核 缺陷数据信息
      qualityInspectionListLoading: true, // 数据加载中
      qualityBugDataCheckStatus: ["验收测试", "生产问题"], // 默认展示的列表数据
      qualityInspectionRowId: "", //列表选中的id
      pageNum: 1,
      pageSize: 10,
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}