import React from "react";
import { Relax } from "plume2";
import ReactEcharts from "echarts-for-react";
import { util } from '@utils';
import { Select, Button, Tabs, Checkbox, Radio, List } from 'antd';
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import attentionImg from '../../../../img/attention.png';
const { TabPane } = Tabs;
const { Option } = Select;



@Relax
class OverviewChart extends React.Component<any, any> {
  static relaxProps = {
    overViewSearch: 'overViewSearch',
    overViewSearchData: 'overViewSearchData',
    changeOverViewSearch: noop,
    tabType: 'tabType',
    mergeData: noop,
    overViewBugData: 'overViewBugData',
    demandOptimizationFlag: 'demandOptimizationFlag',
    customDemandOptimizationFlag: 'customDemandOptimizationFlag',
    projectNumOrRate: 'projectNumOrRate',
    customNumOrRate: 'customNumOrRate',
    getSeverityDistribution: noop,
    severityDistribution: 'severityDistribution',
    priorityDistribution: 'priorityDistribution',
    originPhaseDistribution: 'originPhaseDistribution',
    historyDistribution: 'historyDistribution',
    getOverViewWithDistribution: noop,
    selectProjectType: 'selectProjectType',
    customSelectProjectType: 'customSelectProjectType',
    attention: 'attention',
    remindAttention: noop,
    bugAnalysisSearch:'bugAnalysisSearch',
    customSearch:'customSearch'
  };

  render() {

    const { tabType } = this.props.relaxProps;

    return (
      <div className="bug_analysis_overview" >
        {tabType == 1 ? <Tabs defaultActiveKey="1" >
          <TabPane tab="概览" key="1">
          </TabPane>
        </Tabs> : <div className="headline">数据概览</div>}
        {/* 下拉 */}
        {this._renderSelect()}
        {/* 各项总数+饼图 */}
        {this._renderBugDataWithChart()}
      </div>
    );
  }

  _renderSelect = () => {
    const {
      overViewSearch,
      overViewSearchData,
      demandOptimizationFlag,
      customDemandOptimizationFlag,
      attention,
      tabType,
      remindAttention
    } = this.props.relaxProps;
    const { projectType, belongBaseline, project, iteration } = overViewSearchData?.toJS?.() || {};
    // projectType 0:基线  1:定制
    const isCustomMade = overViewSearch.get('projectType') == 1 ? true : false;
    const iterationId = overViewSearch.get('iteration')
    const demandOptimization = tabType == 1 ? demandOptimizationFlag : customDemandOptimizationFlag
    const attentionBackgroundColor = (!attention && typeof iterationId === 'number') ? "#FF6600" : "#999999"

    return (
      <div className="search-group">
        {/* 筛选项组合 */}
        {tabType == 1 && <OneAuthWrapper functionName="f_bug_fix_project_select_list,f_bug_fix_project_select_list_all">
          {isCustomMade && <Select
            placeholder="请选择项目"
            style={{ width: 300, marginRight: 10 }}
            // disabled={overViewSearch?.get('belongBaseline') === undefined}
            value={overViewSearch?.get('project') ? overViewSearch?.get('project') : null}
            onChange={e => this._handleChange(e, 'project')}
            notFoundContent="暂无项目"
            dropdownClassName="project-iteration-select-dropdown"
          >
            {overViewSearch?.get('project') && project?.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>}
        </OneAuthWrapper>}


        {tabType == 1 && <AuthWrapper functionName="f_bug_fix_iteration_select_list"><Select
          showSearch
          placeholder="请选择迭代"
          style={{ width: 300, marginRight: 20 }}
          // disabled={this._getShow(isCustomMade)}
          defaultValue={null}
          value={overViewSearch?.get('iteration')}
          onChange={e => this._handleChange(e, 'iteration')}
          notFoundContent="暂无迭代"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
        >
          <Option value={null}>全部</Option>
          {iteration && iteration?.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </AuthWrapper>}
        <Checkbox onChange={(e) => this._onChange(e)} checked={demandOptimization}>含需求/优化类</Checkbox>
        {tabType == 1 && <AuthWrapper functionName="f_analyze_push_msg">
          <div className="attention-box" 
            style={{ backgroundColor: attentionBackgroundColor }} 
            onClick={() => {
              if(["#999999"].includes(attentionBackgroundColor)) return;
              remindAttention();
            }}
          >
          <img src={attentionImg} />
          <span>提醒关注</span>
          </div></AuthWrapper>}
      </div>
    );
  }

  _renderBugDataWithChart = () => {
    const { projectNumOrRate, customNumOrRate, tabType } = this.props.relaxProps
    let radioValue;
    if (tabType == 1) {
      radioValue = projectNumOrRate
    } else {
      radioValue = customNumOrRate
    }
    let data = this._getBugData()
    const severityDistributionIsEmpty = this._getIsEmpty('severityDistribution')
    const priorityDistributionIsEmpty = this._getIsEmpty('priorityDistribution')
    const originPhaseDistributionIsEmpty = this._getIsEmpty('originPhaseDistribution')
    const historyDistributionIsEmpty = this._getIsEmpty('historyDistribution')
    return <div>
      <div className="overview-total">
        {/* 缺陷数据 */}
        <div className="total">
          <div className="total-first">
            {data.group1?.map((item) => {
              let itemData = this._thousands(Number(item.data))
              return <div className="total-first-item">
                <span className="title">{item.label}</span>
                <span className="data">{itemData}</span>
              </div>
            })}
          </div>
          <div className="total-first">
            {data.group2?.map((item) => {
              if(item?.children){
                return <div className="total-first-item">
                      <span className="title">{item.label}</span>
                      {item?.children?.map((item1) => {
                        let itemData = this._thousands(Number(item1.data))
                        return <div style={{marginTop: 10}}>
                          <span className="children-title">{item1.label}：</span>
                          <span className="children-data">{itemData}
                          {item1.isRate && '%'}
                          </span>
                        </div>
                      })}
                </div>
              } else {
                let itemData = this._thousands(Number(item.data))
                return <div className="total-first-item">
                  <span className="title">{item.label}</span>
                  <span className="data">{itemData}
                    {item.isRate && '%'}
                  </span>
                </div>
              }
            })}
          </div>
        </div>
        {/* 严重程度分布图 */}
        <div className="severity-chart">
          <div className="head">
            <div className="title">严重程度分布</div>
            <Radio.Group onChange={(e) => this._onChangeSeverity(e)} value={radioValue}>
              <Radio value={0}>按数量</Radio>
              <Radio value={1}>按占比</Radio>
            </Radio.Group>
          </div>
          {!severityDistributionIsEmpty ? <ReactEcharts
            option={this._getOption(1)}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: 290 }}
          /> : <List />}
        </div>
      </div>

      {/* 优先级+发现阶段+历史遗留分布图 */}
      <div className="overview-pie-chart">
        <div className="chart-item">
          <span className="title">优先级分布</span>
          {!priorityDistributionIsEmpty ? <ReactEcharts
            option={this._getOption(2)}
            notMerge={true}
            lazyUpdate={true}
          /> : <List />}
        </div>
        <div className="chart-item">
          <span className="title">发现阶段分布</span>
          {!originPhaseDistributionIsEmpty ? <ReactEcharts
            option={this._getOption(3)}
            notMerge={true}
            lazyUpdate={true}
          /> : <List />}
        </div>
        <div className="chart-item">
          <span className="title">{this._getHistoryDistributionTitle()}</span>
          {!historyDistributionIsEmpty ? <ReactEcharts
            option={this._getOption(4)}
            notMerge={true}
            lazyUpdate={true}
          /> : <List />}
        </div>
      </div>
    </div>
  }

  _getIsEmpty = (type) => {
    const data = this.props.relaxProps?.[type]?.toJS?.() || []
    let flag = false
    if (data?.length == 0) {
      flag = true
    }
    return flag
  }

  _getHistoryDistributionTitle = () => {
    const { tabType, selectProjectType, customSelectProjectType } = this.props.relaxProps
    let title;
    if (tabType == 1) {
      title = selectProjectType == 0 ? '历史遗留分布' : '标品问题分布'
    } else {
      if (customSelectProjectType == 0) {
        title = '历史遗留分布'
      } else if (customSelectProjectType == 1) {
        title = '标品问题分布'
      } else {
        title = '标品问题/历史遗留分布'
      }
    }
    return title
  }

  // 千位数字分隔符处理
  _thousands = (num) => {
    if (num < 1000) return num;
    if (Number.isInteger(num)) {
      return String(num).replace(/(?!^)(?=(\d{3})+$)/g, ',')
    } else {
      return String(num).replace(/(?!^)(?=(\d{3})+\.)/g, ',')
    }
  }

  // 按数量/占比切换
  _onChangeSeverity = (e) => {
    const { mergeData, getSeverityDistribution, tabType } = this.props.relaxProps
    if (tabType == 1) {
      mergeData({
        projectNumOrRate: e.target.value
      })
    } else {
      mergeData({
        customNumOrRate: e.target.value
      })
    }

    getSeverityDistribution()
  }

  _getOption = (type?) => {
    const { tabType, customNumOrRate, projectNumOrRate } = this.props.relaxProps
    // 1: 严重程度  2:优先级 3:发现阶段 4:历史遗留
    let pieData = this._getPieData(type);
    let option = {
      tooltip: {
        trigger: 'item',
        className: 'bug-analysis-pie-tooltip',
        formatter: function (params) {
          const { color, name, value } = params
          let data;
          if (tabType == 1) {
            data = (projectNumOrRate == 1 && type == 1) ? value + '%' : value
          } else {
            data = (customNumOrRate == 1 && type == 1) ? value + '%' : value
          }
          //样式已在外层设置，结构与echarts的提示组件保持一致
          return `
          <div>
            <div>
              <div>
                <span style="background:${color};border-radius:50%;"></span>
                <span>${name}</span>
                <span>${data}</span>
                <div></div>
              <div>
            <div>
            <div></div>
          </div>
          `
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '70%'],
          data: pieData?.data,
          itemStyle: {
            color: function (color) {
              let colorList = pieData?.color;
              return colorList[color?.dataIndex]
            }
          }
        }
      ]
    };
    return option
  }

  _getPieData = (type?) => {
    const { severityDistribution, priorityDistribution, originPhaseDistribution, historyDistribution, tabType } = this.props.relaxProps
    // 1: 严重程度  2:优先级 3:发现阶段 4:历史遗留
    let pieData = { data: [], color: ['#FF9F4D', '#B8ED72', '#3CD495', '#46BFF2', '#FF6C47'] }
    if (type === 1) {
      // 数组名称替换 echart饼图的数据格式
      if (severityDistribution?.toJS()?.length == 0) {
        return
      }
      let data = [
        { value: this._getPieDataValue(severityDistribution, '严重'), name: '严重' },
        { value: this._getPieDataValue(severityDistribution, '一般'), name: '一般' },
        { value: this._getPieDataValue(severityDistribution, '建议'), name: '建议' },
        { value: this._getPieDataValue(severityDistribution, '提示'), name: '提示' },
        { value: this._getPieDataValue(severityDistribution, '致命'), name: '致命' },
      ]
      pieData['data'] = data
    }
    if (type === 2) {
      if (priorityDistribution?.toJS()?.length == 0) {
        return
      }
      let data = [
        { value: this._getPieDataValue(priorityDistribution, '高'), name: '高' },
        { value: this._getPieDataValue(priorityDistribution, '中'), name: '中' },
        { value: this._getPieDataValue(priorityDistribution, '低'), name: '低' },
        { value: this._getPieDataValue(priorityDistribution, '无关紧要'), name: '无关紧要' },
        { value: this._getPieDataValue(priorityDistribution, '紧急'), name: '紧急' },
      ]
      pieData['data'] = data
    } else if (type === 3) {
      if (originPhaseDistribution?.toJS()?.length == 0) {
        return
      }
      let data = [
        { value: this._getPieDataValue(originPhaseDistribution, '线上运营'), name: '线上运营' },
        { value: this._getPieDataValue(originPhaseDistribution, '系统测试'), name: '系统测试' },
        { value: this._getPieDataValue(originPhaseDistribution, '验收测试'), name: '验收测试' },
      ]
      pieData['data'] = data
      pieData['color'] = ['#FF6C47', '#FF9F4D', '#B8ED72']
    } else if (type === 4) {
      if (historyDistribution?.toJS()?.length == 0) {
        return
      }
      if (tabType == 1) {
        pieData['data'] = this._getHistoryDistributionPieData(historyDistribution?.toJS())
        pieData['color'] = ['#FF6C47', '#FF9F4D']
      } else {
        pieData['data'] = this._getHistoryDistributionPieData(historyDistribution?.toJS())
      }

    }
    return pieData
  }

  _getPieDataValue = (data, type) => {
    return data?.toJS()?.filter((v) => v?.type == type)?.[0]?.value || 0
  }

  _getHistoryDistributionPieData = (data) => {
    const { tabType, bugAnalysisSearch, customSearch} = this.props.relaxProps
    const type1 = bugAnalysisSearch?.toJS()?.projectType;
    const type2 = customSearch?.toJS()?.projectType;
    const projectType = tabType == 1 ? type1 : type2;
    const newData = data?.map?.(item => {
      return {
        value: item?.value,
        name: this._getHistoryTitle(item?.type, projectType)
      }
    });
    if (tabType == 1) {
      return newData
    } else {
      return newData?.filter?.((v) => v?.name !== '标品交付')
    }
  }

  _getHistoryTitle = (type, projectType) => {
    const { customSelectProjectType, selectProjectType, tabType } = this.props.relaxProps
    let title;
    if (tabType == 1) {
      if (selectProjectType == 0) {
        title = type == 1 ? '迭代产生' : '历史遗留'
      } else {
        title = type == 3 ? '定制问题' : '标品问题'
      }
    } else {
      if (customSelectProjectType == null) {
        if (type == 1) {
          title = '迭代产生'
        } else if (type == 2) {
          title = '历史遗留'
        } else if (type == 3) {
          title = '定制问题'
        } else {
          title = '标品问题'
        }
      } else {
        if (projectType == 0) {
          title = type == 1 ? '迭代产生' : '历史遗留'
        } else {
          title = type == 3 ? '定制问题' : '标品问题'
        }
      }
    }
    return title
  }

  _getBugData = () => {
    const { overViewBugData } = this.props.relaxProps
    const { bugTotalNum, invalidNum, toFixNnm, toReturnNum, fixNum, closedNum,
      averageDayFixNum, averageDayAddNum, dealTimelyRate, returnTimelyRate,
      repairQuality
    } = overViewBugData?.toJS()
    let obj = {
      group1: [{
        backgroundImage: "usual-img",
        name: "bug-total",
        label: "缺陷总数",
        data: bugTotalNum || 0,
      },
      {
        backgroundImage: "unusual-img",
        name: "invalidDefect",
        label: "无效缺陷",
        data: invalidNum || 0,
      },
      {
        backgroundImage: "waste-img",
        name: "waitFix",
        label: "待修复总数",
        data: toFixNnm || 0,
      },
      {
        backgroundImage: "flirt-img",
        name: "waitReturn",
        label: "待回归总数",
        data: toReturnNum || 0,
      }],
      group2: [{
        backgroundImage: "work-overtime-img",
        name: "fixed",
        label: "已修复总数",
        data: fixNum || 0,
      },
      {
        backgroundImage: "work-overtime-img",
        name: "closed",
        label: "已关闭总数",
        data: closedNum || 0,
      },
      {
        backgroundImage: "work-overtime-img",
        name: "add",
        label: "处理及时率",
        children: [
          {
            name: 'deal',
            label: "修复",
            isRate: true,
            data: dealTimelyRate || 0
          },
          {
            name: 'returned',
            label: "回归",
            isRate: true,
            data: returnTimelyRate || 0
          }
        ],
      },
      {
        backgroundImage: "work-overtime-img",
        name: "repair",
        label: "修复质量",
        isRate: true,
        data: repairQuality || 0,
      }]
    }
    return obj
  }

  _onChange = (e) => {
    const { mergeData, getSeverityDistribution, tabType, getOverViewWithDistribution } = this.props.relaxProps;
    if (tabType == 1) {
      mergeData({
      demandOptimizationFlag: e.target.checked
    })
    } else {
      mergeData({
        customDemandOptimizationFlag: e.target.checked
      })
    }
    getOverViewWithDistribution()
  }

  _getShow = (isCustomMade) => {
    const {
      overViewSearch,
    } = this.props.relaxProps;
    if (isCustomMade) {
      return overViewSearch?.get('project') ? false : true
    } else {
      return false
    }
  }

  _handleChange = (value, type) => {
    let { changeOverViewSearch, getOverViewWithDistribution, mergeData } = this.props.relaxProps;
    changeOverViewSearch(value, type);
    mergeData({
      attention: false
    })
    setTimeout(() => {
      getOverViewWithDistribution()
    }, 0);
  }
}
export default OverviewChart;
