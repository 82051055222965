import React from "react";
import { Relax } from "plume2";
import { Row, Col, Image, Avatar } from "antd";
import "./index.less";
import moment from "moment";

// 默认头像
import default_avatar_svg from "../../img/default_avatar.svg";
import { noop } from "@utils";

// 首页tabs下内容区域
@Relax
class Content extends React.Component<any, any> {
  static relaxProps = {
    dataType: "dataType",
    popularRecommendation_obj: "popularRecommendation_obj",
    _getPopularRecommendation: noop,
  };

  // 处理 - publishTime
  _handlePublishTime = (time) => {
    if (!time) return time;
    return moment(time).format("YYYY-MM-DD");
  };

  render() {
    const { dataType, popularRecommendation_obj, _getPopularRecommendation } =
      this.props.relaxProps;
    return (
      <div className="skyeye_pc_home_content">
        {/* 热门推荐 */}
        {dataType === "hot" && (
          <div className="skyeye_pc_home_content__list">
            {popularRecommendation_obj?.get("records")?.map?.((item, index) => {
              return (
                <div key={index} className="skyeye_pc_home_content__list--item">
                  <Row className="skyeye_pc_home_content__list--item__title">
                    <Col span={18} style={{ marginTop: '-10px' }}>
                      <a
                        rel="noreferrer"
                        href={item?.get("url")}
                        target="_blank"
                      >
                        {item?.get("title")}
                      </a>
                    </Col>
                    <Col span={6} style={{ marginTop: "-4px" }}>
                      <Avatar
                        size={32}
                        src={
                          <Image
                            preview={false}
                            src={item?.get("userHeadUrl") || default_avatar_svg}
                            width={ 32 }
                            height={ 32 }
                          />
                        }
                      />
                      <div>
                        <p>
                          {item?.get("userName") ||
                            `用户${item?.get("userId")}`}
                        </p>
                        <span>
                          {this._handlePublishTime(item?.get("publishTime"))}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="skyeye_pc_home_content__list--item__content">
                    <Col span={24} style={{ marginTop: '-6px' }}>
                      <a
                        rel="noreferrer"
                        href={item?.get("url")}
                        target="_blank"
                      >
                        {item?.get("content")}
                      </a>
                    </Col>
                  </Row>
                </div>
              );
            })}
            {popularRecommendation_obj?.records?.length &&
              popularRecommendation_obj?.current <
                popularRecommendation_obj?.pages && (
                <div
                  className="skyeye_pc_home_content__list--more"
                  onClick={() => {
                    if (!popularRecommendation_obj?.current) return;
                    _getPopularRecommendation(
                      ++popularRecommendation_obj.current
                    );
                  }}
                >
                  查看更多
                </div>
              )}
          </div>
        )}
        {/* 待办事项 */}
        {dataType === "to_do_list" && <></>}
      </div>
    );
  }
}
export default Content;
