import React from "react";
import { Alert } from 'antd';
import up from '../../../../img/up.png';
import down from '../../../../img/down.png';

class Tips extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { isOpen } = this.state;
    return (
      <div className="bug_fixes_tips">
        <div className="bug_fixes_tips_title">
          转测中缺陷修复数据
        </div>
        <Alert
          message=""
          description={
            <>
              <div className="data-description" onClick={() => this._onClick('open') as any} style={{ display: isOpen ? 'none' : '' }}>
                <span>展示数据说明</span>
                <img src={down} width={16} height={16} />
              </div>
              <div style={{ display: isOpen ? '' : 'none', paddingLeft: '12px', paddingTop: '12px' }}>
                <ul><li style={{ color: "#FF6600", fontSize: '14px' }}>基线产品:</li></ul>
                <ul style={{ color: '#333333', fontSize: '12px' }}>
                  <li >
                    1、开发人员在1个工作日内处理缺陷数占比80%算合格（95%为优秀）
                  </li>
                  <li>
                    2、开发人员“已解决”缺陷回归一次通过的占比95%算合格（100%为优秀）
                  </li>
                  <li>3、测试人员在1个工作日内回归验证“已解决”状态缺陷占比95%算合格（100%为优秀）</li>
                </ul>
                <ul><li style={{ color: "#FF6600", fontSize: '14px', marginTop: '4px' }}>定制项目:</li></ul>
                <ul style={{ color: '#333333', fontSize: '12px' }}>
                  <li>
                    1、开发人员在1个工作日内处理缺陷数占比70%算合格（90%为优秀）
                  </li>
                  <li>
                    2、开发人员“已解决”缺陷回归一次通过的占比90%算合格（100%为优秀）
                  </li>
                  <li>3、测试人员在1个工作日内回归验证“已解决”状态缺陷占比90%算合格（100%为优秀）</li>
                </ul>
                <div className="data-description" onClick={() => this._onClick('close') as any} style={{ display: isOpen ? '' : 'none', marginTop: '10px', marginBottom: '8px' }}>
                  <span>收起</span>
                  <img src={up} width={16} height={16} />
                </div>
              </div>

            </>
          }
        />
      </div>
    );
  }

  _onClick = (type) => {
    this.setState({
      isOpen: type === 'open' ? true : false
    })
  }

}
export default Tips;
