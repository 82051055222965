import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      // 个人信息
      userInfoVO: {},
      // 是否显示头像Modal
      clicked: false,
      // 是否显示个人信息Modal
      hovered: false,
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
