import { Store } from "plume2";
import moment from "moment";
import lodash from 'lodash';
import Actor from "./actor/actor";
import * as webapi from "./../../webapi";
import { 
  fetchProjectListPage, fetchProjectListIterationDetail, 
  fetchProjectListProjectDetail, fetchProjectListTaskList,
} from "./webapi";
import { promiseAllSettled } from "@utils/kit";
import { 
  IActor, IFetchProjectListIterationDetail, 
  IFetchProjectListProjectDetail, IFetchProjectListTaskList,
} from "./type";

export default class AppStore extends Store {
  bindActor() {
    return [new Actor()];
  }

  /**
   * 开启 或 关闭 - loading
   * @param bol 
   */
  changeLoadingFn = (bol: boolean) => {
    bol = Boolean(bol);
    if (!bol) {
      setTimeout(() => {
        this.dispatch("projectListActor:all", { 
          key: 'isSpinning', 
          value: false, 
        });
      }, 666);
    } else {
      this.dispatch("projectListActor:all", { 
        key: 'isSpinning', 
        value: true, 
      });
    }
  };

  /**
   * 初始化
   */
  init = async () => {
    const [projectTypeList, belongBaselineList] = await promiseAllSettled([
      webapi.getProjectTypeList({
        function_prefix: "f_project_list",
      }),
      webapi.getBelongBaselineList({
        function_prefix: "f_project_list",
      }),
    ]);

    const searchParams = JSON.parse(localStorage.getItem("project-list-searchParams") || '{}');
    const projectType = searchParams?.projectType ?? projectTypeList?.context?.[0]?.value;
    const belongBaseLine = searchParams?.belongBaseline ?? belongBaselineList?.context?.[0]?.value;
    const startYear = searchParams?.startYear ?? new Date().getFullYear().toString();
    const iterationId = searchParams?.iterationId;
    const projectId = searchParams?.projectId;
    const activeTabKey = searchParams?.activeTabKey;
    // 销毁临时缓存的数据
    localStorage.removeItem("project-list-searchParams");


    this.fetchGetIterationList({ projectType });
    this.dispatch("projectListActor:all", [
      { key: 'projectTypeList', value: projectTypeList?.context || [], },
      { key: 'belongBaselineList', value: belongBaselineList?.context || [], },
      { key: 'projectType', value: projectType, },
      { key: 'belongBaseLine', value: belongBaseLine, },
      { key: 'isUpdateForm', value: true, },
      { key: 'iterationId', value: activeTabKey, },
      { key: 'projectId', value: projectId, },
      { key: 'time', value: startYear, },
    ]);

    const params = { projectType, projectId};
    if(![0, 1].includes(projectType)) return;
    if(projectType === 1) {
      this.fetchGetProjectList(params);
    }
    this.fetchGetIterationList(params);

    setTimeout(() => {
      this.fetchProjectListPageFn({
        startYear: startYear,
        belongBaseLine: belongBaseLine,
        iterationId: iterationId,
        pageNum: 1,
        pageSize: 10,
        projectId: projectId,
        projectType,
      });
    }, 0);
  };

  /**
   * 查询 - “项目” 选项数据
   * @param params 
   */
  fetchGetProjectList = async (params) => {
    if(!params || !Object.keys(params).length) return;

    const { projectType, } = params;
    if(![1].includes(projectType)) return;

    const { time, belongBaseLine, pageSize, pageNum } = this.state()?.toJS?.() as IActor;
    const result = await webapi.getProjectList({
      functionCode: 2,
      time,
      belongBaseLine,
      pageNum,
      pageSize,
      ...params,
    });

    this.dispatch("projectListActor:all", { 
      key: 'projectList', 
      value: result?.context || [], 
    });
  }

  /**
   * 查询 - “迭代” 选项数据
   * @param projectType 
   */
  fetchGetIterationList = async (params) => {
    if(!params || !Object.keys(params).length) return;

    const { projectType, } = params;
    if(![0, 1].includes(projectType)) return;

    const { time, belongBaseLine, pageSize, pageNum} = this.state()?.toJS?.() as IActor;
    const result = await webapi.getIterationList({
      functionCode: 2,
      time,
      belongBaseLine,
      projectId: null,
      onlyTested: false,
      pageSize,
      pageNum,
      ...params,
    });

    this.dispatch("projectListActor:all", { 
      key: 'iterationList', 
      value: result?.context || [], 
    });
  }

  /**
   * 监听 - 表单字段值的变化
   * @param params 
   */
  onFormChange = (params) => {
    if(!params || !Object.keys(params).length) return;

    const { projectType, } = params;
    if(![0, 1].includes(projectType)) return;

    this.fetchGetIterationList(params);
    if(projectType === 1) {
      delete params?.['projectId'];
      this.fetchGetProjectList(params);
    }
  }

  /**
   * 监听 - 表单字段值的变化
   * @param changedValues 
   * @param allValues 
   */
  onValuesChange = (changedValues, allValues) => {
    changedValues = lodash.cloneDeep(changedValues);
    allValues = lodash.cloneDeep(allValues);

    allValues['time'] = allValues['time'] ? moment?.(allValues['time'])?.format?.("YYYY") : null;
    delete allValues['iterationId'];

    if(!allValues?.projectId) {
      delete allValues['projectId'];
    }

    if(!('iterationId' in changedValues)) {
      if(!('projectId' in changedValues) && allValues?.projectType === 1) {
        this.onFormChange?.(allValues);
      }else {
        this.fetchGetIterationList(allValues);
      }
    }
  }

  /**
   * 查询 - 项目列表 - 操作
   * @param params 
   * @param isMerge 
   * @returns 
   */
  fetchProjectListPageFn = async (params, isMerge = true) => {
    const { time, fetchProjectListPageParams, } = this.state()?.toJS?.() as IActor;
    const params_new = {
      time,
      ...(isMerge ? fetchProjectListPageParams : {}),
      ...params,
    };

    const result = await fetchProjectListPage(params_new);

    let projectListDataSource = result?.context?.page?.records || [];
    if(Array.isArray(projectListDataSource)) {
      projectListDataSource = projectListDataSource.map(item => {
        return {
          ...item,
          iterationId: params_new?.iterationId || null,
        }
      });
    }
    this.dispatch("projectListActor:all", [
      { 
        key: 'fetchProjectListPageParams', 
        value: params_new, 
      },
      { 
        key: 'projectListTitle', 
        value: {
          0: "基线产品-迭代列表",
          1: "定制项目-项目列表",
        }[params_new?.projectType] || null, 
      },
      { 
        key: 'projectListDataSource', 
        value: projectListDataSource, 
      },
      { 
        key: 'projectListTotal', 
        value: result?.context?.page?.total ?? 0, 
      },
      { 
        key: 'iterationDetailsTitle', 
        value: {
          0: "基线产品",
          1: "定制项目",
        }[params_new?.projectType] || null, 
      },
      {
        key: 'pageSize',
        value: params.pageSize,
      },
      {
        key: 'pageNum',
        value: params.pageNum,
      },
    ]);

    setTimeout(() => {
      // 模拟点击表格行 - 操作
      this.onRowClick(projectListDataSource?.[0] || {});
    }, 0);

  }

  /**
   * 监听分页器 - 页码、页数、翻页的变化
   * @param pageNum 
   * @param pageSize 
   */
  onPaginationChange = (pageNum, pageSize) => {
    this.fetchProjectListPageFn({
      pageNum,
      pageSize,
    });
  }
  
  /**
   * 查询 - 迭代明细、里程碑清单 - 操作
   * @param params 
   * @returns 
   */
  fetchProjectListIterationDetailFn = async (params: IFetchProjectListIterationDetail) => {
    if(!params || !Object.keys(params).length) return;

    const result = await fetchProjectListIterationDetail(params);
    return result?.context?.iterationVOS?.[0] || {};
  }
  
  /**
   * 查询 - 定制项目-迭代明细 - 操作
   * @param params 
   * @param iterationId 
   * @returns 
   */
  fetchProjectListProjectDetailFn = async (params: IFetchProjectListProjectDetail, iterationId) => {
    if(!params || !Object.keys(params).length) return;

    const [result01, result02] = await promiseAllSettled([
      fetchProjectListProjectDetail(params),
      fetchProjectListIterationDetail({
        ...params,
        iterationId,
      }),
    ]);

    let { iterationVOS } = result02?.context || {};
    if(Array.isArray(iterationVOS)) {
      iterationVOS = iterationVOS.map(item => {
        return {
          ...item,
          ...params,
        };
      })
    }

    return {
      projectDetailMap: result01?.context || {},
      iterationVOS: iterationVOS || [],
    };
  }

  /**
   * 点击行，查看表格行数据 - 操作
   * @param record 
   */
  onRowClick = async (record) => {
    const { fetchProjectListPageParams, activeTabKey} = this.state()?.toJS?.() as IActor;
    const { projectType, belongBaseLine, } = fetchProjectListPageParams || {};
    if(![0, 1].includes(projectType)) return;

    let iterationDetailsMap = {
      mileStoneList: [],
    };
    let projectId = null;
    let milestoneList = [];
    if(record && Object.keys(record).length) {
      if(projectType === 0) {
        projectId = record?.projectId;
        iterationDetailsMap = await this.fetchProjectListIterationDetailFn({
          iterationId: record?.id,
          projectId,
          projectType,
          belongBaseLine,
        });
        milestoneList = iterationDetailsMap?.mileStoneList || [];
        this.dispatch("projectListActor:all", [
          {
            key: 'activeRowKey',
            value: record?.id,
          },
        ]);
      } else {
        projectId = record?.id;
        const { iterationVOS, projectDetailMap } = await this.fetchProjectListProjectDetailFn({
          projectId,
          projectType,
          belongBaseLine,
        }, record?.iterationId);
        iterationDetailsMap = projectDetailMap;
        milestoneList = iterationVOS?.[0]?.mileStoneList || [];

        this.dispatch("projectListActor:all", [
          { 
            key: 'iterationDetailsList', 
            value: iterationVOS, 
          },
          {
            key: 'activeTabKey',
            value: iterationVOS?.[0]?.id,
          },
        ]);
      }
    }

    this.dispatch("projectListActor:all", [
      { 
        key: 'milestoneList', 
        value: milestoneList, 
      },
      { 
        key: 'iterationDetailsMap', 
        value: {
          ...iterationDetailsMap,
          projectId,
        }, 
      }
    ]);

    setTimeout(() => {
      // 模拟点击选择里程牌清单 - 操作
      this.onMilestoneListItemClick(milestoneList?.[0] || {});
    }, 0);
  }

  /**
   * 查询 - 里程碑任务 - 操作
   * @param params 
   * @returns 
   */
  fetchProjectListTaskListFn = async (params: IFetchProjectListTaskList) => {
    if(!params || !Object.keys(params).length) return;

    const result = await fetchProjectListTaskList(params);
    return result?.context || {};
  }

  /**
   * 里程碑清单 - 选择清单 - 操作
   * @param record 
   */
  onMilestoneListItemClick = async (record) => {
    const { fetchProjectListPageParams, iterationDetailsMap, } = this.state()?.toJS?.() as IActor;
    const { projectType, } = fetchProjectListPageParams || {};
    const { projectId, } = iterationDetailsMap || {};
    const result = await this.fetchProjectListTaskListFn({
      iterationId: record?.iterationId,
      mileStoneId: record?.id,
      projectId,
      projectType,
    });

    this.dispatch("projectListActor:all", [
      { 
        key: 'milestoneListDetailsMap', 
        value: record, 
      },
      { 
        key: 'milestoneDetailsColumn', 
        value: result?.dateList || [], 
      },
      { 
        key: 'milestoneDetailsDataSource', 
        value: result?.taskDetailVOList || [], 
      },
    ]);
    
    // 关闭loading
    this.changeLoadingFn(false);
  }

  /**
   * 监听 - Checkbox.Group变化
   * @param checkedValue 
   */
  onCheckboxChange = (checkedValue) => {
    this.dispatch("projectListActor:all", { 
      key: 'milestoneDetailsCheckboxColumn', 
      value: checkedValue, 
    });
  }

  /**
   * 监听 - Tabs变化
   * @param activeTabKey 
   */
  onTabsChange = (activeTabKey) => {
    const { iterationDetailsList, } = this.state()?.toJS?.() as IActor;
    
    // 开启loading
    this.changeLoadingFn(true);
    const record = iterationDetailsList?.find?.(item => item.id === Number(activeTabKey));
    this.onMilestoneListItemClick?.(record?.mileStoneList?.[0] || {});

    this.dispatch("projectListActor:all", {
      key: 'activeTabKey',
      value: activeTabKey,
    });
  }

}
