import { msg, Store } from "plume2";
import MainActor from "./actor/main";
import { SUCCESS_CODE } from "@utils";
import * as webApi from "./webapi";
import { fromJS } from "immutable";
import { message } from "antd";
import moment from "moment";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  /**
   * 改值
   */
  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 初始化
   */
  init = () => {
    this.bugAnalysisSerchInit();
  };

  /**
   * 搜索条件初始化
   */
  bugAnalysisSerchInit = async () => {
    let selectParam = {
      function_prefix: "f_bug_analyze",
    };
    const pushSearch = this.state().get("pushSearch");
    const {
      belongBaseline: pushBelongBaseline,
      projectType: pushProjectType,
      iterationId: pushIterationId,
      projectId: pushProjectId,
      startYear: pushStartYear
    } = pushSearch?.toJS?.() || {};
    await Promise.all([
      webApi.getProjectTypeList(selectParam),
      webApi.getBelongBaselineList(selectParam),
    ]).then(async (firstResults) => {
      let firstProjectType = firstResults?.[0]?.context?.[0]?.value;
      let firstbelongBaselinee = firstResults?.[1]?.context?.[0]?.value;
      let conditions = {
        startYear: pushStartYear || new Date().getFullYear().toString(),
        projectType: parseInt(pushProjectType) || firstProjectType,
        belongBaseline: parseInt(pushBelongBaseline) || firstbelongBaselinee,
      };

      let customSearchConditions = {
        projectType: null,
        belongBaseline: null,
      };
      let customBelongBaseline = [
        { label: "全部", value: null },
        { label: "SBC", value: 0 },
        { label: "零售线", value: 1 },
        { label: "分销线", value: 2 },
        { label: "业务中台", value: 3 },
        { label: "技术中台", value: 4 },
      ];
      let customProjectType = [
        { label: "全部", value: null },
        { label: "基线产品", value: 0 },
        { label: "定制项目", value: 1 },
      ];
      let customSearchDataConditionsData = {
        projectType: customProjectType,
        belongBaseline: customBelongBaseline,
      };
      let param = {
        startYear: new Date().getFullYear().toString(),
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        functionCode: 3,
      };
      if (firstProjectType == 0) {
        param["startYear"] = null;
      }
      if (firstProjectType == 0) {
        const res = await webApi.getIterationList({
          projectId: null,
          startYear: new Date().getFullYear().toString(),
          projectType: 0,
          belongBaseline: firstbelongBaselinee,
          onlyTested: false,
          functionCode: 3,
        });
        if (res.code === SUCCESS_CODE) {
          let conditionsData = {
            projectType: firstResults[0].context,
            belongBaseline: firstResults[1].context,
            iteration: res.context,
            project: null,
          };
          this.mergeData({
            bugAnalysisSearchData: conditionsData,
            bugAnalysisSearch: conditions,
            customSearch: customSearchConditions,
            customSearchData: customSearchDataConditionsData,
            selectProjectType: firstProjectType,
            customSelectProjectType: null,
          });
        } else {
          message.error(res.message);
        }
      } else {
        await Promise.all([webApi.getProjectList(param)]).then(
          (secondResults) => {
            let conditionsData = {
              projectType: firstResults[0].context,
              belongBaseline: firstResults[1].context,
              project: secondResults[0].context,
            };
            this.mergeData({
              bugAnalysisSearchData: conditionsData,
              bugAnalysisSearch: conditions,
              customSearch: customSearchConditions,
              customSearchData: customSearchDataConditionsData,
            });
          }
        );
      }
    });
    this.getProjectPageList(true);
    setTimeout(() => {
      const rowId = pushProjectType === '0' ? parseInt(pushIterationId) : parseInt(pushProjectId);
      if(pushBelongBaseline){
        this.mergeData({
          rowId: rowId,
          attention: false,
        })
        // debugger
        const dataSource = this.state().get("projectPageList")?.toJS().records;
        const record = dataSource?.filter((c) => c.id === rowId)[0];
        this.handleOverviewSearchData(record, true)
        const scrollDom = document.getElementById('bug-analysis-overview-position');
        scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }, 600);
  };

  /**
   *  获取项目列表 handleOverviewSearchFlag: 重置概览搜索条件标识
   */
  getProjectPageList = async (
    handleOverviewSearchFlag,
    pageSize1?,
    pageNum1?
  ) => {
    this.mergeData({
      projectPageLoading: true,
      forUserPageLoading: true,
      forModulePageLoading: true,
      forReasonPageLoading: true,
      forYypePageLoading: true,
      forFixUserPageLoading: true,
      forNewPageLoading: true,
      forFixPageLoading: true,
    });
    const { project, iteration, belongBaseline, projectType, startYear, pageSize, pageNum } =
      this.state().get("bugAnalysisSearch")?.toJS?.() || {};
    const onlyTested = this.state().get("onlyTested");
    const param = {
      projectType,
      belongBaseline: belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      startYear: Number(startYear),
      pageSize: pageSize1 || pageSize,
      pageNum: pageNum1 || 1,
      onlyTested: onlyTested == 0 ? false : true,
    };
    const bugAnalysisSearch =
    this.state().get("bugAnalysisSearch")?.toJS?.() || {};
    bugAnalysisSearch.pageSize = pageSize1 || pageSize;
    bugAnalysisSearch.pageNum = pageNum1 || pageNum;
    this.mergeData({
      bugAnalysisSearch: bugAnalysisSearch,
    });
    const res = await webApi.getProjectPage(param);
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        projectPageList: res?.context,
      });
      // 初始化以及搜索 处理列表返回的第一条数据(设置概览搜索框的数据)
      if (handleOverviewSearchFlag == true) {
        // onlyTested 0:查看全部 1:转测中
        if (onlyTested == 0) {
          if (res?.context?.records.length == 0) {
            this.mergeData({
              severityDistribution: [], // 严重程度分布
              priorityDistribution: [], // 优先级分布
              originPhaseDistribution: [], // 发现阶段分布
              historyDistribution: [], // 历史遗留分布
              forUser: {}, // 按开发人员
              forFixUser: {}, // 按修复人员
              forModule: {}, // 按模块
              forReason: {}, // 按原因
              forYype: {}, // 按类型
            });
          } else {
            this.handleOverviewSearchData(res?.context?.records[0]);
          }
        } else {
          // tab切是转测中 底部的搜索条件与头部搜索保持一致
          this.handleOverviewSearchData({});
        }
      }
    } else {
      message.error(res.message);
    }
    setTimeout(() => {
      this.mergeData({
        projectPageLoading: false,
        forUserPageLoading: false,
        forFixUserPageLoading: false,
        forModulePageLoading: false,
        forReasonPageLoading: false,
        forYypePageLoading: false,
        forNewPageLoading: false,
        forFixPageLoading: false,
      });
    }, 0);
  };

  /**
   *  处理概览搜索项数据 isSelect 点击列表操作
   */
  handleOverviewSearchData = async (data, isSelect?) => {
    const search = this.state().get("bugAnalysisSearch");
    const searchData = this.state().get("bugAnalysisSearchData");
    const isCustomMade = search?.get("projectType") == 1 ? true : false;
    const { startYear, belongBaseline, projectType } = search?.toJS?.();
    const searchProjectId = search?.get("project") || null;
    const searchIterationId = search?.get("iteration") || null;
    const projectPageList = this.state()
      .get("projectPageList")
      ?.toJS()?.records;
    let newConditions = { ...search.toJS?.() };
    let newConditionsData = { ...searchData?.toJS?.() };

    if (isSelect) {
      if (isCustomMade) {
        let newArr = [];
        projectPageList.forEach((value, index) => {
          newArr[index] = value;
          newArr[index].children = value?.children?.filter?.(
            (item) => item.id === data.id
          );
        });
        // 没有迭代信息的定制项目
        let id1 = newArr.filter((i) => i.id == data.id)?.[0]?.id;
        // 有多个迭代信息的定制项目
        let id2 = newArr.filter((v) => v?.children?.length == 1)?.[0]?.id;
        let projectId = id1 ? id1 : id2;
        let iterationId = id1 ? null : data.id;
        newConditions.project = projectId || searchProjectId;
        newConditions.iteration = iterationId || searchIterationId;
      } else {
        newConditions.iteration = data?.id || searchIterationId;
        newConditions.project = searchProjectId;
      }
    } else {
      if (isCustomMade) {
        newConditions.project = data?.id || searchProjectId;
        newConditions.iteration = data?.children?.[0]?.id || searchIterationId;
      } else {
        newConditions.iteration = data?.id || searchIterationId;
        newConditions.project = searchProjectId;
      }
    }
    let { project } = newConditions;
    let param = {
      onlyTested: false,
      belongBaseline,
      functionCode: 3,
      projectId: project,
    };
    if (!isCustomMade) {
      param["startYear"] = startYear;
      param["projectType"] = projectType;
    }
    this.mergeData({ overViewSearch: newConditions });
    const res = await webApi.getIterationList(param);
    if (res.code === SUCCESS_CODE) {
      newConditionsData.iteration = res.context;
      this.mergeData({ overViewSearchData: newConditionsData });
    } else {
      message.error(res.message);
    }
    // 获取概览和分布图
    this.getOverViewBugData();
    this.getOverViewChartData();
    this.getDistributionChart();
    this.getDistributionList();
    this.getNewFixLineChart();
    this.getNewFixList()
  };

  /**
   * 搜索条件变更
   */
  changeSearch = async (value, type) => {
    const bugAnalysisSearch = this.state().get("bugAnalysisSearch");
    const bugAnalysisSearchData = this.state().get("bugAnalysisSearchData");
    let newConditions = { ...bugAnalysisSearch?.toJS?.() };
    let newConditionsData = { ...bugAnalysisSearchData?.toJS?.() };
    newConditions[type] = value;
    if (type !== "iteration") {
      newConditions.iteration = null;
      if (type !== "project") {
        newConditions.project = null;
      }
    }
    this.mergeData({ bugAnalysisSearch: newConditions });
    let { project, projectType, startYear, belongBaseline } = newConditions;

    if (projectType == 0) {
      let param = {
        projectId: project || null,
        startYear: startYear || null,
        projectType: 0,
        belongBaseline,
        onlyTested: false,
        functionCode: 3,
      };
      const res = await webApi.getIterationList(param);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({
          bugAnalysisSearchData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    } else {
      let param = {
        projectId: project || null,
        belongBaseline,
        projectType: 1,
        startYear: startYear || null,
        onlyTested: false,
        functionCode: 3,
      };
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 3,
      };
      const res = await webApi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({
          bugAnalysisSearchData: newConditionsData,
        });
      }
      const iterationListRes = await webApi.getIterationList(param);
      if (iterationListRes.code === SUCCESS_CODE) {
        newConditionsData.iteration = iterationListRes.context;
        this.mergeData({
          bugAnalysisSearchData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    }
  };

  /**
   * 概览的搜索条件变更
   */

  changeOverViewSearch = async (value, type) => {
    const overViewSearch = this.state().get("overViewSearch");
    const overViewSearchData = this.state().get("overViewSearchData");
    let newConditions = { ...overViewSearch?.toJS?.() };
    let newConditionsData = { ...overViewSearchData?.toJS?.() };
    newConditions[type] = value;
    if (type !== "iteration") {
      newConditions.iteration = null;
      if (type !== "project") {
        newConditions.project = null;
      }
    }
    this.mergeData({ overViewSearch: newConditions });
    let { project } = newConditions;
    if (type === "project") {
      const res = await webApi.getIterationList({
        projectId: project || null,
        onlyTested: false,
        functionCode: 3,
      });
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({ overViewSearchData: newConditionsData });
      }
    } else {
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 3,
      };
      const res = await webApi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({ overViewSearchData: newConditionsData });
      }
    }
  };

  /**
   * 自定义搜索变更
   */
  changeCustomSearch = (value, type) => {
    const customSearch = this.state().get("customSearch");
    let newConditions = { ...customSearch?.toJS?.() };
    newConditions[type] = value;
    this.mergeData({ customSearch: newConditions });
  };

  // 列表点击，渲染概览
  renderOverView = async (record) => {
    const styleName = "click-row-bgc";
    // 设置列表样式的styleName与id
    this.mergeData({ rowId: record?.projectId || record?.iterationId });
    this.mergeData({ clickRowClassName: styleName });
    this.handleOverviewSearchData(record);
    setTimeout(() => {
      this.getOverViewChartData();
      this.getDistributionChart();
    }, 600);
  };

  /**
   * 获取概览饼图数据
   */
  getOverViewChartData = async () => {
    let param = this.getOverViewParam();
    let projectNumOrRate = this.state().get("projectNumOrRate");
    let customNumOrRate = this.state().get("customNumOrRate");
    let tabType = this.state().get("tabType");
    let numOrRate;
    if (tabType == 1) {
      numOrRate = projectNumOrRate;
    } else {
      numOrRate = customNumOrRate;
    }
    let query = [
      //严重程度
      webApi.getSeverityDistribution({ numOrRate, ...param }),
      //优先级
      webApi.getPriorityDistributionn(param),
      //发现阶段
      webApi.getOriginPhaseDistribution(param),
      //历史遗留
      webApi.getHistoryDistribution(param),
    ];
    await Promise.all(query).then((results) => {
      this.mergeData({
        severityDistribution: results?.[0]?.context?.severitDistributionList,
        priorityDistribution: results?.[1]?.context?.priorityDistributionList,
        originPhaseDistribution:
          results?.[2]?.context?.originPhaseDistributionList,
        historyDistribution: results?.[3]?.context?.historyDistributionList,
      });
    });
  };

  getOverViewBugData = async () => {
    let param = this.getOverViewParam();
    const res = await webApi.getOverViewBugData(param);

    if(typeof param?.iterationId === 'number') {
      this.fnAnalyzePushExpireTime({ localIterationId: param?.iterationId });
    }

    this.mergeData({
      overViewBugData: res,
    });
  };

  /**
   * 获取概览参数（数量+环形图）
   */
  getOverViewParam = (isDistribution?) => {
    const overViewSearch = this.state().get("overViewSearch");
    const { project, iteration } = overViewSearch?.toJS?.() || {};
    const baseLine1 = this.state()
      .get("bugAnalysisSearch")
      ?.toJS()?.belongBaseline;
    const baseLine2 = this.state().get("customSearch")?.toJS()?.belongBaseline;
    const type1 = this.state().get("bugAnalysisSearch")?.toJS()?.projectType;
    const type2 = this.state().get("customSearch")?.toJS()?.projectType;
    const tabType = this.state().get("tabType");
    const startDate = tabType == 2 ? this.state().get("startDate") : null;
    const endDate = tabType == 2 ? this.state().get("endDate") : null;
    const iterationId = tabType == 1 ? iteration : null;
    const projectId = tabType == 1 ? project : null;
    const belongBaseLine = tabType == 1 ? baseLine1 : baseLine2;
    const projectType = tabType == 1 ? type1 : type2;
    const startYear =
      tabType == 1
        ? this.state().get("bugAnalysisSearch")?.toJS()?.startYear
        : null;
    const demandOptimizationFlag =
      tabType == 1
        ? this.state().get("demandOptimizationFlag")
        : this.state().get("customDemandOptimizationFlag");
    let param = {
      demandOptimizationFlag: demandOptimizationFlag,
      belongBaseline: belongBaseLine,
      startDate: startDate,
      endDate: endDate,
      projectId: projectId,
      iterationId: iterationId,
      projectType: projectType,
      startYear,
    };
    return param;
  };

  /**
   * 获取各个柱状图数据
   */
  getDistributionChart = async () => {
    let param = this.getDistributionParam(false);
    let query = [
      //按开发人员
      webApi.getForUserChart(param),
      //按缺陷类型
      webApi.getForYypeChart(param),
      //按缺陷原因
      webApi.getForReasonChart(param),
      //按模块
      webApi.getForModuleChart(param),
      //按修复人员
      webApi.getForFixUserChart(param)
    ];
    await Promise.all(query).then((results) => {
      this.mergeData({
        forUser: results?.[0]?.context,
        forYype: results?.[1]?.context,
        forReason: results?.[2]?.context,
        forModule: results?.[3]?.context,
        forFixUser: results?.[4]?.context,
      });
    });
  };

  /**
   * 参数获取(分布柱状图+列表)
   */
  getDistributionParam = (isList, pageNum?, pageSize?) => {
    let param = this.getOverViewParam(true);
    // delete param["startYear"];
    if (isList) {
      param["pageNum"] = pageNum || this.state().get("forPageNum");
      param["pageSize"] = pageSize || this.state().get("forPageSize");
    }
    return param;
  };

  /**
   * 获取各个分布列表数据
   */
  getDistributionList = async (pageNum?, pageSize?) => {
    this.mergeData({
      forUserPageLoading: true,
      forModulePageLoading: true,
      forReasonPageLoading: true,
      forYypePageLoading: true,
      forFixUserPageLoading: true,
      forPageNum: pageNum || this.state().get("forPageNum"),
      forPageSize: pageSize || this.state().get("forPageSize"),
    });
    const param = this.getDistributionParam(true, pageNum || this.state().get("forPageNum"), pageSize || this.state().get("forPageSize"));
    const tabType = this.state().get("tabType");
    const tab =
      tabType == 1
        ? this.state().get("scoreType")
        : this.state().get("customScoreType");
    let res = { context: {}, code: "", message: "" };
    if (tab == 1) {
      res = await webApi.getForUserPage(param);
    } else if (tab == 2) {
      res = await webApi.getForYypePage(param);
    } else if (tab == 3) {
      res = await webApi.getForReasonPgae(param);
    } else if(tab == 4){
      res = await webApi.getForModulePage(param);
    } else if(tab == 5){
      res = await webApi.getForFixUserPage(param);
    }
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        forUserPage: res?.context || {},
        forModulePage: res?.context || {},
        forReasonPage: res?.context || {},
        forYypePage: res?.context || {},
        forFixUserPage: res?.context || {},
      });
    } else {
      message.error(res?.message);
    }
    setTimeout(() => {
      this.mergeData({
        forUserPageLoading: false,
        forModulePageLoading: false,
        forReasonPageLoading: false,
        forYypePageLoading: false,
        forFixUserPageLoading: false,
      });
    }, 0);
  };

  // 搜索
  search = (isReset?) => {
    // 清空数据
    const tabType = this.state().get("tabType");
    const selectProjectType = this.state()
      .get("bugAnalysisSearch")
      ?.toJS?.()?.projectType;
    const customSelectProjectType = this.state()
      .get("customSearch")
      ?.toJS?.()?.projectType;
    if (tabType == 1) {
      this.mergeData({
        selectProjectType: selectProjectType,
      });
    } else {
      this.mergeData({
        customSelectProjectType: customSelectProjectType,
      });
    }
    if (isReset) {
      this.mergeData({
        rowId: null,
        projectPageList: {},
        forUserPage: {}, // 按开发人员列表
        forFixUserPage: {}, // 按修复人员列表
        forModulePage: {}, // 按模块列表
        forReasonPage: {}, // 按原因列表
        forYypePage: {}, // 按类型列表
        attention:  false,
      });
    }
    this.tabChange(Number(tabType));
  };

  // 项目/迭代列表 自定义 tab切换处理
  tabChange = (tab) => {
    if (tab == 1) {
      this.getProjectPageList(true);
    } else {
      this.getOverViewWithDistribution();
    }
  };

  // 获取概览+按...分布数据
  getOverViewWithDistribution = () => {
    // 概览数量
    this.getOverViewBugData();
    // 概览环形图
    this.getOverViewChartData();
    // 分布柱状图
    this.getDistributionChart();
    // 分布列表
    this.getDistributionList();
    const tabType = this.state().get("tabType");
    if(tabType == 1){
      this.getNewFixList();
      this.getNewFixLineChart();
    }
  };

  /**
   * 严重程度分布
   */
  getSeverityDistribution = async () => {
    let param = this.getOverViewParam();
    let projectNumOrRate = this.state().get("projectNumOrRate");
    let customNumOrRate = this.state().get("customNumOrRate");
    let tabType = this.state().get("tabType");
    let numOrRate;
    if (tabType == 1) {
      numOrRate = projectNumOrRate;
    } else {
      numOrRate = customNumOrRate;
    }
    param["numOrRate"] = numOrRate;
    const res = await webApi.getSeverityDistribution(param);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        severityDistribution: res.context.severitDistributionList,
      });
    } else {
      message.error(res.message);
    }
  };

  /**
   * 数据是否为空
   */
  getIsEmpty = (type) => {
    const tabType = this.state().get("tabType");
    const tab =
      tabType == 1
        ? this.state().get("scoreType")
        : this.state().get("customScoreType");
    const userData =
      type == "bugAnalyzeDataS"
        ? this.state().get("forUser")
        : this.state().get("forUserPage");
    const typeData =
      type == "bugAnalyzeDataS"
        ? this.state().get("forYype")
        : this.state().get("forYypePage");
    const reasonData =
      type == "bugAnalyzeDataS"
        ? this.state().get("forReason")
        : this.state().get("forReasonPage");
    const moduleData =
      type == "bugAnalyzeDataS"
        ? this.state().get("forModule")
        : this.state().get("forModulePage");
    const fixUserData =
        type == "bugAnalyzeDataS"
            ? this.state().get("forFixUser")
            : this.state().get("forFixUserPage");
    let data;
    if (tab == 1) {
      data = userData?.toJS()?.[type] || [];
    } else if (tab == 2) {
      data = typeData?.toJS()?.[type] || [];
    } else if (tab == 3) {
      data = reasonData?.toJS()?.[type] || [];
    } else if (tab == 4){
      data = moduleData?.toJS()?.[type] || [];
    } else if (tab == 5){
      data = fixUserData?.toJS()?.[type] || [];
    }
    let flag = false;
    if (data?.length == 0) {
      flag = true;
    }
    return flag;
  };

  /**
   * 提醒关注
   */
  remindAttention = async () => {
    const attention = this.state().get("attention");
    const overViewSearch = this.state().get("overViewSearch");
    const {  iteration } =
      overViewSearch?.toJS?.() || {};
    const params = this.getOverViewParam();
    const param = {
      localIterationId: iteration,
      url: 'https://skyeye.wanmi.com/#/quality-assurance?pageType=2&tabType=1&iterationId='
          + params.iterationId + '&projectId=' + params.projectId
          + '&belongBaseline=' + params.belongBaseline
          + '&projectType=' + params.projectType + '&startYear=' + params.startYear
    };
    if (iteration == null) return 
    if (attention == false) {
       const res = await webApi.remindAttention(param);
       if (res.code === SUCCESS_CODE) {
         message.success("提醒成功");
         this.fnAnalyzePushExpireTime({ localIterationId: iteration });
       } else {
         message.error(res.message);
       }
    }
  };

  /**
   * 查询 - 提醒关注 - 按钮状态
   * @returns 
   */
  fnAnalyzePushExpireTime = (params) => {
    if(!params || !Object.keys(params).length) return;

    webApi.analyzePushExpireTime(params).then(res => {
      if (res.code === SUCCESS_CODE) {
        this.mergeData({
          attention: Boolean(res?.context),
        });
      } else {
        if (res?.message) {
          console.error(
            `/analyze-push-expire-time
              接口响应提示：${res?.code} / ${res.message}`,
          );
        }
      }
    });
  };

  /**
   * 获取各个柱状图数据
   */
  getNewFixLineChart = async () => {
    let param = this.getDistributionParam(false);
    const res = await webApi.getForNewfixChart(param);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        newFixLineData: res.context.bugAnalyzeDataS,
      });
    } else {
      message.error(res.message);
    }
  };

  /**
   * 获取各个分布列表数据
   */
  getNewFixList = async (pageNum?, pageSize?) => {
    this.mergeData({
      forNewPageLoading: true,
      forFixPageLoading: true,
      newFixPageNum: pageNum || this.state().get("newFixPageNum"),
      newFixPageSize: pageSize || this.state().get("newFixPageSize"),
    });
    const param = this.getDistributionParam(true, pageNum || this.state().get("newFixPageNum"), pageSize || this.state().get("newFixPageSize"));
    const tabType = this.state().get("tabType");
    const tab =
        tabType == 1
            ? this.state().get("newFixScoreType")
            : this.state().get("customNewFixScoreType");
    let res = { context: {}, code: "", message: "" };
    if (tab == 1) {
      res = await webApi.getForNewCountPage(param);
    } else if (tab == 2) {
      res = await webApi.getForFixCountPage(param);
    }
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        forNewPage: res?.context || {},
        forFixPage: res?.context || {},
      });
    } else {
      message.error(res?.message);
    }
    setTimeout(() => {
      this.mergeData({
        forNewPageLoading: false,
        forFixPageLoading: false,
      });
    }, 0);
  };
}
