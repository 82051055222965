import React from 'react';
import { Relax } from 'plume2';
import { noop } from "@utils";
import { Tooltip, Tabs } from 'antd';


@Relax
export default class Title extends React.Component<any, any> {
  static relaxProps = {
    bugRepairsCountVO: "bugRepairsCountVO",
  };
  render() {
    const {
      bugRepairsCountVO
    } = this.props.relaxProps;

    const tabType = this.props?.tabType;

    const titleData =
      bugRepairsCountVO?.toJS();


    return (
      <div className="detail-list-main-alert">
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '缺陷总数' : '提交缺陷'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内新建的有效缺陷总数（不包含拒绝）' : '对应时间段内新建缺陷总数'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.totalNum || '0') : (titleData?.testTotalNum || '0')}</p>
        </div>
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '参与修复数' : '无效缺陷'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内参与修复缺陷总数' : '对应时间段内拒绝缺陷总数'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.participationNum || '0') : (titleData?.invalidNum || '0')}</p>
        </div>
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '处理及时率' : '回归缺陷'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内符合及时修复规则的占比' : '对应时间段内存在回归记录的缺陷总数'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.dealTimelyRate || '0') + '%' : (titleData?.returnNum || '0')}</p>
        </div>
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '关闭总数' : '回归率'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内已关闭缺陷总数' : '对应时间段内回归缺陷数占比'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.closeNum || '0') : (titleData?.returnRate || '0') + "%"}</p>
        </div>
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '一次回归数' : '回归及时率'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内一次回归通过的缺陷总数' : '对应时间段内符合回归及时规则的占比'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.onceReturnNum || '0') : (titleData?.returnTimelyRate || '0') + "%"}</p>
        </div>
        <div>
          <div className="detail-list-main-alert-title">
            <p>{tabType === 0 ? '修复质量' : '打回缺陷'}</p>
            <Tooltip title={tabType === 0 ? '对应时间段内一次回归通过的缺陷占比' : '对应时间段内回归后打回缺陷总数'}>
              <span></span>
            </Tooltip>
          </div>
          <p className="detail-list-main-alert-num">{tabType === 0 ? (titleData?.repairQuality || '0') + "%" : titleData?.backNum || '0'}</p>
        </div>
      </div>
    );
  }
}
