import React from "react";
import { Relax } from "plume2";
import { Table, Tooltip } from "antd";
import { fromJS } from "immutable";

@Relax
class PersonalScoreData extends React.Component<any, any> {
  static relaxProps = {
    ownerScoreData: "ownerScoreData",
    ownerScoreLoading: "ownerScoreLoading",
    isShow: 'isShow'
  };

  render() {
    const { ownerScoreData, ownerScoreLoading, isShow } = this.props.relaxProps;
    const ownerScoreDataForYear =
      ownerScoreData.get("ownerScoreDataForYear") || fromJS({});
    const ownerScoreDataList =
      ownerScoreData.get("ownerScoreDataList") || fromJS([]);
    return (
      isShow && <div className="personal_ranking__personal_score_data">
        <div className="personal_ranking__personal_score_data__title">
          个人得分数据
        </div>
        <div className="personal_ranking__personal_score_data__table">
          <Table
            columns={this.columns}
            rowKey="iterationName"
            dataSource={ownerScoreDataList?.toJS()}
            pagination={false}
            loading={ownerScoreLoading}
            summary={(_pageData) => {
              //编辑数据
              const data = [];
              this.columns.map((v) =>
                data.push(ownerScoreDataForYear?.get(v.dataIndex))
              );
              return (
                <Table.Summary.Row>
                  {data?.map((v, index) => {
                    return (
                      <Table.Summary.Cell key={Math.random()} index={index}>
                        {index === data.length - 2 ? (
                          <Tooltip
                            title={() => {
                              return (
                                <>
                                  <div>{`参与修复缺陷总数: ${ownerScoreDataForYear?.get(
                                    "fixBugNum"
                                  )}个`}</div>
                                  <div>
                                    {`累计打回数: ${
                                      ownerScoreDataForYear?.get("fixBugNum") -
                                      ownerScoreDataForYear?.get(
                                        "onceFixBugNum"
                                      )
                                    }个`}
                                  </div>
                                </>
                              );
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              {v || "0"}%
                            </div>
                          </Tooltip>
                        ) : (
                          <div
                            style={{ textAlign: index > 0 ? "center" : "left" }}
                          >
                            {v}
                            {[3, 4].includes(index) && "人时"}
                          </div>
                        )}
                      </Table.Summary.Cell>
                    );
                  })}
                </Table.Summary.Row>
              );
            }}
          />
        </div>
      </div>
    );
  }

  /**
   *
   */
  columns = [
    {
      title: "项目/迭代",
      dataIndex: "iterationName",
      key: "iterationName",
      width: "14%",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "计划任务",
      dataIndex: "taskNum",
      key: "taskNum",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "实际完成",
      dataIndex: "finishTaskNum",
      key: "finishTaskNum",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "预算花费",
      dataIndex: "timeSheetNum",
      key: "timeSheetNum",
      align: "center",
      render: (text) => <div>{`${text || "0"}人时`}</div>,
    },
    {
      title: "实际花费",
      dataIndex: "timeSheetSpendNum",
      key: "timeSheetSpendNum",
      align: "center",
      render: (text) => <div>{`${text || "0"}人时`}</div>,
    },
    {
      title: "sonar缺陷",
      dataIndex: "sonarBugNum",
      key: "sonarBugNum",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "缺陷数",
      dataIndex: "bugNum",
      key: "bugNum",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "参与修复数",
      dataIndex: "fixBugNum",
      key: "fixBugNum",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
    {
      title: "修正率",
      dataIndex: "onceFixBugRatio",
      key: "onceFixBugRatio",
      align: "center",
      render: (text, record) => {
        return (
          <Tooltip
            title={() => {
              return (
                <>
                  <div>{`参与修复缺陷总数: ${record.fixBugNum}个`}</div>
                  <div>
                    {`累计打回数: ${record.backNum}个`}
                  </div>
                </>
              );
            }}
          >
            {text || "0"}%
          </Tooltip>
        );
      },
    },
    {
      title: "缺陷密度",
      dataIndex: "bugDensity",
      key: "bugDensity",
      align: "center",
      render: (text) => <div>{text || "0"}</div>,
    },
  ] as any;
}
export default PersonalScoreData;
