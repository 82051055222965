import { Action, Actor } from "plume2";
import { fromJS } from "immutable";
import { IActor } from "../type";

export default class MainActor extends Actor {
  defaultState(): IActor {
    return {
      isSpinning: true,
      projectTypeList: [],
      belongBaselineList: [],
      projectList: [],
      iterationList: [],
      isUpdateForm: false,
      time: new Date().getFullYear().toString(),
      projectType: null,
      belongBaseLine: null,
      fetchProjectListPageParams: {},
      projectListTitle: null,
      projectListDataSource: [],
      projectListTotal: 0,
      iterationDetailsTitle: null,
      iterationDetailsMap: {},
      milestoneList: [],
      milestoneListDetailsMap: {},
      milestoneDetailsColumn: [],
      milestoneDetailsDataSource: [],
      milestoneDetailsCheckboxColumn: ["owner"],
      iterationDetailsList: [],
      activeTabKey: null,
      activeRowKey: null,
      iterationId: null,
      projectId: null,
      startYear: null,
      pageNum: 1,
      pageSize: 10,
    };
  }

  /**
   * 动态更改Actor - 一级
   */
  @Action('projectListActor:all')
  changePageActor(state, params) {
    if (!params) return;
 
     // 数组
    if (Array.isArray(params)) {
      if (!params.length) return;
 
      params.forEach(({key, value,}) => {
        state = state.set(key, fromJS(value));
      });
 
       // 对象
    } else if (typeof params === 'object') {
      if (!params?.key) return;

      state = state.set(params?.key, fromJS(params?.value));
    }
 
    return state;
  }

}