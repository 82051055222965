import React from "react";
import { Relax } from "plume2";
import { Avatar, Popover, Badge, Image, Upload } from "antd";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons";
import { baseURL, loginout, noop } from "@utils";
// 头像右下角icon
import avatar_icon from "./img/avatar_icon.png";
// 修改我的头像 - 按钮
import button_svg from "./img/button.svg";
// 下箭头
import { DownOutlined, RightOutlined } from "./img/arrow";
// 修改密码icon
import change_pwd_icon from "./img/change_pwd_icon.png";
// 退出登录icon
import logout from "./img/logout.png";
// 默认头像
import default_avatar_svg from "./img/default_avatar.svg";

/**
 * 头像 + 用户信息
 */
@Relax
class UserInfos extends React.Component<any, any> {
  static relaxProps = {
    userInfoVO: "userInfoVO",
    clicked: "clicked",
    hovered: "hovered",
    onVisibleChange: noop,
    _beforeUpload: noop,
    _handleChange: noop,
  };

  // 渲染 - 头像组件
  renderAvatarPopover = () => {
    const { token } = this.props;
    const {
      userInfoVO,
      clicked,
      onVisibleChange,
      _beforeUpload,
      _handleChange,
    } = this.props.relaxProps;

    return (
      <Popover
        placement="bottomRight"
        content={
          // 气泡弹窗内容
          <div className="skyeye_pc_header__content--user__avatar">
            <Image
              preview={false}
              width={100}
              height={100}
              src={userInfoVO?.get("headImgUrl") || default_avatar_svg}
            />
            <Upload
              name="uploadFile"
              className="skyeye_pc_header__content--user__avatar--Upload"
              showUploadList={false}
              // 将图片上传到oss，得到oss返回的图片url
              action={`${baseURL}/resource/upload-resource?resourceType=IMAGE`}
              //设置上传的请求头部，IE10 以上有效
              headers={{
                Authorization: "Bearer" + (token ? " " + token : ""),
              }}
              method="post"
              maxCount={1}
              accept="image/png, image/jpeg"
              beforeUpload={_beforeUpload}
              onChange={_handleChange}
            >
              <img src={button_svg} alt="button.svg" width={100} height={32} />
            </Upload>
          </div>
        }
        trigger="hover"
        visible={clicked}
        onVisibleChange={(visible) => onVisibleChange?.(false, visible)}
      >
        {/* 气泡弹窗触发 - 热区 */}
        <Badge
          count={
            <img
              className="skyeye_pc_header__content--user__Badge"
              src={avatar_icon}
              alt="avatar_icon.png"
            />
          }
        >
          <Avatar
            size={40}
            src={userInfoVO?.get("headImgUrl") || default_avatar_svg}
          />
        </Badge>
      </Popover>
    );
  };

  // 根据条件计算当前组件的position right
  handlePositionRight = () => {
    const { userInfoVO } = this.props.relaxProps;
    // userName为空时，有占位符 -
    if (!userInfoVO?.get("userName")) return 95;

    /**
     * 用户名中文字符长度
     *
     * 注：目前只考虑中文名字，英文名字后续迭代兼容处理
     */
    switch (userInfoVO?.get("userName")?.length) {
      case 4:
        return 146;
      case 3:
        return 131;
      case 2:
        return 116;
      default:
        return 101;
    }
  };

  render() {
    const { token } = this.props;
    // if (!token) return null;

    return (
      <div
        className="skyeye_pc_header__content--user"
        style={{ right: `-${this.handlePositionRight() + 30 + 9}px` }}
      >
        {/* 头像 */}
        {this.renderAvatarPopover()}
        {/* 个人信息 */}
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {this.renderUserInfoPopover()}
        </div>
      </div>
    );
  }

  // 渲染 - 个人信息组件
  renderUserInfoPopover = () => {
    const { requireChangePwd, token } = this.props;
    const { userInfoVO, clicked, hovered, onVisibleChange } =
      this.props.relaxProps;

    return (
      <Popover
        placement="bottomRight"
        overlayStyle={{ zIndex: 999 }}
        content={
          // 气泡弹窗内容
          <div className="skyeye_pc_header__content--user__info">
            <div className="skyeye_pc_header__content--user__info--uInfo">
              <div>
                <span>{userInfoVO?.get("userName") || "-"}</span>
                <div>{userInfoVO?.get("jobNumber") || "-"}</div>
              </div>
              <div>
                <span>{userInfoVO?.get("positionName") || "-"}</span>
                <div>{userInfoVO?.get("deptName") || "-"}</div>
              </div>
            </div>
            {
              /** 非首次登录展示修改密码及退出登录 */
              (!requireChangePwd || !token) && (
                <div className="skyeye_pc_header__content--user__info--function">
                  <Link
                    replace
                    to="/change-password"
                    onClick={(e) => {
                      e.stopPropagation();
                      onVisibleChange?.(false, false);
                    }}
                  >
                    <img src={change_pwd_icon} alt="change_pwd_icon.png" />
                    <div>修改密码</div>
                    <RightOutlined />
                  </Link>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onVisibleChange?.(false, false, () => {
                        loginout();
                      });
                    }}
                  >
                    <img src={logout} alt="logout.png" />
                    <div>退出登录</div>
                    <RightOutlined />
                  </div>
                </div>
              )
            }
          </div>
        }
        trigger="hover"
        visible={hovered}
        onVisibleChange={(visible) => onVisibleChange?.(visible, false)}
      >
        {/* 气泡弹窗触发 - 热区 */}
        <div className="skyeye_pc_header__content--user__name">
          <div>{userInfoVO?.get("userName") || "-"}</div>
          <Icon
            component={DownOutlined}
            style={{
              transform: `rotate(${clicked || hovered ? 180 : 0}deg)`,
              transition: "all .3s",
            }}
          />
        </div>
      </Popover>
    );
  };
}
export default UserInfos;
