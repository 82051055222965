/**
 * storage的key，定义为常量统一存放
 */
export default {
  LOGIN_DATA: "skyeye-pc@login", //登录信息缓存
  LOGIN_MENUS: "skyeye-pc@menus", //登录人菜单信息
  LOGIN_FUNCTIONS: "skyeye-pc@functions", //登录人功能权限信息
  LOGIN_URL: "skyeye-pc@login-url", // 重定向页面跳转的url
  SITE_LOGO: "skyeye-pc@logo", //商城logo
  DATA_BOARD: "skyeye-pc@dataBoard:", //控制面板
  SYSTEM_BASE_CONFIG: "skyeye-pc@systemBaseConfig:", //系统配置，如pc，h5系统配置
  FIRST_ACTIVE: "skyeye-pc@firstActive", //选中的第一层菜单下标
  SECOND_ACTIVE: "skyeye-pc@secondActive", //选中的第二层菜单下标
  THIRD_ACTIVE: "skyeye-pc@thirdActive", //选中的第三层菜单下标
  MINI_QRCODE: "skyeye-pc-miniProgram-code", //店铺主页小程序码
  PENDING_AND_REFUSED: "pending-or-refused-useInfo", //审核中或者审核未通过的用户信息
  VALUE_ADDED_SERVICES: "value-added-service", // 增值服务
  HIDE_EMPLOYEE_SETTING: "HIDE_EMPLOYEE_SETTING",
  AREA_PROV: "AREA_PROV",
  AREA_CITY: "AREA_CITY",
  AREA_AREA: "AREA_AREA",
  MOBILE_SITE: "MOBILE_SITE",
  PC_SITE: "PC_SITE",
};
