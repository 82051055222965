import React from 'react';
import { Relax } from 'plume2';
import { Table, Pagination } from 'antd';
import { noop } from "@utils";
import {getForFixUserPage} from "../../webapi";
const { Column } = Table

@Relax
export default class ProjectList extends React.Component<any, any> {
  static relaxProps = {
    scoreType: 'scoreType',
    customScoreType: 'customScoreType',
    forUserPage: 'forUserPage',
    forFixUserPage: 'forFixUserPage',
    forUserPageLoading: 'forUserPageLoading',
    forFixUserPageLoading: 'forFixUserPageLoading',
    forModulePage: 'forModulePage',
    forModulePageLoading: 'forModulePageLoading',
    forReasonPage: 'forReasonPage',
    forReasonPageLoading: 'forReasonPageLoading',
    forYypePage: 'forYypePage',
    forYypePageLoading: 'forYypePageLoading',
    demandOptimizationFlag: 'demandOptimizationFlag',
    customDemandOptimizationFlag: 'customDemandOptimizationFlag',
    tabType: 'tabType',
    mergeData: noop,
    getDistributionList: noop,
    getIsEmpty: noop,
  };

  render() {
    const { getDistributionList, getIsEmpty } = this.props.relaxProps
    const total = this._getRelevantData('total')
    const current = this._getRelevantData('current') || 1
    const pageSize = this._getRelevantData('size') || 10
    const columns = this._getColumn()
    const loading = this._getLoading()

    return (
      <div className="list">
        <Table
          rowKey="projectName"
          dataSource={this._getData()}
          pagination={false}
          scroll={{ x: 'max-content' }}
          loading={loading}
        >
          {columns?.map?.((v, i) => {
            const fixed = (v?.type == 'total' || v?.type == 'deUser' || v?.type == 'fixUser') ? true : false
            return <Column title={v?.text} dataIndex={v?.type} key={v?.type} align={v?.align || 'center'} render={(value, record) => this._renderColumn(value, record, v?.type)} fixed={fixed} width={v?.width || ''} />
          })}
        </Table>
        <div className="pagination">
          <Pagination
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={['10', '20', '50', '100']}
            defaultCurrent={1}
            total={total}
            onChange={(pageNum, pageSize) => {
              getDistributionList(pageNum, pageSize)
            }}
            showTotal={(total, range) => ` 共${total}条`}
            pageSize={pageSize || 10}
            current={current}
          />
        </div>
      </div>
    );
  }


  _renderColumn = (value, records, type) => {
    if (value == null && type !== 'deUser' && type !== 'fixUser') {
      return 0
    }
    if (type == 'deUser' && value == null) {
      return 'null'
    }

    if (type == 'fixUser' && value == null) {
      return 'null'
    }

    if (type == 'bugDensity') {
      return <span style={{ color: records.red ? '#F53731' : '' }}>{value}</span>
    }
    if (type == 'ratio') {
      return value + '%'
    }
    return value
  }

  _getRelevantData = (type?) => {
    const { forUserPage, forModulePage, forReasonPage, forYypePage, scoreType, tabType, customScoreType, forFixUserPage } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let total;
    if (tab == 1) {
      total = forUserPage?.toJS()?.ipage?.[type]
    } else if (tab == 2) {
      total = forYypePage?.toJS()?.ipage?.[type]
    } else if (tab == 3) {
      total = forReasonPage?.toJS()?.ipage?.[type]
    } else if(tab == 4){
      total = forModulePage?.toJS()?.ipage?.[type]
    }else if(tab == 5){
      total = forFixUserPage?.toJS()?.ipage?.[type]
    }
    return total
  }

  _getColumn = () => {
    const { forUserPage, forModulePage, forReasonPage, forYypePage, scoreType, tabType, demandOptimizationFlag, customDemandOptimizationFlag, customScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let columns = []
    if (tab == 1) {
      columns = [
        { type: "bugDensity", text: "缺陷密度(个/K)" },
        { type: "total", text: "缺陷总数" },
        { type: "ratio", text: "缺陷占比" },
        { type: "fatal", text: "致命" },
        { type: "serious", text: "严重" },
        { type: "normal", text: "一般" },
        { type: "prompt", text: "提示" },
        { type: "advice", text: "建议" },
      ]
    } else if (tab == 2) {
      // 判断是否过滤 需求/优化类数据 201 202 203 204 801
      let data = forYypePage?.toJS()?.titleDatas
      let fiterType = ['201xuqiushejicuowu', '202xuqiuyilou', '203xuqiueryixing/miaoshubuqing', '204xuqiubiangeng', '801youhuagaijin']
      let filterData = data?.filter?.((v) => !fiterType.includes(v.type))
      if (tabType == 1) {
        columns = demandOptimizationFlag == true ? data : filterData
      } else {
        columns = customDemandOptimizationFlag == true ? data : filterData
      }
    } else if (tab == 3) {
      columns = forReasonPage?.toJS()?.titleDatas
    } else if (tab == 4){
      columns = forModulePage?.toJS()?.titleDatas
    } else if (tab == 5){
      columns = [
        { type: "deTotal", text: "开发缺陷数" },
        { type: "fixTotal", text: "修复缺陷数" },
        { type: "ratio", text: "修复缺陷占比" },
        { type: "fatal", text: "致命" },
        { type: "serious", text: "严重" },
        { type: "normal", text: "一般" },
        { type: "prompt", text: "提示" },
        { type: "advice", text: "建议" },
      ]
    }
    let newColumns = []
    
    if(columns?.length > 0 && tab == 5){
      let employeColumns = { type: "fixUser", text: "姓名", fixed: true, width: '100px', align: 'left' }
      newColumns = [employeColumns, ...(columns || [])]
    } else if (columns?.length > 0) {
      let employeColumns = { type: "deUser", text: "姓名", fixed: true, width: '100px', align: 'left' }
      newColumns = [employeColumns, ...(columns || [])]
    }
    return newColumns
  }

  _getLoading = () => {
    const { forUserPageLoading, forModulePageLoading, forReasonPageLoading, forYypePageLoading, scoreType, tabType, customScoreType, forFixUserPageLoading } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let loading;
    if (tab == 1) {
      loading = forUserPageLoading
    } else if (tab == 2) {
      loading = forYypePageLoading
    } else if (tab == 3) {
      loading = forReasonPageLoading
    } else if(tab == 4){
      loading = forModulePageLoading
    }else if(tab == 5){
      loading = forFixUserPageLoading
    }
    return loading
  }


  _getData = () => {
    const { forUserPage, forModulePage, forReasonPage, forYypePage, scoreType, customScoreType, tabType, forFixUserPage } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let dataSource = []
    if (tab == 1) {
      dataSource = forUserPage?.toJS()?.ipage?.records
    } else if (tab == 2) {
      dataSource = forYypePage?.toJS()?.ipage?.records
    } else if (tab == 3) {
      dataSource = forReasonPage?.toJS()?.ipage?.records
    } else if (tab == 4){
      dataSource = forModulePage?.toJS()?.ipage?.records
    } else if (tab == 5){
      dataSource = forFixUserPage?.toJS()?.ipage?.records
    }
    return dataSource
  }
}
