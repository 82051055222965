import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import Detail from './detail-calendar';
import Total from './total-calendar';

@Relax
export default class Main extends React.Component<any, any> {
  static relaxProps = {
    mergeData: noop,
    getCalendarData: noop,
    tabType: 'tabType',
  };

  render() {
    const { tabType } = this.props.relaxProps;
    return (
      <div className="calendar-data-contain-main">
        <div className="tab">
          <div style={{ marginRight: 20 }} className={tabType == 1 ? 'select-tab' : ""} onClick={() => { this._handleClick('total') }}>投入汇总</div>
          <div className={tabType == 2 ? 'select-tab' : ""} onClick={() => { this._handleClick('detail') }}>投入明细</div>
        </div>
        {tabType == 1 ? <Total /> : <Detail />}
      </div>
    );
  }

  _handleClick = (type) => {
    const { getCalendarData, mergeData } = this.props.relaxProps;
    if (type == 'total') {
      mergeData({ tabType: 1, calendarTotal: [] })
      getCalendarData('total')
    } else {
      mergeData({ tabType: 2, calendarDetail: [] })
      getCalendarData('detail')
    }

  }
}
