import React from "react";
import { Relax } from "plume2";

/**
 * 页面标题
 */
@Relax
class PageTitle extends React.Component<any, any> {
  static relaxProps = {
    updateTime: "updateTime",
  };
  render() {
    const { updateTime } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_operational_data_page_title">
        <div className="skyeye_pc_operational_data_page_title_label">
          运营数据
        </div>
        <div className="skyeye_pc_operational_data_page_title_update_time">
          更新时间：{updateTime}
        </div>
      </div>
    );
  }
}
export default PageTitle;
