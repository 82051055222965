import React from "react";
import { Relax } from "plume2";
import ReactEcharts from "echarts-for-react";
import { Table, Tag, Space, Pagination, Tooltip, Radio, List } from 'antd';
import { noop, util } from '@utils';
import ModuleList from './module-list'
import BarChart from './bar-chart';


@Relax
class TrendChart extends React.Component<any, any> {
  static relaxProps = {
    scoreType: 'scoreType',
    customScoreType: 'customScoreType',
    tabType: 'tabType',
    forUser: 'forUser',
    forFixUser: 'forFixUser',
    forYype: 'forYype',
    forReason: 'forReason',
    forModule: 'forModule',
    forUserPage: 'forUserPage',
    forFixUserPage: 'forFixUserPage',
    forYypePage: 'forYypePage',
    forReasonPage: 'forReasonPage',
    forModulePage: 'forModulePage',
    mergeData: noop,
    getDistributionList: noop,
    getIsEmpty: noop,
  };

  render() {
    const {
      scoreType,
      getIsEmpty,
      tabType,
      customScoreType
    } = this.props.relaxProps;
    const tab = tabType == 1 ? scoreType : customScoreType
    const isChartEmpty = getIsEmpty('bugAnalyzeDataS')
    const isListEmpty = getIsEmpty('titleDatas')
    return (
      <div className="module-chart" >
        <div className="head">
          <div className="title">{this._getTitle()}</div>
          <Radio.Group
            options={[
              { label: "按开发人员", value: 1 },
              { label: "按修复人员", value: 5 },
              { label: "按缺陷类型", value: 2 },
              { label: "按缺陷原因", value: 3 },
              { label: "按模块", value: 4 },
            ]}
            onChange={(e) => {
              this._changeScoreType(e.target.value)
            }}
            value={tab}
            defaultValue={0}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        {(!isChartEmpty && !isListEmpty) ? <>
          <BarChart />
          <ModuleList />
        </> : <div className="empty-data"><List /></div>}
      </div>
    );
  }

  _getTitle = () => {
    const {
      scoreType,
      mergeData,
      customScoreType,
      tabType
    } = this.props.relaxProps;
    const tab = tabType == 1 ? scoreType : customScoreType
    if (tab == 1) {
      return '按开发人员缺陷分布'
    } else if (tab == 2) {
      return '按缺陷类型分布'
    } else if (tab == 3) {
      return '按缺陷原因分布'
    } else if (tab == 4){
      return '按模块分布'
    } else if (tab == 5){
      return '按修复人员缺陷分布'
    }
  }

  _changeScoreType = (value) => {
    const { mergeData, getDistributionList, tabType, customScoreType } = this.props.relaxProps;
    if (tabType == 1) {
      mergeData({
        scoreType: value
      })
    } else {
      mergeData({
        customScoreType: value
      })
    }
    mergeData({
      forUserPage: {}, // 按开发人员列表
      forFixUserPage: {}, // 按开发人员列表
      forModulePage: {}, // 按模块列表
      forReasonPage: {}, // 按原因列表
      forYypePage: {}, // 按类型列表
    })
    getDistributionList()
  }



}
export default TrendChart;
