import { Store } from 'plume2';
import MainActor from './actor/main-actor';
import { SUCCESS_CODE } from '@utils';
import * as webapi from './webapi';
import { isThisTypeNode } from 'typescript';
import { message } from 'antd';
import { fromJS } from 'immutable';
import { promiseAllSettled } from "@utils/kit";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  /**
   * 改值
   */
  mergeData = (data) => {
    this.dispatch('merge:data', data);
  };

  /**
   * 改值
   */
  changeData = (field, value) => {
    this.dispatch('change:data', { field, value });
  };

  /**
   * 切换tab
   */
  handleChangeTabItem = (e) => {
    //存当前tab，刷新加载当前tab
    // localStorage.setItem("pageType", e);
    this.mergeData({
      pageType: e,
    });
    // 切换到个人排行榜 清空项目排行榜-项目排行内容
    if (e === '0') {
      this.mergeData({
        projectScoreDataSource: [],
        iterationScoreDataSource: [],
      });
    }
  };

  /**
   * 个人排行榜初始化
   */
  personalRankingInit = () => {
    const begin = this.state().get('begin');
    const end = this.state().get('end');
    const codeScorePageNum = this.state().get('codeScorePageNum');
    const bugScorePageNum = this.state().get('bugScorePageNum');
    this._getYearOwnerScoreRank();
    this._yearOwnerFixQualityLine();
    this._findOwnerScore();
    this._bestQualityTopFiveByOwner({ begin: begin, end: end });
    this._findFixBugTopFive({ begin: begin, end: end });
    this._codingScore({
      begin: begin,
      end: end,
      pageNum: codeScorePageNum,
    });
    this._bugScore({ begin: begin, end: end, pageNum: bugScorePageNum });

    //个人排行榜主管视角相关逻辑
    const isLeader = this.state().get('isLeader');

    if (isLeader) {
      // 获取部门列表
      this._getDepartmentList();
      // 获取员工列表
      setTimeout(() => {
        this._getDepartmentUserList();
      }, 1000);
      setTimeout(() => {
        this.personalRankingSearch();
      }, 800);
    } else {
      this.mergeData({ isShow: true });
    }
  };

  /**
   * 项目排行榜初始化
   */
  projectRankingInit = () => {
    this._bestQualityTopFiveByProject();
    this._bestQualityTopFiveByIteration();
    this._getProjectScoreRank();
  };

  /**
   * 千行代码初始化
   */
  thousandCodeInit = () => {
    this._getIterationGroupList();
  };

  /**
   * 实时人效初始化
   */
  realtimeEffectInit = () => {
    this._qualityInspectionInit();
  };

  /*
   * 部门下拉列表
   */
  _getDepartmentList = async () => {
    const deptId = this.state().get('departmentId');
    const param = {
      deptId,
    };
    const res = await webapi.getDepartmentList(param);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        belongDepartmentList: res.context,
        belongDepartmentInfo: res.context[0],
        departmentId: res.context?.[0]?.id,
      });
    } else {
      message.error(res.message);
    }
  };

  /*
   * 员工下拉列表
   */
  _getDepartmentUserList = async () => {
    const deptId = this.state().get('departmentId');
    const param = {
      deptId,
    };
    const res = await webapi.getDepartmentUser(param);
    if ((res.code = SUCCESS_CODE)) {
      this.mergeData({ departmentUserList: res.context });
    } else {
      message.error(res.message);
    }
  };

  // 变更搜索条件

  changeSearchData = async (type, value) => {
    this.changeData(type, value);
    if (type == 'departmentId') {
      this._getDepartmentUserList();
    }
  };

  //个人排行榜（普通用户时间筛选）
  personalRankingSearchByTime = async () => {
    // 更新排名和折线图
    this._getYearOwnerScoreRank();
    this._yearOwnerFixQualityLine();
    this._findOwnerScore();
  };

  personalRankingSearch = async () => {
    const userId = this.state().get('userId');
    //获取登录人权限
    const isLeader = this.state().get('isLeader');
    if (userId == null && isLeader) {
      this.changeData('isShow', false);
    } else {
      this.changeData('isShow', true);
    }
    // 1、研发效能排行榜
    this.getDevEffectRankList();
    // 2、排名
    this._getYearOwnerScoreRank();
    // 3、折线图
    this._yearOwnerFixQualityLine();
    // 4、个人得分数据
    this._findOwnerScore();
    //清空相关数据
    this.mergeData({ effectListUserId: null });
  };

  getParams = () => {
    const isLeader = this.state().get('isLeader');
    const startYear = isLeader
      ? this.state().get('leaderSelectStartYear')
      : this.state().get('personSelectStartYear');
    const userId = this.state().get('userId');
    const deptId = this.state().get('departmentId');
    let params = {
      userId: isLeader ? userId : null,
      startYear,
      deptId: isLeader ? deptId : null,
    };
    return params;
  };

  getDevEffectRankList = async (pageNum?, pageSize?, isChangeData = false) => {
    this.mergeData({ devEffectRankingLoading: true });
    const startYear = this.state().get('leaderSelectStartYear');
    const userId = !isChangeData ? this.state().get('userId') : null;
    const deptId = this.state().get('departmentId');
    const effectPageNum = this.state().get('effectPageNum');
    const effectPageSize = this.state().get('effectPageSize');
    let param = {
      startYear,
      pageNum: pageSize !== 10 ? 1 : pageNum,
      pageSize: pageSize || effectPageSize,
      userId: userId || null,
      deptId: deptId || null,
    };
    this.mergeData({ effectPageNum: pageNum || effectPageNum, effectPageSize: pageSize || effectPageSize });
    const res = await webapi.getDevEffectRank(param);
    if ((res.code = SUCCESS_CODE)) {
      let rank = res.context?.records[0]?.rank;
      this.mergeData({ devEffectRankingList: res.context, ranking: rank });
    }
    setTimeout(() => {
      this.mergeData({ devEffectRankingLoading: false });
    }, 0);

    // 重置数据
    if (isChangeData) {
      this.mergeData({ effectListUserId: null, userId: null });
    }
    if (userId) {
      const record = res.context?.records?.filter((a) => a.userId == userId)[0];
      this.renderOwnerScore(record);
    }
  };

  /**
   * 用户年度数据和排名
   * @private
   */
  _getYearOwnerScoreRank = async () => {
    const isLeader = this.state().get('isLeader');
    const userId = this.state().get('userId');
    if (isLeader && userId == null) return;
    const res = await webapi.yearOwnerScoreRank(this.getParams());
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        ownerScoreRankData: res.context,
      });
    }
  };

  /**
   * 当前用户年度修复质量折线图数据
   * @private
   */
  _yearOwnerFixQualityLine = async () => {
    const isLeader = this.state().get('isLeader');
    const userId = this.state().get('userId');
    if (isLeader && userId == null) return;
    const res = await webapi.yearOwnerFixQualityLine(this.getParams());
    if (res.code === SUCCESS_CODE) {
      this.mergeData({ ownerFixQualityLineData: res.context });
    }
  };

  /**
   * 年度个人得分数据
   * @private
   */
  _findOwnerScore = async () => {
    const isLeader = this.state().get('isLeader');
    const userId = this.state().get('userId');
    if (isLeader && userId == null) return;
    const res = await webapi.findOwnerScore(this.getParams());
    if (res.code === SUCCESS_CODE) {
      this.mergeData({ ownerScoreData: res.context });
    }
    setTimeout(() => {
      this.mergeData({ ownerScoreLoading: false });
    }, 0);
  };

  // 主管视角的个人得分数据
  renderOwnerScore = async (data) => {
    this.mergeData({
      userId: data?.userId,
      effectListUserId: data.userId,
      // departmentId: data.deptId,
      isShow: true,
      ranking: data?.rank,
    });
    // 需要重新查询员工列表
    this._getDepartmentUserList();
    // 1、 个人得分数据
    this._findOwnerScore();
    // 2、排名
    this._getYearOwnerScoreRank();
    // 3、折线图
    this._yearOwnerFixQualityLine();
  };

  /**
   * 获取质量最佳top5
   * @private
   */
  _bestQualityTopFiveByOwner = async (params) => {
    this.mergeData({ bestQualityTopFiveLoading: true });
    const res = await webapi.bestQualityTopFiveByOwner(params);

    if (res.code === SUCCESS_CODE) {
      this.mergeData({ bestQualityTopFiveData: res.context || [] });
    }
    setTimeout(() => {
      this.mergeData({ bestQualityTopFiveLoading: false });
    }, 0);
  };

  /**
   * 修复缺陷top5查询方法
   * @private
   */
  _findFixBugTopFive = async (params) => {
    this.mergeData({ fixBugTopFiveLoading: true });
    const res = await webapi.findFixBugTopFive(params);

    if (res.code === SUCCESS_CODE) {
      this.mergeData({ fixBugTopFiveData: res.context || [] });
    }
    setTimeout(() => {
      this.mergeData({ fixBugTopFiveLoading: false });
    }, 0);
  };

  /**
   * 编码得分排行
   * @private
   */
  _codingScore = async (params) => {
    this.mergeData({ codeScoreLoading: true });
    const res = await webapi.codingScore(params);

    if (res.code === SUCCESS_CODE) {
      const more = [
        {
          rowNum: -1,
          owner: -1,
          progressScore: -1,
          qualityScore: -1,
          totalScore: -1,
        },
      ];
      //处理编码得分排行和缺陷得分排行数据
      this.handleRankData('codeScore', more, res, params?.pageNum);
    }
    setTimeout(() => {
      this.mergeData({ codeScoreLoading: false });
    }, 0);
  };

  /**
   * 缺陷得分排行
   * @private
   */
  _bugScore = async (params) => {
    this.mergeData({ bugScoreLoading: true });
    const res = await webapi.bugScore(params);

    if (res.code === SUCCESS_CODE) {
      const more = [
        {
          rowNum: -1,
          fixUser: -1,
          bugDensityScore: -1,
          fixBugScore: -1,
          totalScore: -1,
        },
      ];
      //处理编码得分排行和缺陷得分排行数据
      this.handleRankData('bugScore', more, res, params?.pageNum);
    }
    setTimeout(() => {
      this.mergeData({ bugScoreLoading: false });
    }, 0);
  };

  /**
   * 处理编码得分排行和缺陷得分排行数据
   */
  handleRankData = (key, moreData, data, pageNum) => {
    //编辑数据
    const scoresData = data?.context?.[`${key}s`] || [];
    //判断数据长度
    const dataLength = scoresData?.length;
    //获取当前数据位置
    let myIndex = this.state().get(`${key}MyIndex`);
    let newData = this.state().get(`${key}Data`)?.toJS();
    //判断当前是否是第一页
    if (pageNum === 1 && dataLength) {
      //第一页 数据小于4条或者等于5条 直接返回
      if (dataLength < 4 || dataLength === 5) {
        this.mergeData({
          [`${key}Data`]: scoresData || [],
        });
        return;
        //第一页四条数据 插入查看更多
      } else {
        //第一页记录当前数据位置
        switch (dataLength) {
          case 4:
            myIndex = -2;
            break;
          default:
            myIndex = 0;
            break;
        }
        this.mergeData({ [`${key}MyIndex`]: myIndex });
        newData = scoresData;
        if (myIndex) {
          newData.splice(myIndex, 0, ...moreData);
        }
      }
      //非第一页 插入数据
    } else {
      newData.splice(+myIndex - 1, 0, ...scoresData);
    }

    //判断是否加载完成 完成删除查看更多
    //取最新列表长度
    const newDataLength = newData.length;
    //取最后一条数据排名
    const rowNum = newData?.[newDataLength - 1]?.rowNum;
    //最新列表长度减去查看更多数据，如果和最后一条数据排名相等，则没有下一页
    //没有下一页删除查看更多
    if (newDataLength - 1 >= rowNum && myIndex) {
      newData.splice(+myIndex - 1, 1);
    }

    this.mergeData({
      [`${key}Data`]: newData || [],
      [`${key}PageNum`]: pageNum,
    });
  };

  /**
   * 更改选中月份
   */
  changeDate = (begin, end) => {
    //更改选中月份
    this.mergeData({
      begin: begin,
      end: end,
      codeScoreData: [], //编码得分排行
      codeScorePageNum: 1, //编码得分排行页码
      codeScoreMyIndex: 0, //编码得分排行当前数据位置
      bugScoreData: [], //缺陷得分排行
      bugScorePageNum: 1, //缺陷得分排行分页
      bugScoreMyIndex: 0, //缺陷得分排行当前数据位置
    });
    this.personalRankingInit();
  };

  /**
   * 获取项目质量最佳top5
   * @private
   */
  _bestQualityTopFiveByProject = async () => {
    this.mergeData({ bestQualityTopFiveForProjectLoading: true });
    const projectStartYear = this.state().get('projectStartYear');
    const params = {
      startYear: projectStartYear !== null ? Number(projectStartYear) : null,
    };
    const res = await webapi.bestQualityTopFiveByProject(params);

    if (res.code === SUCCESS_CODE) {
      this.mergeData({ bestQualityTopFiveForProjectData: res.context || {} });
    }
    setTimeout(() => {
      this.mergeData({ bestQualityTopFiveForProjectLoading: false });
    }, 0);
  };

  /**
   * 获取迭代质量最佳top5
   * @private
   */
  _bestQualityTopFiveByIteration = async () => {
    this.mergeData({ bestQualityTopFiveForIterationLoading: true });
    const projectStartYear = this.state().get('projectStartYear');
    const params = {
      startYear: projectStartYear !== null ? Number(projectStartYear) : null,
    };
    const res = await webapi.bestQualityTopFiveByIteration(params);

    if (res.code === SUCCESS_CODE) {
      this.mergeData({ bestQualityTopFiveForIterationData: res.context || {} });
    }
    setTimeout(() => {
      this.mergeData({ bestQualityTopFiveForIterationLoading: false });
    }, 0);
  };

  /**
   * 查询 项目得分排行数据 按项目/按迭代
   */
  _getProjectScoreRank = async () => {
    this.mergeData({ projectScoreLoading: true });
    const projectScoreType = this.state().get('projectScoreType');
    const projectScorePageNum = this.state().get('projectScorePageNum');
    const iterationScorePageNum = this.state().get('iterationScorePageNum');
    const projectScoreDataSource = this.state()
      .get('projectScoreDataSource')
      ?.toJS();
    const iterationScoreDataSource = this.state()
      .get('iterationScoreDataSource')
      ?.toJS();
    const projectStartYear = this.state().get('projectStartYear');
    const startYear = Number(projectStartYear);
    const param = startYear !== 0 ? `/?startYear=${startYear}` : '';
    //是否展示项目
    const isProject = projectScoreType === '0';
    //默认按项目
    let url = `/statistics/${projectScorePageNum}/query-project-rank${param}`;
    // 项目得分排行 0: 按项目 1: 按迭代
    if (!isProject) {
      url = `/statistics/${iterationScorePageNum}/query-iteration-rank${param}`;
    }
    const res = await webapi.queryProjectScoreRank(url);

    if (res?.code === SUCCESS_CODE) {
      //总数
      const total = res.context.total;
      if (isProject) {
        const projectRankDataList = res.context.projectRankDataList;
        const dataSource = projectScoreDataSource.concat(projectRankDataList);
        this.mergeData({
          projectScoreDataSource: dataSource || [],
          isShowProjectScoreGetMore: dataSource.length < total,
        });
      } else {
        const iterationRankDataList = res.context.iterationRankDataList;
        const dataSource = iterationScoreDataSource.concat(
          iterationRankDataList,
        );
        this.mergeData({
          iterationScoreDataSource: dataSource || [],
          isShowProjectScoreGetMore: dataSource.length < total,
        });
      }
    }
    setTimeout(() => {
      this.mergeData({ projectScoreLoading: false });
    }, 0);
  };

  /**
   * 切换项目得分排行类型
   */
  changeProjectScoreType = (e) => {
    const projectScoreDataSource = this.state().get('projectScoreDataSource');
    const iterationScoreDataSource = this.state().get(
      'iterationScoreDataSource',
    );
    this.mergeData({ projectScoreType: e });

    // 只有第一页在切换按项目/按迭代时查询数据
    if (
      (e === '0' && !projectScoreDataSource.size) ||
      (e === '1' && !iterationScoreDataSource.size)
    ) {
      //切换后查询列表数据
      this._getProjectScoreRank();
    }
  };

  /**
   * 点击项目得分排行查看更多
   */
  changeProjectScorePageNum = () => {
    const projectScoreType = this.state().get('projectScoreType');
    const projectScorePageNum = this.state().get('projectScorePageNum');
    const iterationScorePageNum = this.state().get('iterationScorePageNum');
    //按项目
    if (projectScoreType === '0') {
      this.mergeData({
        projectScorePageNum: projectScorePageNum + 1,
      });
    } else {
      this.mergeData({
        iterationScorePageNum: iterationScorePageNum + 1,
      });
    }
    setTimeout(() => {
      this._getProjectScoreRank();
    }, 0);
  };

  /**
   * 获取项目迭代组织列表
   */
  _getIterationGroupList = async () => {
    const thousandCodeProjectType = this.state().get('thousandCodeProjectType');
    const selectedKeys = this.state().get('selectedKeys');
    //开始年份
    const startYear = this.state().get('startYear');
    const params = {
      startYear: Number(startYear),
    };
    const res = await webapi.thousandCodeIterationGroupList(
      thousandCodeProjectType,
      params,
    );
    if (res?.code === SUCCESS_CODE) {
      const projectId = res.context.projectTreeData?.[0]?.id;
      let thousandCodeIterationGroupList =
        res.context.projectTreeData.length > 0
          ? res.context.projectTreeData
          : [{}];
      //有选中查询
      if (selectedKeys.size) {
        this.changeThousandCodeParams({ iterationId: projectId });
      }
      this.mergeData({
        thousandCodeIterationGroupList: thousandCodeIterationGroupList,
      });
    }
  };

  /**
   * 获取影响代码行数详情
   */
  _getCodeDetail = async () => {
    this.mergeData({ thousandCodeLoading: true });
    const thousandCodeParams = this.state().get('thousandCodeParams');
    const res = await webapi.thousandCodeCodeDetail(thousandCodeParams);
    if (res?.code === SUCCESS_CODE) {
      const timeFrame = {
        beginTime: res.context?.beginTime || null,
        endTime: res.context?.endTime || null,
      };
      this.mergeData({ thousandCodeDataSource: res.context || {} });
      this.mergeData({ timeFrame: timeFrame });
      if (res.context?.beginTime == null && res.context?.endTime == null) {
        this.mergeData({
          isShowTimeFrame: false,
        });
      } else {
        this.mergeData({
          isShowTimeFrame: true,
        });
      }
    }
    setTimeout(() => {
      this.mergeData({ thousandCodeLoading: false });
    }, 0);
  };

  /**
   * 变更获取影响代码行数详情请求参数
   */
  changeThousandCodeParams = async (params) => {
    this.mergeData({ thousandCodeLoading: true });
    const thousandCodeParams = this.state().get('thousandCodeParams');
    const thousandCodeProjectType = this.state().get('thousandCodeProjectType');
    let newParams;
    newParams = Object.assign({}, thousandCodeParams.toJS(), params);
    //基线产品去除projecId属性
    if (thousandCodeProjectType == 0 && newParams.hasOwnProperty('projectId')) {
      delete newParams.projectId;
    }

    this.mergeData({ thousandCodeParams: newParams });
    const res = await webapi.thousandCodeCodeDetail(newParams);
    if (res?.code === SUCCESS_CODE) {
      const timeFrame = {
        beginTime: res.context?.beginTime || null,
        endTime: res.context?.endTime || null,
      };
      this.mergeData({ thousandCodeDataSource: res.context || {} });
      this.mergeData({ timeFrame: timeFrame });
      if (res.context?.beginTime == null && res.context?.endTime == null) {
        this.mergeData({
          isShowTimeFrame: false,
        });
      } else {
        this.mergeData({
          isShowTimeFrame: true,
        });
      }
    }
    setTimeout(() => {
      this.mergeData({ thousandCodeLoading: false });
    }, 0);
  };

  /**
   * 清数据
   */
  clearData = () => {
    this.mergeData({
      thousandCodeDataSource: {},
    });
  };

  /**
   * 实时人效页 搜索条件初始化
   */
  _qualityInspectionInit = async () => {
    let selectParam = {
      function_prefix: 'f_humane_effect',
    };
    await Promise.all([
      webapi.getProjectTypeList(selectParam),
      webapi.getBelongBaselineList(selectParam),
    ]).then(async (firstResults) => {
      const searchParams = JSON.parse(localStorage.getItem("effectiveness-measurement-searchParams") || '{}');
      const firstProjectType = searchParams?.projectType ?? firstResults?.[0]?.context?.[0]?.value;
      const firstbelongBaselinee = searchParams?.belongBaseline ?? firstResults?.[1]?.context?.[0]?.value;
      const startYear = searchParams?.startYear || new Date().getFullYear().toString();

      let conditions = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        ...searchParams,
      };
      // 销毁临时缓存的数据
      localStorage.removeItem("effectiveness-measurement-searchParams");

      let param = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        functionCode: 0,
      };
      if (firstProjectType == 0) {
        param['startYear'] = null;
      }

      if (firstProjectType == 0) {
        const res = await webapi.getIterationList({
          projectId: null,
          startYear,
          projectType: 0,
          belongBaseline: firstbelongBaselinee,
          onlyTested: false,
          functionCode: 0,
        });
        if (res.code === SUCCESS_CODE) {
          let conditionsData = {
            projectType: firstResults[0].context,
            belongBaseline: firstResults[1].context,
            iteration: res.context,
            project: null,
          };
          this.mergeData({
            realtimeEffectConditionsData: conditionsData,
            realtimeEffectConditions: conditions,
          });
        } else {
          message.error(res.message);
        }
      } else {
        const [projectList, iterationList] = await promiseAllSettled([
          webapi.getProjectList(param),
          webapi.getIterationList({
            projectId: searchParams?.projectId,
            belongBaseline: firstbelongBaselinee,
            projectType: firstProjectType,
            startYear,
            onlyTested: false,
            functionCode: 0,
          }),
        ]);

        this.mergeData({
          realtimeEffectConditions: conditions,
          realtimeEffectConditionsData: {
            projectType: firstResults?.[0]?.context || [],
            belongBaseline: firstResults?.[1]?.context || [],
            project: projectList?.context || [],
            iteration: iterationList?.context || [],
          },
        });
      }

      // 实时人效 - 项目类型 - 默认选中第一个
      this.mergeData({ realtime_effect_projectType: firstProjectType});
    });
    this.realtiomeEffectSearch();
  };

  /*
   * 实时人效页 变更查询条件
   */
  changeConditions = async (value, type) => {
    const realtimeEffectConditions = this.state().get(
      'realtimeEffectConditions',
    );
    const realtimeEffectConditionsData = this.state().get(
      'realtimeEffectConditionsData',
    );
    let newConditions = { ...realtimeEffectConditions.toJS() };
    let newConditionsData = { ...realtimeEffectConditionsData.toJS() };
    newConditions[type] = value;
    if (type !== 'iteration') {
      newConditions.iteration = null;
      if (type !== 'project') {
        newConditions.project = null;
      }
    }
    this.mergeData({ realtimeEffectConditions: newConditions });
    this.mergeData({
      isShowTimeFrame: false,
    });
    let { project, projectType, startYear, belongBaseline } = newConditions;
    if (projectType == 0) {
      let param = {
        projectId: project || null,
        startYear: startYear || null,
        projectType: 0,
        belongBaseline,
        onlyTested: false,
        functionCode: 0,
      };
      const res = await webapi.getIterationList(param);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({
          realtimeEffectConditionsData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    } else {
      let param = {
        projectId: project || null,
        belongBaseline,
        projectType: 1,
        startYear: startYear || null,
        onlyTested: false,
        functionCode: 0,
      };
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 0,
      };
      const res = await webapi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({
          realtimeEffectConditionsData: newConditionsData,
        });
      }
      const iterationListRes = await webapi.getIterationList(param);
      if (iterationListRes.code === SUCCESS_CODE) {
        newConditionsData.iteration = iterationListRes.context;
        this.mergeData({
          realtimeEffectConditionsData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    }
  };

  /*
   * 实时人效页 搜索
   */
  realtiomeEffectSearch = async () => {
    this.mergeData({ realtimeEffectLoading: true });
    const {
      startYear,
      projectType,
      belongBaseline,
      project,
      pageNum,
      pageSize,
      iteration,
    } = this.state().get('realtimeEffectConditions').toJS();
    const params = {
      startYear,
      projectType,
      belongBaseline,
      projectId: project || null,
      pageNum: pageNum,
      pageSize,
      iterationId: iteration || null,
    };
    const res = await webapi.getEffectPage(params);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        realtimeEffectDataSource: res.context,
        realtimeEffectTableType: projectType,
      });

      // 实时人效 - 项目类型
      this.mergeData({ realtime_effect_projectType: projectType, });
    }
    this.mergeData({ realtimeEffectLoading: false });
  };

  /*
   * 实时人效页 列表分页改变
   */
  changePage = (pageNum, pageSize) => {
    let realtimeEffectConditions = this.state()
      .get('realtimeEffectConditions')
      .toJS();
    realtimeEffectConditions.pageNum = pageNum;
    realtimeEffectConditions.pageSize = pageSize;
    this.mergeData({ realtimeEffectConditions });
    this.realtiomeEffectSearch();
  };

  /**
   * 展示明细
   * @param rowInfo
   */
  showDrawerModal = async (rowInfo: any, _) => {
    const records = this.state().getIn(['realtimeEffectDataSource', 'records']);
    //项目维度
    if (rowInfo?.children?.length) {
      rowInfo.title = rowInfo.name + '投入明细';
    } else {
      // 迭代维度 需要向上寻找项目的name
      const a = records.find((record) =>
        record
          ?.get('children')
          ?.some((children) => children?.get('id') == rowInfo.id),
      );
      rowInfo.title = (a ? a?.get('name') : '') + rowInfo.name + '投入明细';
    }
    this.transaction(() => {
      this.changeData('detailInfo', fromJS(rowInfo));
      this.changeData('drawerVisible', true);
    });
    this.initHumaneEffect();
  };

  /**
   * 切换tab 查询
   * @param type
   */
  changeTab = async (type) => {
    this.changeData('drawerActiveKey', type);
    this.initHumaneEffect();
  };

  canCelDrawer = () => {
    // 1.关闭抽屉
    this.changeData('drawerVisible', false);
    // 2.初始化tab
    this.changeData('drawerActiveKey', '1');
    // 3.清空数据
    this.mergeData({
      investmentTotalDetail: {
        dateList: [], //表头
        dataList: [], //表格数据
        totalVoList: [], //每日总计
        baseOfWorkingHours: 0, //工时标准
        totalEffort: 0, //计划总投入人天
        workDateList: [], //所有工作日日期
      },
      investmentPhaseDetail: {
        dateList: [], //每月日期
        dataList: [], //表格数据
        totalVoList: [], //每日总计
        baseOfWorkingHours: 0, //工时标准
        workDateList: [], //所有工作日日期
      },
    });
  };

  /**
   * 查询表格信息
   */
  initHumaneEffect = async () => {
    const type = this.state().get('drawerActiveKey');
    const detailInfo = this.state().get('detailInfo');
    //设置表格loading
    this.transaction(() => {
      this.changeData('humaneEffectDetailLoading', true);
      this.changeData('humaneEffectMileStoneLoading', true);
    });
    // 如有children 为项目 否则为迭代
    const key =
      detailInfo.get('children')?.size > 0 ? 'projectId' : 'iterationId';
    const res = await webapi.getHumaneEffectDetail({
      [key]: detailInfo.get('id'),
      type,
    });
    if (res.code === SUCCESS_CODE) {
      this.changeData('investmentTotalDetail', fromJS(res.context));
    }
    this.changeData('humaneEffectDetailLoading', false);
    const res1 = await webapi.getHumaneEffectMileStone({
      [key]: detailInfo.get('id'),
      type,
    });
    if (res1.code === SUCCESS_CODE) {
      this.changeData('investmentPhaseDetail', fromJS(res1.context));
    }
    this.changeData('humaneEffectMileStoneLoading', false);
  };
}
