import React from 'react';
import { Relax } from 'plume2';
import { Select, Button } from 'antd';
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
const { Option } = Select;

@Relax
export default class SelectGroup extends React.Component<any, any> {
  static relaxProps = {
    detailSearch: 'detailSearch',
    detailSearchData: 'detailSearchData',
    changeDetailSearch: noop,
    searchDetail: noop,
    bugRepairsPageVOPage: 'bugRepairsPageVOPage'
  };

  render() {
    const {
      detailSearch,
      detailSearchData,
      searchDetail,
      bugRepairsPageVOPage
    } = this.props.relaxProps;
    const { projectType, belongBaseline, project, iteration } = detailSearchData.toJS();
    // projectType 0:基线  1:定制
    const isCustomMade = detailSearch.get('projectType') == 1 ? true : false;


    return (
      <div className="detail-select-contain">
        {/* 筛选项组合 */}
        <OneAuthWrapper functionName="f_bug_fix_project_select_list,f_bug_fix_project_select_list_all">
          {isCustomMade && <Select
            placeholder="请选择项目"
            style={{ width: 300, marginRight: 10 }}
            disabled={detailSearch.get('belongBaseline') === undefined}
            value={detailSearch?.get('project') ? detailSearch?.get('project') : null}
            onChange={e => this._handleChange(e, 'project')}
            notFoundContent="暂无项目"
            dropdownClassName="project-iteration-select-dropdown"
          >
            {detailSearch?.get('project') && project?.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>}
        </OneAuthWrapper>


        <AuthWrapper functionName="f_bug_fix_iteration_select_list"><Select
          showSearch
          placeholder="请选择迭代"
          style={{ width: 300 }}
          disabled={this._getShow(isCustomMade)}
          defaultValue={null}
          value={detailSearch.get('iteration')}
          onChange={e => this._handleChange(e, 'iteration')}
          notFoundContent="暂无迭代"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
        >
          <Option value={null}>全部</Option>
          {iteration && iteration.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </AuthWrapper>
      </div>
    );
  }

  _getShow = (isCustomMade) => {
    const {
      detailSearch,
    } = this.props.relaxProps;
    if (isCustomMade) {
      return detailSearch.get('project') ? false : true
    } else {
      return false
    }
  }

  _handleChange = (value, type) => {
    let { changeDetailSearch, searchDetail } = this.props.relaxProps;
    changeDetailSearch(value, type);
    searchDetail()
  }
}
