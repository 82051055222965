import React from "react";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import { OneAuthWrapper, AuthWrapper } from "@utils";
import CustomerList from "./components/customer_list";
import LineCharts from "./components/line_charts";
import PageTitle from "./components/page_title";
import RangePickerModal from "./components/range_picker_modal";
import "./index.less";

/**
 * 运营数据
 */
@StoreProvider(AppStore)
class OperationalData extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
    //初始化数据
    this.store.init();
  }
  render() {
    return (
      <OneAuthWrapper functionName="f_operational_data,f_customer_list">
        <div className="common_width_margin_fit skyeye_pc_operational_data">
          {/** 页面标题 */}
          <PageTitle />

          {/** 图表部分 */}
          <AuthWrapper functionName="f_operational_data">
            <LineCharts />
          </AuthWrapper>

          {/** 客户列表 */}
          <AuthWrapper functionName="f_customer_list">
            <CustomerList />
          </AuthWrapper>

          {/** 自定义日期选择弹窗 */}
          {this.store.get("visible") && <RangePickerModal />}
        </div>
      </OneAuthWrapper>

    );
  }
}
export default OperationalData;
