import { Store } from "plume2";
import MainActor from "./actor/actor";
import { SUCCESS_CODE } from "@utils";
import * as webapi from "./webapi";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 初始化
   */
  init = () => {
    this._getPopularRecommendation();
    this._getMust_read_for_new_people();
    this._getPlatform();
    this._getCalendarHeatmap();
    //获取当月花费工时和排名
    this._getSpentRank();
  };

  /**
   * 获取 - 热门推荐 - 数据
   */
  _getPopularRecommendation = async (num = 1) => {
    const params = {
      // 内容类型：0-热门推荐、1-新人必读、2-快速导航入口
      contentType: 0,
      // 分页页码：1为第一页
      pageNum: num,
      // 一页多少条
      pageSize: 10,
    };
    const data = await webapi.getPageList(params);

    if (data.code === SUCCESS_CODE) {
      let popularRecommendation_obj = this.state()
        .get("popularRecommendation_obj")
        ?.toJS();
      const { records, ...rest } =
        data.context?.systemContentSettingVOPage || {};

      if (
        Array.isArray(popularRecommendation_obj.records) &&
        Array.isArray(records)
      ) {
        popularRecommendation_obj = {
          ...rest,
          records: [...popularRecommendation_obj.records, ...records],
        };
      } else {
        popularRecommendation_obj =
          data.context?.systemContentSettingVOPage || {};
      }

      this.mergeData({ popularRecommendation_obj: popularRecommendation_obj });
    }
  };

  /**
   * 获取 - 新人必读 - 数据
   */
  _getMust_read_for_new_people = async () => {
    const params = {
      // 内容类型：0-热门推荐、1-新人必读、2-快速导航入口
      contentType: 1,
      // 分页页码：1为第一页
      pageNum: 1,
      // 一页多少条
      pageSize: 10,
    };
    const data = await webapi.getPageList(params);

    if (data.code === SUCCESS_CODE) {
      this.mergeData({
        must_read_for_new_people_obj:
          data.context?.systemContentSettingVOPage || {},
      });
    }
  };

  /**
   * 获取 - 快速导航入口 - 数据
   */
  _getPlatform = async () => {
    const params = {
      // 内容类型：0-热门推荐、1-新人必读、2-快速导航入口
      contentType: 2,
      // 分页页码：1为第一页
      pageNum: 1,
      // 一页多少条
      pageSize: 10,
    };
    const data = await webapi.getPageList(params);

    if (data.code === SUCCESS_CODE) {
      this.mergeData({
        platform_obj: data.context?.systemContentSettingVOPage || {},
      });
    }
  };

  /**
   * 获取 - 热力图 - 数据
   */
  _getCalendarHeatmap = async () => {
    const data = await webapi.getCalendarHeatmap();

    if (data.code === SUCCESS_CODE) {
      this.mergeData({
        calendarHeatmapData: data.context?.staticsList || [],
      });
    }
  };

  /**
   * 获取当月花费工时和排名
   */
  _getSpentRank = async () => {
    const data = await webapi.getSpentTotal();
    const result = await Promise.all([webapi.getSpentTotal(), webapi.getRank()]);
    let context = {"rank": 0, "spent": 0};
    if (result[0].code === SUCCESS_CODE) {
      context.spent = result[0].context?.spent || 0;
    }

    if (result[1].code === SUCCESS_CODE) {
      context.rank = result[1].context?.rank || 0;
    }
    this.mergeData({
      spentRankData: context
    });
  };
}
