import React from "react";
import { Relax } from "plume2";
import { Table, } from 'antd';
import { noop } from "@utils";
import { IActor } from "../../type";
import { IterationStatusText } from '@components';
import AppStore from "../../store";
// 进度条 - 组件
import Progress from './../progress';
import './index.less';

interface IComponentProps {
    relaxProps?: Partial<IActor> & AppStore;
}

/**
 * 项目列表、迭代列表
 */
@Relax
export default class IterationList extends React.Component<IComponentProps, any> {

    static relaxProps = {
        projectListTitle: 'projectListTitle',
        projectListDataSource: 'projectListDataSource',
        fetchProjectListPageParams: 'fetchProjectListPageParams',
        projectListTotal: 'projectListTotal',
        activeRowKey: 'activeRowKey',
        isSpinning: 'isSpinning',
        onPaginationChange: noop,
        onRowClick: noop,
        changeLoadingFn: noop,
    };

    render() {
        let { 
            projectListTitle, projectListDataSource, fetchProjectListPageParams, 
            projectListTotal, onPaginationChange, onRowClick, changeLoadingFn,
            activeRowKey, isSpinning,
        } = this.props.relaxProps || {};
        projectListDataSource = projectListDataSource?.toJS?.() || [];
        const { projectType, pageNum, pageSize, } = fetchProjectListPageParams?.toJS?.() || {};

        return (
            <div className="project_list_iteration_list">
                {
                    projectListTitle ? (
                        <div className="project_list_iteration_list__title">{ projectListTitle }</div>
                    ) : null
                }

                <div className="project_list_iteration_list__table">
                    <Table 
                        dataSource={ projectListDataSource }
                        rowKey="id"
                        pagination={{
                            current: pageNum,
                            pageSize,
                            total: projectListTotal,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            showTotal: total => `共 ${total} 条`,
                            onChange: (page, pageSize) => {
                                // 开启loading
                                changeLoadingFn?.(true);
                                onPaginationChange?.(page, pageSize);
                            },
                        }}
                        onRow={record => {
                          return {
                            onClick: () => {
                                // 开启loading
                                changeLoadingFn?.(true);
                                onRowClick?.(record);

                                // 滚动至迭代明细
                                setTimeout(() => {
                                    this.scrollToIterationDetails();
                                });
                            },
                          };
                        }}
                        rowClassName={(record) => record?.id === activeRowKey ? 'project_list_iteration_list__table--activeRow' : ''}
                        loading={ isSpinning }
                    >
                        <Table.Column 
                            title="项目/迭代名称" 
                            dataIndex="name" 
                            key="name"
                            width={ 216 }
                            render={(text, record: any) => {
                                if(!text) return "-";
                                if(!record?.no) return text;

                                return `${ record?.no }${ text }`;
                            }}
                        />

                        {
                            projectType === 1 ? (
                                <Table.Column 
                                    title="项目经理" 
                                    dataIndex="projectManagerName" 
                                    key="projectManagerName"
                                    width={ 88 }
                                    align="center"
                                    render={text => text ?? '-'}
                                />
                            ) : null
                        }

                        {
                            projectType === 0 ? (
                                <Table.Column 
                                    title="产品经理" 
                                    dataIndex="implementationManagerName" 
                                    key="implementationManagerName" 
                                    width={ 88 }
                                    align="center"
                                    render={text => text ?? '-'}
                                />
                            ) : null
                        }
                        
                        <Table.Column 
                            title="开发组长" 
                            dataIndex="devLeadName" 
                            key="devLeadName"
                            width={ 88 }
                            align="center"
                            render={text => text ?? '-'}
                        />
                        <Table.Column 
                            title="当前进度" 
                            dataIndex="progressRate" 
                            key="progressRate"
                            width={ 182 }
                            align="center"
                            render={text => {
                                return (<Progress percent={ text ?? 0 } />);
                            }}
                        />

                        {
                            projectType === 1 ? (
                                <Table.Column 
                                    title="立项时间" 
                                    dataIndex="projectDate" 
                                    key="projectDate"
                                    width={ 122 }
                                    align="center"
                                    render={text => text ?? '-'}
                                />
                            ) : null
                        }

                        <Table.Column 
                            title="开始时间" 
                            dataIndex="startDate" 
                            key="startDate"
                            width={ 122 }
                            align="center"
                            render={text => text ?? '-'}
                        />

                        {
                            projectType === 0 ? (
                                <Table.Column 
                                    title="转测时间" 
                                    dataIndex="testDate" 
                                    key="testDate"
                                    width={ 122 }
                                    align="center"
                                    render={text => text ?? '-'}
                                />
                            ) : null
                        }

                        <Table.Column 
                            title="完成时间" 
                            dataIndex="finishDate" 
                            key="finishDate"
                            width={ 166 }
                            align="center"
                            render={(text, record: any) => {
                                if(!text) return "-";
                                if(record?.status === 0 || (projectType === 1 && record?.status === 1)) {
                                    return `计划：${ text }`;
                                }

                                return text;
                            }}
                        />
                        <Table.Column 
                            title="状态" 
                            dataIndex="status" 
                            key="status"
                            width={ 76 }
                            align="center"
                            render={text => (
                                <div className='project_list_iteration_list__table--status'>
                                    <IterationStatusText 
                                        projectType={ projectType } 
                                        status={ text } 
                                    />
                                </div>
                            )}
                        />
                    </Table>
                </div>
            </div>
        );
    }

    /**
     * 滚动至迭代明细 - 操作
     */
    scrollToIterationDetails = () => {
        const element = document.getElementById('quality_assurance_project_list__content');
        const headerOffset = 60;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

};
