import React from "react";
import { Relax } from "plume2";
import { fromJS } from "immutable";
import { IActor } from "../../type";
import AppStore from "../../store";
import { Tabs } from 'antd';
import { noop } from "@utils";
// 迭代明细 - 组件
import IterationDetails from "./../iteration-details";
// 里程碑清单 - 组件
import MilestoneList from './../milestone-list';
import './index.less';

interface IComponentProps {
    relaxProps?: Partial<IActor> & AppStore;
}

/**
 * 定制项目 - 迭代标签菜单
 */
@Relax
export default class IterationTabs extends React.Component<IComponentProps, any> {

    static relaxProps = {
        iterationDetailsList: 'iterationDetailsList',
        activeTabKey: 'activeTabKey',
        onTabsChange: noop,
    };

    render() {
        let { 
            iterationDetailsList, activeTabKey,
            onTabsChange,
        } = this.props.relaxProps || {};
        iterationDetailsList = iterationDetailsList?.toJS?.() || [];
        if(!Array.isArray(iterationDetailsList) || !iterationDetailsList.length) return null;

        return (
            <div className="project_list_iteration_tabs">
                <Tabs
                    activeKey={ String(activeTabKey) }
                    onChange={(activeKey) => onTabsChange?.(activeKey)}
                >
                    {
                        iterationDetailsList?.map?.(item => {
                            if(!item || !Object.keys(item).length) return null;

                            const { mileStoneList, ...restItem } = item;
                            return (
                                <Tabs.TabPane 
                                    key={ item?.id }
                                    tab={ item?.name } 
                                >
                                    {/* 迭代明细 - 组件 */}
                                    <IterationDetails 
                                        projectType={ 0 } 
                                        iterationDetailsTitle=""
                                        iterationDetailsMap={ fromJS(restItem) }
                                    />
            
                                    {/* 里程碑清单 - 组件 */}
                                    <MilestoneList 
                                        milestoneList={ fromJS(mileStoneList) }
                                    />
                                </Tabs.TabPane>
                            );
                        })
                    }
                </Tabs>
            </div>
        );
    }
};
