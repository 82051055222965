import { Axios } from "@utils";

//  项目类型下拉列表
export const getProjectTypeList = (params = {}) => {
  return Axios("/select-list/project-type", "post", params);
};

//  所属基线下拉列表
export const getBelongBaselineList = (params = {}) => {
  return Axios("/select-list/belong-baseline", "post", params);
};

//  项目下拉列表
export const getProjectList = (params = {}) => {
  return Axios("/select-list/project", "post", params);
};

//  迭代下拉列表
export const getIterationList = (params = {}) => {
  return Axios("/select-list/iteration", "post", params);
};

//  趋势图
export const getTrendChart = (params = {}) => {
  return Axios("/bug-repairs/charts", "post", params);
};

//  缺陷明细
export const getBugDetail = (params = {}) => {
  return Axios("/bug-repairs/detail", "post", params);
};

//  缺陷周数据
export const getBugWeeks = (params = {}) => {
  return Axios("/bug-repairs/get-weeks", "post", params);
};

// 缺陷汇总
export const getBugTotal = (params = {}) => {
  return Axios("/bug-repairs/page", "post", params);
};


// 修复明细数量
export const getBugDetailCount = (params = {}) => {
  return Axios("/bug-repairs/detail/count", "post", params);
};
