import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      selectType: "0", // 新增客户数 - 选中类型 0: 今日 1: 本周 2: 本月 3: 全年 4: 自定义
      rangeData: [], //所选时间范围
      customerIncreaseData: {}, //新增客户数数据
      customerOverviewData: {}, //客户概览数据 - 累计客户数，活跃客户数，沉默客户数
      lineChartsData: [], //折线图数据
      listData: [], //列表数据
      listPage: {
        total: 0,
        size: 10,
        current: 1,
      }, //列表分页
      visible: false, //自定义弹窗开关
      tabType: "0", //0: 新增客户数 1: 累计客户数 2: 活跃客户数 3: 沉默客户数
      searchValue: "", //搜索内容
      updateTime: "", //更新时间
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
