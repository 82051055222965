import React from 'react';
import { Relax } from 'plume2';
import { noop, util } from "@utils";
import ReactEcharts from "echarts-for-react";


@Relax
export default class ProjectList extends React.Component<any, any> {
  static relaxProps = {
    scoreType: 'scoreType',
    customScoreType: 'customScoreType',
    forReason: 'forReason',
    forUser: 'forUser',
    forFixUser: 'forFixUser',
    forModule: 'forModule',
    forYype: 'forYype',
    tabType: 'tabType',
    demandOptimizationFlag: 'demandOptimizationFlag',
    customDemandOptimizationFlag: 'customDemandOptimizationFlag',
    mergeData: noop,
  };
  render() {
    return (
      <div className="chart">
        <ReactEcharts
          option={this._getOption()}
          notMerge={true}
          lazyUpdate={true}
          style={{ minHeight: "400px" }}
        />
      </div>
    );
  }


  /**
  * echarts配置
  */

  _getOption = () => {

    let option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        className: 'bug-analysis-bar-chart-tooltip',
        // 控制提示弹窗的滚动条
        enterable: true,
        position: function(pos, params, dom, rect, size) {
          const [x, y] = pos || [];
          if(!Array.isArray(pos) || !pos.length) return pos;
          if(typeof x !== 'number' || typeof y !== 'number') return pos;

          let [width, height] = size?.contentSize || [];
          if(typeof height !== 'number') {
            height = 0;
          } 
          return [x + 20, y - height - 10];
        },
      },
      // 头部提示图标
      legend: {
        left: '0%',
        itemWidth: 8,
        itemHeight: 8,
        textStyle: {
          color: '#666666',
          fontSize: 12,
          padding: [0, 0, 0, 0],
          // 按缺陷类型设置的间距
          backgroundColor: 'transparent',
          width: 120
        },
        // 文字过长自动省略
        formatter: function (name) {
          return (name.length > 11) ? (name.slice(0, 11) + '...') : name
        },
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 40,
        top: this._getGridTop(),
        containLabel: true,
        height: 'auto',
      },
      xAxis: [
        {
          type: 'category',
          data: this._getXaxisData(),
          axisLine: {
            lineStyle: {
              color: "#999999",
              fontSize: 12
            },

          },
          axisTick: {
            show: false // 去除刻度线
          },
          axisLabel: {
            color: "#666666 ", // 不设置默认取axisLine.lineStyle的色值
            fontSize: 12,
          },
        }
      ],
      yAxis: [
        {
          min: function (value) {//取最小值向下取整 * 10 为最小刻度
            return Math.floor(value.min / 10) * 10
          },
          // max: function (value) {//取最大值向上取整 * 10 为最大刻度
          //   return Math.ceil(value.max / 10) * 10
          // },
          axisLabel: {
            color: "#666666",
            fontSize: 12,
          },
          splitLine: { //多条横线
            show: true,
            lineStyle: {
              color: "rgba(0, 0, 0, 0.15)",
              width: 1
            }
          }
        }
      ],
      dataZoom: [
        {
          type: "slider",
          left: "4%",
          right: "4%",
          height: 20,
          textStyle: {
            color: "#666666",
            fontSize: 12,
          },
          bottom: 0,
          borderColor: '#FFF2E9',
          brushSelect: false,//是否开启刷选功能。
          backgroundColor: "rgba(230, 87, 44, 0)",
          dataBackground: {
            areaStyle: {
              color: "rgba(255, 102, 0, 0.1)"
            },
            lineStyle: {
              color: "rgba(255, 102, 0, 0.3)"
            }
          },
          selectedDataBackground: {
            lineStyle: {
              color: "rgba(255, 102, 0, 0.3)",
              width: 1,
              opacity: 1
            },
            areaStyle: {
              color: "rgba(255, 102, 0, 0.1)"
            }
          },
          fillerColor: "rgba(225, 140, 50, 0.1)"
        },

      ],

      series: this._getSeries()
    };
    return option
  }

  _getGridTop = () => {
    const { scoreType, forReason, forUser, forModule, forYype, tabType, customScoreType, forFixUser } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let base = 40;
    let step = 20
    if (tab == 1) {
      const { titleDatas } = forUser?.toJS?.() || {}
      let len = titleDatas?.length / 8 || 0
      if (len > 1) {
        base = base + Math.ceil(len) * step;
      } else {
        base = base + step
      }
    } else if (tab == 2) {
      const { titleDatas } = forYype?.toJS?.() || {}
      let len = titleDatas?.length / 8 || 0
      if (len > 1) {
        base = base + Math.ceil(len) * step;
      } else {
        base = base + step
      }
    } else if (tab == 3) {
      const { titleDatas } = forReason?.toJS?.() || {}
      let len = titleDatas?.length / 8 || 0
      if (len > 1) {
        base = base + Math.ceil(len) * step;
      } else {
        base = base + step
      }
    } else if(tab == 4){
      const { titleDatas } = forModule?.toJS?.() || {}
      let len = titleDatas?.length / 8 || 0
      if (len > 1) {
        base = base + Math.ceil(len) * step;
      } else {
        base = base + step
      }
    } else if(tab == 5){
      const { titleDatas } = forFixUser?.toJS?.() || {}
      let len = titleDatas?.length / 8 || 0
      if (len > 1) {
        base = base + Math.ceil(len) * step;
      } else {
        base = base + step
      }
    }
    return base
  }

  /**
  * x轴的数据
  */
  _getXaxisData = () => {
    const { scoreType, forReason, forUser, forModule, forYype, tabType, customScoreType, forFixUser } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let arr = [];
    if (tab == 1) {
      forUser?.toJS()?.bugAnalyzeDataS?.map?.((v) => {
        arr.push(v?.deUser)
      })
    } else if (tab == 2) {
      forYype?.toJS()?.bugAnalyzeDataS?.map?.((v) => {
        arr.push(v?.deUser)
      })
    } else if (tab == 3) {
      forReason?.toJS()?.bugAnalyzeDataS?.map?.((v) => {
        arr.push(v?.deUser)
      })
    } else if(tab == 4){
      forModule?.toJS()?.bugAnalyzeDataS?.map?.((v) => {
        arr.push(v?.deUser)
      })
    } else if(tab == 5){
      forFixUser?.toJS()?.bugAnalyzeDataS?.map?.((v) => {
        arr.push(v?.fixUser)
      })
    }
    return arr
  }

  /**
  * 图表数据配置
  */
  _getSeries = () => {
    const { scoreType, demandOptimizationFlag, customDemandOptimizationFlag, forReason, forUser, forModule, forYype, tabType, customScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let serise = [];
    if (tab == 1) {
      let titleDatas = [
        { type: "fatal", text: "致命" },
        { type: "serious", text: "严重" },
        { type: "normal", text: "一般" },
        { type: "prompt", text: "提示" },
        { type: "advice", text: "建议" }
      ]
      let data = this._getSeriesOption(titleDatas)
      serise = data
    } else if (tab == 2) {
      let { titleDatas } = forYype?.toJS?.() || {}
      let data = this._getSeriesOption(titleDatas)
      data = data.filter((v) => v.param != 'total');
      // 判断是否过滤 需求/优化类数据 201 202 203 204 801
      let fiterType = ['201xuqiushejicuowu', '202xuqiuyilou', '203xuqiueryixing/miaoshubuqing', '204xuqiubiangeng', '801youhuagaijin']
      let filterData = data.filter((v) => !fiterType.includes(v.param))
      if (tabType == 1) {
        serise = demandOptimizationFlag == true ? data : filterData
      } else {
        serise = customDemandOptimizationFlag == true ? data : filterData
      }
    } else if (tab == 3) {
      let { titleDatas } = forReason?.toJS?.() || {}
      let data = this._getSeriesOption(titleDatas)
      data = data.filter((v) => v.param != 'total');
      serise = data
    } else if(tab == 4){
      let { titleDatas } = forModule?.toJS?.() || {}
      let data = this._getSeriesOption(titleDatas)
      data = data.filter((v) => v.param != 'total');
      serise = data
    } else if(tab == 5){
      let titleDatas = [
        { type: "fatal", text: "致命" },
        { type: "serious", text: "严重" },
        { type: "normal", text: "一般" },
        { type: "prompt", text: "提示" },
        { type: "advice", text: "建议" }
      ]
      let data = this._getSeriesOption(titleDatas)
      serise = data
    }
    return serise
  }


  /**
  * 获取图表具体配置信息
  */
  _getSeriesOption = (data) => {
    // echarts的数据格式
    // serise: [
    //   {
    //     name: '头部标题',
    //     statck: "Ad", //堆叠
    //     type: "bar", //图表类型
    //     color:'pink' ,// 小图标颜色
    //     data:[1，2，3],具体数据 ，与头部内容对应
    //   },
    //   {
    //     name: '头部标题',
    //     statck: "Ad", //堆叠
    //     type: "bar", //图表类型
    //     color: 'pink',// 小图标颜色，
    //     data: [1，2，3], 具体数据，与头部内容对应
    //   }
    // ]
    // 严重程度的颜色值是固定的 {致命：#FE6D46 ，严重：#FF9F4D，一般：#3CD495，提示：#B8ED72，建议：#46BFF2}
    let color = util.colorGradientArr('#FF6C47', '#B8ED72', data?.length)
    const { scoreType, tabType, customScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    if (tab == 1) {
      color = ['#FE6D46', '#FF9F4D', '#3CD495', '#B8ED72', '#46BFF2']
    }
    let serise = data?.map?.((v) => {
      return {
        name: v?.text,
        param: v?.type, // 用来处理业务逻辑，与echarts配置项无关
        stack: "Ad", //堆叠
        type: "bar", //图表类型
      }
    })
    // 拼接颜色属性
    let res = color?.map?.((v, index) => {
      return {
        color: v,
        ...this._getSeriesData(serise)?.[index]
      }
    })
    return res
  }

  /**
  * y轴数据处理
  */
  _getSeriesData = (data) => {
    data?.map((v, i) => {
      this._getSeriesItem(v, v?.param, v?.name)
    })
    return data
  }

  /**
  * 获取y轴具体值
  */
  _getSeriesItem = (value, param, type) => {
    const { scoreType, forReason, forUser, forModule, forYype, customScoreType, tabType, forFixUser } = this.props.relaxProps
    const tab = tabType == 1 ? scoreType : customScoreType
    let data = [];
    if (tab == 1) {
      data = forUser?.toJS()?.bugAnalyzeDataS
    } else if (tab == 2) {
      data = forYype?.toJS()?.bugAnalyzeDataS
    } else if (tab == 3) {
      data = forReason?.toJS()?.bugAnalyzeDataS
    } else if (tab == 4) {
      data = forModule?.toJS()?.bugAnalyzeDataS
    } else if (tab == 5) {
      data = forFixUser?.toJS()?.bugAnalyzeDataS
    }
    if (value.name == type) {
      let arr = [];
      data?.forEach?.((item) => {
        arr.push(item?.[param] || 0)
      })
      return value.data = arr
    }
  }


}
