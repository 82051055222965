import React from 'react';
import { BackTop } from 'antd';
// 回到顶部 - 图片
import back_top_bg from './img/back_top_bg.png';
import './index.less';

// 回到顶部
export default class SkyeyePcBackTop extends React.Component {
    render() {
        return (
            <BackTop className='skyeye_pc_back_top'>
                <img src={ back_top_bg } alt='back_top_bg' />
            </BackTop>
        );
    }
}