// logo - 图片
import { Image } from 'antd';
import logo from './img/logo.svg';
import './index.less';

/**
 * 数字化平台logo
 */
const SkyeyePcLogo = () => {
    return (
        <div className='skyeye_pc_logo'>
            <Image preview={false} src={ logo } alt="logo" />
        </div>
    );
}

export default SkyeyePcLogo;