import React from "react";
import { Relax } from "plume2";
import CalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";
import "react-calendar-heatmap/dist/styles.css";
import "./index.less";

const today = new Date();

// git日历热力图
@Relax
class GitCalendarHeatmap extends React.Component<any, any> {
  static relaxProps = {
    calendarHeatmapData: "calendarHeatmapData",
  };

  render() {
    const { calendarHeatmapData } = this.props.relaxProps;
    if (!calendarHeatmapData?.size) {
      return null;
    }
    return (
      <div className="skyeye_pc_home_sider_git_calendar_heatmap">
        <div className="skyeye_pc_home_sider_git_calendar_heatmap__title">
          Git merge and push events
        </div>
        <CalendarHeatmap
          startDate={calendarHeatmapData?.getIn([0, "date"]) || today}
          endDate={
            calendarHeatmapData?.getIn([
              calendarHeatmapData?.length - 1,
              "date",
            ]) || today
          }
          values={calendarHeatmapData?.toJS()}
          classForValue={(value) => {
            // value不存在 或 value.count为0
            if (!value || !value.count) {
              return "color_empty";
            }
            // 0 - 10 次
            if (value.count > 0 && value.count <= 10) {
              return "color_github_0_10";
            }
            // 11 - 20 次
            if (value.count >= 11 && value.count <= 20) {
              return "color_github_11_20";
            }
            // 21 - 30 次
            if (value.count >= 21 && value.count <= 29) {
              return "color_github_21_29";
            }
            // 大于 30 次
            if (value.count >= 30) {
              return "color_github_30";
            }
          }}
          tooltipDataAttrs={(value) => ({
            "data-tip": `${value.date} 提交 ${value.count || 0} 次代码`,
          })}
          showWeekdayLabels={false}
        />

        {/* 热力图气泡提示组件 */}
        <ReactTooltip />
      </div>
    );
  }
}
export default GitCalendarHeatmap;
