import React from 'react';
import { Relax } from 'plume2';
import { Table, Tag, Space, Pagination, Tooltip, Radio } from 'antd';
import { noop } from "@utils";
import online from '../../../../img/online.png';
import testing from '../../../../img/testing.png';
import moment from 'moment';
import { registerCoordinateSystem } from 'echarts';
import {IterationStatusText} from "@components";

const { Column, ColumnGroup } = Table;


@Relax
export default class ProjectList extends React.Component<any, any> {
  props: {
    relaxProps?: {
      projectPageList: any;
      pageItem: any;
      overViewSearch: any;
      clickRowClassName: any;
      rowId: any;
      getOverViewWithDistribution: Function;
      projectPageLoading: boolean;
      scoreType: number;
      mergeData: Function;
      tabType: number;
      onlyTested: number;
      getProjectPageList: Function;
      handleOverviewSearchData: Function;
      bugAnalysisSearch: any;
      customSearch: any;
    };
  };
  static relaxProps = {
    projectPageList: 'projectPageList',
    pageItem: 'pageItem',
    overViewSearch: 'overViewSearch',
    clickRowClassName: 'clickRowClassName',
    rowId: 'rowId',
    getOverViewWithDistribution: noop,
    getProjectPageList: noop,
    mergeData: noop,
    handleOverviewSearchData: noop,
    projectPageLoading: 'projectPageLoading',
    scoreType: 'scoreType',
    tabType: 'tabType',
    onlyTested: 'onlyTested',
    bugAnalysisSearch: 'bugAnalysisSearch',
    customSearch: 'customSearch'
  };

  render() {
    const { projectPageList, overViewSearch, projectPageLoading, tabType, onlyTested, getProjectPageList } = this.props.relaxProps || {}
    const dataSource = projectPageList?.toJS().records
    const total = projectPageList.toJS()?.total
    const pageSize = projectPageList.toJS()?.size
    const current = projectPageList.toJS()?.current
    const isEmpty = dataSource?.length == 0 ? true : false


    return (
      tabType == 1 && <div className="bug_analysis_project_list">
        <div className="bug_analysis_project_list_header">
          <div className="bug_analysis_project_list_title">项目列表</div>
          <Radio.Group
            options={[
              { label: "全部", value: 0 },
              { label: "仅看转测", value: 1 },
            ]}
            onChange={(e) => {
              this._changeScoreType(e.target.value)
            }}
            value={onlyTested}
            defaultValue={0}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div className="table-list">
          <Table
            rowKey="no"
            dataSource={dataSource}
            loading={projectPageLoading}
            pagination={false}
            columns={this._getColumns() as any}
            onRow={record => {
              return {
                onClick: () => {
                  this._columsClick(record)
                }, // 点击行
              };
            }}
            childrenColumnName="children"
            rowClassName={this._setRowClassName}
          >
          </Table>
        </div>

        {/* id用于点击列表滚动到概览部分 */}
        {!isEmpty && <div id="bug-analysis-overview-position">
          <Pagination
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={['10', '20', '50', '100']}
            defaultCurrent={1}
            total={total}
            onChange={(page, pageSize) => { getProjectPageList(false, pageSize, page) }}
            showTotal={(total, range) => ` 共${total}条`}
            pageSize={pageSize || 10}
            current={current}
          />
        </div>}
      </div>
    );
  }




  _getColumns = () => {

    let columns = [
      {
        title: "项目/迭代名称",
        dataIndex: 'name',
        key: "name",
        align: 'left',
        width: '26%',
        render: (text, record) => this._renderName(text, record),
      },
      {
        title: "转测时间",
        dataIndex: "testStartDate",
        key: "testStartDate",
        align: 'center',
        render: (value, record) => this._getDate(record, 'test')
      },
      {
        title: "UAT时间",
        dataIndex: "uatStartDate",
        key: "uatStartDate",
        align: 'center',
        render: (value, record) => this._getDate(record, 'uat')
      },
      {
        title: "内测缺陷密度(个/KL)",
        dataIndex: "bugDensity",
        key: "bugDensity",
        align: 'center',
        render: (text, records) => <span style={{ color: records.devIsBugDensityExceed ? '#F53731' : '' }}>{text || 0}</span>,
      },
      {
        title: "UAT缺陷率",
        dataIndex: "uatBugRate",
        key: "uatBugRate",
        width: "13%",
        align: 'center',
        render: (text, records) => <span style={{ color: records.uatIsBugRateExceed ? '#F53731' : '' }}>{text ? text + '%' : '0%'}</span>,
      },
    ];
    return columns
  }

  _getDate = (record, type) => {
    let start = type == 'test' ? 'testStartDate' : 'uatStartDate'
    let end = type == 'test' ? 'testEndDate' : 'uatEndDate'
    if (record[start] == null && record[end] == null) {
      return '-'
    }
    if (record[start] == null || record[end] == null) {
      if (record[start] == null && record[end] !== null) {
        return <span>{'null' + ' ～ ' + moment(record[end]).format("YYYY-MM-DD")}</span>
      }
      if (record[start] !== null && record[end] == null) {
        return <span>{moment(record[start]).format("YYYY-MM-DD") + ' ～ ' + 'null'}</span>
      }
    }
    return <span>{moment(record[start]).format("YYYY-MM-DD") + ' ～ ' + moment(record[end]).format("YYYY-MM-DD")}</span>
  }

  _renderName = (value, record) => {
    const { overViewSearch } = this.props.relaxProps;
    const projectType = overViewSearch.get('projectType');
    if (record.testStartDate || record.testEndDate) {
      return <div className="project-name">
        <div style={{marginRight:5}}>
          <IterationStatusText
              projectType={ record.projectNodeFlag ? 1 : 0}
              status={ record.status }
              placeholder={ null }
          />
        </div>
        <span
            className='hover-cls'
            onClick={() => {
              window.open("/quality-assurance?pageType=0");
              this.cacheSearchParams("project-list", record.projectId, record.id);
            }}
        >{value}</span>
      </div>
    } else {
      return <div className="project-name">
        <div style={{marginRight:5}}>
          <IterationStatusText
              projectType={ projectType }
              status={ record.status }
              placeholder={ null }
          />
        </div>
        <span
            className='hover-cls'
            onClick={() => {
              window.open("/quality-assurance?pageType=0");
              this.cacheSearchParams("project-list", record.id, null);
            }}
        >{value}</span>
      </div>
    }
  }

  _changeScoreType = (value) => {
    const { mergeData, getProjectPageList } = this.props.relaxProps;
    mergeData({
      onlyTested: value
    })
    getProjectPageList(false, 1, 10)
  }

  _setRowClassName = (record) => {
    const { rowId } = this.props.relaxProps || {}
    const id = record?.id
    return id === rowId ? 'click-row-bgc' : ''
  }

  _columsClick = (record) => {
    const { mergeData, handleOverviewSearchData } = this.props.relaxProps
    mergeData({
      rowId: record.id,
      attention: false,
    })
    handleOverviewSearchData(record, true)
    const scrollDom = document.getElementById('bug-analysis-overview-position');
    scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  /**
   * 缓存 - 搜索条件
   * @param key
   * @returns
   */
  cacheSearchParams = (key, projectId, iterationId) => {
    if(!key) return;
    const { tabType, bugAnalysisSearch, customSearch } = this.props.relaxProps || {}
    const selectProjectType = bugAnalysisSearch?.toJS?.()?.projectType;
    const selectBelongBaseline = bugAnalysisSearch?.toJS?.()?.belongBaseline;
    const selectStartYear = bugAnalysisSearch?.toJS?.()?.startYear;
    const customSelectProjectType = customSearch?.toJS?.()?.projectType;
    const customSelectBelongBaseline = customSearch?.toJS?.()?.belongBaseline;
    let projectType;
    let belongBaseline;
    let startYear;
    if (tabType == 1) {
      projectType = selectProjectType;
      belongBaseline = selectBelongBaseline;
      startYear = selectStartYear;
    } else {
      projectType = customSelectProjectType;
      belongBaseline = customSelectBelongBaseline;
    }
    const params = {
      projectType: projectType,
      belongBaseline: belongBaseline,
      iterationId: projectType == 1 ? null : iterationId,
      projectId: projectId,
      startYear: startYear,
      activeTabKey: iterationId,
    };

    localStorage.setItem(`${key}-searchParams`, JSON.stringify(params));
  }
}
