import React from "react";
import { Relax } from "plume2";
import { noop, cache } from "@utils";

@Relax
class TabItem extends React.Component<any, any> {
  static relaxProps = {
    pageType: "pageType",
    handleChangeTabItem: noop,
  };

  render() {
    const login_menus = localStorage.getItem(cache.LOGIN_MENUS);
    const { pageType, handleChangeTabItem } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_effectiveness_measurement__tabs">
        {
          [
            '项目列表', 
            '质量考核',
            '缺陷修复', 
            '缺陷分析'
          ].map((item, index) => {
            if(!login_menus?.includes?.(item)) return null;

            return (
              <div
                key={item}
                className={`skyeye_pc_effectiveness_measurement__tabs_item ${+pageType === index && "activeKey"}`}
                onClick={() => handleChangeTabItem(String(index))}
              >
                {item}
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default TabItem;