import React from "react";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import SearchGroup from "./components/search-group/index";
import ProjectList from './components/project-list/index';
import Tab from './components/tab/index'
import OverView from './components/overview-chart/index';
import ModuleChart from './components/module-chart';
import NewRepairLineChart from './components/new-repair-line-chart';
import { OneAuthWrapper, AuthWrapper, getQueryVariable } from "@utils";
import "./index.less";
import {fromJS} from "immutable";

/**
 * 缺陷分析 - 组件
 */
@StoreProvider(AppStore)
export default class BugAnalysis extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
      const tabType = getQueryVariable("tabType")?.tabType;
      if(tabType){
          const iterationId = getQueryVariable("iterationId").iterationId;
          const projectId = getQueryVariable("projectId").projectId;
          const belongBaseline = getQueryVariable("belongBaseline").belongBaseline;
          const projectType = getQueryVariable("projectType").projectType;
          const startYear = getQueryVariable("startYear").startYear;
          this.store.mergeData({tabType: tabType});
          this.store.mergeData(fromJS({pushSearch: {belongBaseline: belongBaseline, projectType: projectType,iterationId: iterationId, projectId: projectId, startYear:startYear}}));

      }
      this.store.init();
  }


  render() {
    return (
      <div className="bug_analysis">
        {/* Tab切换 */}
        <OneAuthWrapper functionName="f_bug_analyze_project_list,f_bug_analyze_overview,f_bug_analyze_customerize">
          <Tab />
        </OneAuthWrapper>
        {/** 查询件组 */}
        <OneAuthWrapper functionName="f_bug_analyze_project_list,f_bug_analyze_overview,f_bug_analyze_customerize">
          <SearchGroup />
        </OneAuthWrapper>

        {/* 项目列表 */}
        <AuthWrapper functionName="f_bug_analyze_project_list">
          <ProjectList />
        </AuthWrapper>

        {/* 概览 */}
        <AuthWrapper functionName="f_bug_analyze_overview">
          <OverView />
        </AuthWrapper>
        {/* 各模块柱状图 */}
        <AuthWrapper functionName="f_bug_analyze_overview">
          <NewRepairLineChart/>
          <ModuleChart />
        </AuthWrapper>
      </div>

    );
  }
}
