import { Axios } from '@utils';

//  项目类型下拉列表
export const getProjectTypeList = (params = {}) => {
  return Axios("/select-list/project-type", "post", params);
};

//  所属基线下拉列表
export const getBelongBaselineList = (params = {}) => {
  return Axios("/select-list/belong-baseline", "post", params);
};

//  项目下拉列表
export const getProjectList = (params = {}) => {
  return Axios('/select-list/project', 'post', params);
};

//  迭代下拉列表
export const getIterationList = (params = {}) => {
  return Axios('/select-list/iteration', 'post', params);
};

//  项目基本信息
export const getProjectInfo = (params = {}) => {
  return Axios('/quality-check/project-info', 'post', params);
};

//  迭代列表信息
export const getIterationInfoList = (params = {}) => {
  return Axios('/quality-check/iteration-info-list', 'post', params);
};


//  缺陷数据列表
export const getQualityInspectionBugList = (params = {}) => {
  return Axios("/quality-check/problem/page", "post", params);
};