import React from "react";
import { Relax } from "plume2";
import { noop, cache } from "@utils";

let tabsData = [];

@Relax
class TabItem extends React.Component<any, any> {
  static relaxProps = {
    handleChangeTabItem: noop,
    pageType: "pageType",
  };
  componentWillMount() {
    let login_menus = localStorage.getItem(cache.LOGIN_MENUS);;
    if (login_menus?.includes?.('个人排行榜')) {
      tabsData.push('个人排行榜')
    }
    if (login_menus?.includes?.('项目排行榜')) {
      tabsData.push('项目排行榜')
    }
    if (login_menus?.includes?.('千行代码')) {
      tabsData.push('千行代码')
    }
    if (login_menus?.includes?.('资源日历')) {
      tabsData.push('资源日历')
    }
    if (login_menus?.includes?.('实时人效')) {
      tabsData.push('实时人效')
    }
  }
  componentWillUnmount() {
    tabsData = []
  }
  render() {
    const { handleChangeTabItem, pageType } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_effectiveness_measurement__tabs">
        {tabsData.map((item, index) => {
          return (
            <div
              key={item}
              className={`skyeye_pc_effectiveness_measurement__tabs_item ${
                +pageType === index && "activeKey"
              }`}
              onClick={() => handleChangeTabItem(String(index))}
            >
              {item}
            </div>
          );
        })}
      </div>
    );
  }
}
export default TabItem;
