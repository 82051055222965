import React from "react";
import { Relax } from "plume2";
import { Input, Table, Image } from "antd";
import { noop } from "@utils";
import SearchImg from "../img/search.png";
import moment from "moment";

@Relax
class CustomerList extends React.Component<any, any> {
  static relaxProps = {
    listData: "listData",
    listPage: "listPage",
    changeListPage: noop,
    changeSearchValue: noop,
    searchValue: "searchValue",
  };
  render() {
    return (
      <div className="skyeye_pc_operational_data__customer_list">
        {/** 渲染标题部分 */}
        {this.renderTitle()}

        {/** 渲染列表 */}
        {this.renderTable()}
      </div>
    );
  }

  /**
   * 渲染标题部分
   */
  renderTitle = () => {
    const { changeSearchValue, searchValue } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_operational_data__customer_list_title">
        <div>客户列表</div>
        <div className="skyeye_pc_operational_data__customer_list_title--input">
          <Input
            placeholder="请输入客户名称/客户编码"
            suffix={<Image src={SearchImg} width={16} preview={false} />}
            value={searchValue}
            onChange={(e) => changeSearchValue(e.target.value)}
          />
        </div>
      </div>
    );
  };

  /**
   * 渲染列表
   */
  renderTable = () => {
    const { listData, listPage, changeListPage } = this.props.relaxProps;

    const columns = [
      {
        title: "客户名称",
        dataIndex: "customerName",
        key: "customerName",
        render: (text) => text || "-",
      },
      {
        title: "客户编号",
        dataIndex: "customerCode",
        key: "customerCode",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "签约时间",
        dataIndex: "contractDate",
        key: "contractDate",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "所在城市",
        dataIndex: "city",
        key: "city",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "最近一次授权时间",
        dataIndex: "lastAuthorizeDate",
        key: "lastAuthorizeDate",
        align: "center",
        render: (text) =>
          text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-",
      },
      {
        title: "网站地址",
        dataIndex: "webSiteAddress",
        key: "webSiteAddress",
        align: "center",
        render: (value) => {
          return (
            <a
              href={value}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#1890FF" }}
            >
              {value || "-"}
            </a>
          );
        },
      },
    ] as any;

    return (
      <div className="skyeye_pc_operational_data__customer_list_table">
        <Table
          rowKey="customerName"
          dataSource={listData?.toJS()}
          columns={columns}
          pagination={{
            pageSize: listPage?.get("size"),
            total: listPage?.get("total"),
            current: listPage?.get("current"),
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showQuickJumper: true,
            showTotal: () => `共 ${listPage?.get("total") || 0} 条`,
            onChange: (pageNum, pageSize) => {
              changeListPage(pageNum, pageSize);
            },
          }}
        />
      </div>
    );
  };
}
export default CustomerList;
