import { Axios } from "@utils";

/**
 * 概览-新增客户
 */
export const getCustomerIncreaseOverview = (params = {}) => {
  return Axios("/statistics/customer/increase/overview", "post", params);
};

/**
 * 概览-累计客户数，活跃客户数，沉默客户数
 */
export const getCustomerTotalOverview = () => {
  return Axios("/statistics/customer/total/overview", "post");
};

/**
 * 查询趋势图
 */
export const getChartsData = (params = {}) => {
  return Axios("/statistics/customer/list", "post", params);
};

/**
 * 客户列表分页
 */
export const getCustomerPage = (params = {}) => {
  return Axios("/statistics/customer/page", "post", params);
};