import React from "react";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import Content from "./components/content";
import Sider from "./components/sider";
import { AuthWrapper } from '@utils'
import "./index.less";

// 首页
@StoreProvider(AppStore)
class Home extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
    this.store.init();
  }

  render() {
    const dataType = this.store.get("dataType");
    return (
      <div className="common_width_margin skyeye_pc_home">
        {/* tabs */}
        <AuthWrapper functionName="f_popular_recommend">
          <div
            className="skyeye_pc_home__tabs"
            onClick={(e: any) => {
              if (!e?.target?.dataset?.type) return;
              this.store.mergeData({
                dataType: e.target.dataset.type,
              });
            }}
          >
            <span
              data-type="hot"
              className={
                dataType === "hot" ? `skyeye_pc_home__tabs--active` : undefined
              }
            >
              热门推荐
            </span>
            {/*<span data-type='to_do_list' className={ dataType === 'to_do_list' ? `skyeye_pc_home__tabs--active` : undefined }>待办事项</span>*/}
          </div>
        </AuthWrapper>

        {/* tabs下对应的内容 */}
        <div className="skyeye_pc_home__content">
          {/* 内容区域 */}
          <AuthWrapper functionName="f_popular_recommend">
            <Content />
          </AuthWrapper>
          {/* 右侧边栏 */}
          <Sider />
        </div>
      </div>
    );
  }
}
export default Home;
