import React from "react";
import { AuthWrapper, OneAuthWrapper, noop } from "@utils";
import { Relax } from "plume2";
import SearchGroup from "./search-group";
import ProjectInformation from "./project-information";
import IterativeInformation from "./iterative-information";
import BugData from "./bug-data";
import "./index.less";


/**
 * 质量考核 - 组件
 */
@Relax
export default class QualityInspection extends React.Component<any, any> {
  static relaxProps = {
    qualityInspectionInit: noop,
  };

  componentDidMount() {
    const { qualityInspectionInit } = this.props.relaxProps
    qualityInspectionInit()
  }


  // color: #fff;
  // background-color: #F53731;



  render() {
    return (
        <div className="quality_inspection">
          {/** 查询件组 */}
          <SearchGroup />

          {/** 项目信息 */}
          {/*<AuthWrapper functionName="f_quality_check_project_iteration_info">*/}
          {/*  <ProjectInformation />*/}
          {/*</AuthWrapper>*/}


          {/** 迭代信息 */}
          {/*<AuthWrapper functionName="f_quality_check_project_iteration_info">*/}
          {/*  <IterativeInformation />*/}
          {/*</AuthWrapper>*/}

        {/** 缺陷数据 */}
        <BugData />
      </div>
    );
  }
}
