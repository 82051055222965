import axios from 'axios';
import {baseURL, cache} from '@utils';
import { LOGIN_DATA, history } from '../utils';
import {message} from "antd";

// axios配置

// url前缀
axios.defaults.baseURL = baseURL;
// 指定超时时间
axios.defaults.timeout = 30000;

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // console.log('1111111111', config);
  const token = JSON.parse(localStorage.getItem(LOGIN_DATA))?.token;

  // 在发送请求之前做些什么
  if (token) {
    config.headers.Authorization = 'Bearer' + (token ? ' ' + token : '')
  }
  config.headers["Content-type"] = "application/json;charset=UTF-8";
  return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // console.log('2222222222', response);
    // 对响应数据做点什么
    if (response?.data?.code === 'S-000011') {
        message.error('登录信息已失效，请重新登录！');
        // 跳转登录页
        history.push("/");
        // 清空缓存
        localStorage.clear();
        localStorage.removeItem(LOGIN_DATA);
        localStorage.removeItem(cache.LOGIN_FUNCTIONS);
        localStorage.removeItem(cache.LOGIN_MENUS);
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});