import {Axios} from '@utils';
import moment from "moment";
// 热门推荐、新人必读、快速导航入口 - 获取请求
export const getPageList = (req = {}) => {
    return Axios('/system-content-setting/page', 'post', req);
};

// 热力图 - 获取请求
export const getCalendarHeatmap = (req = {}) => {
    return Axios('/git-event/statics', 'get', req);
};

/**
 * 当年花费工时 - 获取请求
 * @param {*} req 
 * @returns 
 */
export const getSpentTotal = (req = {}) => {
    return Axios('/statistics/current-month-spent-rank', 'get', req);
};

/**
 * 当年排名
 * @returns
 */
export const getRank = (params = { startYear: moment().format("YYYY") }) => {
  return Axios("/statistics/year-owner-score-rank", "post", params);
};