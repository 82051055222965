import React from "react";
import { Relax } from "plume2";
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import moment from "moment";

import { Select, DatePicker, Button } from 'antd';
const { Option } = Select;

/**
 * 查询条件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    realtimeEffectConditions: 'realtimeEffectConditions',
    realtimeEffectConditionsData: 'realtimeEffectConditionsData',
    changeConditions: noop,
    realtiomeEffectSearch: noop
  };

  componentWillUnmount(): void {
    // 销毁临时缓存的数据
    localStorage.removeItem("effectiveness-measurement-searchParams");
  }

  render() {
    const { realtimeEffectConditions, realtimeEffectConditionsData, realtiomeEffectSearch } = this.props.relaxProps;
    const { projectType, belongBaseline, project, iteration } = realtimeEffectConditionsData.toJS();
    const startYear = realtimeEffectConditions.get('startYear');
    // projectType 0:基线  1:定制
    const isCustomMade = realtimeEffectConditions.get('projectType') == 1 ? true : false;

    return (
      <div className="search_group">
        <DatePicker
          className="search_group_item"
          picker="year"
          value={startYear ? moment(startYear) : null}
          onChange={(e, dateString) => this._handleChange(dateString, 'startYear')}
        />

        <OneAuthWrapper functionName="f_humane_effect_project_type_baseline,f_humane_effect_project_type_customized">
          <Select
            placeholder="请选择项目类型"
            className="search_group_item"
            onChange={e => this._handleChange(e, 'projectType')}
            value={realtimeEffectConditions.get('projectType')}
          >
          {projectType && projectType?.map?.(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_humane_effect_belong_baseline_sbc,f_humane_effect_belong_baseline_yxd,f_humane_effect_belong_baseline_b2b,f_humane_effect_belong_baseline_jszt,f_humane_effect_belong_baseline_ywzt">
        <Select
          placeholder="请选择所属基线"
            className="search_group_item"
          disabled={realtimeEffectConditions.get('projectType') === undefined}
          value={realtimeEffectConditions.get('belongBaseline')}
          onChange={e => this._handleChange(e, 'belongBaseline')}
        >
          {belongBaseline && belongBaseline.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_humane_effect_project_select_list,f_humane_effect_project_select_list_all">
        {isCustomMade && <Select
          placeholder="请选择项目"
            className="search_group_item"
            disabled={realtimeEffectConditions.get('belongBaseline') === undefined}
            defaultValue={null}
            value={realtimeEffectConditions.get('project')}
            onChange={e => this._handleChange(e, 'project')}
          showSearch
          notFoundContent="暂无项目"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
          >
            <Option value={null}>全部</Option>
          {project && project.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>}
        </OneAuthWrapper>

        <AuthWrapper functionName="f_humane_effect_iteration_select_list">
        <Select
          placeholder="请选择迭代"
            className="search_group_item"
          disabled={this._getShow(isCustomMade)}
          defaultValue={null}
          value={realtimeEffectConditions.get('iteration')}
          onChange={e => this._handleChange(e, 'iteration')}
          showSearch
          notFoundContent="暂无迭代"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
          >
            <Option value={null}>全部</Option>
          {iteration && iteration.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
        </AuthWrapper>

        <Button
          className="search_group_button"
          type="primary"
          onClick={() => realtiomeEffectSearch()}
        >
          搜索
        </Button>
      </div>
    );
  }

  _handleChange = (value, type) => {
    let { changeConditions } = this.props.relaxProps;
    changeConditions(value, type);
  }

  _getShow = (isCustomMade) => {
    const {
      realtimeEffectConditions,
    } = this.props.relaxProps;
    if (isCustomMade) {
      return realtimeEffectConditions.get('project') ? false : true
    } else {
      return false
    }
  }

}
