import React from "react";
import { Relax } from "plume2";
import { Image, Table, Pagination, Tooltip } from "antd";
import { noop } from "@utils";

@Relax
class List extends React.Component<any, any> {
  static relaxProps = {
    repairsDetailPageVOPage: "repairsDetailPageVOPage",
    getDetailPageName: noop,
    repairsDetailLoading: "repairsDetailLoading",
  };
  render() {
    const {
      repairsDetailPageVOPage,
      getDetailPageName,
      repairsDetailLoading
    } = this.props.relaxProps;
    const tabType = this.props?.tabType
    const dataSource =
      repairsDetailPageVOPage?.toJS().records;

    const total = repairsDetailPageVOPage?.toJS().total;
    const pageSize = repairsDetailPageVOPage?.toJS().size;
    const current = repairsDetailPageVOPage?.toJS().current;
    const listColumns = tabType === 0 ? this.getDevelopColumns() as any : this.getTestColumns() as any;
    return (
      <div className="detail_data_list">
          <Table
            // rowKey="totalNum"
            columns={listColumns}
            dataSource={dataSource}
            loading={repairsDetailLoading}
            pagination={false}
          />
          <Pagination
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={['10', '20', '50', '100']}
            defaultCurrent={1}
            total={total}
            onChange={(page, pageNumber) => { getDetailPageName(tabType, page, pageNumber) }}
            showTotal={(total, range) => ` 共${total}条`}
            pageSize={pageSize || 10}
            current={current}
          />
      </div>
    );
  }

  /**
   * 获取开发侧列表列数据
   */
  getDevelopColumns = () => {
    let columns = [
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        render: (text) => text || "-",
      },
      {
        title: "缺陷数",
        dataIndex: "totalNum",
        key: "totalNum",
        align: "center",
        render: (text, record) => {
          return (
            <Tooltip
              title={() => {
                return (
                  <>
                    <div>{`致命: ${record.fatal ? record.fatal : 0}个`}</div>
                    <div>{`严重: ${record.serious ? record.serious : 0}个`}</div>
                    <div>{`一般: ${record.normal ? record.normal : 0}个`}</div>
                    <div>{`建议: ${record.advice ? record.advice : 0}个`}</div>
                    <div>{`提示: ${record.prompt ? record.prompt : 0}个`}</div>
                  </>
                );
              }}
            >
              {text ? text : '-'}
            </Tooltip>
          );
        },
      },
      {
        title: "参与修复数",
        align: "center",
        dataIndex: "participationNum",
        key: "participationNum",
        render: (text) => text || "-",
      },
      {
        title: "修复次数",
        align: "center",
        dataIndex: "repairedNum",
        key: "repairedNum",
        render: (text) => text || "-",
      },
      {
        title: "处理及时率",
        align: "center",
        dataIndex: "dealTimelyRate",
        key: "dealTimelyRate",

        render: (text) => (text ? text + '%' : "-"),
      },
      {
        title: "打回数",
        align: "center",
        dataIndex: "devBackedNum",
        key: "devBackedNum",
        render: (text) => text || "-",
      },
      {
        title: "修复未回归",
        align: "center",
        dataIndex: "unReturn",
        key: "unReturn",
        render: (text) => text || "-",
      },
      {
        title: "修复质量",
        align: "center",
        dataIndex: "repairedQuality",
        key: "repairedQuality",
        render: (text) => (text ? text + '%' : "-"),
      },
    ];

    return columns;
  };

  /**
  * 获取测试侧列表列数据
  */
  getTestColumns = () => {
    let columns = [
      {
        title: "姓名",
        dataIndex: "name",
        key: "name",
        render: (text) => text || "-",
      },
      {
        title: "提交缺陷",
        dataIndex: "testTotalNum",
        key: "testTotalNum",
        align: "center",
        render: (text, record) => {
          return (
            <Tooltip
              title={() => {
                return (
                  <>
                    <div>{`致命: ${record.fatal ? record.fatal : 0}个`}</div>
                    <div>{`严重: ${record.serious ? record.serious : 0}个`}</div>
                    <div>{`一般: ${record.normal ? record.normal : 0}个`}</div>
                    <div>{`建议: ${record.advice ? record.advice : 0}个`}</div>
                    <div>{`提示: ${record.prompt ? record.prompt : 0}个`}</div>
                  </>
                );
              }}
            >
              {text ? text : '-'}
            </Tooltip>
          );
        },
      },
      {
        title: "无效缺陷",
        dataIndex: "invalidNum",
        key: "invalidNum",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "回归次数",
        dataIndex: "returnedNum",
        key: "returnedNum",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "打回数",
        dataIndex: "testBackNum",
        key: "testBackNum",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "待回归",
        dataIndex: "toReturnNum",
        key: "toReturnNum",
        align: "center",
        render: (text) => text || "-",
      },
      {
        title: "回归率",
        dataIndex: "returnRate",
        key: "returnRate",
        align: "center",
        render: (text) => (text ? text + '%' : "-"),
      },
      {
        title: "回归及时率",
        dataIndex: "returnTimelyRate",
        key: "returnTimelyRate",
        align: "center",
        render: (text) => (text ? text + '%' : "-"),
      },
      {
        title: "回归占比",
        dataIndex: "returnProportion",
        key: "returnProportion",
        align: "center",
        render: (text) => (text ? text + '%' : "-"),
      },
    ];

    return columns;
  };

}
export default List;
