import React from "react";
import {Relax} from "plume2";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

const ColorArray = ['#2db7f5', '#7dc856', '#df6898', '#895adf'];

/**
 * 趋势图
 */
@Relax
export default class NewLineCharts extends React.Component<any, any> {
  static relaxProps = {
    newFixLineData: "newFixLineData",
  };

  render() {
    return (
        <div className="skyeye_pc_operational_data__line_charts" style={{paddingTop: "0px"}}>

          {/** 渲染趋势图 */}
          {this.renderCharts()}
        </div>
    );
  }

  /**
   * 渲染趋势图
   */
  renderCharts = () => {
    const { newFixLineData } = this.props.relaxProps;

    const dataDesc = [
      {title: '新增缺陷数', key: 'newBugCount'},
      {title: '修复缺陷数', key: 'dealBugCount'},
    ]
    //组装图例
    const legend = [];
    dataDesc.forEach((value) => {
      let dataObject: any = {
        name: value.title,
      };
      legend.push(dataObject);
    });

    const date = []; //x轴日期数据
    //编辑数据
    newFixLineData?.forEach((v, index) => {
      // 日期最后一条替换为今日
      date.push(v?.get("xdate")
      );
    });
    let yAxis = [];
    yAxis.push({
      type: 'value',
    });

    let seriesJson = {};
    newFixLineData?.toJS().forEach((valueData) => {
      dataDesc.forEach((keyData) => {
        let array;
        if (seriesJson[keyData.title]) {
          array = seriesJson[keyData.title];
        } else {
          array = [];
          seriesJson[keyData.title] = array;
        }
        array.push(valueData[keyData.key]);
      });
    });

    let seriesArray = [];
    let count = 0;
    for (let obj in seriesJson) {
        seriesArray.push({
          name: obj,
          type: 'line',
          areaStyle: {
            // 颜色自上而下渐变
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                // 1代表上面
                offset: 0,
                color: "#1890FF",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
            opacity: 0.2, // 填充区域透明度
          },
          yAxisIndex: 0,
          data: seriesJson[obj],
          lineStyle: { normal: { color: ColorArray[count] } },
          itemStyle: { normal: { color: ColorArray[count] } }
        });
      count++;
    }
    //编辑图表
    const option = {
      color: ["#1890FF"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: (params) => {
          let str =
              '<span style="font-size: 10px;font-weight: 400;color: #000000;line-height: 14px;">' +
              params[0].name +
              "</span>" +
              "<br />";
          params.forEach((item) => {
            str +=
                '<div style="font-size: 10px;font-weight: 400;color: #666666;display: flex;justify-content: space-between;align-items: center;min-width: 160px">' +
                '<span style="display: flex;align-items: center;">' +
                '<div style="line-height: 10px;margin-right:6px;border-radius:50%;width:4px;height:4px;background-color:' +
                item.color +
                '">' +
                "</div>" +
                " <span>" +
                item.seriesName +
                "</span>" +
                "</span>" +
                "<span>" +
                item.data +
                "</span>" +
                "</div>";
          });
          return str;
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: false, //不展示保存为图片
          },
        },
      },
      grid: {
        left: "0%",
        right: "1%",
        top: "10%",
        bottom: "3%",
        containLabel: true,
      },
      legend: {
        data: legend,
        left: "0%",
        icon: "ract",
        itemHeight: 2,
        itemWidth: 12,
      },
      xAxis: [
        {
          type: "category",
          data: date,
        },
      ],
      yAxis: yAxis,
      series: seriesArray,
    };
    return (
        <div className="chart">
            <ReactEcharts
                option={option}
                notMerge={true}
                lazyUpdate={true}
                style={{ width: "100%", minHeight: "200px" }}
            />
        </div>
    );
  };
}
