import { Axios } from "@utils";

// -------------- 搜索条件 --------------
// 项目类型下拉列表
export const getProjectTypeList = (params = {}) => {
  return Axios("/select-list/project-type", "post", params);
};

// 所属基线下拉列表
export const getBelongBaselineList = (params = {}) => {
  return Axios("/select-list/belong-baseline", "post", params);
};

// 项目下拉列表
export const getProjectList = (params = {}) => {
  return Axios("/select-list/project", "post", params);
};

// 迭代下拉列表
export const getIterationList = (params = {}) => {
  return Axios("/select-list/iteration", "post", params);
};

//项目列表
export const getProjectPage = (params = {}) => {
  return Axios("/bug-analyze/project-page", "post", params);
};

// -------------- 概览 --------------
//概览缺陷数量
export const getOverViewBugData = (params = {}) => {
  return Axios("/bug-analyze/bug-overview", "post", params);
};

//历史遗留分布
export const getHistoryDistribution = (params = {}) => {
  return Axios("/bug-analyze/iteration-history-distribution", "post", params);
};


//发现阶段分布
export const getOriginPhaseDistribution = (params = {}) => {
  return Axios(
    "/bug-analyze/iteration-origin-phase-distribution",
    "post",
    params
  );
};

//优先级分布
export const getPriorityDistributionn = (params = {}) => {
   return Axios("/bug-analyze/iteration-priority-distribution", "post", params);
};


//严重程度分布
export const getSeverityDistribution = (params = {}) => {
  return Axios("/bug-analyze/iteration-severity-distribution", "post", params);
};

// -------------- 分布柱状图 --------------
//按开发人员
export const getForUserChart = (params = {}) => {
  return Axios(
    "/bug-analyze/bug-analyze-histogram-for-de-user",
    "post",
    params
  );
};

//按修复人员
export const getForFixUserChart = (params = {}) => {
  return Axios(
      "/bug-analyze/bug-analyze-histogram-for-fix-user",
      "post",
      params
  );
};

//按模块
export const getForModuleChart = (params = {}) => {
  // let url = "/bug-analyze​/bug-analyze-histogram-for-module";
  return Axios("/bug-analyze/bug-analyze-histogram-for-module", "post", params);
};


//按原因
export const getForReasonChart = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-histogram-for-reason", "post", params);
};

//按缺陷类型
export const getForYypeChart = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-histogram-for-type", "post", params);
};

// -------------- 分布列表 --------------
//按开发人员
export const getForUserPage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-de-user", "post", params);
};

//按修复人员
export const getForFixUserPage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-fix-user", "post", params);
};

//按模块
export const getForModulePage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-module", "post", params);
};

//按原因
export const getForReasonPgae = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-reason", "post", params);
};

//按缺陷类型
export const getForYypePage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-type", "post", params);
};


//提醒关注
export const remindAttention = (params = {}) => {
  return Axios("/bug-analyze/analyze-push-msg", "post", params);
};

/**
 * 查询 - 提醒关注 - 按钮状态
 * @param params 
 * @returns 
 */
export const analyzePushExpireTime = (params = {}) => {
  return Axios("/bug-analyze/analyze-push-expire-time", "post", params);
};

//新增修复趋势图
export const getForNewfixChart = (params = {}) => {
  return Axios(
      "/bug-analyze/line-chart-for-new-fix",
      "post",
      params
  );
};

//按新增趋势
export const getForNewCountPage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-new-count", "post", params);
};

//按修复趋势
export const getForFixCountPage = (params = {}) => {
  return Axios("/bug-analyze/bug-analyze-for-fix-count", "post", params);
};

