import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { Calendar, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';


@Relax
export default class Main extends React.Component<any, any> {
  static relaxProps = {
    mergeData: noop,
    getDepartmentDetailList: noop,
    getCalendarData: noop,
    changeData: noop,
    totalStartYear: 'totalStartYear',
    totalStartMonth: 'totalStartMonth',
    calendarTotal: 'calendarTotal',
  };

  render() {

    const { totalStartYear, totalStartMonth } = this.props.relaxProps;
    const totalValue = totalStartYear + '-' + totalStartMonth;

    return (
      <div className="calendar-data-putinto">
        <Calendar dateCellRender={this._dateTotalCellRender}
          headerRender={({ value, onChange }) => this._headerRender(value, onChange) as any}
          onSelect={(data) => this._onSelect(data)}
          value={moment(totalValue)}
        />
      </div>
    );
  }

  /**
   * 自定义日期
   */
  _dateTotalCellRender = (value) => {
    const listData = this._isPassCurrentMonth('total', value) ? this.getTotalListDataByRest(value) : this.getTotalListData(value)
    //加班
    const workOvertime = listData?.filter?.((b) => b?.content?.includes('加班'));
    const workOvertimeArr = workOvertime.filter?.((b) => b?.num !== 0);
    //过滤加班和休息
    const noWorkOvertimeAndRestArr = listData?.filter?.((b) => b?.content?.indexOf('加班') == -1 && b?.content?.indexOf('休息'));

    const rest = listData.filter?.((b) => b?.content?.includes('休息'));
    const restArr = rest.filter?.((b) => b?.num !== 0);
    let workOvertimeFlag;
    restArr?.map?.((item) => {
      if (item.num > 0) {
        workOvertimeFlag = true;
      } else {
        workOvertimeFlag = false
      }
    })

    // 处理假期样式
    restArr?.map((item) => {
      if (item.num > 0) {
        var element = document.querySelectorAll(".ant-picker-cell-in-view");
        element?.forEach(function (i) {
          let a = i?.querySelector(".ant-picker-calendar-date")
          if (!a?.hasAttribute('style')) {
            if (moment(i?.getAttribute('title')).isSame(item?.date)) {
              a?.setAttribute("style", "background:#F7F9FC !important;")
              return
            }
          }
        });
      }
    })

    //设置今天的样式
    let element = document.querySelectorAll(".ant-picker-cell");
    let arr = Array.from(element)
    arr?.forEach(function (item) {
      let b = item?.getElementsByClassName('setting-today')
      b?.[0]?.setAttribute("class", 'ant-picker-calendar-date-value ')
      if (item?.getAttribute('title') == moment().format("YYYY-MM-DD")) {
        let a = item?.getElementsByClassName('ant-picker-calendar-date-value')
        a[0]?.setAttribute("class", 'ant-picker-calendar-date-value setting-today')
        return
      }
    });

    // 日期填充处理，antd默认展示六行，按照需求动态展示日期行数据
    this._handleDateFill()

    return (
      <>
        {workOvertimeFlag && <div className="vacation-status-box">
          假期
        </div>}
        <ul className="events">
          {workOvertimeFlag ? workOvertimeArr?.map?.(item => (
            <li key={item?.day} >
              <span className="status" style={{ background: `${item?.color}` }}></span>
              <span style={{ color: "#666666" }}>{item?.content}</span>
            </li>
          )) : noWorkOvertimeAndRestArr?.map?.(item => (
            <li key={item?.day} >
              <span className="status" style={{ background: `${item?.color}` }}></span>
              <span style={{ color: item?.content?.indexOf('虚耗') !== -1 ? "#F53731" : "#666666" }}>{item?.content}</span>
            </li>
          ))}
        </ul>
      </>
    );
  }

  _handleDateFill = () => {
    const { totalStartMonth, totalStartYear } = this.props.relaxProps;
    let pickerContent = document.querySelector?.(".ant-picker-content");
    let tr = pickerContent?.getElementsByTagName?.('tr')
    let lastTrElement = tr?.[tr?.length - 1]
    let lastTrElementMonth = moment(lastTrElement?.firstElementChild?.getAttribute?.('title')).format("M")
    let lastTrElementYear = moment(lastTrElement?.firstElementChild?.getAttribute?.('title')).format("YYYY")

    // 先清除样式
    lastTrElement?.removeAttribute?.('style')
    if (lastTrElementYear == totalStartYear) {
      if (lastTrElementMonth > totalStartMonth) {
        lastTrElement?.setAttribute?.('style', 'display:none')
      }
    } else if (lastTrElementYear > totalStartYear) {
      if (lastTrElementMonth < totalStartMonth) {
        lastTrElement?.setAttribute?.('style', 'display:none')
      }
    } else {
      // todo
    }
  }

  getTotalListData = (value) => {
    let listData;
    const calendarTotal = this.props.relaxProps?.calendarTotal?.toJS()
    calendarTotal?.map((item) => {
      let year = item?.date?.year
      let month = item?.date?.monthValue
      let dayOfMonth = item?.date?.dayOfMonth
      let date = year + '-' + month + '-' + dayOfMonth;
      let day = moment(value).format('YYYY-M-D')
      switch (day) {
        case moment(date).format('YYYY-M-D'):
          listData = [
            { color: 'linear-gradient(90deg, #31CC6F 0%, #15B354 100%)', content: '正常 ' + `(${item.normal}人)`, num: item.normal, date: date },
            { color: 'linear-gradient(90deg, #FFA42E 0%, #F58C22 100%)', content: '异常 ' + `(${item.abnormal}人)`, num: item.abnormal, date: date },
            { color: 'linear-gradient(90deg, #FF6666 0%, #F54E4E 100%)', content: '虚耗 ' + `(${item.waste}人)`, num: item.waste, date: date },
            { color: 'linear-gradient(90deg, #3DA5FF 0%, #3C82FA 100%)', content: '调休 ' + `(${item.paidLeave}人)`, num: item.paidLeave, date: date },
            { color: 'linear-gradient(90deg, #9D85FF 0%, #935FFA 100%)', content: '加班 ' + `(${item.workOvertime}人)`, num: item.workOvertime, date: date },
            { color: '#FF6600', content: '休息' + `(${item.rest})人`, num: item.rest, date: date, isHoliday: item?.isHoliday || 0 },
          ];
          break;
        default:
      }
    })
    return listData || [];
  }


  /**
    *  获取休息的数据
    */
  getTotalListDataByRest = (value) => {
    let listData;
    const calendarTotal = this.props.relaxProps?.calendarTotal?.toJS()
    calendarTotal?.map((item) => {
      let year = item?.date?.year
      let month = item?.date?.monthValue
      let dayOfMonth = item?.date?.dayOfMonth
      let date = year + '-' + month + '-' + dayOfMonth;
      let day = moment(value).format('YYYY-M-D')
      switch (day) {
        case moment(date).format('YYYY-M-D'):
          listData = [
            { color: '#FF6600', content: '休息' + `(${item.rest})人`, num: item.rest, date: date, isHoliday: item?.isHoliday || 0 },
          ];
          break;
        default:
      }
    })
    return listData || [];
  }


  /**
   *  是否超过当前时间
  */
  _isPassCurrentMonth = (type, list?) => {
    let flag = false;
    const { totalStartYear, totalStartMonth } = this.props.relaxProps;
    let todayDate = moment().format('YYYY-M-D');
    let listDate = type === 'total' ? moment(list).format('YYYY-M-D') : list?.[0]?.year + '-' + list?.[0]?.month + '-' + list?.[0]?.dayOfMonth
    if (Number(totalStartYear) > Number(moment().format('YYYY'))) {
      flag = true
    } else {
      if (Number(totalStartYear) == Number(moment().format('YYYY'))) {
        if (Number(totalStartMonth) > Number(moment().format('M'))) {
          flag = true
        }
        flag = moment(listDate).isAfter(todayDate) || moment(listDate).isSame(todayDate)
      }
    }
    return flag
  }

  /**
  *    头部月份切换
 */
  _headerRender = (value, onChange) => {
    let currentYear = value.format('YYYY年')
    let currentMonth = value.format('M月')
    const { mergeData, getCalendarData } = this.props.relaxProps;
    const next = () => {
      let month = moment(value).add(1, 'months')
      let newMonth = moment(month).format("M")
      let year = Number(moment(value).format("YYYY"))
      if (Number(newMonth) == 1) {
        year = Number(year) + 1
      }
      mergeData({ projectModalVisible: false, personModalVisible: false, calendarTotal: [], totalStartYear: String(year), totalStartMonth: newMonth })
      getCalendarData('total')
      // 清空相关样式
      let element = document.querySelectorAll('.ant-picker-calendar-date')
      element.forEach((i) => {
        i.removeAttribute('style')
      })
    }

    const prev = () => {
      let month = moment(value).subtract(1, 'months')
      let prevMonth = moment(month).format("M")
      let year = Number(moment(value).format("YYYY"))
      if (Number(prevMonth) == 12) {
        year = Number(year) - 1
      }
      mergeData({ projectModalVisible: false, personModalVisible: false, calendarTotal: [], totalStartYear: String(year), totalStartMonth: prevMonth })
      getCalendarData('total')
      // 清空相关样式
      //今天
      let element = document.querySelectorAll('.ant-picker-calendar-date')
      element.forEach((i) => {
        i.removeAttribute('style')
      })
    }

    return (
      <div className="calendar-data-contain-main-title" style={{ marginTop: 14, marginBottom: 14 }}>
        <Button size="small" type="text" icon={<LeftOutlined />}
          onClick={() => prev()}
        />
        <div className="title-text">
          <span >{currentYear} </span>
          <span >{currentMonth}</span>
        </div>
        <Button size="small" type="text" icon={<RightOutlined />}
          onClick={() => next()}
        />
      </div>
    )
  }

  /**
   *    选择具体日期
  */
  _onSelect = (data) => {
    this._onSelectDateStyleChange(data)
    const { getDepartmentDetailList, mergeData, calendarTotal } = this.props.relaxProps
    let holiday = calendarTotal?.toJS()?.filter((v) => v.isHoliday == 1)
    let arr = []
    holiday?.map?.((i) => {
      arr.push(i.day)
    })
    if (arr.includes(Number(moment(data).format("D")))) {
      let workOvertimeArr = holiday.filter((v) => v.workOvertime > 0)
      let currentWorkOvertime = workOvertimeArr.filter((v) => v.day == moment(data).format("D"))
      let flag = currentWorkOvertime.length == 1 ? true : false
      if (flag) {
        mergeData({
          status: 4
        })
      } else {
        return
      }
    }
    mergeData({
      projectModalVisible: true,
      projectModalYear: moment(data).format("YYYY"), // 开始年份
      projectModalMonth: moment(data).format("M"), // 开始月份
      projectModalDay: moment(data).format("D"), // 开始月份
    })
    getDepartmentDetailList()
  }


  /**
  * 选中具体日期样式更改
  */
  _onSelectDateStyleChange = (data) => {
    let element = document.querySelectorAll(".calendar-data-putinto .ant-picker-cell");
    let elementArr = Array.from(element)
    elementArr?.forEach((item) => {
      let a = item?.querySelectorAll('.ant-picker-calendar-date')?.[0]
      a?.removeAttribute('style')
      if (item?.getAttribute('title') == moment(data).format("YYYY-MM-DD")) {
        a?.setAttribute('style', "border-color: #FF6600;border-right:1px solid #FF6600;border-bottom:1px solid #FF6600")
      }
    })
  }
}
