import { Axios } from "@utils";

//  所属部门
export const getDepartmentList = (params = {}) => {
  return Axios("/select-list/department-tree", "post", params);
};


//  部门员工投入汇总卡片数据
export const getEffortSummaryCard = (params = {}) => {
  return Axios("/resource-calendar/effort-summary/card", "post", params);
};

//  部门员工投入数据汇总列表
export const getEffortSummaryPage = (params = {}) => {
  return Axios("/resource-calendar/effort-summary/page", "post", params);
};

// 资源日历 -----------------------

//  投入汇总
export const getCalendarTotal = (params = {}) => {
  return Axios("/resource-calendar/in-summary", "post", params);
};

//  投入明细
export const getCalendarDetail = (params = {}) => {
  return Axios("/resource-calendar/in-detail", "post", params);
};

//  部门(弹窗列表)
export const getDepartmentDetailList = (params = {}) => {
  return Axios("/resource-calendar/department-detail", "post", params);
};

//  任务（弹窗列表）
export const getTaskDetailList = (params = {}) => {
  return Axios("/resource-calendar/task-detail", "post", params);
};

// 部门员工下拉
export const getDepartmentUser = (params = {}) => {
  return Axios("/select-list/department-user", "post", params);
};

