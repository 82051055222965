import { Axios } from '@utils';

// 用户年度数据和排名
export const yearOwnerScoreRank = (params = {}) => {
  return Axios("/statistics/year-owner-score-rank", "post", params);
};

// 当前用户年度修复质量折线图数据
export const yearOwnerFixQualityLine = (params = {}) => {
  return Axios("/statistics/year-owner-fix-quality-line", "post", params);
};

// 年度个人得分数据
export const findOwnerScore = (params = {}) => {
    return Axios('/statistics/find-owner-score', 'post', params);
};

// 获取质量最佳top5
export const bestQualityTopFiveByOwner = (params = {}) => {
    return Axios('/statistics/find-best-quality-top-five-by-owner', 'post', params);
};

// 修复缺陷top5查询方法
export const findFixBugTopFive = (params = {}) => {
    return Axios('/statistics/find-fix-bug-top-five', 'post', params);
};

// 编码得分排行
export const codingScore = (params = {}) => {
    return Axios('/statistics/coding-score', 'post', params);
};

//  缺陷得分排行
export const bugScore = (params = {}) => {
    return Axios('/statistics/bug-score', 'post', params);
};

//  获取项目质量最佳top5
export const bestQualityTopFiveByProject = (params = {}) => {
    return Axios('/statistics/find-best-quality-top-five-by-project', 'post', params);
};

//  获取迭代质量最佳top5
export const bestQualityTopFiveByIteration = (params = {}) => {
    return Axios('/statistics/find-best-quality-top-five-by-iteration', 'post', params);
};

// 项目得分排行-项目/迭代
export const queryProjectScoreRank = (url) => {
    return Axios(url, 'get');
};

// 千行代码/获取项目迭代组织列表
export const thousandCodeIterationGroupList = (projectType = 0, params = {}) => {
    return Axios(`/code-line/project-tree/${projectType}`, 'post', params);
};

// 千行代码/影响代码行数查询
export const thousandCodeCodeDetail = (params = {}) => {
    return Axios('/code-line/query', 'post', params);
};

//  项目类型下拉列表
export const getProjectTypeList = (params = {}) => {
    return Axios('/select-list/project-type', 'post', params);
};

//  所属基线下拉列表
export const getBelongBaselineList = (params = {}) => {
    return Axios('/select-list/belong-baseline', 'post', params);
};

//  项目下拉列表
export const getProjectList = (params = {}) => {
    return Axios('/select-list/project', 'post', params);
};

//  迭代下拉列表
export const getIterationList = (params = {}) => {
  return Axios('/select-list/iteration', 'post', params);
};

//  实时人效分页列表
export const getEffectPage = (params = {}) => {
    return Axios('/real-time-humane-effect/page', 'post', params);
};

//  部门下拉列表
export const getDepartmentList = (params = {}) => {
  return Axios("/select-list/department-tree", "post", params);
};


// 部门员工下拉
export const getDepartmentUser = (params = {}) => {
  return Axios("/select-list/department-user", "post", params);
};

// 研发效能排行榜 
export const getDevEffectRank = (params = {}) => {
  return Axios("/statistics/dev-effect-rank", "post", params);
};

// 投入预算/投入核算 计划总投入人天 
export const getHumaneEffectDetail = (params = {}) => {
  return Axios("/real-time-humane-effect/detail", "post", params);
};

// 投入预算/投入核算 里程碑计划投入 
export const getHumaneEffectMileStone = (params = {}) => {
  return Axios("/real-time-humane-effect/mileStone", "post", params);
};