import { ITERATION_STATUS, PROJECT_STATUS, MILESTONE_STATUS, } from './data';
import './index.less';

interface IProps {
    /**
     * 项目类型
     * 
     * 0基线产品、1定制项目、2里程碑
     */
    projectType: 0 | 1 | 2;
    /**
     * 项目状态、迭代状态、里程碑状态
     */
    status: number | string;
    /**
     * 占位符
     */
    placeholder?: string | React.ReactNode;
}

/**
 * 项目状态、迭代状态、里程碑状态
 * 
 * 展示状态文本的UI组件
 * @returns 
 */
export default function IterationStatusText(props: IProps) {

    const { className, str, } = {
        0: ITERATION_STATUS,
        1: PROJECT_STATUS,
        2: MILESTONE_STATUS,
    }?.[props?.projectType]?.[props?.status] || {};
    if(!str) {
        if(typeof props?.placeholder === 'undefined') {
            return <>-</>;
        }else {
            return <>{ props?.placeholder || null }</>;
        }
    }

    return (
        <div className={ className }>{ str }</div>
    );
};
