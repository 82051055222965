import {Axios} from '@utils';

// 发送修改密码短信验证码
export const sendVerifyCode = (req = {}) => {
    return Axios('/sms/send-change-password-verify-code', 'post', req);
};

// 验证修改密码短信验证码
export const validateVerifyCode = (req = {}) => {
    return Axios('/sms/validate-change-password-verify-code', 'post', req);
};

// 检测用户是否存在
export const checkPhoneNumber = (req = {}) => {
    return Axios('/user-info-web/check-user-exists', 'post', req);
};

// 修改密码
export const updatePassword = (req = {}) => {
    return Axios('/user-info-web/update-user-password', 'post', req);
};