import { Action, Actor } from "plume2";
import { merge } from "immutable";
import moment from "moment";

export default class SearchGroupActor extends Actor {
  defaultState() {
    return {
      tabType: 1, //头部的tab切换 1:项目/迭代 2:自定义
      bugAnalysisSearch: {
        pageNum: 1,
        pageSize: 10,
      }, //搜索条件
      bugAnalysisSearchData: {}, //搜索条件数据
      // --------- 项目列表相关  ---------
      onlyTested: 0, // 0: 全部 1: 仅看转测
      projectPageList: {},
      projectPageLoading: true, // 项目列表加载中
      clickRowClassName: "", // 列表点击的样式名称 （转测中缺陷修复数据）
      rowId: "", // 列表id
      selectProjectType: 0, //0:基线 1:定制
      // --------- 概览 ---------
      overViewSearch: {}, // 概览搜索条件
      attention: false, // 提醒关注
      overViewSearchData: {},
      overViewBugData: {}, // 橄榄缺陷数量
      demandOptimizationFlag: true, //需求优化指示 true:包含 false:不包含
      projectNumOrRate: 0, //项目/迭代 0：按数量，1：按比例
      customNumOrRate: 0, // 自定义 0:按数量 1:按占比
      severityDistribution: [], // 严重程度分布
      priorityDistribution: [], // 优先级分布
      originPhaseDistribution: [], // 发现阶段分布
      historyDistribution: [], // 历史遗留分布
      // --------- 柱状图  ---------
      scoreType: 1, // 柱状图tab切换 1:按开发人员 2:按缺陷类型 3:按缺陷原因 4:按模块 5:按修复人员
      customScoreType: 1, //定制的柱状图tab切换  1:按开发人员 2:按缺陷类型 3:按缺陷原因 4:按模块 5:按修复人员
      forUser: [], // 按开发人员
      forFixUser: [], // 按修复人员
      forModule: [], // 按模块
      forReason: [], // 按原因
      forYype: [], // 按类型

      forUserPage: {}, // 按开发人员列表
      forFixUserPage: {}, // 按修复人员列表
      forModulePage: {}, // 按模块列表
      forReasonPage: {}, // 按原因列表
      forYypePage: {}, // 按类型列表
      forPageNum: 1,
      forPageSize: 10,
      forUserPageLoading: true,
      forFixUserPageLoading: true,
      forModulePageLoading: true,
      forReasonPageLoading: true,
      forYypePageLoading: true,
      // --------- 自定义  ---------
      startDate: moment().subtract(3, "months").format("YYYY-MM-DD"), // 自定义的开始时间，
      endDate: moment().format("YYYY-MM-DD"), // 自定义的结束时间，
      customSearch: {}, // 自定义搜索条件
      customSearchData: {},
      customSelectProjectType: 0, //0:基线 1:定制
      customDemandOptimizationFlag: true, //自定义需求优化指示 true:包含 false:不包含

      pushSearch: {}, // 提醒关注搜索条件

      newFixLineData:[],
      forNewPage:[],
      forFixPage:[],
      forNewPageLoading: true,
      forFixPageLoading: true,
      newFixScoreType: 1, // 折线图tab切换 1:按新增，2按修复
      customNewFixScoreType: 1, //定制的折线图tab切换 1:按新增，2按修复
      newFixPageNum: 1,
      newFixPageSize: 10,
    };
  }
  

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
