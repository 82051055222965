import { Axios } from '@utils';
import { 
  IFetchProjectListPage, IFetchProjectListIterationDetail, 
  IFetchProjectListProjectDetail, IFetchProjectListTaskList,
} from './type';

interface IResponse<T = any> {
  /** 响应码 */
  code: string;
  /** 主体内容 */
  context: T;
  /** 错误数据 */
  errorData: any;
  /** 提示 */
  message: string;
}

/**
 * 查询 - 项目列表
 * @param params 
 * @returns 
 */
export const fetchProjectListPage = (params: IFetchProjectListPage): Promise<IResponse> => {
  return Axios("/project-list/page", "post", params);
};

/**
 * 查询 - 迭代明细、里程碑清单
 * @param params 
 * @returns 
 */
export const fetchProjectListIterationDetail = (params: IFetchProjectListIterationDetail): Promise<IResponse> => {
  return Axios("/project-list/iteration-detail", "post", params);
};

/**
 * 查询 - 定制项目-迭代明细
 * @param params 
 * @returns 
 */
export const fetchProjectListProjectDetail = (params: IFetchProjectListProjectDetail): Promise<IResponse> => {
  return Axios("/project-list/project-detail", "post", params);
};

/**
 * 查询 - 里程碑任务
 * @param params 
 * @returns 
 */
export const fetchProjectListTaskList = (params: IFetchProjectListTaskList): Promise<IResponse> => {
  return Axios("/project-list/task-list", "post", params);
};
