import { Store } from "plume2";
import MainActor from "./actor/actor";
import {
  history,
  LOGIN_DATA,
  SUCCESS_CODE,
  util,
  getQueryVariable,
  firstMenusData,
} from "@utils";
import * as webapi from "./webapi";
import { message } from "antd";
import { cache } from "@utils";
import { fromJS } from "immutable";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 改值
   */
  changeData = (field, value) => {
    this.dispatch("change:data", { field, value });
  };

  // 改变登录类型（账号登录 or 扫码登录）
  _changeLoginType = () => {
    const loginType = this.state().get("loginType");
    this.mergeData({
      loginType: loginType === "account" ? "code_QR" : "account",
    });
  };

  /**
   * 账号密码 登录
   */
  _login = async (values) => {
    let base64 = new util.Base64();
    const account = base64.urlEncode(values.username);
    const password = base64.urlEncode(values.password);
    const params = {
      customerAccount: account,
      customerPassword: password,
    };
    const res = await webapi.login(JSON.stringify(params));

    if (res?.code === SUCCESS_CODE) {
      this.setLoginData(
        res.context.access_token,
        res.context.username,
        res.context.requireChangePwd
      );
      //记住账号密码
      if (values.remember) {
        localStorage.setItem("username", values.username);
        localStorage.setItem("password", window.btoa(values.password)); //加密
      }
      //获取登录人拥有的权限
      this.getUserInfoAuthority();
      //登录成功调用一次查用户信息
      setTimeout(() => {
        this.getUserInfo(res?.context?.requireChangePwd);
      }, 300);
    } else {
      message.error(res.message);
    }
  };

  /**
   * 企业微信扫码登录后，拿到code，调用当前接口，获取当前用户信息
   */
  _get_user_info_by_auth_cod_wei_xin = async () => {
    const { code } = getQueryVariable(["code"]);
    if (!code) return;
    this.mergeData({
      loading: true,
    });
    const res = await webapi.get_user_info_by_auth_cod_wei_xin({
      code,
    });

    if (res.code === SUCCESS_CODE) {
      this.setLoginData(
        res.context.access_token,
        res.context.username,
        res.context.requireChangePwd
      );
      //获取登录人拥有的权限
      this.getUserInfoAuthority();
      //登录成功调用一次查用户信息
      setTimeout(() => {
         this.getUserInfo(res?.context?.requireChangePwd);
      }, 300);
    }
    this.mergeData({
      loading: false,
    });
  };

  /**
   * 设置登录信息缓存
   */
  setLoginData = (token, username, requireChangePwd) => {
    (window as any).token = token;
    localStorage.setItem(
      LOGIN_DATA,
      JSON.stringify({
        token: token,
        username: username,
        requireChangePwd: requireChangePwd,
      })
    );
  };

  /**
   * 登录成功调用一次查用户信息
   */
  getUserInfo = async (requireChangePwd) => {
    //登录成功调用一次查用户信息
    const data = await webapi.getUserInfo();

    if (data.code === SUCCESS_CODE) {
      //缓存个人信息
      localStorage.setItem(
        "userInfo",
        JSON.stringify(data.context?.userInfoVO || {})
      );

      //判断是否是第一次登录 requireChangePwd为true 需要修改密码
      //第一次登录跳转修改密码页，强制修改
      if (requireChangePwd) {
        history.replace("/change-password");
      } else {
        // 需要做权限处理 不能直接跳转首页
        const url = localStorage.getItem(cache.LOGIN_URL);
        console.log("debug===>url", url);
        const newUrl = url?.substr?.(2);
        if (!url) {
          // 跳转配置的第一个菜单页
          history.replace(firstMenusData()[0]?.url);
        } else {
          // 跳转重定向
          history.replace(`${newUrl}`);
        }
      }
    }
  };

  /**
   * 登录成功查询用户权限
   */
  getUserInfoAuthority = async () => {
    const menusRes = await webapi.fetchMenus();
    if (menusRes.code === SUCCESS_CODE) {
      localStorage.setItem(
        cache.LOGIN_MENUS,
        JSON.stringify(menusRes.context)
      );
      const functionsRes = (await webapi.fetchFunctions()) as any;
      localStorage.setItem(
        cache.LOGIN_FUNCTIONS,
        JSON.stringify(functionsRes.context)
      );
    } else {
      message.error(menusRes.message);
    }
  };

  /**
   * 获取子菜单
   * @param list
   * @private
   */
  _getChildren = (list, dataList) => {
    return list.map((data) => {
      const children = dataList.filter(
        (item) => item.get("pid") == data.get("id")
      );
      if (!children.isEmpty()) {
        data = data.set("children", this._getChildren(children, dataList));
      }
      return data;
    });
  };
}
