import React from 'react';
import { Relax } from 'plume2';
import { noop, util } from '@utils';
import { Drawer, Table, Tabs, Image } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import milestone from '../../img/milestone.png';
const SummaryRow = Table.Summary.Row;
const SummaryCell = Table.Summary.Cell;
const { TabPane } = Tabs;

// 数组指定切割
const spArr = (arr, num) => {
  //arr是你要分割的数组，num是以几个为一组
  let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
  for (let i = 0; i < arr.length; ) {
    //注意：这里与for循环不太一样的是，没有i++
    newArr.push(arr.slice(i, (i += num)));
  }
  return newArr;
};
const _getWeeksName = (data) => {
  // data星期日为 0、星期六为 6。
  if (moment(data).day() == 0) {
    return '周日';
  } else {
    return '周' + util.numToChinaNum(moment(data).day());
  }
};
@Relax
export default class ParticularsDrawer extends React.Component<any, any> {
  static relaxProps = {
    changeData: noop,
    changeTab: noop,
    canCelDrawer: noop,
    detailInfo: 'detailInfo',
    drawerVisible: 'drawerVisible',
    drawerActiveKey: 'drawerActiveKey',
    investmentTotalDetail: 'investmentTotalDetail',
    investmentPhaseDetail: 'investmentPhaseDetail',
    humaneEffectDetailLoading: 'humaneEffectDetailLoading',
    humaneEffectMileStoneLoading: 'humaneEffectMileStoneLoading',
  };

  render() {
    const {
      drawerVisible,
      changeData,
      drawerActiveKey,
      changeTab,
      detailInfo,
      canCelDrawer,
    } = this.props.relaxProps;
    return (
      <Drawer
        maskClosable
        destroyOnClose
        className="particulars_drawer"
        title={detailInfo.get('title')}
        placement="right"
        extra={
          <CloseOutlined
            onClick={() => {
              canCelDrawer();
            }}
          />
        }
        closable={false}
        width="100vw"
        visible={drawerVisible}
      >
        <div>
          <Tabs
            activeKey={drawerActiveKey}
            onChange={(value) => changeTab(value)}
          >
            <TabPane tab="投入预算" key="0">
              {this.investment_total('investment_total1')}
              <div className="perch" />
              {this.investment_phase('investment_phase2')}
            </TabPane>
            <TabPane tab="投入核算" key="1">
              {this.investment_total('investment_total3')}
              <div className="perch" />
              {this.investment_phase('investment_phase4')}
            </TabPane>
          </Tabs>
        </div>
      </Drawer>
    );
  }

  /**
   * 计划总投入
   */
  investment_total = (key) => {
    const {
      investmentTotalDetail,
      humaneEffectDetailLoading,
      drawerActiveKey,
    } = this.props.relaxProps;
    return (
      <Table
        key={key}
        bordered
        rowKey="id"
        className="investment_total"
        loading={humaneEffectDetailLoading}
        columns={this.investment_total_column()}
        dataSource={investmentTotalDetail.get('dataList').toJS()}
        pagination={false}
        scroll={{ x: 2301, y: 5000 }}
        sticky={false}
        summary={() => (
          <Table.Summary fixed="bottom">
            <SummaryRow>
              <SummaryCell
                index={0}
                colSpan={3}
                align="center"
                className="weight gray-color"
              >
                {drawerActiveKey == '0' ? '计划总投入' : '实际总投入'}(人天):
              </SummaryCell>
              <SummaryCell
                index={3}
                align="center"
                className="combinedColor weight"
              >
                {investmentTotalDetail.get('totalEffort') || 0}
              </SummaryCell>
              <SummaryCell
                index={4}
                align="center"
                className="weight gray-color"
              >
                各周合计:
              </SummaryCell>
              {investmentTotalDetail.get('totalVoList').map((item, index) => (
                <SummaryCell
                  key={index}
                  index={index + 5}
                  align="center"
                  className="combinedColor weight"
                >
                  {item.get('value') != -1 ? item.get('value') : ''}
                </SummaryCell>
              ))}
            </SummaryRow>
          </Table.Summary>
        )}
      />
    );
  };

  /**
   * 各里程碑计划投入
   */
  investment_phase = (key) => {
    const {
      investmentPhaseDetail,
      humaneEffectMileStoneLoading,
    } = this.props.relaxProps;
    return (
      <Table
        key={key}
        bordered
        rowKey={(record) => {
          return record.id + record.name;
        }}
        className="investment_phase"
        columns={this.investment_phase_column()}
        dataSource={investmentPhaseDetail.get('dataList').toJS()}
        pagination={false}
        loading={humaneEffectMileStoneLoading}
        scroll={{ x: 2301, y: 5000 }}
        sticky={false}
        summary={() => (
          <Table.Summary fixed="bottom">
            <SummaryRow>
              <SummaryCell
                index={0}
                colSpan={2}
                align="center"
                className="gray-color"
              />
              <SummaryCell
                index={2}
                align="center"
                className="weight gray-color"
              >
                各周合计:
              </SummaryCell>
              {investmentPhaseDetail.get('totalVoList').map((item, index) => (
                <SummaryCell
                  index={index + 3}
                  align="center"
                  className="combinedColor weight"
                >
                  {item.get('value') != -1 ? item.get('value') : ''}
                </SummaryCell>
              ))}
            </SummaryRow>
          </Table.Summary>
        )}
      />
    );
  };

  investment_total_column = (): any => {
    const { investmentTotalDetail, drawerActiveKey } = this.props.relaxProps;
    const weeks = spArr(investmentTotalDetail.get('dateList').toJS(), 8);
    // 工时标准
    const baseOfWorkingHours = investmentTotalDetail.get('baseOfWorkingHours');
    // 工作日日期
    const workDateList = investmentTotalDetail.get('workDateList');
    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        fixed: 'left',
        width: '68px',
        render: (_, text, index) => {
          return <div>{index + 1}</div>;
        },
      },
      {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        fixed: 'left',
        width: '96px',
        render: (text) => text || '-',
      },
      {
        title: '所属部门',
        dataIndex: 'department',
        key: 'department',
        align: 'center',
        fixed: 'left',
        width: '99px',
        render: (text) => text || '-',
      },
      {
        title: '岗位',
        dataIndex: 'position',
        key: 'position',
        align: 'center',
        fixed: 'left',
        width: '140px',
        render: (text) => text || '-',
      },
      {
        title: `${drawerActiveKey == '0' ? '计划投入' : '实际投入'}(人天)`,
        dataIndex: 'effort',
        key: 'effort',
        align: 'center',
        fixed: 'left',
        width: '104px',
        onCell: () => ({
          className: 'combinedColor',
        }),
        render: (text) => text || 0,
      },
      ...weeks.map((week, index) => {
        const isCurrentWeek = week.includes(moment().format('YYYY-MM-DD'));
        return {
          title: `第${index + 1}周`,
          className: isCurrentWeek ? 'current-week-cell' : 'week-cell',
          children: week.map((day, i) => {
            // 是否是当前天
            const isCurrentDay = moment(moment().format('YYYY-MM-DD')).isSame(
              day,
            );
            // 是否是合计
            const isCombined = (i + 1) % 8 == 0;
            return {
              title: this._renderTtile(day, isCombined),
              width: '50px',
              className: isCurrentDay
                ? 'current-day-cell day-cell'
                : 'day-cell',
              dataIndex: isCombined
                ? ['workDateList', `total${index + 1}`]
                : ['workDateList', day],
              key: `${day}`,
              align: 'center',
              onCell: (record) =>
                this._renderCellStyle(
                  isCombined,
                  day,
                  record['workDateList'][day],
                  baseOfWorkingHours,
                  workDateList,
                ),
              render: (text) =>
                this._renderDayValue(text, day, isCombined, workDateList),
            };
          }),
        };
      }),
    ];
    return columns;
  };

  investment_phase_column = (): any => {
    const { investmentPhaseDetail, drawerActiveKey } = this.props.relaxProps;
    const weeks = spArr(investmentPhaseDetail.get('dateList').toJS(), 8);
    // 工时标准
    const baseOfWorkingHours = investmentPhaseDetail.get('baseOfWorkingHours');
    // 工作日日期
    const workDateList = investmentPhaseDetail.get('workDateList');
    const columns = [
      {
        title: '',
        dataIndex: 'rank',
        key: 'rank',
        align: 'center',
        fixed: 'left',
        width: '182px',
        onCell: (_, index) => {
          return {
            className: 'weight',
            rowSpan:
              index === 0 ? investmentPhaseDetail.get('dataList').size : 0,
          };
        },
        render: () => {
          return (
            <div>
              <Image src={milestone} width={16} height={16} preview={false} />
              &nbsp;各里程碑{drawerActiveKey == '0' ? '计划投入' : '实际投入'}
            </div>
          );
        },
      },
      {
        title: '里程碑',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        fixed: 'left',
        width: '220px',
        render: (text) => text || 0,
      },
      {
        title: `${drawerActiveKey == '0' ? '计划投入' : '实际投入'}(人天)`,
        dataIndex: 'effort',
        key: 'effort',
        align: 'center',
        fixed: 'left',
        width: '104px',
        onCell: () => ({
          className: 'combinedColor',
        }),
        render: (text) => text || 0,
      },
      ...weeks.map((week, index) => {
        const isCurrentWeek = week.includes(moment().format('YYYY-MM-DD'));
        return {
          title: `第${index + 1}周`,
          className: isCurrentWeek ? 'current-week-cell' : 'week-cell',
          children: week.map((day, i) => {
            // 是否是当前天
            const isCurrentDay = moment(moment().format('YYYY-MM-DD')).isSame(
              day,
            );
            // 是否是合计
            const isCombined = (i + 1) % 8 == 0;
            return {
              title: this._renderTtile(day, isCombined),
              width: '50px',
              className: isCurrentDay
                ? 'current-day-cell day-cell'
                : 'day-cell',
              dataIndex: isCombined
                ? ['workDateList', `total${index + 1}`]
                : ['workDateList', day],
              key: `${day}`,
              align: 'center',
              onCell: (_) =>
                this._renderPhaseCellStyle(isCombined, day, workDateList),
              render: (text) =>
                this._renderPhaseDayValue(text, day, isCombined, workDateList),
            };
          }),
        };
      }),
    ];
    return columns;
  };

  /**
   * @param day 时间
   * @param isCombined 是否合计
   */
  _renderTtile = (day: string, isCombined: boolean) => {
    return isCombined ? (
      <div>
        <span className="week">合计</span>
      </div>
    ) : (
      <div>
        <span className="week">{_getWeeksName(day)}</span>
        <br />
        <span className="day">{moment(day).format('M/D')}</span>
      </div>
    );
  };

  /**
   * 单元格样式展示
   * @param isCombined 是否是合计
   * @param day 时间
   * @param dayValue  时间对应的值
   * @param baseOfWorkingHours 标准工时
   * @param workDateList 工作日list
   * @param checkHours 是否校验工时
   */
  _renderCellStyle = (
    isCombined: boolean,
    day: string,
    dayValue: number,
    baseOfWorkingHours: number,
    workDateList: any,
    checkHours: boolean = true,
  ) => {
    // 情况区分
    // 1. 工作日 1.符合工时标准=>默认色 2.不符合工时标准=>红色 3.无投入展示 ‘-’ =>灰色
    // 2. 节假日 1.加班=>白色 2.休息=>combinedColor
    // 3. 合计 蓝色 无值展示0
    let colorName = '';
    //非合计
    if (!isCombined) {
      // 工作日
      if (workDateList.includes(day)) {
        //无投入或计划外
        if (dayValue == null || dayValue == -1) {
          colorName = 'noPutColor';
        }
        //工时标准判断
        if (dayValue !== -1 && dayValue != null && checkHours) {
          colorName = dayValue !== baseOfWorkingHours ? 'lackColor' : '';
        }
      } else {
        //节假日
        colorName = 'weekendColor';
      }
    } else {
      // 合计
      colorName = 'combinedColor';
    }

    return {
      className: colorName,
    };
  };

  /**
   * 里程碑单元格样式展示
   * @param isCombined 是否是合计
   * @param day 时间
   * @param workDateList 工作日list
   */
  _renderPhaseCellStyle = (
    isCombined: boolean,
    day: string,
    workDateList: any,
  ) => {
    // 情况区分
    // 1. 工作日 白色
    // 2. 节假日 灰色
    // 3. 合计 蓝色
    let colorName = '';
    //非合计
    if (!isCombined) {
      //节假日
      if (!workDateList.includes(day)) {
        colorName = 'weekendColor';
      }
    } else {
      // 合计
      colorName = 'combinedColor';
    }

    return {
      className: colorName,
    };
  };

  /**
   *
   * @param dayValue  时间对应的值
   * @param day 时间
   * @param isCombined 是否是合计
   * @param workDateList 工作日list
   */
  _renderDayValue = (
    dayValue: number,
    day: string,
    isCombined: boolean,
    workDateList: any,
  ) => {
    // 情况区分
    // 1. 工作日 1.有值正常展示 3.无投入展示 ‘-’
    // 2. 节假日 1.有值展示 2.无值展示‘’
    // 3. 合计  1.有值展示 2. 无值展示0

    // 无值区分情况
    if (dayValue == null || dayValue == -1) {
      // 合计
      if (isCombined) return 0;
      // 工作日
      if (workDateList.includes(day)) {
        return '-';
      }
      // 节假日
      return '';
    }
    return dayValue;
  };

  /**
   *
   * @param dayValue  时间对应的值
   * @param day 时间
   * @param isCombined 是否是合计
   * @param workDateList 工作日list
   */
  _renderPhaseDayValue = (
    dayValue: number,
    day: string,
    isCombined: boolean,
    workDateList: any,
  ) => {
    // 情况区分
    // 1. 工作日 1.有值展示 无值展示0
    // 2. 节假日 1.有值展示 2.无值展示‘’
    // 3. 合计  1.有值展示 2. 无值展示0

    // 无值区分情况
    if (dayValue == null || dayValue == -1) {
      // 合计 工作日
      if (isCombined || workDateList.includes(day)) return 0;
      // 节假日
      return '';
    }
    return dayValue;
  };
}
