import { Store } from "plume2";
import MainActor from "./actor/actor";
import { SUCCESS_CODE } from "@utils";
import * as webapi from "./webapi";
import { message } from "antd";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  // 获取 - 个人信息 = 数据
  _getUserInfo = async () => {
    const data = await webapi.getUserInfo();

    if (data.code === SUCCESS_CODE) {
      //缓存个人信息
      localStorage.setItem(
        "userInfo",
        JSON.stringify(data.context?.userInfoVO || {})
      );
      this.mergeData({
        userInfoVO: data.context?.userInfoVO || {},
      });
    } else {
      message.error(data.message);
    }
  };

  // 监听气泡弹窗的变化
  onVisibleChange = (hovered, clicked, callBack) => {
    this.mergeData({
      hovered: Boolean(hovered),
      clicked: Boolean(clicked),
    });

    setTimeout(() => {
      callBack?.();
    }, 0);
  };

  // 头像上传前 - 处理
  // 限制图片格式、图片大小等
  _beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("目前只支持JPG / PNG格式的图片！");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("上传图片大小限制在2MB内！");
    }

    return isJpgOrPng && isLt2M;
  };

  // 监听上传头像操作
  _handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      if (info?.file?.response?.code === SUCCESS_CODE) {
        const [imgUrl] = info?.file?.response?.context || [];
        this._updateUserHeadImg(imgUrl);
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} 上传失败！`);
    }
  };

  // 更新用户头像
  _updateUserHeadImg = async (url = "") => {
    if (!url) return;

    const data = await webapi.updateUserHeadImg({
      headImgUrl: url,
    });

    if (data.code === SUCCESS_CODE) {
      message.success(`上传成功！`);
      this._getUserInfo();
    } else {
      message.error(`上传失败！`);
    }
  };
}
