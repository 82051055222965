import React from "react";
import { Relax } from "plume2";
import { noop, util } from "@utils";
import { Table, Pagination, Radio, Tooltip, Checkbox } from 'antd';
import moment from 'moment';
import { fromJS } from 'immutable'
import alertImg from '../../../../img/alert.png';

const { Column, ColumnGroup } = Table;

@Relax
export default class TitleData extends React.Component<any, any> {
  static relaxProps = {
    changeData: noop,
    getEffortSummaryPage: noop,
    getCalendarData: noop,
    mergeData: noop,
    getDepartmentUser: noop,
    scoreType: 'scoreType',
    effortSummaryPage: 'effortSummaryPage',
    departmentId: 'departmentId',
    listUserId: 'listUserId',
    effortSummaryPageLoading: 'effortSummaryPageLoading',
    positionDepartmentArr: 'positionDepartmentArr',
    totalStartMonth: 'totalStartMonth',
    totalStartYear: 'totalStartYear'
  };

  render() {
    const { scoreType, effortSummaryPage, getEffortSummaryPage, effortSummaryPageLoading, positionDepartmentArr } = this.props.relaxProps;
    const dataSource = effortSummaryPage?.toJS()?.page?.records
    const dateList = effortSummaryPage?.toJS()?.dateList
    const total = effortSummaryPage?.toJS()?.page?.total || 0
    const current = effortSummaryPage?.toJS()?.page?.current || 1
    const departmentShowFlag = positionDepartmentArr.includes('department') ? true : false;
    const positionShowFlag = positionDepartmentArr.includes('position') ? true : false;
    const options = [
      { label: '所属部门', value: 'department' },
      { label: '岗位', value: 'position' },
    ];
    const pageSize = effortSummaryPage?.toJS()?.page?.size || 10
    return (
      <div className="list-data-contain">
        <div className="tabs-btn">
          <Checkbox.Group options={options} defaultValue={['department']} onChange={(e) => { this._onChangeCheck(e) }} />
          <Radio.Group
            options={[
              { label: "全部", value: 0 },
              { label: "仅看异常", value: 1 },
            ]}
            onChange={(e) => {
              this._changeScoreType(e.target.value)
            }}
            value={scoreType}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <Table
          dataSource={dataSource}
          scroll={{ x: this._getSrollValue(dateList, departmentShowFlag, positionShowFlag) as any }}
          pagination={false}
          onRow={record => {
            return {
              onClick: () => {
                this._handleClick(record)
                this._scrollToDetail()
              }, // 点击行
            };
          }}
          rowKey="id"
          rowClassName={this._setRowClassName}
          loading={effortSummaryPageLoading}
        >
          <Column title="姓名" dataIndex="username" key="username" width="100px" align="left" fixed="left" />
          {departmentShowFlag && <Column title="所属部门" dataIndex="deptName" key="deptName" width="140px" align="left" fixed="left" />}
          {positionShowFlag && <Column title="岗位" dataIndex="position" key="position" width="200px" align="left" fixed="left" className="employee-data-position-column" />}
          <ColumnGroup title={this._renderDay(dateList)} className="employee-data-position-shadow">
            <Column title={this._renderWeek(dateList)} dataIndex="workDateList" render={(workDateList, data) => this._renderStatus(workDateList, data) as any} className="employee-data-position-shadow" />
          </ColumnGroup>
        </Table>
        <div className="alert">
          <img src={alertImg}></img>
          <span>正常：有计划且有产出；异常：有计划无产出，有产出计划；虚耗：无计划无产出；调休：当天请假；加班：周末加班</span>
        </div>
        <div id="scroll-positon"></div>
        <Pagination
          showQuickJumper={true}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']}
          total={total}
          onChange={(page, pageNumber) => { this._paginationOnChange(page, pageNumber) }}
          showTotal={(total, range) => ` 共${total}条`}
          current={current}
          pageSize={pageSize}
        />
      </div>
    );
  }

  _paginationOnChange = (page, pageSize) => {
    const { mergeData, getEffortSummaryPage } = this.props.relaxProps;
    mergeData({
      effortPageNum: page,
      effortPageSize: pageSize,
    })
    getEffortSummaryPage(page)
  }


  _onChangeCheck = (e) => {
    const { changeData } = this.props.relaxProps;
    changeData('positionDepartmentArr', e)
  }

  _handleClick = (record) => {
    const { getCalendarData, totalStartMonth, totalStartYear, mergeData } = this.props.relaxProps;
    const data = {
      children: record.username,
      value: record.id
    }
    mergeData({
      departmentUserInfo: fromJS(data),
      tabType: '2',
      listUserId: record.id,
      calendarDetail: [],
      detailStartYear: totalStartYear,
      detailStartMonth: totalStartMonth,
    })
    getCalendarData('detail')
    // 清空日期的样式
    let element = document.querySelectorAll('.ant-picker-calendar-date')
    element.forEach((i) => {
      i.removeAttribute('style')
    })
  }

  _setRowClassName = (record) => {
    const { listUserId } = this.props.relaxProps
    if (record.id == null) return ''
    return record.id == listUserId ? 'click-row-bgc' : ''
  }

  // 页面滚动到投入明细
  _scrollToDetail = () => {
    const scrollDom = document.getElementById('scroll-positon');
    scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
  }


  _changeScoreType = (value) => {
    const { changeData, getEffortSummaryPage, getDepartmentUser } = this.props.relaxProps;
    changeData("scoreType", value);
    getEffortSummaryPage(1, 10)
    getDepartmentUser()
  }

  _getSrollValue = (dateList, departmentShowFlag, positionShowFlag) => {
    if (departmentShowFlag && positionShowFlag) {
      if (dateList?.length == 31) {
        return 2301
      } else if (dateList?.length == 30) {
        return 2241
      } else if (dateList?.length == 29) {
        return 2181
      } else {
        return 2121
      }
    } else if (departmentShowFlag) {
      if (dateList?.length == 31) {
        return 2301 - 200
      } else if (dateList?.length == 30) {
        return 2241 - 200
      } else if (dateList?.length == 29) {
        return 2181 - 200
      } else {
        return 2121 - 200
      }
    } else if (positionShowFlag) {
      if (dateList?.length == 31) {
        return 2301 - 140
      } else if (dateList?.length == 30) {
        return 2241 - 140
      } else if (dateList?.length == 29) {
        return 2181 - 140
      } else {
        return 2121 - 140
      }
    } else {
      if (dateList?.length == 31) {
        return 2301 - 340
      } else if (dateList?.length == 30) {
        return 2241 - 340
      } else if (dateList?.length == 29) {
        return 2181 - 340
      } else {
        return 2121 - 340
      }
    }
  }

  _renderDay = (dateList) => {
    // workDateType: 0:普通日期 8:节假日
    return dateList?.map((item) => <div style={{ fontWeight: 500, width: '60px', display: 'inline-block', textAlign: 'center', height: '34px', background: item.date == moment().format("YYYY-MM-DD") ? '#F56C1D' : item.workDateType == 8 ? '#F7F9FC' : '', color: item.date == moment().format("YYYY-MM-DD") ? '#fff' : '#333333', lineHeight: '34px' }}>{moment(item.date).format("D")}</div>)
  }

  _renderWeek = (dateList) => {
    // workDateType: 0:普通日期 8:节假日
    return dateList?.map((item) => <div style={{ width: '60px', display: 'inline-block', textAlign: 'center', height: '36px', background: item.date == moment().format("YYYY-MM-DD") ? '#F56C1D' : item.workDateType == 8 ? '#F7F9FC' : '', color: item.date == moment().format("YYYY-MM-DD") ? '#fff' : '', lineHeight: '34px' }}>{'周' + this._getWeeksName(item?.date)}</div>)
  }

  _handleDateList = (arr) => {
    let dateList = [];
    arr?.forEach?.(item => {
      if(item.joinTime && item.joinTime == item.date){
        item["join"] = 1;
        item.workDateType = -1;
      }
      if(item.departureTime && item.departureTime == item.date){
        item["departure"] = 1;
        item.workDateType = -1;
      }
      if (item.workDateType == 3 || item.workDateType == 4 || item.workDateType == 5 || item.workDateType == -1) {
        dateList.push(item);
      } else {
        if (!dateList.length) {
          dateList.push(item);
        } else {
          const last_item = dateList[dateList.length - 1];
          const day1 = new Date(Array.isArray(last_item.date) ? last_item.date[last_item.date.length - 1] : last_item.date).getDate();
          const day2 = new Date(item.date).getDate();
          let flag = false;
          if (item.workDateType == 9 || item.workDateType == 10) {
            if (item.vacationRange == null && last_item.vacationRange == null && last_item.workDateType === item.workDateType) {
              flag = true;
            }
          } else if (last_item.workDateType === item.workDateType) {
            flag = true;
          }
          if (flag) {
            if (day1 < day2 && day2 - day1 === 1) {
              let _arr = [];
              if (Array.isArray(last_item.date)) {
                _arr = [...last_item.date, item.date];
              } else {
                _arr = [last_item.date, item.date];
              }
              dateList[dateList.length - 1] = {
                ...last_item,
                date: _arr,
              };
            }
          } else {
            dateList.push(item);
          }
        }
      }
    });
    return dateList;
  }

  _renderStatus = (dateList, data) => {
    dateList = this._handleDateList(dateList);
    return dateList.map((item, index) => {
      // 日期类型，0正常 1异常 2调休 3 休半天（调休） 4 休半天（异常） 5 休半天（虚耗） 6 虚耗 7 加班 8 休息 9:有计划 10:无计划 
      let color, text, classname, vacationRangeText, vacationRangeStatusBgc;
      // dateType 0:一天  1:半天
      let dateType = 0;
      if (item.workDateType == 0) {
        text = '正常';
        classname = 'usual';
      } else if (item.workDateType === 1) {
        text = '异常';
        classname = 'unusual';
      } else if (item.workDateType === 2) {
        text = '调休';
        classname = 'flirt';
      } else if (item.workDateType === 3) {
        text = '休半天';
        vacationRangeText = '调休'
        classname = 'flirt';
        vacationRangeStatusBgc = '#1890FF';
        dateType = 1;
      } else if (item.workDateType === 4) {
        text = '休半天';
        vacationRangeText = '异常'
        classname = 'flirt';
        dateType = 1;
        vacationRangeStatusBgc = '#FAAD14';
      } else if (item.workDateType === 5) {
        text = '休半天';
        vacationRangeText = '虚耗'
        classname = 'flirt';
        vacationRangeStatusBgc = '#F53731';
        dateType = 1;
      } else if (item.workDateType === 6) {
        text = '虚耗';
        classname = 'waste';
      } else if (item.workDateType === 7) {
        text = '加班';
        classname = 'work-overtime'
      } else if (item.workDateType === 8) {
        text = '休息';
        classname = 'rest'
      } else if (item.workDateType === 9) {
        if (item.vacationRange === 0) {
          text = '调休';
          classname = 'flirt';
        } else if (item.vacationRange === 1 || item.vacationRange === 2) {
          text = '休半天';
          vacationRangeText = '有计划'
          dateType = 1
          classname = 'flirt';
          vacationRangeStatusBgc = '#1890FF';
        } else {
          text = '有计划';
          classname = 'have-plan';
        }
      } else if (item.workDateType === 10) {
        if (item.vacationRange === 0) {
          text = '调休';
          classname = 'flirt';
        } else if (item.vacationRange === 1 || item.vacationRange === 2) {
          text = '休半天';
          dateType = 1;
          classname = 'flirt';
          vacationRangeText = '无计划';
          vacationRangeStatusBgc = '#1890FF';
        } else {
          text = '无计划';
          classname = 'no-plan'
        }
      } else {
        // 需要设置，不然仅看异常的情况下 会有样式问题
        text = '空白';
        classname = 'no-data'
      }
      if(item.join){
        text = '入职';
        classname = 'join';
      }
      if(item.departure){
        text = '离职';
        classname = 'departure';
      }
      let n = 1;
      if (Array.isArray(item?.date)) {
        n = item?.date?.length;
      }
      if (dateType == 1) {
        return <Tooltip color="#fff"
          overlayStyle={{ width: '108px' }}
          title={() => {
            return (
              <div style={{ color: "#666666" }}>
                <div style={{ color: '#000000', fontSize: '10px', marginBottom: 4, marginTop: 4 }}>{this._getStatusDate(item.date)}</div>
                <div style={{ marginBottom: 4 }}>
                  {this._getStatus(item, vacationRangeText, vacationRangeStatusBgc)}
                </div>
              </div>
            );
          }}
        >
          <div className='list-status-box' style={{ width: `${60 * n}px`, paddingLeft: '3px', paddingRight: '3px' }}>
            <div style={{ height: '54px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span className={`${classname}`} style={{ width: `${60 * n}px`, borderRadius: '12px' }}>{text}</span>
            </div>
          </div>
        </Tooltip>
      }
      return <div className='list-status-box' style={{ width: `${60 * n}px`, paddingLeft: '3px', paddingRight: '3px', background: item.workDateType == 8 || item.workDateType == 7 ? '#F7F9FC' : '', }}>
        <div style={{ height: '54px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span className={`${classname}`} style={{ width: `${60 * n}px`, borderRadius: item.workDateType == 8 ? '0px' : '12px' }}>{text}</span>
        </div>
      </div>
    })
  }

  _getStatus = (data, text, bgc) => {
    // 休上午
    let title1 = (data.workDateType == 9 || data.workDateType == 10) ? '休息' : text
    let title2 = data.workDateType == 9 ? '有计划' : data.workDateType == 10 ? '无计划' : '正常';

    // 休下午
    let title3 = data.workDateType == 9 ? '有计划' : data.workDateType == 10 ? '无计划' : '正常'
    let title4 = title1 = (data.workDateType == 9 || data.workDateType == 10) ? '休息' : text;

    if (data.vacationRange == 1) {
      return <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px', color: '#666666' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ background: bgc, display: "inline-block", height: '4px', width: '4px', borderRadius: '2px' }}></span>
            <span style={{ marginLeft: '5px' }}>上午</span>
          </div>
          <span  >{title1}</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px', marginTop: 2 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ background: '#0EC75A', display: "inline-block", height: '4px', width: '4px', borderRadius: '2px' }}></span>
            <span style={{ marginLeft: '5px' }}>下午</span>
          </div>
          <span>{title2}</span>
        </div>
      </div>
    } else {
      return <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px', color: '#666666' }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ background: '#0EC75A', display: "inline-block", height: '4px', width: '4px', borderRadius: '2px' }}></span>
            <span style={{ marginLeft: '5px' }}>上午</span>
          </div>
          <span>{title3}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', fontSize: '10px', marginTop: 2 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ background: bgc, display: "inline-block", height: '4px', width: '4px', borderRadius: '2px' }}></span>
            <span style={{ marginLeft: '5px' }}>下午</span>
          </div>
          <span>{title4}</span>
        </div>
      </div>
    }
  }

  _getStatusDate = (data) => {
    return moment(data).format("M") + '月' + moment(data).format("D") + '日' + '周' + this._getWeeksName(data)
  }

  _getWeeksName = (data) => {
    // data星期日为 0、星期六为 6。
    if (moment(data).day() == 0) {
      return '日'
    } else {
      return util.numToChinaNum(moment(data).day())
    }
  }
}
