import React from "react";
import { Relax } from "plume2";
import { noop } from '@utils'
let dataSource = [
  {
    backgroundImage: "rank_img",
    name: "rank",
    label: "我的排名",
  },
  {
    backgroundImage: "task_img",
    name: "taskNum",
    label: "全年完成任务",
    unit: "（个）",
  },
  {
    backgroundImage: "time_img",
    name: "timeSheetSpendNum",
    label: "全年花费工时",
    unit: "（人时）",
  },
  {
    backgroundImage: "issue_img",
    name: "fixBugNum",
    label: "全年修复缺陷数",
    unit: "（个）",
  },
  {
    backgroundImage: "rate_img",
    name: "onceFixBugRatio",
    label: "全年缺陷修正率",
  },
];

/**
 * 年度数据
 */
@Relax
class AnnualData extends React.Component<any, any> {
  static relaxProps = {
    ownerScoreRankData: "ownerScoreRankData",
    isLeader: 'isLeader',
    ranking: 'ranking',
    isShow: 'isShow',
    mergeData: noop,
  };
  componentWillMount() {
    //获取登录人信息
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //获取登录人权限
    const isLeader = userInfo?.isLeader
    if (isLeader) {
      dataSource[0].label = '他的排名'
    }
  }


  render() {
    const { ownerScoreRankData, isShow, isLeader } = this.props.relaxProps;
    return (
      isShow && <div className="personal_ranking__annulData">
        {dataSource?.map?.((item, index) => {
          return (
            <div
              key={item.name}
              className={`personal_ranking__annulData__item ${item.backgroundImage}`}
            >
              <div className="personal_ranking__annulData__item__title">
                {item.label || "-"}
                {item?.unit && <span>{item.unit || ""}</span>}
              </div>
              <div className="personal_ranking__annulData__item__content">
                {this._getItemName(item)}
                {item.name === "onceFixBugRatio" ? "%" : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  _getItemName = (item) => {
    const { ownerScoreRankData, isLeader, ranking } = this.props.relaxProps;
    if (item.name == 'rank') {
      if (isLeader) {
        return ranking
      }
    }
    return ownerScoreRankData?.get(item.name) || 0
  }
}
export default AnnualData;
