import React from "react";
import { Steps, Image } from "antd";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import { loginout } from "@utils";

// 步骤1 - 正常icon
import step_01 from "./img/step_01.svg";
// 步骤1 - 高亮icon
import step_01_active from "./img/step_01_active.svg";
// 步骤1 - 身份认证
import IdentityAuthentication from "./components/identity_authentication";

// 步骤2 - 正常icon
import step_02 from "./img/step_02.svg";
// 步骤2 - 高亮icon
import step_02_active from "./img/step_02_active.svg";
// 步骤2 - 设置新密码
import SetANewPassword from "./components/set_a_new_password";

// 步骤3 - 正常icon
import step_03 from "./img/step_03.svg";
// 步骤3 - 高亮icon
import step_03_active from "./img/step_03_active.svg";
// 步骤3 - 完成
import Complete from "./components/complete";

import "./index.less";

const steps = [
  {
    title: "身份认证",
    content: "身份认证",
    icon: step_01,
    icon_active: step_01_active,
  },
  {
    title: "设置新密码",
    content: "设置新密码",
    icon: step_02,
    icon_active: step_02_active,
  },
  {
    title: "完成",
    content: "完成",
    icon: step_03,
    icon_active: step_03_active,
  },
];

// 修改密码 / 忘记密码
@StoreProvider(AppStore)
class ChangePassword extends React.Component {
  store: AppStore;
  _form = null;

  //点击下一步
  _handleNextBtn = () => {
    const current = this.store.get("current");
    if (current < 2) {
      this._form
        ?.validateFields?.()
        .then((values) => {
          // 0身份认证、1设置新密码、2完成
          // 校验验证码
          if (current === 0) {
            this.store._validateVerifyCode(values, this._form);
          }
          // 修改密码
          if (current === 1) {
            this.store._updatePassword(values, this._form);
          }
        })
        .catch((err) => {
          console.log("6666666", err);
        });
    }
  };

  // 渲染对应步骤下的内容

  _renderStepComponent = (current) => {
    // 0身份认证、1设置新密码、2完成
    if (current === 0)
      return (
        <IdentityAuthentication
          _ref={(node) => (this._form = node)}
          {...this.props}
          {...this.state}
        />
      );
    if (current === 1)
      return <SetANewPassword _ref={(node) => (this._form = node)} />;
    if (current === 2) return <Complete />;
  };

  render() {
    const current = this.store.get("current");
    return (
      <div className="skyeye_pc_change_password">
        <div className="common_width_margin">
          {/* 分步流程图 */}
          <Steps current={current} className="skyeye_pc_change_password__Steps">
            {steps.map((item, index) => (
              <Steps.Step
                disabled={true}
                key={item.title}
                title={item.title}
                icon={
                  <Image
                    width={44}
                    preview={false}
                    src={index <= current ? item.icon_active : item.icon}
                  />
                }
                className={`skyeye_pc_change_password__Steps--Step${
                  index <= current ? "_active" : ""
                }`}
              />
            ))}
          </Steps>

          {/* 流程对应内容 */}
          <div
            className="skyeye_pc_change_password__form_list"
          >
            {this._renderStepComponent(current)}
          </div>

          {/* 下一步 */}
          {current !== 2 && (
            <div
              className="skyeye_pc_change_password__next"
              onClick={this._handleNextBtn}
            >
              下一步
            </div>
          )}

          {/** 完成 */}
          {current === 2 && (
            <div
              className="skyeye_pc_change_password__next"
              onClick={() => loginout()}
            >
              完成
            </div>
          )}
        </div>
      </div>
    );
  }

}
export default ChangePassword;
