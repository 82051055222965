import { msg, Store } from "plume2";
import MainActor from "./actor/main";
import { SUCCESS_CODE } from "@utils";
import * as webApi from "./webapi";
import { fromJS } from "immutable";
import { message } from "antd";
import { promiseAllSettled } from "@utils/kit";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  /**
   * 改值
   */
  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 初始化
   */
  init = () => {
    //搜索条件初始化
    this.bugRepairsSearchInit();
  };

  /**
   * 缺陷修复 搜索条件初始化(汇总+明细)
   */
  bugRepairsSearchInit = async () => {
     let selectParam = {
       function_prefix: "f_bug_fix",
     };
    await Promise.all([
      webApi.getProjectTypeList(selectParam),
      webApi.getBelongBaselineList(selectParam),
    ]).then(async (firstResults) => {
      const searchParams = JSON.parse(localStorage.getItem("bug_fixes-searchParams") || '{}');
      const firstProjectType = searchParams?.projectType ?? firstResults?.[0]?.context?.[0]?.value;
      const firstbelongBaselinee = searchParams?.belongBaseline ?? firstResults?.[1]?.context?.[0]?.value;
      const startYear = searchParams?.startYear || new Date().getFullYear().toString();

      let conditions = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        ...searchParams,
      };
      // 销毁临时缓存的数据
      localStorage.removeItem("bug_fixes-searchParams");

      let param = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        functionCode: 1,
      };
      if (firstProjectType == 0) {
        param["startYear"] = null;
      }
      if (firstProjectType == 0) {
        const res = await webApi.getIterationList({
          projectId: null,
          startYear,
          projectType: 0,
          belongBaseline: firstbelongBaselinee,
          onlyTested: true,
          functionCode: 1,
        });
        if (res.code === SUCCESS_CODE) {
          let conditionsData = {
            projectType: firstResults[0].context,
            belongBaseline: firstResults[1].context,
            iteration: res.context,
            project: null,
          };
          this.mergeData({
            bugRepairsSearchData: conditionsData,
            bugRepairsSearch: conditions,
          });

          if(searchParams && Object.keys(searchParams).length) {
            // 页面滚动到明细部分
            const scrollDom = document.getElementById('bug-fixes-detail-position');
            scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
          }
          
        } else {
          message.error(res.message);
        }
      } else {
        const [projectList, iterationList] = await promiseAllSettled([
          webApi.getProjectList(param),
          webApi.getIterationList({
            projectId: searchParams?.projectId,
            belongBaseline: firstbelongBaselinee,
            projectType: firstProjectType,
            startYear,
            onlyTested: true,
            functionCode: 1,
          }),
        ]);

        this.mergeData({
          bugRepairsSearch: conditions,
          bugRepairsSearchData: {
            projectType: firstResults?.[0]?.context || [],
            belongBaseline: firstResults?.[1]?.context || [],
            project: projectList?.context || [],
            iteration: iterationList?.context || [],
          },
        });
      }

      if(searchParams && Object.keys(searchParams).length) {
        // 页面滚动到明细部分
        const scrollDom = document.getElementById('bug-fixes-detail-position');
        scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    });
    this.search();
  };

  /**
   *  搜索（汇总） ---- 会影响明细的数据
   */
  search = async () => {
    const bugRepairsSearch = this.state().get("bugRepairsSearch");
    const bugRepairsSearchData = this.state().get("bugRepairsSearchData");
    const detailSearch = this.state().get("detailSearch");
    //数据汇总
    this.getTotalInterfaceData(bugRepairsSearch, bugRepairsSearchData);
    // 重置列表点击的样式名称以及id
    this.mergeData({ rowId: "" });
    this.mergeData({ clickRowClassName: "" });
  };

  /**
   *  搜索(明细) ----- 不影响汇总数据
   */
  searchDetail = async () => {
    this.mergeData({ repairsDetailLoading: true });
    const detailSearch = this.state().get("detailSearch");
    this.getWeeksInterfaceData(detailSearch, true);
    // 重置列表点击的样式名称以及id
    this.mergeData({ rowId: "", clickRowClassName: "" });

    setTimeout(() => {
      this.mergeData({ repairsDetailLoading: false });
    }, 0);
  };

  /**
   *  汇总 ---- 接口调用
   */
  getTotalInterfaceData = async (data, searchData) => {
    this.mergeData({ totalInterfaceLoading: true, repairsDetailLoading: true });
    const { project, iteration, belongBaseline, projectType, startYear, pageSize, pageNum } =
      data?.toJS() || {};
    const isCustomMade = data?.get("projectType") == 1 ? true : false;
    const totalParam = {
      belongBaseLine: belongBaseline,
      iterationId: iteration || null,
      projectId: project || null,
      projectType,
      time: Number(startYear),
      pageNum: 1,
      pageSize: this.state().get("pageSize") || 10,
    };
    const res = await webApi.getBugTotal(totalParam);
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        bugRepairsPageVOPage: res?.context?.bugRepairsPageVOPage,
        pageItem: res?.context?.pageItem,
      });
      // 处理列表返回的第一条数据(设置明细搜索框的数据)
      if (
        res?.context?.bugRepairsPageVOPage.records.length == 0 &&
        res?.context?.pageItem.records.length == 0
      ) {
        // 清空相关数据
        this.mergeData({
          weeksData: {},
          detailSearch: {},
          detailSearchData: {},
          bugRepairsWeeksVOS: {},
          bugRepairsCountVO: {},
          bugRepairsChartsVOS: {},
          repairsDetailPageVOPage: {},
          tabType:0
        });
      } else {
          this.changeDetailSearchData(
            isCustomMade
           ? res?.context?.bugRepairsPageVOPage.records[0]
           : res?.context?.pageItem.records[0],
           data,
          searchData
           );
         const detailSearch = this.state().get("detailSearch");
         this.getWeeksInterfaceData(detailSearch, false);
      }

     
    } else {
      message.error(res.message);
    }

    setTimeout(() => {
      this.mergeData({
        totalInterfaceLoading: false,
        repairsDetailLoading: false,
      });
    }, 0);
  };

  changeDetailSearchData = async (data, search, searchData) => {
    const isCustomMade = search?.get("projectType") == 1 ? true : false;
    const { startYear, belongBaseline, projectType } = search?.toJS();
    const searchProjectId = search?.get("project") || null;
    const searchLabel = searchData?.get("project")?.toJS()[0]?.label;
    const projectName = this.state()
      .get("bugRepairsPageVOPage")
      ?.toJS()
      .records?.filter?.((b) => b.projectId == data?.projectId)[0]?.projectName;
    const label = isCustomMade ? projectName : data?.projectName;
    let projectParam = {
      value: data?.projectId || searchProjectId,
      label: label || searchLabel,
    };
    let newConditions = { ...search.toJS() };
    let newConditionsData = { ...searchData.toJS() };
    newConditions.project = data?.projectId || searchProjectId;
    newConditions.iteration = data?.iterationId || search?.get("iteration");
    let param;
    if (!isCustomMade) {
      param = {
        projectId: data?.projectId || searchProjectId,
        startYear: startYear || null,
        onlyTested: true,
        belongBaseline,
        projectType,
        functionCode: 1,
      };
    } else {
      param = {
        projectId: data?.projectId || searchProjectId,
        onlyTested: true,
        belongBaseline,
        functionCode: 1,
      };
    }
    this.mergeData({ detailSearch: newConditions });
    const res = await webApi.getIterationList(param);
    if (res.code === SUCCESS_CODE) {
      newConditionsData.project = [projectParam];
      newConditionsData.iteration = res.context;
      this.mergeData({ detailSearchData: newConditionsData });
    } else {
      message.error(res.message);
    }
  };

  /**
   *  数据周 ---- 接口调用
   */
  getWeeksInterfaceData = async (datas, isDetailSearch) => {
    const data = this.state().get("detailSearch");
    let weekParam = {
      iterationId: data.get("iteration") || null,
      projectId: data.get("project") || null,
    };

    const res = await webApi.getBugWeeks(weekParam);
    // 搜索时将数据先清空
    this.mergeData({
      bugRepairsWeeksVOS: {},
      bugRepairsChartsVOS: {},
      // weeksData: {},
    });
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        bugRepairsWeeksVOS: res?.context?.bugRepairsWeeksVOS,
        weeksData: res?.context?.bugRepairsWeeksVOS?.[0],
        tabType: 0,
      });
      const bugRepairsSearch = this.state().get("bugRepairsSearch");
      const detailSearch = this.state().get("detailSearch");
      const data = isDetailSearch == true ? detailSearch : bugRepairsSearch;
      setTimeout(() => {
        this.getBaseInterfaceData(data);
      }, 500);
    } else {
      message.error(res.message);
    }
  };

  /**
   *  明细分页+趋势图+明细数量 ---- 接口调用
   */

  getBaseInterfaceData = async (data) => {
    this.mergeData({ repairsDetailLoading: true });
    const { projectType, belongBaseline, project, iteration } = this.state()
      .get("detailSearch")
      ?.toJS();
    const weeksData = this.state().get("weeksData")?.toJS();
    const tabType = this.state().get("tabType");
    let param = {
      beginTime: weeksData?.beginTime || null,
      endTime: weeksData?.endTime || null,
      belongBaseLine: belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      projectType,
      tabType,
      pageNum: 1,
      pageSize: this.state().get("detailPageSize") || 10,
    };
    let query = [
      //明细分页
      webApi.getBugDetail({...param }),
      //趋势图
      webApi.getTrendChart(param),
      //明细数量
      webApi.getBugDetailCount(param),
    ];
    await Promise.all(query).then((results) => {
      this.mergeData({
        repairsDetailPageVOPage: results[0].context?.repairsDetailPageVOPage,
        bugRepairsChartsVOS: results[1]?.context?.bugRepairsChartsVOS || {},
        bugRepairsCountVO: results[2]?.context?.bugRepairsCountVO || {},
      });
    });

    setTimeout(() => {
      this.mergeData({ repairsDetailLoading: false });
    }, 0);
  };

  getDetailSearchByTotal = (data, datas) => {
    let newConditions = { ...data?.toJS() };
    let newConditionsData = { ...datas?.toJS() };
    this.mergeData({ detailSearch: newConditions });
    this.mergeData({ detailSearchData: newConditionsData });
  };

  /**
   * 搜索条件变更
   */
  changeSearch = async (value, type) => {
    const bugRepairsSearch = this.state().get("bugRepairsSearch");
    const bugRepairsSearchData = this.state().get("bugRepairsSearchData");
    let newConditions = { ...bugRepairsSearch.toJS() };
    let newConditionsData = { ...bugRepairsSearchData.toJS() };
    newConditions[type] = value;
    if (type !== "iteration") {
      newConditions.iteration = null;
      if (type !== "project") {
        newConditions.project = null;
      }
    }
    this.mergeData({ bugRepairsSearch: newConditions });
    let { project, projectType, startYear, belongBaseline } = newConditions;

    if (projectType == 0) {
      let param = {
        projectId: project || null,
        startYear: startYear || null,
        projectType: 0,
        belongBaseline,
        onlyTested: true,
        functionCode: 1,
      };
      const res = await webApi.getIterationList(param);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({
          bugRepairsSearchData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    } else {
      let param = {
        projectId: project || null,
        belongBaseline,
        projectType: 1,
        startYear: startYear || null,
        onlyTested: true,
        functionCode: 1,
      };
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 1,
      };
      const res = await webApi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({
          bugRepairsSearchData: newConditionsData,
        });
      }
      const iterationListRes = await webApi.getIterationList(param);
      if (iterationListRes.code === SUCCESS_CODE) {
        newConditionsData.iteration = iterationListRes.context;
        this.mergeData({
          bugRepairsSearchData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    }
  };

  /**
   * 明细的搜索条件变更
   */

  changeDetailSearch = async (value, type) => {
    const detailSearch = this.state().get("detailSearch");
    const detailSearchData = this.state().get("detailSearchData");
    //  let valueArr = value?.split?.("-");
    let newConditions = { ...detailSearch.toJS() };
    let newConditionsData = { ...detailSearchData.toJS() };
    newConditions[type] = value;
    if (type !== "iteration") {
      newConditions.iteration = null;
      if (type !== "project") {
        newConditions.project = null;
      }
    }
    this.mergeData({ detailSearch: newConditions });
    let { project } = newConditions;
    if (type === "project") {
      const res = await webApi.getIterationList({
        projectId: project || null,
        onlyTested: true,
        functionCode: 1,
      });
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({ detailSearchData: newConditionsData });
      }
    } else {
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 1,
      };
      const res = await webApi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({ detailSearchData: newConditionsData });
      }
    }
  };

  /**
   *  汇总的分页查询
   */
  getTotalDataPageName = async (pageNum, pageSize) => {
    this.mergeData({ totalInterfaceLoading: true });
    const { projectType, belongBaseline, project, iteration, startYear } =
      this.state().get("bugRepairsSearch").toJS();
    let params = {
      time: Number(startYear),
      projectType,
      belongBaseLine: belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      pageNum: pageNum || 1,
      pageSize,
    };
    this.mergeData({
      pageNum: pageNum,
      pageSize: pageSize,
    });
    const res = await webApi.getBugTotal(params);
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        bugRepairsPageVOPage: res?.context?.bugRepairsPageVOPage,
        pageItem: res?.context?.pageItem,
      });
    } else {
       message.error(res?.message);
    }
      setTimeout(() => {
        this.mergeData({
          totalInterfaceLoading: false,
        });
      }, 0);
  };

  /**
   * 明细的分页查询 tab 0:开发侧 1:测试侧
   */

  getDetailPageName = async (tabType, pageNum?, pageSize?) => {
    this.mergeData({ repairsDetailLoading: true });
    let { belongBaseline, project, projectType, iteration } = this.state()
      .get("detailSearch")
      ?.toJS();
    let weeksData = this.state().get("weeksData")?.toJS();
    // 明细搜索的参数
    let params = {
      beginTime: weeksData?.beginTime || null,
      endTime: weeksData?.endTime || null,
      projectType,
      belongBaseLine: belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      pageNum: pageNum || this.state().get('detailPageNum'),
      pageSize: pageSize || this.state().get('detailPageSize'),
      tabType,
    };
    this.mergeData({
      detailPageNum: pageNum || this.state().get('detailPageNum'),
      detailPageSize: pageSize || this.state().get('detailPageSize'),
    });
     // 如果搜索条件为空的话 不查询接口
    const detailSearch = this.state().get("detailSearch")?.toJS();
    if (Object.getOwnPropertyNames(detailSearch)?.length == 0) {
      this.mergeData({
        bugRepairsCountVO: {},
        bugRepairsChartsVOS: {},
        repairsDetailPageVOPage: {},
      });
    } else {
      const res = await webApi.getBugDetail(params);
      if (res?.code === SUCCESS_CODE) {
        this.mergeData({
          repairsDetailPageVOPage: res?.context?.repairsDetailPageVOPage,
        });
      } else {
        message.error(res.message);
      }
    }
  
    setTimeout(() => {
      this.mergeData({ repairsDetailLoading: false });
    }, 0);
  };

  detailRightTabsChange = async (tab) => {
    this.mergeData({
      tabType: Number(tab),
    });
    // 如果搜索条件为空的话 不查询接口
    const detailSearch = this.state().get("detailSearch")?.toJS();
    if (Object.getOwnPropertyNames(detailSearch)?.length == 0) {
     this.mergeData({
       bugRepairsCountVO: {},
       bugRepairsChartsVOS: {},
       repairsDetailPageVOPage: {},
     });
    } else {
      this.getDetailPageName(tab);
      this.getTotalAndTrandChart();
    }
  
  };

  detailLeftTabsChange = async (tab) => {
    const bugRepairsWeeksVOS = this.state().get("bugRepairsWeeksVOS")?.toJS();
    let tabType = this.state().get("tabType");
    bugRepairsWeeksVOS?.forEach(async (item, i) => {
      if (tab == i) {
        // 1、实时存储开始和结束时间
        let weeksData = {
          beginTime: item.beginTime,
          endTime: item.endTime,
          week: Number(tab) + 1,
        };
        this.mergeData({ weeksData: weeksData });
        // 2、调用明细接口，传入时间参数
        this.getDetailPageName(tabType);
        // 3、明细头部数据+趋势图数据
        this.getTotalAndTrandChart();
      }
    });
  };

  // 列表点击，渲染缺陷明细图表
  renderBugDetail = async (record) => {
    const bugRepairsSearch = this.state().get("bugRepairsSearch");
    const bugRepairsSearchData = this.state().get("bugRepairsSearchData");
    const styleName = "click-row-bgc";
    // 设置列表样式的styleName与id
    this.mergeData({ rowId: record?.projectId || record?.iterationId });
    this.mergeData({ clickRowClassName: styleName });
    this.getWeeksData(record);
    this.changeDetailSearchData(record, bugRepairsSearch, bugRepairsSearchData);
    setTimeout(() => {
      this.getDetailMainData(record);
    }, 600);
  };

  // 列表获取明细相关数据
  getDetailMainData = async (record) => {
    this.mergeData({ repairsDetailLoading: true });
    const bugRepairsSearch = this.state().get("bugRepairsSearch");
    const bugRepairsSearchData = this.state().get("bugRepairsSearchData");
    const { projectType, belongBaseline } = bugRepairsSearch?.toJS() || {};

    let weeksData = this.state().get("weeksData")?.toJS();
    let tabType = this.state().get("tabType");

    // 数量和明细、趋势图的参数一样
    let detailParam = {
      beginTime: weeksData?.beginTime || null,
      endTime: weeksData?.endTime || null,
      belongBaseLine: belongBaseline,
      projectId: record?.projectId || null,
      iterationId: record?.iterationId || null,
      projectType: projectType,
      tabType: tabType,
    };

    let query = [
      //明细
      webApi.getBugDetail(detailParam),
      //明细数量
      webApi.getBugDetailCount(detailParam),
      //趋势图
      webApi.getTrendChart(detailParam),
    ];
    await Promise.all(query).then((results) => {
      this.mergeData({
        repairsDetailPageVOPage: results[0].context?.repairsDetailPageVOPage,
        bugRepairsCountVO: results[1]?.context?.bugRepairsCountVO || {},
        bugRepairsChartsVOS: results[2]?.context?.bugRepairsChartsVOS || {},
      });
    });
    setTimeout(() => {
      this.mergeData({ repairsDetailLoading: false });
    }, 0);
  };

  getWeeksData = async (record) => {
    let weekParam = {
      iterationId: record?.iterationId || null,
      projectId: record?.projectId || null,
    };
    const res = await webApi.getBugWeeks(weekParam);
    if (res?.code === SUCCESS_CODE) {
      this.mergeData({
        bugRepairsWeeksVOS: res.context?.bugRepairsWeeksVOS,
        weeksData: res?.context?.bugRepairsWeeksVOS?.[0],
      });
    } else {
      message.error(res.message);
    }
  };

  getTotalAndTrandChart = async () => {
    let { belongBaseline, project, projectType, iteration } = this.state()
      .get("detailSearch")
      ?.toJS();
    let weeksData = this.state().get("weeksData")?.toJS();
    let tabType = this.state().get("tabType");
    let params = {
      beginTime: weeksData?.beginTime || null,
      endTime: weeksData?.endTime || null,
      projectType,
      belongBaseLine: belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      tabType,
    };
    let query = [
      //明细数量
      webApi.getBugDetailCount(params),
      //趋势图
      webApi.getTrendChart(params),
    ];
    await Promise.all(query).then((results) => {
      this.mergeData({
        bugRepairsCountVO: results[0]?.context?.bugRepairsCountVO || {},
        bugRepairsChartsVOS: results[1]?.context?.bugRepairsChartsVOS || {},
      });
    });
  };
}
