import React from "react";
import { Relax } from "plume2";
import Project from './project';
import Custom from './custom'

/**
 * 查询组件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    tabType: 'tabType',
  };

  render() {
    const {
      tabType,
    } = this.props.relaxProps;

    return (
      <div >
        {tabType == 1 ? <Project /> : <Custom />}
      </div>
    );
  }

}
