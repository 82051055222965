import React from 'react';
import tip_png from './img/tip.png';
import './index.less';

// 完成
export default class Complete extends React.Component {

    render() {
        return (
            <div className='skyeye_pc_complete_steps03'>
                <img draggable={ false } src={ tip_png } alt='tip.png' />
                <div>密码重置成功</div>
            </div>
        );
    }
}