import React from "react";
import { Button, Form, Input, message, Select, DatePicker } from "antd";
import moment from 'moment';
import { SUCCESS_CODE, ValidConst } from "@utils";
import { userInfoWebRegister } from "./webapi";
// 下拉箭头 - 图片
import bottom_arrow_svg from './img/bottom_arrow.svg';
import "./index.less";

/**
 * 新员工注册 - 表单
 */
export default class RegisterForm extends React.Component<any, any> {
  formRef = React.createRef() as any;

  /**
   * 注册 - 发送请求
   * 即：提交用户注册信息
   */
  submitUserInfo = async (values) => {
    // moment格式的日期 转为 普通格式YYYY-MM-DD
    values['birthday'] = moment.isMoment(values?.['birthday']) ? values?.['birthday'].format('YYYY-MM-DD') : values['birthday'];

    const res = await userInfoWebRegister(values);
    if(res?.code === SUCCESS_CODE) {
      message.success('注册成功！');
      //注册成功后清空表单数据
      (this.formRef.current as any).setFieldsValue({
        username: null,
        pinYin: null,
        phoneNumber: null,
        gender: null,
        birthday: null,
      })
    }else {
      message.error(res?.message || '很遗憾，注册失败！');
    }
  }

  render() {
    return (
      <div className="skyeye_pc_register_form">
        <Form
          className="skyeye_pc_register_form__form"
          onFinish={ this.submitUserInfo }
          ref={this.formRef}
        >
          {/* 标题 */}
          <div className="skyeye_pc_register_form__title">新员工注册</div>

          {/* 姓名 */}
          <Form.Item
            name="username"
            rules={[
              {
                validator(_, value) {
                  value = value?.trim?.();
                  if(!value) {
                    return Promise.reject(new Error('请输入姓名'));
                  }
                  if(!ValidConst?.noNumber?.test?.(value)) {
                    return Promise.reject(new Error('目前支持输入中文 或 英文'));
                  }
                  if(value.length > 10) {
                    return Promise.reject(new Error('目前最多输入64个字符'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input className='skyeye_pc_register_form__form--input' placeholder="请输入姓名"  />
          </Form.Item>

          {/* 姓名全拼 */}
          <Form.Item
            name="pinYin"
            rules={[
              {
                validator(_, value) {
                  const val = value;
                  value = value?.trim?.();
                  if(!value) {
                    return Promise.reject(new Error('请输入姓名全拼'));
                  }
                  if(!(/^[0-9a-zA-Z]+$/)?.test?.(val)) {
                    return Promise.reject(new Error('目前支持输入字母 或 数字'));
                  }
                  if(value.length > 64 || value.length < 1) {
                    return Promise.reject(new Error('长度限制1-64位字符'));
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input className='skyeye_pc_register_form__form--input' placeholder="请输入姓名全拼" />
          </Form.Item>

          {/* 手机号码 */}
          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "请输入手机号码",
              },
              {
                pattern: ValidConst?.phone,
                message: "请输入正确格式的手机号码",
              },
            ]}
          >
            <Input className='skyeye_pc_register_form__form--input' placeholder="请输入手机号码" />
          </Form.Item>

          {/* 性别 */}
          <Form.Item
            name="gender"
            rules={[
              {
                required: true,
                message: "请选择性别",
              },
            ]}
          >
            <Select
              className='skyeye_pc_register_form__form--select'
              placeholder="请选择性别"
              allowClear
              // 自定义后缀图标
              suffixIcon={<img src={ bottom_arrow_svg } alt='icon' />}
            >
              <Select.Option value="0">男</Select.Option>
              <Select.Option value="1">女</Select.Option>
            </Select>
          </Form.Item>
          
          {/* 出生日期 */}
          <Form.Item
            name="birthday"
            rules={[
              {
                required: true,
                message: "请选择出生日期",
              },
            ]}
          >
            <DatePicker className='skyeye_pc_register_form__form--datePicker' placeholder='请选择出生日期' 
              // 出生日期不能大于今天
              disabledDate={(current) => current && current > moment().endOf('day')}
            />
          </Form.Item>

          {/* 注册 - 按钮 */}
          <Form.Item className="skyeye_pc_register_form__form--submit">
            <Button type="primary" htmlType="submit">注册</Button>
          </Form.Item>

          {/* 温馨提示 */}
          <Form.Item className="skyeye_pc_register_form__form--tip">
            <div>忘记密码？</div>
            <div>告知公司HR人员，可以在项目数字化后台重置。</div>
          </Form.Item>
        </Form>
      </div>
    );
  }

}
