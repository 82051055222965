import { Store } from "plume2";
import MainActor from "./actor/actor";
import { SUCCESS_CODE } from "@utils";
import * as webapi from "./webapi";
import { message } from "antd";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 校验验证码
   * @param {*} values
   */
  _validateVerifyCode = async (values, _form) => {
    const current = this.state().get("current");
    const params = {
      phoneNumber: values?.phoneNumber || "",
      verifyCode: values?.code || "",
    };
    const data = await webapi.validateVerifyCode(params);

    if (data.code === SUCCESS_CODE) {
      //记录返回的account和id，下一步
      this.mergeData({
        current: current < 2 ? current + 1 : 0,
        validCode: 1,
        id: data.context.id,
        account: data.context.jobNumber,
      });

      //验证不正确
    } else if (data.code === "S-000008") {
      _form.validateFields(["code"]);
      this.mergeData({ validCode: 2 });
    }
  };

  /**
   * 修改密码
   * @param {*} values
   */
  _updatePassword = async (values, _form) => {
    const current = this.state().get("current");
    const id = this.state().get("id");
    const account = this.state().get("account");
    const data = await webapi.updatePassword({
      account: account,
      id: id,
      password: values.new_password,
    });

    if (data.code === SUCCESS_CODE) {
      //下一步
      this.mergeData({
        current: current < 2 ? current + 1 : 0,
      });

      //验证不正确
    } else if (data.code === "S-000008") {
      _form.validateFields(["code"]);
    } else {
      message.error(data.message);
    }
  };

  /**
   * 获取验证码
   */
  _getVerifyCode = async (phoneNumber) => {
    //倒计时
    this._setCountdown();

    const data = await webapi.sendVerifyCode({
      phoneNumber: phoneNumber,
    });

    if (data.code === SUCCESS_CODE) {
      message.success("操作成功");
    } else {
      message.error(data.message);
    }
  };

  /**
   * 倒计时
   * @returns
   */
  _setCountdown = () => {
    const countdown = this.state().get("countdown");

    //设置计时时间
    let timeoffset = 60;

    this.mergeData({ countdown: timeoffset-- });

    //未结束
    if (!countdown) {
      const time = setInterval(() => {
        //倒计时结束清除
        if (timeoffset === 0) {
          clearInterval(time);
        }
        this.mergeData({
          countdown: timeoffset--,
        });
      }, 1000);
    }
  };

  /**
   * 校验手机号是否存在
   */
  _checkPhoneNumber = async (form) => {
    const phoneNumber = this.state().get("phoneNumber");
    const validPhoneNumber = this.state().get("validPhoneNumber");

    //未校验通过不校验手机号是否存在
    if (validPhoneNumber === 0) return;

    //校验手机号是否存在
    const data = await webapi.checkPhoneNumber({
      phoneNumber,
    });

    if (data.code === SUCCESS_CODE) {
      this.mergeData({
        validPhoneNumber: data.context?.isExists ? 2 : 1,
      });

      //重新校验
      form.validateFields(["phoneNumber"]);
    }
  };
}
