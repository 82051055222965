import React from "react";
import { Relax } from "plume2";
import { Checkbox, Table, Tooltip, } from 'antd';
import { noop } from "@utils";
import { IActor } from "../../type";
import AppStore from "../../store";
import { IterationStatusText } from '@components';
// 进度条 - 组件
import Progress from './../progress';
import { WEEK_LIST } from './data';
import task_svg from './imgs/task.svg';
import './index.less';

interface IComponentProps {
    relaxProps?: Partial<IActor> & AppStore;
    /** 里程碑清单 - 左侧标签 */
    milestoneList?: Array<{
        [key: string]: any;
    }> | any;
}

/**
 * 里程碑清单
 */
@Relax
export default class MilestoneList extends React.Component<IComponentProps, any> {

    static relaxProps = {
        milestoneList: 'milestoneList',
        milestoneListDetailsMap: 'milestoneListDetailsMap',
        milestoneDetailsColumn: 'milestoneDetailsColumn',
        milestoneDetailsDataSource: 'milestoneDetailsDataSource',
        milestoneDetailsCheckboxColumn: 'milestoneDetailsCheckboxColumn',
        isSpinning: 'isSpinning',
        onMilestoneListItemClick: noop,
        onCheckboxChange: noop,
        changeLoadingFn: noop,
    };

    render() {
        let { 
            milestoneList, milestoneListDetailsMap,
            onMilestoneListItemClick,
            milestoneDetailsColumn,
            milestoneDetailsDataSource,
            milestoneDetailsCheckboxColumn,
            isSpinning,
            onCheckboxChange,
            changeLoadingFn,
        } = this.props.relaxProps || {};
        milestoneList = this.props.milestoneList ?? milestoneList;
        const { 
            primaryKey, startDate, endDate, workload, 
            progressRate, status, statusLabel,
        } = milestoneListDetailsMap?.toJS?.() || {};
        milestoneDetailsCheckboxColumn = milestoneDetailsCheckboxColumn?.toJS?.() || [];

        // 是否展示 ”开始时间“
        const isPredictStartTime = milestoneDetailsCheckboxColumn?.includes?.("predictStartTime") || false;
        // 是否展示 ”结束时间“
        const isPredictEndTime = milestoneDetailsCheckboxColumn?.includes?.("predictEndTime") || false;
        // 是否展示 ”责任人“
        const isOwner = milestoneDetailsCheckboxColumn?.includes?.("owner") || false;

        // ”任务列表“ 列宽
        const taskNameWidth = 206;
        // ”开始时间“ 列宽
        const predictStartTimeWidth = 96;
        // ”结束时间“ 列宽
        const predictEndTimeWidth = 96;
        // ”责任人“ 列宽
        const ownerWidth = 70;

        const predictStartTimeWidth2 = isPredictStartTime ? predictStartTimeWidth : 0;
        const predictEndTimeWidth2 = isPredictEndTime ? predictEndTimeWidth : 0;
        const ownerWidth2 = isOwner ? ownerWidth : 0;

        // ”进度条(自然周)“ 列宽
        const progressRateWidth = milestoneDetailsColumn?.size * 40;
        // 表格总宽度
        const scrollX = taskNameWidth + (predictStartTimeWidth2 + predictEndTimeWidth2 + ownerWidth2) + progressRateWidth;

        return (
            <div className="project_list_milestone_list">
                <div className="project_list_milestone_list__title">
                    <div className="project_list_milestone_list__title--text">里程碑清单</div>

                    <Checkbox.Group 
                        className="project_list_milestone_list__title--checkbox"
                        options={[
                            { label: '开始时间', value: 'predictStartTime' },
                            { label: '结束时间', value: 'predictEndTime' },
                            { label: '责任人', value: 'owner' },
                        ]}
                        value={ milestoneDetailsCheckboxColumn }
                        onChange={(checkedValue) => onCheckboxChange?.(checkedValue)} 
                    />
                </div>

                <div className="project_list_milestone_list__content">
                    <ul className="project_list_milestone_list__content--left">
                        {
                            milestoneList?.map?.((item, index) => {
                                if(!item?.get?.('planName')) return '-';

                                const i = index + 1;
                                return (
                                    <li 
                                        key={ item?.get?.('primaryKey') }
                                        className={ primaryKey === item?.get?.('primaryKey') ? 'project_list_milestone_list__content--left__active' : '' }
                                        onClick={() => {
                                            // 开启loading
                                            changeLoadingFn?.(true);
                                            onMilestoneListItemClick?.(item?.toJS?.() || {});
                                        }}
                                    >
                                        <span>{ i < 10 && i >= 1 ? `0${i}` : i }</span>
                                        { item?.get?.('planName') }
                                    </li>
                                );
                            })
                        }
                    </ul>
                    
                    <div className="project_list_milestone_list__content--right">
                        <ul className="project_list_milestone_list__content--right__list">
                            <li>
                                <span>里程碑时间：</span>
                                <div>{ (startDate || endDate) ? `${ startDate } ~ ${ endDate }` : '-' }</div>
                            </li>
                            <li>
                                <span>累计已投入：</span>
                                <div>{ typeof workload === 'number' ? `${ workload }人天` : '-' }</div>
                            </li>
                            <li>
                                <span>里程碑进度：</span>
                                <div>
                                    { 
                                        typeof progressRate === 'number' ? (
                                            <Progress percent={ progressRate } width={ 120 } />
                                        ) : '-'
                                    }
                                </div>
                            </li>
                            <li>
                                <span>里程碑状态：</span>
                                <IterationStatusText 
                                    projectType={ 2 }
                                    status={ statusLabel }
                                />
                            </li>
                        </ul>

                        <div className="project_list_milestone_list__content--right__table">
                            <Table 
                                dataSource={ milestoneDetailsDataSource?.toJS?.() || [] }
                                scroll={{ x: scrollX, y: 615, }}
                                rowKey="taskDetailId"
                                pagination={ false }
                                loading={ isSpinning }
                            >
                                <Table.Column 
                                    title="任务列表" 
                                    dataIndex="taskName" 
                                    key="taskName"
                                    fixed="left"
                                    width={ taskNameWidth }
                                    render={text => {
                                        if(!text) return "-";

                                        return (
                                            <div className="project_list_milestone_list__content--right__table--column">
                                                <img src={ task_svg } alt="任务图标" />
                                                <Tooltip title={ text }>
                                                    <span>{ text }</span>
                                                </Tooltip>
                                            </div>
                                        );
                                    }}
                                />

                                {
                                    isPredictStartTime && (
                                        <Table.Column 
                                            title="开始时间" 
                                            dataIndex="predictStartTime" 
                                            key="predictStartTime"
                                            fixed="left"
                                            width={ predictStartTimeWidth }
                                            align="center"
                                            render={text => text ?? '-'}
                                        />
                                    )
                                }

                                {
                                    isPredictEndTime && (
                                        <Table.Column 
                                            title="结束时间" 
                                            dataIndex="predictEndTime" 
                                            key="predictEndTime"
                                            fixed="left"
                                            width={ predictEndTimeWidth }
                                            align="center"
                                            render={(text) => text || '-'}
                                        />
                                    )
                                }

                                {
                                    isOwner && (
                                        <Table.Column 
                                            title="责任人" 
                                            dataIndex="owner" 
                                            key="owner"
                                            fixed="left"
                                            width={ ownerWidth }
                                            align="center"
                                            render={text => text ?? '-'}
                                        />
                                    )
                                }

                                <Table.Column 
                                    className="project_list_milestone_list__content--right__table--columnGroup"
                                    title={
                                        <>
                                            {
                                                milestoneDetailsColumn?.map?.(item => {
                                                    const weekName = WEEK_LIST[item?.get?.('week')];
                                                    const date = `${ item?.get?.('month') }/${ item?.get?.('day') }`;
                                                    return (
                                                        <ul 
                                                            key={ date }
                                                            className={
                                                                item?.get?.('todayFlag') ? "project_list_milestone_list__content--right__table--columnGroup__active" : ""
                                                            }
                                                        >
                                                            <li>{ weekName ? `周${ weekName }` : '-' }</li>
                                                            <li>{ date }</li>
                                                        </ul>
                                                    );
                                                })
                                            }
                                        </>
                                    }
                                    dataIndex="progressRate" 
                                    key="progressRate"
                                    render={(text, record: any) => {
                                        const n = record?.dataList?.filter?.(item => item?.active)?.length ?? 0;
                                        const i = record?.dataList?.findIndex?.(item => item?.active) ?? -1;

                                        return (
                                            <div className="project_list_milestone_list__content--right__table--list">
                                                <ul>
                                                    {
                                                        record?.dataList?.map?.((item, index) => {
                                                            return (
                                                                <li 
                                                                    key={ index }
                                                                    style={{
                                                                        background: ![6, 7].includes(item?.week) ? "#fff" : "#F0F2F5",
                                                                    }}
                                                                ></li>
                                                            );
                                                        })
                                                    }
                                                </ul>

                                                <div 
                                                    className="project_list_milestone_list__content--right__table--list__progress"
                                                    style={{ left: i > -1 ? i * 40 : 0, }}
                                                >
                                                    <Progress percent={ text } width={ n * 40 } />
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
