import React from "react";
import TabItems from "./components/tab_items";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import { getQueryVariable, secondMenusData } from '@utils'
// 个人排行榜 - 组件
import PersonalRanking from "./components/personal_ranking";
// 项目排行榜 - 组件
import ProjectRanking from "./components/project_ranking";
// 千行代码 - 组件
import ThousandCode from "./components/thousand_code";
// 资源日历
import ResourceCalendar from "./components/resource-calendar";
// 实时人效 - 组件
import RealtimeEffect from "./components/realtime_effect";

import "./index.less";

let comp = []

@StoreProvider(AppStore)
class EffectivenessMeasurement extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
    const { pageType } = getQueryVariable(["pageType"]) || {};
    this.store.mergeData({
      pageType: pageType ?? '0',
    });
  }

  componentWillUnmount() {
    comp = []
  }

  render() {
    //取页面类型： 个人排行榜 项目排行榜
    const pageType = this.store.get("pageType");
    if (secondMenusData('个人排行榜')) {
      comp.push(<PersonalRanking />)
    }
    if (secondMenusData('项目排行榜')) {
      comp.push(<ProjectRanking />)
    }
    if (secondMenusData('千行代码')) {
      comp.push(<ThousandCode thousandCodeIterationGroupList={this.store.state().toJS().thousandCodeIterationGroupList} />)
    }
    if (secondMenusData('资源日历')) {
      comp.push(<ResourceCalendar />)
    }
    if (secondMenusData('实时人效')) {
      comp.push(<RealtimeEffect />)
    }

    return (
      <div className="common_width_margin_fit skyeye_pc_effectiveness_measurement">
        <TabItems />
        {comp[+pageType]}
      </div>
    );
  }
}
export default EffectivenessMeasurement;
