import React from "react";
import { Link } from "react-router-dom";
import { firstMenusData } from '@utils'
// logo图片
import logo from "./img/logo.svg";

/**
 * logo组件
 */
export default class Logo extends React.Component {
  render() {

    return (
      <div className="skyeye_pc_header__content--logo">
        <Link
          to={`${!localStorage.getItem("userInfo") ? "/" : firstMenusData()[0]?.url}`}
          replace
        >
          <img draggable={false} src={logo} alt="logo" />
        </Link>
      </div>
    );
  }
}
