import React from "react";
import { Relax } from "plume2";
import time_img from "./img/time.png";
import rank_img from "./img/rank.png";
import "./index.less";

//编辑数据
const indicators = [
  {
    url: rank_img,
    name: "rank",
    label: "本年排名",
  },
  {
    url: time_img,
    name: "spent",
    label: "年花费工时",
  },
];

// 产出指标 本月排名/月花费工时
@Relax
class Rank extends React.Component<any, any> {
  static relaxProps = {
    spentRankData: "spentRankData",
  };

  render() {
    const { spentRankData } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_home_sider_rank">
        {indicators?.map?.((item, index) => {
          return (
            <div
              key={index}
              className="skyeye_pc_home_sider_rank__item"
              style={{ backgroundImage: `url(${item.url})` }}
            >
              <div className="skyeye_pc_home_sider_rank__item__title">
                {item.label}
                {item.name === "spent" && <span>(人时)</span>}
              </div>
              <div className="skyeye_pc_home_sider_rank__item__content">
                {spentRankData?.get(item.name) ?? '-'}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default Rank;
