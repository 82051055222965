import React from "react";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import SearchGroup from "./components/search-group/index";
import TotalData from './components/total-data/index';
import DetailData from './components/detail-data/index';
import Tips from './components/tip/index'
import TrendChart from './components/trend-chart/index';
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import "./index.less";

/**
 * 缺陷修复 - 组件
 */
@StoreProvider(AppStore)
export default class BugFixes extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
    this.store.init();
  }

  componentWillUnmount(): void {
    // 销毁临时缓存的数据
      localStorage.removeItem("bug_fixes-searchParams");
  }

  render() {
    return (
      <div className="bug_fixes">
        {/* 提示信息 */}
        <Tips />

        {/** 查询件组 */}
        <SearchGroup />

        {/* 数据汇总 */}
        <AuthWrapper functionName="f_bug_fix_list">
          <TotalData />
        </AuthWrapper>

        {/* 缺陷修复明细 */}
        <AuthWrapper functionName="f_bug_fix_detail">
          <DetailData />
        </AuthWrapper>

        {/* 数据趋势图 */}
        <AuthWrapper functionName="f_bug_fix_trend_chart">
          <TrendChart />
        </AuthWrapper>
      </div>

    );
  }
}
