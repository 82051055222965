import React from "react";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import { cache, secondMenusData, getQueryVariable } from '@utils'
import TabItems from "./components/tab_items";
// 项目列表 - 组件
import ProjectList from "./components/project-list";
// 质量考核 - 组件
import QualityInspection from "./components/quality_inspection";
// 缺陷修复 
import BugFixes from "./components/bug_fixes";
// 缺陷分析
import BugAnalysis from './components/bug_analysis'
@StoreProvider(AppStore)
class QualityAssurance extends React.Component {
  store: AppStore;
  componentDidMount() {
    const { pageType } = getQueryVariable(["pageType"]) || {};
    if(pageType){
      this.store.handleChangeTabItem(pageType);
    }
  }

  render() {
    //取页面类型： 个人排行榜 项目排行榜
    const pageType = this.store.get("pageType");

    return (
      <div className="common_width_margin_fit skyeye_pc_quality-assurance">
        <TabItems />
        {
          secondMenusData('项目列表') && pageType == '0' && (
            <ProjectList />
          )
        }
        {
          secondMenusData('质量考核') && pageType == '1'  && (
            <QualityInspection />
          )
        }
        {
          secondMenusData('缺陷修复') && pageType == '2'  && (
            <BugFixes />
          )
        }
        {
          secondMenusData('缺陷分析') && pageType == '3'  && (
            <BugAnalysis />
          )
        }
      </div>
    );
  }
}
export default QualityAssurance;
