import React from "react";
import { Relax } from "plume2";
import { Modal, Form, DatePicker } from "antd";
import { noop } from "@utils";
import moment from "moment";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const rangeFormat = "YYYY-MM-DD";

@Relax
class RangePickerModal extends React.Component<any, any> {
  formRef = React.createRef() as any;

  static relaxProps = {
    rangeData: "rangeData",
    changeRangePickerModalVisible: noop,
    visible: "visible",
    changeRangeData: noop,
  };

  render() {
    const { rangeData, changeRangePickerModalVisible, visible } =
      this.props.relaxProps;
    return (
      <Modal
        title="自定义时间段"
        visible={visible}
        onCancel={() => changeRangePickerModalVisible()}
        onOk={() => this.handleOk()}
      >
        <Form ref={this.formRef}>
          <FormItem
            label="请选择时间区间"
            name="rangePicker"
            rules={[{ required: true, message: "请选择时间区间" }]}
            initialValue={
              rangeData?.length
                ? [moment(rangeData[0]), moment(rangeData[1])]
                : null
            }
          >
            <RangePicker />
          </FormItem>
        </Form>
      </Modal>
    );
  }

  /**
   * 点击确定
   */
  handleOk = () => {
    const { changeRangePickerModalVisible, changeRangeData } =
      this.props.relaxProps;
    //校验表单
    this.formRef.current
      .validateFields()
      .then((value) => {
        //转换值类型
        const startDate = moment(value.rangePicker[0]).format(rangeFormat);
        const endDate = moment(value.rangePicker[1]).format(rangeFormat);
        //修改所选时间范围
        changeRangeData(startDate, endDate);

        //关闭弹窗
        changeRangePickerModalVisible();
      })
      .catch((err) => {
        console.log("err ===>", err);
      });
  };
}
export default RangePickerModal;
