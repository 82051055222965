import React from "react";
import { Relax } from "plume2";
import { Table, Radio, Image } from "antd";
import { noop } from "@utils";
import first from "./../../img/first.png";
import second from "./../../img/second.png";
import third from "./../../img/third.png";

@Relax
class ScoreRanking extends React.Component<any, any> {
  static relaxProps = {
    projectScoreDataSource: "projectScoreDataSource",
    isShowProjectScoreGetMore: "isShowProjectScoreGetMore",
    iterationScoreDataSource: "iterationScoreDataSource",
    projectScoreType: "projectScoreType",
    changeProjectScoreType: noop,
    changeProjectScorePageNum: noop,
    projectScoreLoading: "projectScoreLoading",
  };
  render() {
    const {
      projectScoreDataSource,
      isShowProjectScoreGetMore,
      iterationScoreDataSource,
      projectScoreType,
      changeProjectScoreType,
      changeProjectScorePageNum,
      projectScoreLoading,
    } = this.props.relaxProps;
    return (
      <div className="project_ranking__score_ranking">
        <div className="project_ranking__score_ranking__top_part">
          <div className="project_ranking__score_ranking__top_part__title">
            项目得分排行
          </div>
          <div className="project_ranking__score_ranking__top_part__tabs_btn">
            <Radio.Group
              options={[
                { label: "按项目", value: "0" },
                { label: "按迭代", value: "1" },
              ]}
              onChange={(e) => {
                changeProjectScoreType(e.target.value);
              }}
              defaultValue={projectScoreType}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
        <div className="project_ranking__score_ranking__table">
          <Table
            rowKey="rank"
            columns={this.getColumns()}
            dataSource={
              projectScoreType === "0"
                ? projectScoreDataSource?.toJS()
                : iterationScoreDataSource?.toJS()
            }
            pagination={false}
            loading={projectScoreLoading}
          />
        </div>
        {isShowProjectScoreGetMore && (
          <div
            className="project_ranking__score_ranking__more"
            onClick={() => changeProjectScorePageNum()}
          >
            查看更多
          </div>
        )}
      </div>
    );
  }

  /**
   * 获取列表信息
   */
  getColumns = (): any => {
    const { projectScoreType } = this.props.relaxProps;

    const columns = [
      {
        title: "排名",
        dataIndex: "rank",
        key: "rank",
        align: "center",
        render: (text) => {
          const images = [first, second, third];
          if (+text < 4) {
            return (
              <div>
                <Image src={images[+text - 1]} width={20} height={20} />
              </div>
            );
          }
          return <div>{text || "-"}</div>;
        },
      },
      {
        title: projectScoreType === "0" ? "项目名称" : "迭代名称",
        dataIndex: "name",
        key: "name",
        width: "23%",
        render: (text) => text || "-",
      },
      {
        title: "上线（20分）",
        children: [
          {
            title: "延期天数",
            dataIndex: "delayDayNum",
            key: "delayDayNum",
            align: "center",
            render: (text) => {
              //0: 黑色 大于0: 红色 小于0: 绿色
              let color = "#000000";
              if (text) {
                color = text > 0 ? "red" : "green";
              }
              return (
                <div style={{ color: color }}>{`${+text > 0 ? "+" : ""}${
                  text || "0"
                }天`}</div>
              );
            },
          },
          {
            title: "得分",
            dataIndex: "onlineScore",
            key: "onlineScore",
            align: "center",
            render: (text) => text || "0",
          },
        ],
      },
      {
        title: "需求（25分）",
        children: [
          {
            title: "覆盖率",
            dataIndex: "demandCoverage",
            key: "demandCoverage",
            align: "center",
            render: (text) => `${text || "0"}%`,
          },
          {
            title: "得分",
            dataIndex: "demandScore",
            key: "demandScore",
            align: "center",
            render: (text) => text || "0",
          },
        ],
      },
      {
        title: "缺陷（25分）",
        children: [
          // {
          //   title: "缺陷总数",
          //   dataIndex: "bugTotal",
          //   key: "bugTotal",
          //   align: "center",
          //   render: (text) => text || "0",
          // },
          // {
          //   title: "投入工时",
          //   dataIndex: "spendTotal",
          //   key: "spendTotal",
          //   align: "center",
          //   render: (text) => text || "0",
          // },
          {
            title: "缺陷密度",
            dataIndex: "density",
            key: "density",
            align: "center",
            render: (text) => text || "0",
          },
          {
            title: "得分",
            dataIndex: "bugDensityScore",
            key: "bugDensityScore",
            align: "center",
            render: (text) => text || "0",
          },
        ],
      },
      {
        title: "事故（30分）",
        children: [
          {
            title: "事故数",
            dataIndex: "accidentTotal",
            key: "accidentTotal",
            align: "center",
            render: (text) => text || "0",
          },
          {
            title: "得分",
            dataIndex: "accidentScore",
            key: "accidentScore",
            align: "center",
            render: (text) => text || "0",
          },
        ],
      },
      {
        title: "综合得分",
        dataIndex: "totalScore",
        key: "totalScore",
        align: "center",
        render: (text) => (
          <div style={{ fontWeight: "500" }}>{text || "0"}</div>
        ),
      },
    ];

    return columns;
  };
}
export default ScoreRanking;
