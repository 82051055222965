import React from 'react';
import { StoreProvider } from 'plume2';
import { Affix } from 'antd';
import AppStore from './store';
import { LOGIN_DATA } from '@utils';
// logo - 组件
import Logo from './components/logo';
// 菜单导航 - 组件
import Nav from './components/nav';
// 头像 + 用户信息 - 组件
import UserInfos from './components/user_infos';
import './index.less';

/**
 * 页面头部区域
 */
@StoreProvider(AppStore)
class SkyeyePCHeader extends React.Component {
  store: AppStore;

  componentDidMount() {
    const token = localStorage.getItem(LOGIN_DATA);
    const userInfoVO = localStorage.getItem('userInfo');

    //查询个人信息
    if (token && !userInfoVO) {
      this.store._getUserInfo();
    } else {
      this.store.mergeData({
        userInfoVO: JSON.parse(userInfoVO) || {},
      });
    }
  }

  render() {
    //获取登录信息
    const loginData = JSON.parse(localStorage.getItem(LOGIN_DATA)) || {};
    const token = loginData?.token;
    //是否为首次登录
    const requireChangePwd = loginData?.requireChangePwd;

    return (
      <Affix>
        <div className="skyeye_pc_header">
          <div className="common_width_margin skyeye_pc_header__content">
            {/* logo */}
            <Logo />
            {/* 主菜单 */}
            <Nav isLogin={token} requireChangePwd={requireChangePwd} />
            {/* 头像 + 用户信息 */}
            {token && (
              <UserInfos token={token} requireChangePwd={requireChangePwd} />
            )}
          </div>
        </div>
      </Affix>
    );
  }
}
export default SkyeyePCHeader;
