import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { Calendar, Button, Select } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fromJS } from 'immutable'
const { Option } = Select;


@Relax
export default class Main extends React.Component<any, any> {
  static relaxProps = {
    mergeData: noop,
    getTaskDetailList: noop,
    getCalendarData: noop,
    getDepartmentUser: noop,
    changeData: noop,
    totalStartYear: 'totalStartYear',
    totalStartMonth: 'totalStartMonth',
    detailStartYear: 'detailStartYear',
    detailStartMonth: 'detailStartMonth',
    departmentUserList: 'departmentUserList',
    departmentUserId: 'departmentUserId',
    departmentUserInfo: 'departmentUserInfo',
    calendarDetail: 'calendarDetail',
  };

  render() {

    const { detailStartYear, detailStartMonth } = this.props.relaxProps;
    const detailValue = detailStartYear + '-' + detailStartMonth;

    return (
      <div className="calendar-data-detail">
        <Calendar dateCellRender={this._dateCellRender}
          headerRender={({ value, onChange }) => this._headerRender(value, onChange) as any}
          onSelect={(data) => this._onSelect(data)}
          value={moment(detailValue)}
        />
      </div>
    );
  }

  /**
 *  自定义日期
 */
  _dateCellRender = (value) => {
    const list = this.getDetailListData(value);
    // 处理假期样式
    const restArr = list.filter?.((b) => b?.status === 5);
    restArr?.map((item) => {
      let element = document.querySelectorAll(".ant-picker-cell-in-view");
      element.forEach(function (i) {
        let a = i?.querySelector(".ant-picker-calendar-date")
        if (!a?.hasAttribute('style')) {
          if (moment(i?.getAttribute('title')).isSame(moment(item?.date).format("YYYY-MM-DD"))) {
            a?.setAttribute("style", "background:#F7F9FC !important;")
              return
            }
          }
        });
    })

    // 日期填充处理，antd默认展示六行，按照需求动态展示日期行数据
    this._handleDateFill()

    //设置今天的样式
    let element = document.querySelectorAll(".ant-picker-cell");
    let arr = Array.from(element)
    arr.forEach(function (item) {
      let b = item?.getElementsByClassName('setting-today')
      b?.[0]?.setAttribute("class", 'ant-picker-calendar-date-value ')
      if (item?.getAttribute('title') == moment().format("YYYY-MM-DD")) {
        let a = item?.getElementsByClassName('ant-picker-calendar-date-value')
        a[0]?.setAttribute("class", 'ant-picker-calendar-date-value setting-today')
        return
      }
    });

    return (
      this._isPassCurrentMonth(list) ? this._detailDateDataFilter(list) : this._detailDateData(list)
    );
  }

  _handleDateFill = () => {
    const { detailStartMonth, detailStartYear } = this.props.relaxProps;
    let pickerContent = document.querySelector?.(".ant-picker-content");
    let tr = pickerContent?.getElementsByTagName?.('tr')
    let lastTrElement = tr?.[tr?.length - 1]
    let lastTrElementMonth = moment(lastTrElement?.firstElementChild?.getAttribute?.('title')).format("M")
    let lastTrElementYear = moment(lastTrElement?.firstElementChild?.getAttribute?.('title')).format("YYYY")

    // 先清除样式
    lastTrElement?.removeAttribute?.('style')
    if (lastTrElementYear == detailStartYear) {
      if (lastTrElementMonth > detailStartMonth) {
        lastTrElement?.setAttribute?.('style', 'display:none')
      }
    } else if (lastTrElementYear > detailStartYear) {
      if (lastTrElementMonth < detailStartMonth) {
        lastTrElement?.setAttribute?.('style', 'display:none')
      }
    } else {
      // todo
    }
  }

  /**
  *  是否超过当前时间
  */
  _isPassCurrentMonth = (list?) => {
    let flag = false;
    const { detailStartYear, detailStartMonth } = this.props.relaxProps;
    let todayDate = moment().format('YYYY-MM-DD');
    let listDate = list?.[0]?.year + '-' + list?.[0]?.month + '-' + list?.[0]?.dayOfMonth
    if (Number(detailStartYear) > Number(moment().format('YYYY'))) {
      flag = true
    } else {
      if (Number(detailStartYear) == Number(moment().format('YYYY'))) {
        if (Number(detailStartMonth) > Number(moment().format('M'))) {
          flag = true
        }
        flag = moment(listDate).isAfter(todayDate) || moment(listDate).isSame(todayDate)
      }
    }
    return flag
  }


  /**
  *  过滤掉当前时间后的部分日期相关数据
  */

  _detailDateDataFilter = (list) => {
    return <>
      {list.map((item) => (
        <div>
          <div className="detail-status-box">
            <span style={{ color: item.status == 5 ? "#FF6600" : '' }} >{item.status == 5 ? '假期' : ''}</span>
          </div>
          <div style={{ marginTop: moment(item.date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') && item.status == 5 ? '30px' : '6px' }} >
            {item.status == 2 ? null : item.status !== 5 ?
              this._renderDetailDateList(item, true) : null
            }
          </div>
        </div>
      ))}
    </>
  }

  /**
  *  具体日期相关数据
  */

  _detailDateData = (list) => {
    return <>
      {list.map((item) => (
        <div>
          {
            (item.joinTime > moment(item.date).format('YYYY-MM-DD') || (item.departureTime && item.departureTime < moment(item.date).format('YYYY-MM-DD')))
                && item.status != 5
                ? null
                : <div>
                  <div className="detail-status-box">
                    {item.status !== 5 && <span className="status" style={{ background: `${this._getBackGround(item)}` }}></span>}
                    <span style={{ color: item.join ? '#31CC6F' : item.departure ? '#f53731' : item.status == 2 ? '#f53731' : item.status == 5 ? "#ff6600" : '#333333' }} >
                      {
                        item.join ? '入职' : item.departure ? '离职' : this._getStatus(item?.status)
                      }
                    </span>
                          </div>
                          <div style={{ marginTop: '-20px', marginLeft: '-6px', marginRight: '-6px' }} >
                            {
                              item.join ? <div className='detail-calendar-data-join-box'></div> :
                              item.departure ? <div className='detail-calendar-data-departure-box'></div> :
                              item.status == 2 ?
                                <div className='detail-calendar-data-waste-box'></div> : item.status !== 5 ?
                                    this._renderDetailDateList(item, false) : null
                    }
                  </div>
                </div>
          }
        </div>
      ))}
    </>
  }


  /**
  *  具体日期相关列表数据
  */

  _renderDetailDateList = (item, isFilterData?) => {
    const { planList, actualList, actualSpent, planSpent } = item
    return <>
      <div className="detail-calendar-data" style={{ display: isFilterData && planList === null ? 'none' : '', marginTop: '8px' }}>
        <span>计划{`(${planSpent || 0}人时):`}</span>
        {planList?.[0] ? <span>{planList?.[0]?.taskName}</span> : ''}
        {planList?.[1] ? <span>{planList?.[1]?.taskName}</span> : ''}
        {planList?.length == 0 ? <span>无</span> : ''}
        {planList?.length > 1 ? <span>...</span> : ''}
      </div>
      {(!isFilterData || isFilterData && actualSpent > 0) && <div className="detail-calendar-data" style={{ marginTop: '4px' }} >
        <span>实际 {`(${actualSpent || 0}人时):`}</span>
        {actualList?.[0] ? <span>{actualList?.[0]?.taskName}</span> : ''}
        {actualList?.[1] ? <span>{actualList?.[1]?.taskName}</span> : ''}
        {actualList?.length == 0 ? <span>无</span> : ''}
        {actualList?.length > 1 ? <span>...</span> : ''}
      </div>}</>
  }

  /**
 *  状态背景颜色
 */
  _getBackGround = (data) => {
    if(data?.join){
      return 'linear-gradient(90deg, #31CC6F 0%, #15B354 100%)'
    }
    // if(data?.departure){
    //   return 'linear-gradient(90deg, #f53731 0%, #15B354 100%)'
    // }
    if (data?.status == 0) {
      return 'linear-gradient(90deg, #31CC6F 0%, #15B354 100%)'
    } else if (data?.status == 1) {
      return 'linear-gradient(90deg, #FFA42E 0%, #F58C22 100%)'
    } else if (data?.status == 2) {
      return 'linear-gradient(90deg, #FF6666 0%, #F54E4E 100%)'
    } else if (data?.status == 3) {
      return 'linear-gradient(90deg, #3DA5FF 0%, #3C82FA 100%)'
    } else if (data?.status == 4) {
      return 'linear-gradient(90deg, #9D85FF 0%, #935FFA 100%)'
    } else {
      return ''
    }
  }

  /**
  *  状态
  */

  _getStatus = (data) => {
    if (data == 0) {
      return '正常'
    } else if (data == 1) {
      return '异常'
    } else if (data == 2) {
      return '虚耗'
    } else if (data == 3) {
      return '调休'
    } else if (data == 4) {
      return '加班'
    } else {
      return '假期'
    }
  }

  /**
  * 明细日期数据处理
  */

  getDetailListData = (value) => {
    let listData;
    const calendarDetail = this.props.relaxProps.calendarDetail?.toJS()
    calendarDetail?.map?.((item) => {
      const year = item?.date?.year
      const month = item?.date?.monthValue
      const dayOfMonth = item?.date?.dayOfMonth
      const date = year + '-' + month + '-' + dayOfMonth;
      const day = moment(value).format('YYYY-MM-DD')
      switch (day) {
        case moment(date).format('YYYY-MM-DD'):
          listData = [
            { actualList: item.actualList, planList: item.planList,
              status: item.status, year: item?.date?.year,
              month: item?.date?.monthValue, dayOfMonth: item?.date?.dayOfMonth,
              isHoliday: item?.isHoliday || 0, actualSpent: item?.actualSpent,
              planSpent: item?.planSpent, date: date,
              joinTime: item.joinTime,
              departureTime: item.departureTime,
              join: item.joinTime == moment(date).format('YYYY-MM-DD'),
              departure: item.departureTime == moment(date).format('YYYY-MM-DD')
            },
          ];
          break;
        default:
      }
    })
    return listData || [];
  }

  /**
 *  头部月份切换
 */
  _headerRender = (value, onChange) => {
    let currentYear = value.format('YYYY年')
    let currentMonth = value.format('M月')
    const { mergeData, departmentUserList, departmentUserInfo, getCalendarData } = this.props.relaxProps;
    const next = () => {
      let month = moment(value).add(1, 'months')
      let newMonth = moment(month).format("M")
      let year = Number(moment(value).format("YYYY"))
      if (Number(newMonth) == 1) {
        year = Number(year) + 1
      }
      mergeData({ projectModalVisible: false, personModalVisible: false, calendarDetail: [], detailStartYear: String(year), detailStartMonth: newMonth })
      getCalendarData('detail')
      // 清空相关样式
      let element = document.querySelectorAll('.ant-picker-calendar-date')
      element.forEach((i) => {
        i.removeAttribute('style')
      })
    }

    const prev = () => {
      let month = moment(value).subtract(1, 'months')
      let prevMonth = moment(month).format("M")
      let year = Number(moment(value).format("YYYY"))
      if (Number(prevMonth) == 12) {
        year = Number(year) - 1
      }
      mergeData({ projectModalVisible: false, personModalVisible: false, calendarDetail: [], detailStartYear: String(year), detailStartMonth: prevMonth })
      getCalendarData('detail')
      // 清空相关样式
      let element = document.querySelectorAll('.ant-picker-calendar-date')
      element.forEach((i) => {
        i.removeAttribute('style')
      })
    }

    return (
      <div className="calendar-data-contain-main-title" style={{ marginTop: 14, marginBottom: 14 }}>
        <Button size="small" type="text" icon={<LeftOutlined />}
          onClick={() => prev()}
        />
        <div className="title-text">
          <span >{currentYear} </span>
          <span >{currentMonth}</span>
          <span > - </span>
          <span >{departmentUserInfo?.toJS()?.label || departmentUserInfo?.toJS()?.children}</span>
        </div>
        <Button size="small" type="text" icon={<RightOutlined />}
          onClick={() => next()}
        />
        <div className="calendar-data-contain-main-search" >
          <Select
            showSearch
            placeholder="请选择员工"
            style={{ width: "140px" }}
            value={departmentUserInfo?.get('value')}
            onChange={(value, option) => {
              this._handleChange(fromJS(option))
            }}
            filterOption={(input, option: any) => {
              return typeof option.props.children == 'string'
                ? option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                : true;
            }}
            dropdownClassName="user-select-dropdown"
          >
            {departmentUserList?.toJS()?.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
        </div>
      </div>
    )
  }

  /**
  * 下拉选择
  */
  _handleChange = (value) => {
    const { getCalendarData, mergeData } = this.props.relaxProps;
    mergeData({ calendarDetail: [], departmentUserInfo: value })
    getCalendarData('detail')
  }

  /**
   * 选择具体日期
   */
  _onSelect = (data) => {
    const { mergeData, getTaskDetailList } = this.props.relaxProps
    this._onSelectDateStyleChange(data)
    mergeData({
      personModalVisible: true,
      personModalYear: moment(data).format("YYYY"), // 开始年份
      personModalMonth: moment(data).format("M"), // 开始月份
      personModalDay: moment(data).format("D"), // 开始月份
    })
    getTaskDetailList()
  }

  /**
   * 选中具体日期样式更改
   */
  _onSelectDateStyleChange = (data) => {
    let element = document.querySelectorAll(".calendar-data-detail .ant-picker-cell");
    let elementArr = Array.from(element)
    elementArr?.forEach((item) => {
      let a = item?.querySelectorAll('.ant-picker-calendar-date')?.[0]
      a?.removeAttribute('style')
      if (item?.getAttribute('title') == moment(data).format("YYYY-MM-DD")) {
        a?.setAttribute('style', "border-color: #FF6600;border-right:1px solid #FF6600;border-bottom:1px solid #FF6600")
      }
    })
  }
}
