import React from "react";
import { StoreProvider } from "plume2";
import { AuthWrapper, } from "@utils";
import { IActor } from "./type";
import AppStore from "./store";
// 查询条件 - 组件
import SearchGroup from "./components/search-group";
// 项目列表、迭代列表 - 组件
import IterationList from "./components/iteration-list";
// 迭代明细 - 组件
import IterationDetails from "./components/iteration-details";
// 里程碑清单 - 组件
import MilestoneList from './components/milestone-list';
// 定制项目 - 迭代标签菜单 - 组件
import IterationTabs from './components/iteration-tabs';
import "./index.less";

interface IComponentProps {}

/**
 * 项目列表
 */
@StoreProvider(AppStore)
export default class ProjectList extends React.Component<IComponentProps, any> {
  store: AppStore;

  componentDidMount() {
    this.store.init();
  }

  render() {
    const { fetchProjectListPageParams, } = this.store.state()?.toJS?.() as IActor;
    const { projectType } = fetchProjectListPageParams || {};

    return (
      <div className="quality_assurance_project_list">
        {/* 查询条件 - 组件 */}
        <SearchGroup />

        <AuthWrapper functionName="f_project_list">
          {/* 项目列表、迭代列表 - 组件 */}
          <IterationList />

          <div 
            id="quality_assurance_project_list__content"
            className="quality_assurance_project_list__content"
          >
            {/* 迭代明细 - 组件 */}
            <IterationDetails {...this.props} />

            {/* 渲染 - 里程碑 -组件 */}
            { this.renderMilestone(projectType) }
          </div>
        </AuthWrapper>
      </div>
    );
  }

  /**
   * 渲染 - 里程碑 -组件
   * @param projectType 
   */
  renderMilestone = (projectType) => {
    if(![0, 1].includes(projectType)) return null;

    if(projectType === 0) {
      // 里程碑清单 - 组件
      return <MilestoneList />;
    }

    // 定制项目 - 迭代标签菜单
    return <IterationTabs />;
  }

}
