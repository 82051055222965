import React from "react";
import { AuthWrapper, OneAuthWrapper, noop, cache } from '@utils'
import { Relax } from "plume2";
import AnnualData from "./annual_data";
import LineChartItem from "./lineChart";
import PersonalScoreData from "./personal_score_data";
import RankList from "./rank_list";
import SearchGroup from "./search";
import SearchTime from "./search-time";
import EffectList from "./effect-list";

/**
 * 个人排行榜 - 组件
 */
@Relax
export default class PersonalRanking extends React.Component<any, any> {
  static relaxProps = {
    personalRankingInit: noop,
    mergeData: noop,
    isLeader: 'isLeader'
  };

  componentDidMount() {
    const { personalRankingInit, mergeData } = this.props.relaxProps
    const leader = JSON.parse(localStorage.getItem?.("userInfo"))?.isLeader;
    const LOGIN_FUNCTIONS = JSON.parse(localStorage.getItem?.(cache.LOGIN_FUNCTIONS));
    const flag = (leader || LOGIN_FUNCTIONS?.includes?.('f_dept_filter_leader')) ? true : false
    mergeData({
      isLeader: flag
    })
    personalRankingInit()
  }

  render() {
    const { isLeader } = this.props.relaxProps
    return (
      <div className="personal_ranking">
        {/* 搜索组合 */}
        <SearchGroup />

        {/* 研发效能排行榜 */}
        <EffectList />
        <OneAuthWrapper functionName="f_year_data,f_repair_quality_line">
          {/* 时间选择 */}
          <SearchTime />
        </OneAuthWrapper>

        {/** 年度数据 */}
        <AuthWrapper functionName="f_year_data">
          <AnnualData />
        </AuthWrapper>

        {/** 修复质量折线图 */}
        <AuthWrapper functionName="f_repair_quality_line">
          <LineChartItem />
        </AuthWrapper>

        {/** 个人得分数据 */}
        <AuthWrapper functionName="f_personal_score">
          <PersonalScoreData />
        </AuthWrapper>

        {/** 质量最佳Top5，缺陷修复Top5，编码得分排行，缺陷得分排行 */}
        <OneAuthWrapper functionName="f_quality_optimal_top5,f_fix_bug_top5,f_code_score_rank,">
          <RankList />
        </OneAuthWrapper>
      </div>
    );
  }
}
