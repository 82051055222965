import React from "react";
import { Form, Input } from "antd";
import info_svg from "../../img/info.svg";
import "./index.less";
import { testPassword } from "@utils/validate";
import { Relax } from "plume2";

// 设置新密码
@Relax
class SetANewPassword extends React.Component<any, any> {
  static relaxProps = {
    account: "account",
  };

  render() {
    const { _ref } = this.props;
    const { account } = this.props.relaxProps;
    return (
      <Form ref={_ref} className="skyeye_pc_set_a_new_password_steps02">
        <Form.Item
          label="新密码"
          className="skyeye_pc_set_a_new_password_steps02__new_password"
          required
          extra={
            <div className="skyeye_pc_set_a_new_password_steps02__new_password--tip">
              <img src={info_svg} alt="info.svg" />
              <span>八位以上大小写字母数字符号4选3,且不能包含工号信息</span>
            </div>
          }
        >
          <Form.Item
            name="new_password"
            noStyle
            rules={[
              {
                validator: (_rule, value, callback) => {
                  //获取工号信息
                  const jobNumber = account || "";
                  //转换为大写字符
                  const upperCaseJobNumber = jobNumber?.toUpperCase(); //工号
                  const upperCaseValue = value?.toUpperCase(); //输入的密码
                  //输入的密码包含工号信息提示
                  if (
                    jobNumber &&
                    upperCaseValue.includes(upperCaseJobNumber)
                  ) {
                    callback("不能包含工号信息");
                  } else if (value === "Qianmi.com") {
                    callback("不能设置为初始化密码");
                  }
                  testPassword(value, callback);
                },
              },
            ]}
          >
            <Input type="password" placeholder="请输入新密码" />
          </Form.Item>
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="确认密码"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: "密码不符合格式要求",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码输入不一致"));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="请输入确认密码" />
        </Form.Item>
      </Form>
    );
  }
}
export default SetANewPassword;
