/**
 * 周几
 */
export const WEEK_LIST = {
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "日",
};
