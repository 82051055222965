// 大背景图
import big_png from './img/big.png';
import './index.less';

/**
 * 欢迎页 - 壳子组件
 */
const SkyeyePcWelcomePage = (props) => {

    // 重新计算style样式
    const handlePageContentStyle = () => {
        let obj = {};
        if(['/register'].includes(props?.pathname)) {
            obj = {
                height: 'auto',
                minHeight: '500px',
                padding: '40px 38px',
            }
        }else {
            obj = {
                height: '480px',
                minHeight: 'auto',
                padding: '50px 38px 60px',
            }
        }

        return obj;
    }

    return (
        <div className='skyeye_pc_welcome_page'>
            <img src={big_png} alt='大图' width="572px" height="500px" />
            <div className='skyeye_pc_welcome_page__content'
                style={ handlePageContentStyle() }
            >
                { props.children }
            </div>
        </div>
    );
}

export default SkyeyePcWelcomePage;