import React from "react";
import { Tabs } from 'antd';
import { Relax } from 'plume2';
import { noop } from "@utils";
import { checkAuth } from "@utils";

const { TabPane } = Tabs;

@Relax
export default class Tab extends React.Component<any, any> {
  props: {
    relaxProps?: {
      mergeData: Function;
      tabChange: Function;
      tabType: number;
    };
  };
  static relaxProps = {
    mergeData: noop,
    tabChange: noop,
    tabType: 'tabType'
  };

  render() {
    const { tabType } = this.props.relaxProps
    const tab = tabType.toString()
    return (
      <div className="bug_analysis_tips">
        {
          checkAuth('f_bug_analyze_customerize') ?
              <Tabs activeKey={tab}
                    onChange={(e) => this._setActiveKey(e)}>
                <TabPane tab="项目/迭代" key="1">
                </TabPane>
                <TabPane tab="自定义" key="2">
                </TabPane>
              </Tabs>
              :
              <Tabs activeKey={tab}
                    onChange={(e) => this._setActiveKey(e)}>
                <TabPane tab="项目/迭代" key="1">
                </TabPane>
              </Tabs>
        }
      </div>
    );
  }

  _setActiveKey = (e) => {
    const { mergeData, tabChange } = this.props.relaxProps
    // 切换tab，清空相关数据
    mergeData({
      tabType: Number(e),
      severityDistribution: [],
      priorityDistribution: [],
      originPhaseDistribution: [],
      historyDistribution: [],
      forUser: [],
      forModule: [],
      forReason: [],
      forYype: [],
      forUserPage: {},
      forFixUserPage: {},
      forModulePage: {},
      forReasonPage: {},
      forYypePage: {},
      rowId: ""
    })
    tabChange(Number(e))
  }
}
