import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      // account账号登录、code_QR二维码登录
      loginType: "account",
      //加载中
      loading: false,
      // ding_talk钉钉扫码登录、wechat企业微信扫码登录
      code_QR_Type: "wechat",
      // 重定向页面
      redirect_uri: window.location.origin + window.location.pathname,
      // 企业微信appid，在企业微信管理端查看,千米corp_id=wxc09a2bf3e18e4519
      corp_id: "ww73d2cadefe008540",
      // 授权方的网页应用ID，在具体的网页应用中查看，千米agentid=1000057
      agentid: "1000017",
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
