
interface IProps {
    /**
     * 填充颜色
     */
    fillColor?: string;
}

/**
 * 向下箭头
 * @returns 
 */
export default function BottomArrowSvg(props: IProps) {
    return (
        <svg width="9px" height="6px" viewBox="0 0 9 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>向下箭头</title>
            <defs>
                <linearGradient x1="-1.11022302e-14%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
                    <stop stopColor="#6BE653" offset="0%"></stop>
                    <stop stopColor="#00C25A" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="9月迭代" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="质量保证-项目列表-基线产品" transform="translate(-1367.000000, -755.000000)" 
                    // 默认是渐变绿
                    fill={ props?.fillColor || "url(#linearGradient-1)" }
                >
                    <g id="定制项目-迭代明细" transform="translate(360.000000, 662.000000)">
                        <g id="编组-3" transform="translate(60.000000, 56.000000)">
                            <g id="-copy-2" transform="translate(947.000000, 37.000000)">
                                <path d="M0.112430914,0.731707317 L4.16791335,5.84320557 C4.25512846,5.94773571 4.36246853,6 4.48993677,6 C4.61740501,6 4.72474508,5.94773571 4.81196019,5.84320557 L8.88756909,0.731707317 C9.00832847,0.592333798 9.03180911,0.435540941 8.95801171,0.261324042 C8.88421431,0.0871071429 8.7533936,0 8.56554567,0 L0.434454333,0 C0.246606399,0 0.115785693,0.0871071429 0.0419882909,0.261324042 C-0.0318091117,0.435540941 -0.00832847215,0.592333798 0.112430914,0.731707317 Z" id="-copy"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
