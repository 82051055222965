import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// 全局公共组件
import { SkyeyePCHeader, SkyeyePCFooter, SkyeyePcBackTop } from './components';
// 路由
import { MAIN_ROUTERS } from './router';
import './App.less';
import { LOGIN_DATA, firstMenusData, history, cache } from './utils';

// 设置大背景颜色
const APP_CONTENT_BG: any = {
  '/change-password': {
    paddingTop: '40px',
  },
};
export default class App extends React.Component<any, any> {
  componentDidMount() {
    // 监听路由变化
    this.props.history.listen((l: any) => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    const { pathname } = this.props.location || {};
    if (window.location.hash !== '#/home') {
      localStorage.setItem(cache.LOGIN_URL, window.location.hash);
    }

    return (
      <div className="skyeye_pc_app">
        <SkyeyePCHeader {...this.props} />
        <div
          className="skyeye_pc_app__content"
          style={APP_CONTENT_BG[pathname] || {}}
        >
          {MAIN_ROUTERS.map((item, index) => {
            return (
              <Route
                key={Math.random()}
                exact={item.exact}
                path={item.path}
                render={(props) => {
                  const loginData = JSON.parse(
                    localStorage.getItem(LOGIN_DATA) || JSON.stringify({}),
                  );
                  // 未登录跳转登录页
                  if (
                    Object.getOwnPropertyNames(loginData).length == 0 &&
                    item.path !== '/change-password'
                  ) {
                    history.replace('/');
                  }

                  if (!item.component)
                    return <Redirect to={firstMenusData()[0]?.url} />;
                  //不需要登录
                  if (item?.noLogin) {
                    return <item.component {...props} />;
                    //已登录
                  } else if (loginData) {
                    //取用户信息
                    const userInfo = JSON.parse(
                      localStorage.getItem('userInfo'),
                    );

                    //首次登录跳转修改密码
                    if (loginData?.requireChangePwd) {
                      return <Redirect to="/home/change-password" />;
                    }
                    return <item.component {...props} />;
                  }
                  return <Redirect to="/" />;
                }}
              />
            );
          })}
        </div>
        <SkyeyePCFooter />
        <SkyeyePcBackTop />
      </div>
    );
  }
}
