import React from "react";
import { Alert } from 'antd';

export default class Tips extends React.Component<any, any> {
  render() {
    return (
      <div className="tips">
        <Alert
          message=""
          description={
            <>
              <div>
                <ul>
                  <li >
                    1、人效数据因Tapd任务花费同步延迟而存在延迟生效的情况，可等待10-30分钟后再查看
                  </li>
                  <li>
                    2、当迭代未上线/实施中时，实际人效=当前预计消耗/实际消耗；当迭代为“已完成”状态时，实际人效=立项预算/实际消耗
                  </li>
                  <li>3、立项预算=迭代立项预算；计划投入=Tapd任务预估花费合计/项目人天工时；计划人效=立项预算/计划投入；当前预计消耗=截止到目前的Tapd预估花费；实际消耗=截止到目前的Tapd实际花费（存在延迟）；实际人效=当前预计消耗/实际消耗</li>
                </ul>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
