import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import moment from "moment";
import { fromJS } from 'immutable';
import { Select, DatePicker, Button, TreeSelect } from 'antd';
import { AuthWrapper } from '@utils';
const { Option } = Select;
const { TreeNode } = TreeSelect;


/**
 * 查询组件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    changeSearchData: noop,
    personalRankingSearch: noop,
    mergeData: noop,
    belongDepartmentList: 'belongDepartmentList',
    belongDepartmentInfo: 'belongDepartmentInfo',
    departmentId: 'departmentId',
    departmentUserInfo: 'departmentUserInfo',
    departmentUserList: 'departmentUserList',
    userId: 'userId',
    leaderSelectStartYear: 'leaderSelectStartYear',
    isLeader: 'isLeader'
  };

  componentWillUnmount() {
    // 清空相关数据
    const { mergeData } = this.props.relaxProps
    mergeData({ departmentUserList: [], departmentId: null, belongDepartmentList: [], belongDepartmentInfo: [], userId: null })
  }

  render() {
    const {
      belongDepartmentList,
      departmentId,
      departmentUserList,
      userId,
      personalRankingSearch,
      leaderSelectStartYear,
      isLeader
    } = this.props.relaxProps;

    return (
      isLeader ? <div className="input_content">
        <DatePicker
          style={{ width: 208, marginRight: 10, borderRadius: 4 }}
          picker="year"
          value={leaderSelectStartYear ? moment(leaderSelectStartYear) : null}
          onChange={(e, dateString) => this._handleChange('leaderSelectStartYear', dateString)}
        />

        <TreeSelect
          showSearch
          style={{ width: 208, marginRight: 10 }}
          placeholder="请选择所属部门"
          allowClear
          onChange={(value, label) => {
            this._handleChange('departmentId', value)
          }}
          defaultValue={departmentId}
          value={departmentId}
          filterTreeNode={(input, treeNode) =>
            treeNode.props.title
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          treeDefaultExpandedKeys={[departmentId?.toString()]}
          dropdownClassName="department-select-dropdown"
        >
          {this._loop(belongDepartmentList?.toJS())}
        </TreeSelect>

        <Select
          showSearch
          placeholder="请选择员工"
          style={{ width: 208, marginRight: 10 }}
          defaultValue={null}
          onChange={(value, option) => {
            this._handleChange('userId', value)
            this._handleChange('departmentUserInfo', fromJS(option))
          }}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          value={userId}
          dropdownClassName="user-select-dropdown"
        >
          <Option value={null}>全部</Option>
          {departmentUserList?.toJS()?.map?.(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>

        <Button
          className="search_group_button"
          type="primary"
          onClick={() => personalRankingSearch()}
        >搜索</Button>
      </div> : null
    );
  }

  // 处理所属部门的树形结构数据
  _loop = (data) =>
    data?.map((item) => {
      if (item?.children?.length > 0) {
        return (
          <TreeNode
            key={item.id}
            value={item.id}
            title={item.name}
          >
            {this._loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          value={item.id}
          title={item.name}
        />
      );
    });

  _handleChange = (type, value) => {
    let { changeSearchData } = this.props.relaxProps;
    changeSearchData(type, value);
  }
}