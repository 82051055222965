import { Action, Actor } from "plume2";
import { merge, fromJS } from "immutable";
import moment from "moment";

export default class MainActor extends Actor {
  defaultState() {
    return {
      // 搜索
      selectYear: moment().format("YYYY"), //开始年份
      selectMonth: moment().format("M"), // 开始月份
      belongDepartmentList: [], //所属部门
      departmentId: null, // 所选部门
      departmentName: "", // 所选部门名称
      belongDepartmentInfo: {},
      isLeader: JSON.parse(localStorage.getItem?.("userInfo"))?.isLeader, // 主管角色

      // 部门员工投入数据汇总
      scoreType: 0, //列表按钮切换 0:全部 1:仅看异常
      effortSummaryCard: {}, // 顶部总数据
      effortSummaryPage: {}, // 列表
      effortPageNum: 1,
      effortPageSize: 10,
      effortSummaryPageLoading: true, //加载中
      positionDepartmentArr: ["department"], // 岗位和所属部门是否展示数组 默认只展示所属部门

      //资源日历
      //投入汇总
      calendarTotal: [], // 投入汇总
      totalStartYear: moment().format("YYYY"), // 开始年份
      totalStartMonth: moment().format("M"), // 开始月份

      //投入明细
      calendarDetail: [], // 投入明细
      detailStartYear: moment().format("YYYY"), // 开始年份
      detailStartMonth: moment().format("M"), // 开始月份
      userId: null, // 明细的所选员工
      selectUserName: null, // 明细右侧下拉所选员工
      detailPageNum: 1,
      detailPageSize: 10,

      // 项目投入弹窗
      projectModalVisible: false, // 项目投入明细弹窗
      projectModldepartmentName: "", // 所选部门名称
      projectModlDepartmentId: null, // 所选部门
      departmentDetailList: {}, // 部门弹窗列表
      projectLoading: true, // 加载中
      projectModalYear: moment().format("YYYY"), // 年份
      projectModalMonth: moment().format("M"), // 月份
      projectModalDay: moment().format("D"), // 日期

      // 人员任务弹窗
      personModalVisible: false, // 人员任务明细弹窗
      personModalYear: moment().format("YYYY"), // 年份
      personModalMonth: moment().format("M"), // 月份
      personModalDay: moment().format("D"), // 日期
      userName: "", //  输入框的姓名
      status: null, // 投入状态
      taskName: "", // 任务名称
      taskDetailList: {}, // 任务明细弹窗列表
      departmentUserList: [], // 部门员工列表
      departmentUserId: null, //
      departmentUserInfo: {},
      personLoading: true, // 加载中

      tabType: 1, //tab切换 1:投入汇总 2:投入明细
      listUserId: null, // 汇总列表员工id（选中效果使用）
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }

  @Action("change:data")
  change(state, { field, value }) {
    return state.set(field, value);
  }
}
