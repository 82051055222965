import React from "react";
import { copyright } from "@utils";
import "./index.less";

// 页面底部区域
export default class SkyeyePCFooter extends React.Component {
  render() {
    const {
      licence,
      software_product_number,
      certificate,
      software_enterprise_number,
      high_and_new_tech_enterprises_number,
      start_year,
      end_year,
      company,
      put_on_record_number,
    } = copyright;
    return (
      <div className="skyeye_pc_footer">
        <div className="common_width_margin skyeye_pc_footer__copyright">
          <p>{`增值电信业务经营许可证：${licence}`}</p>
          <p>{`软件产品编号：${software_product_number} ISO27001证书：${certificate} 软件企业编号：${software_enterprise_number} 高新技术企业编号：${high_and_new_tech_enterprises_number}`}</p>
          <div>
            <span>{`©Copyright ${start_year}年-${end_year}年 ${company} 版权所有 | `}</span>
            <a
              rel="noreferrer"
              href="https://beian.miit.gov.cn/"
              target="_blank"
            >{` ${put_on_record_number} `}</a>
          </div>
        </div>
      </div>
    );
  }
}
