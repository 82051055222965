import './index.less';

interface IProps {
    /**
     * 百分比
     */
    percent?: number;
    /**
     * 进度条宽度
     */
    width?: number;
}

/**
 * 进度条
 * @returns 
 */
export default function Progress(props: IProps) {
    const percent = props?.percent ?? 0;
    const percentValue = `${ percent }%`;

    return (
        <div className="skyeye_pc_progress"
            style={{ width: `${ props?.width || 160 }px`, }}
        >
            <div className='skyeye_pc_progress__active'
                style={{ width: percentValue, }}
            >
                <span className='skyeye_pc_progress__active--text'>{ percentValue }</span>
            </div>
        </div>
    );
};
