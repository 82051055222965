import React from 'react';
import Select from './select';
import { Tabs } from 'antd';
import { Relax } from "plume2";
import List from './list';
import Title from './title';
import { noop, util } from "@utils";
import moment from 'moment';


const TabPane = Tabs.TabPane;
@Relax
export default class DetailData extends React.Component<any, any> {
  static relaxProps = {
    bugRepairsWeeksVOS: "bugRepairsWeeksVOS",
    detailRightTabsChange: noop,
    detailLeftTabsChange: noop,
    tabType: 'tabType',
    weeksData: 'weeksData'
  };
  render() {
    const {
      bugRepairsWeeksVOS,
      detailRightTabsChange,
      detailLeftTabsChange,
      tabType,
      weeksData
    } = this.props.relaxProps;
    const beginTime = weeksData?.toJS()?.beginTime == null ? null : moment(weeksData?.toJS()?.beginTime).format('YYYY/M/D')
    const endTime = weeksData?.toJS()?.endTime == null ? null : moment(weeksData?.toJS()?.endTime).format('YYYY/M/D')
    const timeFrame = beginTime + ' ～ ' + endTime

    return (
      <div className="bug_fixes_total_data">
        <div className="bug_fixes_total_data_title">
          缺陷修复明细（周）
        </div>
        {/* 筛选项组合 */}
        <Select />
        {/* 主要内容 */}
        <div className="detail-list-contain">
          {/* 左侧 */}
          <div className="detail-list-sider">
            <Tabs tabPosition="left" onTabClick={(e) => { detailLeftTabsChange(e) }}>
              {this.weeksData(bugRepairsWeeksVOS?.toJS())?.map((value, index) => {
                return <TabPane tab={value} key={index}></TabPane>
              })}
            </Tabs>
          </div>
          {/* 右侧 */}
          <div className="detail-list-main">
            <Tabs onTabClick={(e) => { detailRightTabsChange(Number(e)) }}
              activeKey={tabType.toString()}
            >
              <TabPane tab="开发侧" key={0} >
                <div className="statistical-range">
                  <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>统计范围:&nbsp;&nbsp;</span>
                  <span>{timeFrame}</span>
                </div>
                <Title tabType={0} />
                <List tabType={0} />
              </TabPane>
              <TabPane tab="测试侧" key={1}>
                <div className="statistical-range">
                  <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>统计范围:&nbsp;&nbsp;</span>
                  <span>{timeFrame}</span>
                </div>
                <Title tabType={1} />
                <List tabType={1} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

  weeksData = (data) => {
    var weekArr = [];
    if (!data) weekArr[0] = '全阶段'
    data?.forEach?.((item) => {
      weekArr?.push("第" + util.numToChinaNum(item?.week) + "周")
    })
    if (weekArr[0] === '第零周') {
      weekArr[0] = '全阶段'
    }
    return weekArr
  }
}
