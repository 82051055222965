import React from "react";
import { Relax } from "plume2";
@Relax
class TitleData extends React.Component<any, any> {
  static relaxProps = {
    effortSummaryCard: 'effortSummaryCard',
  };
  render() {
    return (
      <div className="title-data-contain">
        <div className="alert">部门员工投入数据汇总</div>
        <div className="data">
          {this._getTitle()?.map((item, index) => {
            return <div key={item.name} className={`${item.backgroundImage} item-content`}>
              <div className="item-words">
                <span>{item.label}</span>
                <span>{item.rate !== null ? `(${item.rate + "%"})` : ''}</span>
              </div>
              <div className="item-data">
                <span>{item.dayData ? item.dayData : '0'}</span>
                <span>{item.unit}</span>
              </div>
            </div>
          })}
        </div>

      </div>
    );
  }
  _getTitle = () => {
    const effortSummaryCard = this.props.relaxProps?.effortSummaryCard?.toJS()
    const { normalRatio, normalDays, abnormalDays, abnormalRatio, overtimeDays, restDays, restRatio, wasteDays, wasteRatio } = effortSummaryCard || {}
    let title = [
      {
        backgroundImage: "usual-img",
        name: "usual",
        label: "正常",
        unit: "人天",
        rate: normalRatio || 0,
        dayData: normalDays
      },
      {
        backgroundImage: "unusual-img",
        name: "unusual",
        label: "异常",
        unit: "人天",
        rate: abnormalRatio || 0,
        dayData: abnormalDays
      },
      {
        backgroundImage: "waste-img",
        name: "waste",
        label: "虚耗",
        unit: "人天",
        rate: wasteRatio || 0,
        dayData: wasteDays
      },
      {
        backgroundImage: "flirt-img",
        name: "flirt",
        label: "调休",
        unit: "人天",
        rate: restRatio || 0,
        dayData: restDays
      },
      {
        backgroundImage: "work-overtime-img",
        name: "work-overtime",
        label: "加班",
        unit: "人天",
        rate: null,
        dayData: overtimeDays
      },
    ];
    return title
  }
}

export default TitleData
