import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      // 0身份认证、1设置新密码、2完成
      current: 0,
      // 校验验证码是否正确 0: 未校验 1: 正确 2: 错误
      validCode: 0,
      account: "", //账号
      id: "", //用户id
      phoneNumber: "", // 手机号码
      countdown: 0, //倒计时
      validPhoneNumber: 0, //校验手机号 0: 校验未通过 1: 手机号不存在 2: 校验通过
      username: "", // 姓名
      code: "", // 短信验证码
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
