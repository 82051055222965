import { Action, Actor } from "plume2";
import { merge } from "immutable";

export default class MainActor extends Actor {
  defaultState() {
    return {
      // tabs对应的类型
      dataType: "hot",
      // 热门推荐
      popularRecommendation_obj: {},
      // 新人必读
      must_read_for_new_people_obj: {},
      // 快速导航入口
      platform_obj: {},
      // 热力图数据
      calendarHeatmapData: [],
      // 当月花费工时和排名
      spentRankData: {},
    };
  }

  @Action("merge:data")
  mergeData(state, data) {
    return merge(state, data);
  }
}
