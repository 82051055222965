import React from "react";
import { Relax } from "plume2";
// 右箭头 - icon
import rightOutlined from "./img/rightOutlined.svg";
import "./index.less";

// 新人必读
@Relax
class MustReadForNewPeople extends React.Component<any, any> {
  static relaxProps = {
    must_read_for_new_people_obj: "must_read_for_new_people_obj",
  };

  render() {
    const { must_read_for_new_people_obj } = this.props.relaxProps;
    if (!must_read_for_new_people_obj?.get("records")?.size) return null;
    return (
      <div className="skyeye_pc_home_sider_skyeye_pc_home_sider">
        {/* 标题 */}
        <div className="skyeye_pc_home_sider_skyeye_pc_home_sider__title">
          新人必读
        </div>
        {/* 内容列表 */}
        <div className="skyeye_pc_home_sider_skyeye_pc_home_sider__list">
          {must_read_for_new_people_obj
            ?.get("records")
            ?.map?.((item, index) => {
              return (
                <a
                  key={index}
                  className="skyeye_pc_home_sider_skyeye_pc_home_sider__list--item"
                  rel="noreferrer"
                  href={item?.get("url")}
                  target="_blank"
                >
                  <span>{item?.get("title") || "-"}</span>
                  <img src={rightOutlined} alt="箭头" />
                </a>
              );
            })}
        </div>
      </div>
    );
  }
}
export default MustReadForNewPeople;
