import { Store } from "plume2";
import MainActor from "./actor/main-actor";
import { SUCCESS_CODE } from "@utils";
import * as webapi from "./webapi";
import { message } from "antd";
import { promiseAllSettled } from "@utils/kit";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  /**
   * 改值
   */
  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  /**
   * 切换tab
   */
  handleChangeTabItem = (e) => {
    this.mergeData({
      pageType: e,
    });
  };

  /**
   * 质量考核页 初始化
   */
  qualityInspectionInit = async () => {
    //搜索条件
    this.getQualityInspectionSerachData();
  };

  /**
   * 质量考核页 搜索条件
   */
  getQualityInspectionSerachData = async () => {
    let selectParam = {
      function_prefix: "f_quality_check",
    };
    await Promise.all([
      webapi.getProjectTypeList(selectParam),
      webapi.getBelongBaselineList(selectParam),
    ]).then(async (firstResults) => {
      const searchParams = JSON.parse(localStorage.getItem("quality_inspection-searchParams") || '{}');
      const firstProjectType = searchParams?.projectType ?? firstResults?.[0]?.context?.[0]?.value;
      const firstbelongBaselinee = searchParams?.belongBaseline ?? firstResults?.[1]?.context?.[0]?.value;
      const startYear = searchParams?.startYear || new Date().getFullYear().toString();

      let conditions = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        ...searchParams,
      };
      // 销毁临时缓存的数据
      localStorage.removeItem("quality_inspection-searchParams");

      this.mergeData({
        projectType: firstProjectType,
        belongBaseline:firstbelongBaselinee
      })
      let param = {
        startYear,
        projectType: firstProjectType,
        belongBaseline: firstbelongBaselinee,
        functionCode: 2,
      };
      if (firstProjectType == 0) {
        param["startYear"] = null;
      }

      if (firstProjectType == 0) {
        const res = await webapi.getIterationList({
          projectId: null,
          startYear,
          projectType: 0,
          belongBaseline: firstbelongBaselinee,
          onlyTested: true,
          functionCode: 2,
        });
        if (res.code === SUCCESS_CODE) {
          let conditionsData = {
            projectType: firstResults[0].context,
            belongBaseline: firstResults[1].context,
            iteration: res.context,
            project: null,
          };
          this.mergeData({
            qualityInspectionConditionsData: conditionsData,
            qualityInspectionConditions: conditions,
          });
          setTimeout(() => {
            // 项目或者迭代信息
            this.getProjectOrIterationInfo();
            // 缺陷数据
            this.getQualityInspectionBugData();
          }, 500);
        } else {
          message.error(res.message);
        }
      } else {
        const [projectList, iterationList] = await promiseAllSettled([
          webapi.getProjectList(param),
          webapi.getIterationList({
            projectId: searchParams?.projectId,
            belongBaseline: firstbelongBaselinee,
            projectType: firstProjectType,
            startYear,
            onlyTested: true,
            functionCode: 2,
          }),
        ]);

        this.mergeData({
          qualityInspectionConditions: conditions,
          qualityInspectionConditionsData: {
            projectType: firstResults?.[0]?.context || [],
            belongBaseline: firstResults?.[1]?.context || [],
            project: projectList?.context || [],
            iteration: iterationList?.context || [],
          },
        });
        
        setTimeout(() => {
          // 项目或者迭代信息
          this.getProjectOrIterationInfo();
          // 缺陷数据
          this.getQualityInspectionBugData();
        }, 500);
      }
      if (firstProjectType == 1) {
        this.mergeData({
          qualityBugDataCheckStatus: ["验收测试"],
        });
      } else {
        this.mergeData({
          qualityBugDataCheckStatus: ["验收测试", "生产问题"],
        });
      }
    });
  };

  /**
   * 质量考核页 项目/迭代信息
   */

  getProjectOrIterationInfo = async (isReset?) => {
    if (isReset) {
      this.mergeData({
        qualityInspectionProjectData: [],
        qualityInspectionIterativeData: [],
      });
    }
    const qualityInspectionConditions = this.state().get(
      "qualityInspectionConditions"
    );
    const { startYear, projectType, belongBaseline, project, iteration } =
      qualityInspectionConditions.toJS();
    let query = [];
    let params = {
      projectId: project || null,
      iterationId: iteration || null,
    };
    let getIterationInfoListParam;
    if (projectType !== 0) {
      getIterationInfoListParam = {
        projectId: project || null,
        iterationId: iteration || null,
        belongBaseline,
        pageNum: 1,
        pageSize: this.state().get("pageSize")
      };
    } else {
      getIterationInfoListParam = {
        projectId: project || null,
        iterationId: iteration || null,
        startYear: startYear || null,
        projectType: projectType,
        belongBaseline,
        pageNum: 1,
        pageSize: this.state().get("pageSize")
      };
    }
    if (projectType == 1) {
      if (project) {
        query.push(webapi.getProjectInfo(params));
        query.push(webapi.getIterationInfoList(getIterationInfoListParam));
      }
    } else {
      query.push(webapi.getIterationInfoList(getIterationInfoListParam));
    }

    await Promise.all(query).then((results) => {
      if (projectType == 1) {
        if (project) {
          this.mergeData({
            qualityInspectionProjectData: results[0]?.context || {},
            qualityInspectionIterativeData: results[1]?.context || {},
          });
        }
      } else {
        this.mergeData({
          qualityInspectionIterativeData: results[0]?.context || {},
        });
      }
    });
  };

  /**
   * 缺陷数据列表点击查询 项目/迭代信息
   */
  getProjectInfoWithList = async (data, isReset?) => {
    const { belongBaseline } = this.state()
      .get("qualityInspectionConditions")
      .toJS();
    const projectPageList = this.state()
      .get("qualityInspectionListInfo")
      ?.toJS?.()?.records;
    let newArr = [];
    projectPageList?.forEach?.((value, index) => {
      newArr[index] = value;
      newArr[index].children = value?.children?.filter?.(
        (item) => item?.id === data?.id
      );
    });
    // 没有迭代信息的定制项目
    let id1 = newArr?.filter?.((i) => i?.id == data?.id)?.[0]?.id;
    // 有多个迭代信息的定制项目
    let id2 = newArr?.filter?.((v) => v?.children?.length == 1)?.[0]?.id;
    let projectId = id1 ? id1 : id2;
    let iterationId = id1 ? null : data.id;
    let param = {
      projectId: projectId || null,
      iterationId: iterationId || null,
    };
    if (isReset) {
      this.mergeData({
        qualityInspectionProjectData: [],
        qualityInspectionIterativeData: [],
      });
    }
    await Promise.all([
      webapi.getProjectInfo(param),
      webapi.getIterationInfoList({ belongBaseline, ...param }),
    ]).then((results) => {
      this.mergeData({
        qualityInspectionProjectData: results?.[0]?.context || {},
        qualityInspectionIterativeData: results?.[1]?.context || {},
      });
    });
  };

  /**
   * 质量考核页 搜索
   */

  qualityInspectionSearch = (isReset) => {
    const { projectType } =
      this.state().get?.("qualityInspectionConditions")?.toJS() || {};
    if (isReset) {
      this.mergeData({
        projectType: projectType,
        qualityInspectionRowId:''
      });
    }
    const qualityBugDataCheckStatus = this.state().get(
      "qualityBugDataCheckStatus"
    );
    if (projectType == 1) {
      this.mergeData({
        qualityBugDataCheckStatus: ["验收测试"],
      });
    } else {
      this.mergeData({
        qualityBugDataCheckStatus: ["验收测试", "生产问题"],
      });
    }
    this.getProjectOrIterationInfo(isReset);
    this.getQualityInspectionBugData(isReset);
  };

  /**
   * 质量考核页 缺陷数据
   */
  getQualityInspectionBugData = async (isReset?, pageNum?, pageSize?) => {
    if (isReset) {
      this.mergeData({
        qualityInspectionListInfo: {},
        qualityInspectionListLoading: true,
      });
    }
    this.mergeData({
      pageNum: pageNum || this.state().get("pageNum"),
      pageSize: pageSize || this.state().get("pageSize")
    });
    const { projectType, belongBaseline, project, iteration, startYear } =
      this.state().get("qualityInspectionConditions")?.toJS();
    let param = {
      projectType,
      belongBaseline,
      projectId: project || null,
      iterationId: iteration || null,
      startYear,
      pageNum: pageNum || 1,
      pageSize: pageSize || this.state().get("pageSize"),
    };
    const res = await webapi.getQualityInspectionBugList(param);
    console.log("debug==>res", res);
    if (res?.code == SUCCESS_CODE) {
      this.mergeData({
        qualityInspectionListInfo: res?.context,
      });
    }
    setTimeout(() => {
      this.mergeData({
        qualityInspectionListLoading: false,
      });
    }, 0);
  };

  /**
   * 质量考核页 搜索条件变更
   */
  changeConditions = async (value, type) => {
    const qualityInspectionConditions = this.state().get(
      "qualityInspectionConditions"
    );
    const qualityInspectionConditionsData = this.state().get(
      "qualityInspectionConditionsData"
    );
    let newConditions = { ...qualityInspectionConditions.toJS() };
    let newConditionsData = { ...qualityInspectionConditionsData.toJS() };
    newConditions[type] = value;
    if (type !== "iteration") {
      newConditions.iteration = null;
      if (type !== "project") {
        newConditions.project = null;
      }
    }
    this.mergeData({ qualityInspectionConditions: newConditions });
    let { project, projectType, startYear, belongBaseline } = newConditions;
    if (projectType == 0) {
      let param = {
        projectId: project || null,
        startYear: startYear || null,
        projectType: 0,
        belongBaseline,
        onlyTested: true,
        functionCode: 2,
      };
      const res = await webapi.getIterationList(param);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.iteration = res.context;
        this.mergeData({
          qualityInspectionConditionsData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    } else {
      let param = {
        projectId: project || null,
        belongBaseline,
        projectType: 1,
        startYear: startYear || null,
        onlyTested: true,
        functionCode: 2,
      };
      let conditions = {
        startYear:
          newConditions.projectType == 1 ? newConditions.startYear : null,
        projectType: newConditions.projectType,
        belongBaseline: newConditions.belongBaseline,
        functionCode: 2,
      };
      const res = await webapi.getProjectList(conditions);
      if (res.code === SUCCESS_CODE) {
        newConditionsData.project = res.context;
        this.mergeData({
          qualityInspectionConditionsData: newConditionsData,
        });
      }
      const iterationListRes = await webapi.getIterationList(param);
      if (iterationListRes.code === SUCCESS_CODE) {
        newConditionsData.iteration = iterationListRes.context;
        this.mergeData({
          qualityInspectionConditionsData: newConditionsData,
        });
      } else {
        message.error(res.message);
      }
    }
  };
}
