import React from "react";
import { Relax } from "plume2";
import { Spin } from "antd";
import { noop } from "@utils";
// 千米企业微信扫码 - 图片
import wechat_png from "./img/wechat.png";
import "./index.less";

// 二维码登录
@Relax
class ScanAndLogin extends React.Component<any, any> {
  static relaxProps = {
    _get_user_info_by_auth_cod_wei_xin: noop,
    loading: "loading",
    code_QR_Type: "code_QR_Type",
    redirect_uri: 'redirect_uri',
    corp_id: "corp_id",
    agentid: "agentid",
  };

  componentDidMount() {
    const { _get_user_info_by_auth_cod_wei_xin } = this.props.relaxProps;
    this._initWechatFunction();
    _get_user_info_by_auth_cod_wei_xin();
  }

  // 企业微信扫码
  _initWechatFunction = () => {
    const { redirect_uri, corp_id, agentid } = this.props.relaxProps;
    // 实例化对象，生成二维码
    (window as any).WwLogin({
      id: "code_QR_wechat",
      appid: corp_id,
      agentid: agentid,
      redirect_uri: encodeURIComponent(redirect_uri),
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIxMHB4O30=", //自定义样式 .impowerBox .qrcode {width: 210px;}
    });
  };

  render() {
    const { loading, code_QR_Type } = this.props.relaxProps;
    return (
      <div className="skyeye_pc_loginscan_and_login">
        <div className="skyeye_pc_loginscan_and_login__content">
          {/* 标题 */}
          <div className="skyeye_pc_loginscan_and_login__content--title">
            企业微信扫码登录
          </div>
          {/* 二维码 */}
          <div
            className={`skyeye_pc_loginscan_and_login__content--code_QR ${
              loading && "skyeye_pc_loginscan_and_login__content--loading"
            }`}
          >
            {/* 企业微信 */}
            <div
              id="code_QR_wechat"
              style={{
                display:
                  code_QR_Type === "wechat" && !loading ? "block" : "none",
              }}
              className="skyeye_pc_loginscan_and_login__content--code_QR__code_wechat"
            />
            {loading && <Spin size="large" />}
          </div>
        </div>

        {/* 扫码方式tabs */}
        <div className="skyeye_pc_loginscan_and_login__tabs">
          <img src={wechat_png} alt="万米企业微信扫码" />
          <span>万米企业微信扫码</span>
        </div>
        {/* <ul
          className="skyeye_pc_loginscan_and_login__tabs"
          onClick={(e) => {
            const type = e?.target?.dataset?.type;
            if (!type) return;

            this.setState(
              {
                code_QR_Type: type,
              },
              () => {
                if (type === "wechat") {
                  this._initWechatFunction();
                }
              }
            );
          }}
        >
          <li
            data-type="wechat"
            className={
              code_QR_Type === "wechat"
                ? `skyeye_pc_loginscan_and_login__tabs--active`
                : undefined
            }
          />
        </ul> */}
      </div>
    );
  }
}
export default ScanAndLogin;
