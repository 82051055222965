import React from "react";
import { NavLink } from "react-router-dom";
import { firstMenusData } from "@utils";

let _data = [];

/**
 * 菜单导航 - 组件
 */
export default class Nav extends React.Component<any, any> {

  static defaultProps = {
    // 是否已登录
    isLogin: false,
    requireChangePwd: false,
  };

  componentWillMount() {
    _data = firstMenusData()
  }
  componentWillUnmount() {
    _data = []
  }

  render() {
    const { isLogin, requireChangePwd } = this.props;
    return (
      <div className="skyeye_pc_header__content--nav">
        {/** 已登录并且非首次登录展示菜单栏 */}
        {isLogin && !requireChangePwd
          ? _data.map((item, index) => {
            // 不可跳转当前选中的菜单
            if (window.location.hash === `#${item.url}`) {
              return (
                <a key={ item?.url } className="skyeye_pc_header__content--nav__active">
                  {item.title}
                </a>
              );
            }
              return (
                <NavLink
                  key={index}
                  to={item.url}
                  replace
                  activeClassName="skyeye_pc_header__content--nav__active"
                >
                  {item.title}
                </NavLink>
              );
            })
          : null}
      </div>
    );
  }
}
