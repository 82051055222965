import React from "react";
import { Form, Input } from "antd";
import "./index.less";
import { LOGIN_DATA, noop } from "@utils";
import { ValidConst } from "@utils/validate";
import info_svg from "../../img/info.svg";
import { Relax } from "plume2";

// 身份认证
@Relax
class IdentityAuthentication extends React.Component<any, any> {
  _form = null;
  static relaxProps = {
    mergeData: noop,
    validCode: "validCode",
    phoneNumber: "phoneNumber",
    _getVerifyCode: noop,
    countdown: "countdown",
    _checkPhoneNumber: noop,
    validPhoneNumber: "validPhoneNumber",
    username: "username",
    code: "code",
  };

  componentDidMount() {}

  render() {
    const { _ref } = this.props;
    const {
      mergeData,
      validCode,
      phoneNumber,
      countdown,
      _checkPhoneNumber,
      validPhoneNumber,
      username,
      code,
    } = this.props.relaxProps;

    //取缓存中的个人信息
    const userInfoVO = JSON.parse(localStorage.getItem("userInfo"));
    //取登录信息
    const loginData = JSON.parse(localStorage.getItem(LOGIN_DATA));
    //编辑手机号
    const userInfoVOPhoneNumber = userInfoVO?.phoneNumber
      ? `${userInfoVO?.phoneNumber.slice(
          0,
          3
        )}****${userInfoVO?.phoneNumber.slice(-4)}`
      : "-";

    return (
      <Form
        ref={(node) => {
          this._form = node;
          _ref(node);
        }}
        className="skyeye_pc_change_password_steps01"
      >
        {userInfoVO ? (
          <Form.Item
            label={
              <>
                <span>姓</span>
                <span style={{ textIndent: "16px" }}>名</span>
              </>
            }
            style={{
              marginBottom: `${userInfoVO ? 30 : 48}px`,
            }}
          >
            <Form.Item
              name="username"
              noStyle
              initialValue={userInfoVO ? userInfoVO.userName : username}
            >
              <Input
                disabled={userInfoVO}
                placeholder="请输入姓名"
                onChange={(e) => {
                  mergeData({
                    username: e.target.value,
                  });
                }}
              />
            </Form.Item>

            {loginData?.requireChangePwd && (
              <div className="skyeye_pc_change_password_steps01--new_password__tip">
                <img src={info_svg} alt="info.svg" />
                由于您是首次登录，您需要重置您的密码
              </div>
            )}
          </Form.Item>
        ) : null}
        <Form.Item
          name="phoneNumber"
          label="手机号"
          rules={[
            {
              validator: (rule, value, callback):any => {
                //手机号不存在时的校验提示
                if (validPhoneNumber === 1) {
                  callback("该手机号不存在");
                  return false;
                }
                //手机号是否填写
                if (value) {
                  //校验通过
                  if (ValidConst.phone.test(value)) {
                    mergeData({ validPhoneNumber: 2 });

                    callback();

                    //校验未通过
                  } else {
                    mergeData({ validPhoneNumber: 0 });
                    callback("请输入正确的手机号");
                  }
                } else {
                  callback("请输入正确的手机号");
                }
                callback();
              },
            },
          ]}
          initialValue={userInfoVO ? userInfoVO.phoneNumber : phoneNumber}
        >
          {userInfoVO ? (
            <span>{userInfoVOPhoneNumber}</span>
          ) : (
            <Input
              disabled={userInfoVO}
              placeholder="请输入手机号码"
              onChange={(e) => {
                mergeData({
                  phoneNumber: e.target.value,
                  validPhoneNumber: 0,
                });
              }}
              onBlur={() => _checkPhoneNumber(this._form)}
            />
          )}
        </Form.Item>
        <Form.Item
          label="短信验证码"
          className="skyeye_pc_change_password_steps01__code"
        >
          <div className="skyeye_pc_change_password_steps01__code--input">
            <Form.Item
              name="code"
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      if (validCode === 2) {
                        callback("该验证码不正确");
                      } else {
                        callback();
                      }
                    }
                    callback("请输入验证码");
                  },
                },
              ]}
              initialValue={code}
            >
              <Input
                placeholder="请输入验证码"
                onChange={(e) => {
                  //清除验证码校验状态
                  mergeData({ validCode: 0, phoneNumber: e.target.value });
                }}
              />
            </Form.Item>
          </div>

          {/* 验证码 - 获取 / 倒计时 */}
          <div
            className={`skyeye_pc_change_password_steps01__code--${
              countdown ? "disabled" : "btn"
            }`}
            onClick={() => {
              if (countdown) return;

              this._validatePhoneNumber();
            }}
          >
            {!countdown ? "获取" : `${countdown}s`}
          </div>
        </Form.Item>
      </Form>
    );
  }

  /**
   * 校验手机号
   */
  _validatePhoneNumber = () => {
    //取缓存中的个人信息
    const userInfoVO = JSON.parse(localStorage.getItem("userInfo"));
    const { _getVerifyCode } = this.props.relaxProps;

    //有个人信息,使用缓存的手机号
    if (userInfoVO) {
      _getVerifyCode(userInfoVO.phoneNumber);
      //校验手机号
    } else {
      this._form
        .validateFields?.(["phoneNumber"])
        .then((values) => {
          if (values.phoneNumber) {
            _getVerifyCode(values.phoneNumber);
          }
        })
        .catch((err) => console.log(err));
    }
  };
}
export default IdentityAuthentication;
