import React from "react";
import { Input } from "antd";

/**
 * 简单封装Input
 * 
 * 原因：
 * 当Input 和 Input.Password写在同一组件内时，autoComplete会失效，
 * 从而无法禁用浏览器自动填充的功能。
 * 
 * 解决方法：readOnly属性 + onFocus方法
 */
export default class SkyeyeInput extends React.Component<any, any> {

    constructor(props: any) {
      super(props);
      this.state = {
        // input框是否只读
        isReadOnly: true,
      };
    }

    render() {
        const { isReadOnly } = this.state;

        return (
            <Input {...this.props} 
                readOnly={ isReadOnly }
                onFocus={() => this.setState({ isReadOnly: false })}
            />
        );
    }
};

(SkyeyeInput as any).Password = Input.Password;