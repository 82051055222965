import React from 'react';
import { Relax } from 'plume2';
import { Table, Tag, Space, Pagination, Tooltip } from 'antd';
import { noop } from "@utils";
import good from '../../../../img/good.png';
import bad from '../../../../img/bad.png';
import standard from '../../../../img/standard.png';
import onLine from '../../../../img/online.png'
import {IterationStatusText} from "@components";
const { Column, ColumnGroup } = Table;


@Relax
export default class TotalData extends React.Component<any, any> {
  props: {
    relaxProps?: {
      bugRepairsPageVOPage: any;
      pageItem: any;
      detailSearch: any;
      clickRowClassName: any;
      rowId: any;
      getTotalDataPageName: Function;
      renderBugDetail: Function;
      totalInterfaceLoading: boolean;
      bugRepairsSearch: any;
    };
  };
  static relaxProps = {
    bugRepairsPageVOPage: 'bugRepairsPageVOPage',
    pageItem: 'pageItem',
    detailSearch: 'detailSearch',
    clickRowClassName: 'clickRowClassName',
    rowId: 'rowId',
    getTotalDataPageName: noop,
    renderBugDetail: noop,
    totalInterfaceLoading: 'totalInterfaceLoading',
    bugRepairsSearch: 'bugRepairsSearch',
  };

  render() {
    const { bugRepairsPageVOPage, getTotalDataPageName, renderBugDetail, pageItem, detailSearch, clickRowClassName, totalInterfaceLoading } = this.props.relaxProps || {}
    // projectType 基线：0 定制：1
    const isCustomMade = detailSearch?.get('projectType') == 1 ? true : false
    const dataSource = isCustomMade ? bugRepairsPageVOPage?.toJS().records : pageItem?.toJS()?.records
    const total = isCustomMade ? bugRepairsPageVOPage.toJS()?.total : pageItem?.toJS()?.total
    const pageSize = isCustomMade ? bugRepairsPageVOPage.toJS()?.size : pageItem?.toJS().size
    const current = isCustomMade ? bugRepairsPageVOPage.toJS()?.current : pageItem?.toJS().current

    return (
      <div className="bug_fixes_total_data">
        <Table
          rowKey="no"
          dataSource={dataSource}
          loading={totalInterfaceLoading}
          pagination={false}
          columns={this._getColumns() as any}
          onRow={record => {
            return {
              onClick: () => {
                renderBugDetail(record)
                this._scrollToDetail()
              }, // 点击行
            };
          }}
          childrenColumnName="bugRepairsItemVOS"
          rowClassName={this._setRowClassName}
        >
        </Table>
        {/* 用于点击列表滚动到明细部分 */}
        <div id="bug-fixes-detail-position">
        <Pagination
          showQuickJumper={true}
          showSizeChanger={true}
          pageSizeOptions={['10', '20', '50', '100']}
          defaultCurrent={1}
          total={total}
          onChange={(page, pageNumber) => { getTotalDataPageName(page, pageNumber) }}
          showTotal={(total, range) => ` 共${total}条`}
          pageSize={pageSize || 10}
          current={current}
        />
        </div>
      </div>
    );
  }




  _getColumns = () => {

    let columns = [
      {
        title: "项目/迭代名称",
        dataIndex: 'projectName',
        key: "projectName",
        width: "23%",
        align: 'left',
        render: (text, record) => this._renderName(text, record),
      },
      {
        title: "缺陷总数（个）",
        dataIndex: "totalNum",
        key: "totalNum",
        width: "13%",
        align: 'center',
        render: (text) => text || "-",
      },
      {
        title: "上周",
        children: [
          {
            title: "处理及时率",
            width: "10%",
            dataIndex: "dealTimelyRateByWeek",
            key: "dealTimelyRateByWeek",
            align: 'center',
            render: (text, data) => this._renderMarket("dealTimelyRate", text, 'week', data),
          },
          {
            title: "修复质量",
            width: "10%",
            dataIndex: "repairQualityByWeek",
            key: "repairQualityByWeek",
            align: 'center',
            render: (text, data) => this._renderMarket("repairQuality", text, 'week', data),
          },
          {
            title: "回归及时率",
            width: "10%",
            dataIndex: "returnTimelyRateByWeek",
            key: "returnTimelyRateByWeek",
            align: 'center',
            render: (text, data) => this._renderMarket("returnTimelyRate", text, 'week', data),
          },
        ]
      },
      {
        title: "全阶段",
        // key: "all",
        children: [
          {
            title: "处理及时率",
            width: "10%",
            dataIndex: "dealTimelyRate",
            key: "dealTimelyRate",
            align: 'center',
            render: (text, data) => this._renderMarket("dealTimelyRate", text, 'all', data),
          },
          {
            title: "修复质量",
            width: "10%",
            dataIndex: "repairQuality",
            key: "repairQuality",
            align: 'center',
            render: (text, data) => this._renderMarket("repairQuality", text, 'all', data),
          },
          {
            title: "回归及时率",
            width: "10%",
            dataIndex: "returnTimelyRate",
            key: "returnTimelyRate",
            align: 'center',
            render: (text, data) => this._renderMarket("returnTimelyRate", text, 'all', data),
          },
        ]
      },
    ];
    return columns
  }

  _renderMarket = (title, text, type?, data?) => {
    const { detailSearch } = this.props.relaxProps || {}
    // projectType 基线：0 定制：1
    const isCustomMade = detailSearch?.get('projectType') == 1 ? true : false
    let goodData, standardData, src;
    if (title == 'dealTimelyRate') {
      if (text == null) return '-'
      isCustomMade ? goodData = 90 : goodData = 95
      isCustomMade ? standardData = 70 : standardData = 80;
      src = text >= goodData ? good : text >= standardData ? standard : bad
      return (<Tooltip
        title={() => {
          return (
            <>
              <div>{`可处理次数: ${type == 'week' ? data.dealCountByWeek : data.dealCount}次`}</div>
              <div>
                {`实际处理次数: ${type == 'week' ? data.jsDealCountByWeek : data.jsDealCount}次`}
              </div>
            </>
          );
        }}
      >
        <div className="table-rate-content">
          <span>{text + '%'}</span>
          <img src={src} className={src == bad ? 'rate-bad-market' : "rate-market"} />
        </div>
      </Tooltip>)
    } else if (title == 'repairQuality') {
      goodData = 100
      isCustomMade ? standardData = 90 : standardData = 95;
      src = text >= goodData ? good : text >= standardData ? standard : bad
      if (text == null) return '-'
      return <Tooltip
        title={() => {
          return (
            <>
              <div>{`一次回归通过: ${type == 'week' ? data.onceSuccessCountByWeek : data.onceSuccessCount}个`}</div>
              <div>
                {`打回: ${type == 'week' ? data.backedNumByWeek : data.backedNum}个`}
              </div>
              <div>
                {`修复未回归: ${type == 'week' ? data.unReturnByWeek : data.unReturn}个`}
              </div>
            </>
          );
        }}
      >
        <div className="table-rate-content">
          <span>{text + '%'}</span>
          <img src={src} className={src == bad ? 'rate-bad-market' : "rate-market"} />
        </div>
      </Tooltip>
    } else {
      goodData = 100
      isCustomMade ? standardData = 90 : standardData = 95;
      src = text >= goodData ? good : text >= standardData ? standard : bad
      if (text == null) return '-'
      return (<Tooltip
        title={() => {
          return (
            <>
              <div>{`可回归次数: ${type == 'week' ? data.returnCountByWeek : data.returnCount}次`}</div>
              <div>
                {`实际回归次数: ${type == 'week' ? data.jsReturnCountByWeek : data.jsReturnCount}次`}
              </div>
            </>
          );
        }}
      >
        <div className="table-rate-content">
          <span>{text + '%'}</span>
          <img src={src} className={src == bad ? 'rate-bad-market' : "rate-market"} />
        </div>
      </Tooltip>)
    }
  }

  _renderName = (value, record) => {
    const { detailSearch } = this.props.relaxProps || {}
    let projectName;
    if (record?.projectName && record?.iterationName) {
      projectName = record?.projectName + '-' + record?.iterationName
    } else {
      projectName = record?.projectName ? record?.projectName : record?.iterationName
    }
    return <div style={{ display: 'flex', alignItems: 'center' }}>
      <div  style={{marginRight:5}}>
        <IterationStatusText
            projectType={ record.hasOwnProperty('bugRepairsItemVOS') ? 1 : 0}
            status={ record.status }
            placeholder={ null }
        />
      </div>
      <span
          className='hover-cls'
          onClick={() => {
            window.open("/quality-assurance?pageType=0");
            this.cacheSearchParams("project-list", record.projectId, record.iterationId);
          }}
      >{projectName}</span>
    </div>
  }

  _setRowClassName = (record) => {
    const { detailSearch, rowId } = this.props.relaxProps || {}
    const isCustomMade = detailSearch?.get('projectType') == 1 ? true : false
    const id = isCustomMade ? record?.projectId : record?.iterationId;
    return id === rowId ? 'click-row-bgc' : ''
  }

  // 页面滚动到明细部分
  _scrollToDetail = () => {
    const scrollDom = document.getElementById('bug-fixes-detail-position');
    scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  /**
   * 缓存 - 搜索条件
   * @param key
   * @returns
   */
  cacheSearchParams = (key, projectId, iterationId) => {
    if(!key) return;

    let { bugRepairsSearch } = this.props.relaxProps || {};
    const {
      projectType, belongBaseline, startYear
    } = bugRepairsSearch?.toJS?.();
    const params = {
      projectType: projectType,
      belongBaseline: belongBaseline,
      iterationId: projectType == 1 ? null : iterationId,
      projectId: projectId,
      startYear: startYear,
      activeTabKey: iterationId,
    };

    localStorage.setItem(`${key}-searchParams`, JSON.stringify(params));
  }
}
