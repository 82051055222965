import React from "react";
import { Relax } from "plume2";
import TitleData from './title-data';
import List from './list'

@Relax
export default class EmployeeData extends React.Component<any, any> {

  render() {
    return (
      <div className="employee-data-contain">
        <TitleData />
        <List />
      </div>
    );
  }
}
