import React from "react";
import { Relax } from "plume2";
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import moment from "moment";

import { Select, DatePicker, Button } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * 自定义查询组件
 */
@Relax
export default class CustomSearch extends React.Component<any, any> {
  static relaxProps = {
    customSearch: 'customSearch',
    customSearchData: 'customSearchData',
    tabType: 'tabType',
    startDate: 'startDate',
    endDate: 'endDate',
    search: noop,
    mergeData: noop,
    changeCustomSearch: noop
  };

  render() {
    const {
      customSearch,
      customSearchData,
      search,
      startDate,
      endDate
    } = this.props.relaxProps;
    const { projectType, belongBaseline } = customSearchData.toJS();

    return (
      <div className="input_content">
        <OneAuthWrapper functionName="f_bug_analyze_project_type_baseline,f_bug_analyze_project_type_customized">
          <Select
            placeholder="请选择项目类型"
            className="input_content_item"
            onChange={e => this._handleChange(e, 'projectType')}
            value={customSearch.get('projectType')}
            style={{ width: 210 }}
          >
            {projectType && projectType.map(item =>
              <Option value={item.value}>{item.label}</Option>
            )}
          </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_bug_analyze_belong_baseline_sbc,f_bug_analyze_belong_baseline_yxd,f_bug_analyze_belong_baseline_b2b,f_bug_analyze_belong_baseline_ywzt,f_bug_analyze_belong_baseline_jszt">
          <Select
            placeholder="请选择所属基线"
            className="input_content_item"
            value={customSearch.get('belongBaseline')}
            onChange={e => this._handleChange(e, 'belongBaseline')}
            style={{ width: 210 }}
          >
            {belongBaseline && belongBaseline.map(item => <Option value={item.value}>{item.label}</Option>)}
          </Select>
        </OneAuthWrapper>

        <RangePicker style={{ width: 260 }}
          onChange={(date, dateStr) => this._onChangeDate(date, dateStr)}
          value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
        />
        <Button
          className="search_group_button"
          type="primary"
          onClick={() => search()}
          style={{ marginLeft: 10 }}
        >查询</Button>
      </div>
    );
  }


  _handleChange = (value, type) => {
    let { changeCustomSearch } = this.props.relaxProps;
    changeCustomSearch(value, type)
  }

  _onChangeDate = (date, dateStr) => {
    const { mergeData } = this.props.relaxProps;
    let startDate = null;
    let endDate = null;
    if (date && date?.length > 0) {
      startDate = dateStr?.[0];
      endDate = dateStr?.[1];
    }
    mergeData({
      startDate: startDate,
      endDate: endDate
    })
  }
}
