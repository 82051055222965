/**
 * 迭代状态
 */
export const ITERATION_STATUS = {
    0: {
        className: "iteration_status_text_04",
        str: "实施中",
    },
    1: {
        className: "iteration_status_text_01",
        str: "已完成",
    },
    2: {
        className: "iteration_status_text_02",
        str: "暂停",
    },
};

/**
 * 项目状态
 */
export const PROJECT_STATUS = {
    0: {
        className: "iteration_status_text_04",
        str: "实施中",
    },
    1: {
        className: "iteration_status_text_04",
        str: "验收中",
    },
    2: {
        className: "iteration_status_text_01",
        str: "已验收",
    },
    3: {
        className: "iteration_status_text_03",
        str: "维稳中",
    },
    4: {
        className: "iteration_status_text_01",
        str: "已完成",
    },
    5: {
        className: "iteration_status_text_02",
        str: "暂停",
    },
    6: {
        className: "iteration_status_text_02",
        str: "烂尾",
    },
};

/**
 * 里程碑状态
 */
 export const MILESTONE_STATUS = {
    '未开始': {
        className: "iteration_status_text_05",
        str: "未开始",
    },
    '进行中': {
        className: "iteration_status_text_04",
        str: "进行中",
    },
    '已实现': {
        className: "iteration_status_text_01",
        str: "已实现",
    },
};
