import React from "react";
import BestQualityLists from "./best_quality_lists";
import ScoreRanking from "./score_ranking";
import { DatePicker } from "antd";
import { noop, AuthWrapper, OneAuthWrapper } from "@utils";
import moment from "moment";
import { Relax } from "plume2";


/**
 * 项目排行榜 - 组件
 */
@Relax
class ProjectRanking extends React.Component<any, any> {
  static relaxProps = {
    mergeData: noop,
    projectStartYear: "projectStartYear",
    projectRankingInit: noop,
  };
  componentDidMount() {
    const { projectRankingInit } =
      this.props.relaxProps;
    projectRankingInit()
  }

  render() {
    const { projectStartYear, mergeData, projectRankingInit } =
      this.props.relaxProps;

    return (
      <OneAuthWrapper functionName="f_project_quality_optimal_top5,f_iteration_quality_optimal_top5,f_project_score_rank">
      <div className="project_ranking">
          {/* 时间年份筛选 */}
          <div className="thousand_code-date">
            <DatePicker
              picker="year"
              defaultValue={moment(projectStartYear)}
              onChange={(date) => {
                mergeData({ projectStartYear: date !== null ? moment(date).format("YYYY") : null, projectScoreDataSource: [], iterationScoreDataSource: [], iterationScorePageNum: 1 });
                projectRankingInit()
              }}
              className="thousand_code-date_picker"
            />
          </div>

        {/** 项目质量最佳Top5， 迭代质量最佳Top5 */}
        <OneAuthWrapper functionName="f_project_quality_optimal_top5,f_iteration_quality_optimal_top5">
          <BestQualityLists />
        </OneAuthWrapper>

        {/** 项目得分排行， 迭代得分排行 */}
        <AuthWrapper functionName="f_project_score_rank">
          <ScoreRanking />
        </AuthWrapper>

        </div>
      </OneAuthWrapper>
    );
  }
}
export default ProjectRanking;
