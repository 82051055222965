import React from "react";
import { Relax } from "plume2";
import { Button, Form } from "antd";
import { history, LOGIN_DATA, noop } from "@utils";
import { SkyeyeInput } from "@components";

import "./index.less";

// 账号登录
@Relax
class LoginForm extends React.Component<any, any> {
  _form = null;
  static relaxProps = {
    _login: noop,
  };

  componentDidMount() {
    const username = localStorage.getItem("username") || "";
    const password = localStorage.getItem("password") || "";
    //如果缓存中有账号密码
    if (username && password) {
      this._form.setFieldsValue({
        remember: true,
        username,
        password: window.atob(password), //解密
      });
    }
  }

  render() {
    const SkyeyeInputPassword = (SkyeyeInput as any).Password;
    return (
      <div className="skyeye_pc_login_login_form">
        <Form
          name="normal_login"
          className="skyeye_pc_login_login_form__form"
          ref={(node) => (this._form = node)}
        >
          {/* 标题 */}
          <div className="skyeye_pc_login_login_form__title">域账号登录(LDAP)</div>

          {/* 表单 */}
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "请输入手机号/邮箱/工号",
              },
            ]}
          >
            <SkyeyeInput placeholder="请输入手机号/邮箱/工号" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "请输入密码",
              },
            ]}
          >
            <SkyeyeInputPassword type="password" placeholder="请输入密码" />
          </Form.Item>

          {/* 登录按钮 */}
          <Form.Item className="skyeye_pc_login_login_form__form--submit">
            <Button type="primary" htmlType="submit" onClick={this._login}>
              登录
            </Button>
          </Form.Item>
          {/* 登录按钮 */}
          <Form.Item className="skyeye_pc_login_login_form__form--info">
            <div>
              南京万米信息技术有限公司
            </div>
            <div>
              版本号：V1.4.1
            </div>
          </Form.Item>
          {/* 记住密码 - 忘记密码 */}
          <Form.Item className="skyeye_pc_login_login_form__form--remember_me">
            {/* 老梁说，拿到这个功能，代码先保留，后续有需要可以清除注释 */}
            {/* <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>下次自动登录</Checkbox>
            </Form.Item> */}
            <div onClick={() => this.handleToChangePwd()}>忘记密码？</div>
          </Form.Item>
        </Form>
      </div>
    );
  }
  /**
   * 跳转忘记密码
   */
  handleToChangePwd = () => {
    //清空登录状态
    localStorage.clear();
    localStorage.removeItem(LOGIN_DATA);

    //跳转忘记密码页面
    history.push("/change-password");
  };

  /**
   * 登录
   */
  _login = () => {
    const { _login } = this.props.relaxProps;
    this._form
      ?.validateFields?.()
      .then((values) => {
        _login(values);
      })
      .catch((err) => {
        console.log("login:".concat(err));
      });
  };
}
export default LoginForm;
