import React from "react";
import { Relax } from "plume2";
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import moment from "moment";


import { Select, DatePicker, Button } from 'antd';
const { Option } = Select;

/**
 * 查询组件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    qualityInspectionConditions: 'qualityInspectionConditions',
    qualityInspectionConditionsData: 'qualityInspectionConditionsData',
    changeConditions: noop,
    qualityInspectionSearch: noop,
  };

  componentWillUnmount(): void {
    // 销毁临时缓存的数据
    localStorage.removeItem("quality_inspection-searchParams");
  }

  render() {
    const {
      qualityInspectionConditions,
      qualityInspectionConditionsData,
      qualityInspectionSearch
    } = this.props.relaxProps;
    const { projectType, belongBaseline, project, iteration } = qualityInspectionConditionsData.toJS();
    const startYear = qualityInspectionConditions.get('startYear');
    // projectType 0:基线  1:定制
    const isCustomMade = qualityInspectionConditions.get('projectType') == 1 ? true : false;



    return (
      <div className="input_group">
        <DatePicker
          className="input_group_item"
          picker="year"
          disabledDate={current => current && current > moment().subtract('year')}
          value={startYear ? moment(startYear) : null}
          onChange={(e, dateString) => this._handleChange(dateString, 'startYear')}
        />

        <OneAuthWrapper functionName="f_quality_check_project_type_baseline,f_quality_check_project_type_customized">
        <Select
          placeholder="请选择项目类型"
          className="input_group_item"
          onChange={e => this._handleChange(e, 'projectType')}
          value={qualityInspectionConditions.get('projectType')}
        >
          {projectType && projectType?.map?.(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_quality_check_belong_baseline_sbc,f_quality_check_belong_baseline_yxd,f_quality_check_belong_baseline_b2b,f_quality_check_belong_baseline_jszt,f_quality_check_belong_baseline_ywzt">
        <Select
          placeholder="请选择所属基线"
          className="input_group_item"
          disabled={qualityInspectionConditions.get('projectType') === undefined}
          value={qualityInspectionConditions.get('belongBaseline')}
          onChange={e => this._handleChange(e, 'belongBaseline')}
        >
          {belongBaseline && belongBaseline.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_quality_check_project_select_list_all,f_quality_check_project_select_list">
        {isCustomMade && <Select
          placeholder="请选择项目"
          className="input_group_item"
          disabled={qualityInspectionConditions.get('belongBaseline') === undefined}
          defaultValue={null}
          value={qualityInspectionConditions.get('project')}
          onChange={e => this._handleChange(e, 'project')}
          showSearch
          notFoundContent="暂无项目"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
          >
            <Option value={null}>全部</Option>
          {project && project.map(item => <Option value={item.value} className="test">{item.label}</Option>)}
          </Select>}
        </OneAuthWrapper>

        <AuthWrapper functionName="f_quality_check_iteration_select_list">
        <Select
          placeholder="请选择迭代"
          className="input_group_item"
          disabled={this._getShow(isCustomMade) as any}
          defaultValue={null}
          value={qualityInspectionConditions.get('iteration')}
          onChange={e => this._handleChange(e, 'iteration')}
          showSearch
          notFoundContent="暂无迭代"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
        >
          <Option value={null} className="test">全部</Option>
          {iteration && iteration.map(item => <Option value={item.value} className="test">{item.label}</Option>)}
        </Select>
        </AuthWrapper>

        <Button
          className="search_group_button"
          type="primary"
          onClick={() => qualityInspectionSearch(true)}
        >搜索</Button>
      </div>
    );
  }

  _getShow = (isCustomMade) => {
    const {
      qualityInspectionConditions,
    } = this.props.relaxProps;
    if (isCustomMade) {
      return qualityInspectionConditions.get('project') ? false : true
    } else {
      return false
    }
  }

  _handleChange = (value, type) => {
    let { changeConditions } = this.props.relaxProps;
    changeConditions(value, type);
  }
}
