import React from "react";
import { Relax } from "plume2";
import { noop, OneAuthWrapper, AuthWrapper } from "@utils";
import moment from "moment";

import { Select, DatePicker, Button } from 'antd';
const { Option } = Select;

/**
 * 查询组件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    bugRepairsSearch: 'bugRepairsSearch',
    bugRepairsSearchData: 'bugRepairsSearchData',
    changeSearch: noop,
    search: noop,
  };

  render() {
    const {
      bugRepairsSearch,
      bugRepairsSearchData,
      search
    } = this.props.relaxProps;
    const { projectType, belongBaseline, project, iteration } = bugRepairsSearchData.toJS();
    const startYear = bugRepairsSearch.get('startYear');
    // projectType 0:基线  1:定制
    const isCustomMade = bugRepairsSearch.get('projectType') == 1 ? true : false;


    return (
      <div className="input_content">
        <DatePicker
          className="input_content_item"
          picker="year"
          disabledDate={current => current && current > moment().subtract('year')}
          value={startYear ? moment(startYear) : null}
          onChange={(e, dateString) => this._handleChange(dateString, 'startYear')}
        />

        <OneAuthWrapper functionName="f_bug_fix_project_type_baseline,f_bug_fix_project_type_customized">
        <Select
          placeholder="请选择项目类型"
          className="input_content_item"
          onChange={e => this._handleChange(e, 'projectType')}
          value={bugRepairsSearch.get('projectType')}
        >
          {projectType && projectType.map(item =>
            <Option value={item.value}>{item.label}</Option>
          )}
        </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_bug_fix_belong_baseline_sbc,f_bug_fix_belong_baseline_yxd,f_bug_fix_belong_baseline_b2b,f_bug_fix_belong_baseline_ywzt,f_bug_fix_belong_baseline_jszt">
        <Select
          placeholder="请选择所属基线"
          className="input_content_item"
          disabled={bugRepairsSearch.get('projectType') === undefined}
          value={bugRepairsSearch.get('belongBaseline')}
          onChange={e => this._handleChange(e, 'belongBaseline')}
        >
          {belongBaseline && belongBaseline.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </OneAuthWrapper>

        <OneAuthWrapper functionName="f_bug_fix_project_select_list_all,f_bug_fix_project_select_list">
        {isCustomMade && <Select
          placeholder="请选择项目"
          className="input_content_item"
          defaultValue={null}
          value={bugRepairsSearch.get('project')}
          onChange={e => this._handleChange(e, 'project')}
          showSearch
          notFoundContent="暂无项目"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
          >
            <Option value={null}>全部</Option>
          {project && project.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>}
        </OneAuthWrapper>

        <AuthWrapper functionName="f_bug_fix_iteration_select_list">
        <Select
          placeholder="请选择迭代"
          className="input_content_item"
          disabled={this._getShow(isCustomMade)}
          defaultValue={null}
          value={bugRepairsSearch.get('iteration')}
          onChange={e => this._handleChange(e, 'iteration')}
          showSearch
          notFoundContent="暂无迭代"
          allowClear={true}
          optionFilterProp="children"
          filterOption={(input, option: any) => {
            return typeof option.props.children == 'string'
              ? option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              : true;
          }}
          dropdownClassName="project-iteration-select-dropdown"
        >
          <Option value={null}>全部</Option>
          {iteration && iteration.map(item => <Option value={item.value}>{item.label}</Option>)}
        </Select>
        </AuthWrapper>

        <Button
          className="search_group_button"
          type="primary"
          onClick={() => search()}
        >搜索</Button>
      </div>
    );
  }

  _getShow = (isCustomMade) => {
    const {
      bugRepairsSearch,
    } = this.props.relaxProps;
    if (isCustomMade) {
      return bugRepairsSearch.get('project') ? false : true
    } else {
      return false
    }
  }

  _handleChange = (value, type) => {
    let { changeSearch } = this.props.relaxProps;
    changeSearch(value, type);
  }
}
