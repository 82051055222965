import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { Table, Pagination, Modal, Select, Input, Button } from 'antd';
import moment from "moment";
const { Option } = Select;
const { Column } = Table;


@Relax
export default class PersonModal extends React.Component<any, any> {
  static relaxProps = {
    personModalVisible: 'personModalVisible',
    personModalMonth: 'personModalMonth',
    personModalDay: 'personModalDay',
    taskName: 'taskName',
    mergeData: noop,
    changeData: noop,
    getTaskDetailList: noop,
    taskDetailList: 'taskDetailList',
    personLoading: 'personLoading',
    departmentUserInfo: 'departmentUserInfo'
  };

  render() {
    const { personModalVisible, mergeData, getTaskDetailList, taskName, personModalMonth, personModalDay, taskDetailList, personLoading, departmentUserInfo } = this.props.relaxProps;
    const userName = departmentUserInfo?.toJS().label || departmentUserInfo?.toJS().children
    const title = userName + personModalMonth + '月' + personModalDay + '日' + '任务明细';
    const dataSource = taskDetailList?.toJS()?.records
    const total = taskDetailList?.toJS()?.total || 0
    const current = taskDetailList?.toJS()?.current || 1

    return (
      <div className="project-modal-contain">
        <Modal title={title} visible={personModalVisible} width={1000}
          onCancel={() => this._emptyData()}
          onOk={() => this._emptyData()} >
          <div className="project-modal-contain-search">
            <Input addonBefore="任务名称" placeholder="请输入任务名称" style={{ marginRight: '10px' }}
              onChange={(e) => { this._handleChange('taskName', e.target.value) }}
              value={taskName}
              onPressEnter={(e: any) => { this._handleChange('taskName', e.target.value, true) }}
            />
            <Button
              className="search_group_button"
              type="primary"
              onClick={() => getTaskDetailList()}
            >搜索</Button>
          </div>
          <div className="project-modal-contain-list">
            <div className="personal_ranking__effect-list__table">
              <Table dataSource={dataSource}
                pagination={false}
                loading={personLoading}
              >
                <Column title="项目名称" dataIndex="projectName" key="projectName" width="170px" />
                <Column title="迭代名称" dataIndex="iterationName" key="iterationName" width="150px" />
                <Column title="任务名称" dataIndex="taskName" key="taskName" width="210px" />
                <Column title="预估花费" dataIndex="planSpent" key="planSpent" align="center" render={(data) => this._getColumnData(data, 'spent')} />
                <Column title="实际花费" dataIndex="actualSpent" key="actualSpent" align="center" render={(data) => this._getColumnData(data, 'spent')} />
                <Column title="开始时间" dataIndex="startDate" key="startDate" align="center" render={(data) => this._getColumnData(data, 'date')} />
                <Column title="结束时间" dataIndex="endDate" key="endDate" align="center" render={(data) => this._getColumnData(data, 'date')} />
                <Column title="任务状态" dataIndex="taskStatus" key="taskStatus" align="center" render={(data) => this._getStatus(data)} />
              </Table>
            </div>
            <div className="project-modal-contain-list-pagination">
              <Pagination
                showQuickJumper={true}
                showSizeChanger={true}
                pageSizeOptions={['10', '20', '50', '100']}
                defaultCurrent={1}
                total={total}
                onChange={(page, pageNumber) => { getTaskDetailList(page, pageNumber) }}
                showTotal={(total, range) => ` 共${total}条`}
                current={current}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  // 清空相关数据
  _emptyData = () => {
    const { changeData } = this.props.relaxProps;
    changeData('personModalVisible', false)
    changeData('taskName', '')
  }

  _handleChange = (type, value, isSearch?) => {
    const { changeData, getTaskDetailList } = this.props.relaxProps;
    changeData(type, value)
    if (isSearch) {
      getTaskDetailList()
    }
  }

  _getColumnData = (data, type?) => {
    if (data == null) {
      return '-'
    }
    if (type == 'spent') {
      return data + '人时'
    }
    if (type == 'date') {
      return moment(data).format('YYYY/M/D')
    }
    return data
  }

  _getStatus = (data) => {
    // 0：未开始 1：进行中 2：已完成
    if (data == 0) {
      return '未开始'
    } else if (data == 1) {
      return '进行中'
    } else {
      return '已完成'
    }
  }
}
