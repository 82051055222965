import React from 'react';
import { noop, OneAuthWrapper, AuthWrapper } from '@utils';
import { Relax } from 'plume2';
import Tip from './tip';
import SearchGroup from './search_group';
import EffectTable from './effect_table';
import ParticularsDrawer from './particulars_drawer';

/**
 * 实时人效 - 组件
 */
@Relax
export default class RealtimeEffect extends React.Component<any, any> {
  static relaxProps = {
    realtimeEffectInit: noop,
  };

  componentDidMount() {
    const { realtimeEffectInit } = this.props.relaxProps;
    realtimeEffectInit();
  }

  render() {
    return (
      <div className="realtime_effect">
        {/* 提示信息 */}
        <Tip />

        {/** 搜索条件 */}
        <SearchGroup />

        {/** 能效列表 */}
        <AuthWrapper functionName="f_humane_effect_page">
          <EffectTable />
        </AuthWrapper>
        <ParticularsDrawer />
      </div>
    );
  }
}
