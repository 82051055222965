import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { Table, Pagination, Modal, Select, Input, Button, TreeSelect, Badge } from 'antd';
const { Option } = Select;
const { Column } = Table;
const { TreeNode } = TreeSelect;

@Relax
export default class ProjectModal extends React.Component<any, any> {
  static relaxProps = {
    changeData: noop,
    projectModalSearch: noop,
    projectModalVisible: 'projectModalVisible',
    departmentDetailList: 'departmentDetailList',
    status: 'status',
    userName: 'userName',
    projectModlDepartmentId: 'projectModlDepartmentId',
    projectModldepartmentName: 'projectModldepartmentName',
    projectModalMonth: 'projectModalMonth',
    projectModalDay: 'projectModalDay',
    departmentId: 'departmentId',
    departmentName: 'departmentName',
    belongDepartmentInfo: 'belongDepartmentInfo',
    belongDepartmentList: 'belongDepartmentList',
    projectLoading: 'projectLoading',
    departmentUserInfo: 'departmentUserInfo'
  };

  render() {
    const { projectModalVisible, departmentDetailList,
      status, userName, projectModlDepartmentId, projectModalSearch, projectModldepartmentName, projectModalDay, projectModalMonth, belongDepartmentInfo, belongDepartmentList, projectLoading } = this.props.relaxProps;
    const dataSource = departmentDetailList?.toJS()?.records
    const total = departmentDetailList?.toJS()?.total || 0
    const current = departmentDetailList?.toJS()?.current || 1
    const departmentName = projectModldepartmentName || ''
    const title = departmentName + projectModalMonth + '月' + projectModalDay + '日' + '投入明细';

    return (
      <div className="project-modal-contain">
        <Modal title={title} visible={projectModalVisible} width={1000}
          onCancel={() => this._emptyData()}
          onOk={() => this._emptyData()}
        >
          <div className="project-modal-contain-search">
            <div className="select-department-contain">
              <span>所属部门</span>
              <TreeSelect
                showSearch
                style={{ width: 188, marginRight: '10px' }}
                placeholder="请选择所属部门"
                onChange={(value, label) => {
                  this._handleChange('projectModlDepartmentId', value)
                  this._handleChange('projectModldepartmentName', label?.[0])
                }}
                value={projectModlDepartmentId}
                defaultValue={belongDepartmentInfo?.toJS()?.id}
                filterTreeNode={(input, treeNode) =>
                  treeNode.props.title
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                treeDefaultExpandedKeys={[projectModlDepartmentId?.toString()]}
                dropdownClassName="department-select-dropdown"
              >
                {this._loop(belongDepartmentList?.toJS())}
              </TreeSelect>
            </div>
            <Input addonBefore="姓名" placeholder="请输入姓名" style={{ marginRight: '10px', width: 240 }}
              onChange={(e: any) => { this._handleChange('userName', e.target.value) }}
              onPressEnter={(e: any) => { this._handleChange('userName', e.target.value, true) }}
              value={userName}
            />
            <div className="input-status-box">
              <span className="input-status-words"> 投入状态</span>
              <Select
                placeholder="请选择投入状态"
                optionFilterProp="children"
                onChange={(value) => { this._handleChange('status', value) }}
                defaultValue={null}
                value={status}
                style={{ width: 188 }}
              >
                <Option value={null}>全部</Option>
                <Option value={0}>正常</Option>
                <Option value={1}>异常</Option>
                <Option value={2}>虚耗</Option>
                <Option value={3}>调休</Option>
                <Option value={4}>加班</Option>
                <Option value={5}>休息</Option>
              </Select>
            </div>

            <Button
              className="search_group_button"
              type="primary"
              onClick={() => projectModalSearch()}
            >搜索</Button>
          </div>
          <div className="project-modal-contain-list">
            <div className="personal_ranking__effect-list__table">
              <Table dataSource={dataSource}
                pagination={false}
                loading={projectLoading}
              >
                <Column title="序号" dataIndex="number" key="number" width="6%" align="center" render={(text, record, index) => `${index + 1}`} />
                <Column title="姓名" dataIndex="userName" key="userName" width="14%" align="center" />
                <Column title="所属部门" dataIndex="department" key="department" width="20%" align="center" />
                <Column title="状态" dataIndex="status" key="status" width="12%" align="center" render={(data) => this._getStatus(data)} />
                <Column title="预估花费（人时）" dataIndex="planSpent" key="planSpent" width="16%" align="center" render={(data) => this._getColumnData(data)} />
                <Column title="实际花费（人时）" dataIndex="actualSpent" key="actualSpent" width="16%" align="center" render={(data) => this._getColumnData(data)} />
              </Table>
            </div>
            <div className="project-modal-contain-list-pagination">
              <Pagination
                showQuickJumper={true}
                showSizeChanger={true}
                pageSizeOptions={['10', '20', '50', '100']}
                defaultCurrent={1}
                total={total}
                onChange={(page, pageNumber) => { projectModalSearch(page, pageNumber) }}
                showTotal={(total, range) => ` 共${total}条`}
                current={current}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }



  // 处理所属部门的树形结构数据
  _loop = (data) =>
    data.map((item) => {
      if (item?.children?.length > 0) {
        return (
          <TreeNode
            key={item.id}
            value={item.id}
            title={item.name}
          >
            {this._loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          value={item.id}
          title={item.name}
        />
      );
    });



  _getStatus = (data) => {
    // 投入状态，0正常、1异常、2虚耗、3调休、4加班
    if (data == 0) {
      return <Badge text='正常' color="green" />
    } else if (data == 1) {
      return <Badge text='异常' color="yellow" />
    } else if (data == 2) {
      return <Badge text='虚耗' color="red" />
    } else if (data == 3) {
      return <Badge text='调休' color="blue" />
    } else if (data == 4) {
      return <Badge text='加班' color="purple" />
    } else {
      return <Badge text='休息' color="#FF6600" />
    }
  }

  _getColumnData = (data) => {
    return data == null ? '-' : data
  }

  // 重置相关数据
  _emptyData = () => {
    const { changeData, departmentId, projectModldepartmentName, departmentName, departmentUserInfo } = this.props.relaxProps;
    changeData('projectModalVisible', false)
    changeData('projectModlDepartmentId', departmentId)
    changeData('projectModldepartmentName', departmentName)
    changeData('userName', '')
    changeData('status', null)
  }

  _handleChange = (type, value, isSearch?) => {
    const { changeData, projectModalSearch } = this.props.relaxProps;
    changeData(type, value)
    if (isSearch) {
      projectModalSearch()
    }
  }
}
