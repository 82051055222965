import React from "react";
import { Relax } from "plume2";
import { Image, Table } from "antd";
import { AuthWrapper } from "@utils";
import first from "./../../img/first.png";
import second from "./../../img/second.png";
import third from "./../../img/third.png";

@Relax
class BestQualityLists extends React.Component<any, any> {
  static relaxProps = {
    bestQualityTopFiveForProjectData: "bestQualityTopFiveForProjectData",
    bestQualityTopFiveForIterationData: "bestQualityTopFiveForIterationData",
    bestQualityTopFiveForProjectLoading: "bestQualityTopFiveForProjectLoading", //项目质量最佳top5加载中
    bestQualityTopFiveForIterationLoading: "bestQualityTopFiveForIterationLoading", //迭代质量最佳top5加载中
  };
  render() {
    const {
      bestQualityTopFiveForProjectData,
      bestQualityTopFiveForIterationData,
      bestQualityTopFiveForProjectLoading,
      bestQualityTopFiveForIterationLoading,
    } = this.props.relaxProps;
    const bestQualityTopFiveDataListForProject =
      bestQualityTopFiveForProjectData.get("bestQualityTopFiveDataList");
    const bestQualityTopFiveDataListForIteration =
      bestQualityTopFiveForIterationData.get("bestQualityTopFiveDataList");
    const lists = [
      {
        title: "项目质量最佳Top5",
        dataSource: bestQualityTopFiveDataListForProject?.toJS(),
        key: "project",
        loading: bestQualityTopFiveForProjectLoading,
        functionName: "f_project_quality_optimal_top5"
      },
      {
        title: "迭代质量最佳Top5",
        img: "projectImg",
        dataSource: bestQualityTopFiveDataListForIteration?.toJS(),
        key: "iteration",
        loading: bestQualityTopFiveForIterationLoading,
        functionName: "f_iteration_quality_optimal_top5"
      },
    ];
    return (
      <div className="project_ranking__best_quality_lists">
        {lists?.map((list) => {
          return this.renderBestQualityList(list);
        })}
      </div>
    );
  }

  /**
   * 渲染单个列表
   */
  renderBestQualityList = (list) => {
    const listColumns = this.getColumns(list.key) as any;
    return (
      <AuthWrapper functionName={list.functionName}>
      <div className="project_ranking__best_quality_lists_box" key={list.key}>
        <div className="project_ranking__best_quality_lists_box_title">
          {list.title}
        </div>
        <div className="project_ranking__best_quality_lists_box_item">
          <Table
            rowKey="rowNum"
            columns={listColumns}
            dataSource={list.dataSource}
            loading={list.loading}
            pagination={false}
          />
        </div>
        </div>
      </AuthWrapper>
    );
  };

  /**
   * 获取列表列数据
   */
  getColumns = (key) => {
    //项目质量最佳
    const projectColumns = [
      {
        title: "项目名称",
        dataIndex: "projectName",
        key: "projectName",
        width: '23%',
        render: (text) => text || "-",
      },
      {
        title: "项目经理",
        dataIndex: "projectManagerName",
        key: "projectManagerName",
        align: "center",
        width: "10%",
        render: (text) => text || "-",
      },
    ];

    //迭代质量最佳
    const iterationColumns = [
      {
        title: "迭代名称",
        dataIndex: "iterationName",
        key: "iterationName",
        width: '23%',
        render: (text) => text || "-",
      },
      {
        title: "开发组长",
        dataIndex: "devLeadName",
        key: "devLeadName",
        align: "center",
        width: "10%",
        render: (text) => text || "-",
      },
    ];
    let rankColumn = {
      title: "排名",
      dataIndex: "rowNum",
      key: "rowNum",
      align: "center",
      width: "7%",
      render: (text) => {
        const images = [first, second, third];
        if (+text < 4) {
          return (
              <div>
                <Image
                    src={images[+text - 1]}
                    width={20}
                    height={20}
                    preview={false}
                />
              </div>
          );
        }
        return <div>{text || "-"}</div>;
      }
    };
    let commentColumns = [
      {
        title: "任务数",
        dataIndex: "taskNum",
        key: "taskNum",
        align: "center",
        width: "10%",
        render: (text) => text || "0",
      },
      {
        title: "花费工时",
        dataIndex: "codeTimeNum",
        key: "codeTimeNum",
        align: "center",
        width: "10%",
        render: (text) => `${text || "0"}人时`,
      },
      {
        title: "缺陷总数",
        dataIndex: "bugNum",
        key: "bugNum",
        align: "center",
        width: "10%",
        render: (text) => text || "0",
      },
      {
        title: "千行代码",
        dataIndex: "codeLine",
        key: "codeLine",
        align: "center",
        width: "10%",
        render: (text) => text || "0",
      },
      {
        title: "缺陷密度",
        dataIndex: "bugDensity",
        key: "bugDensity",
        align: "center",
        width: "10%",
        render: (text) => text || "0",
      },
    ];
    let targetColumns = [];
    targetColumns.push(rankColumn);
    targetColumns.push(...key === "project" ? projectColumns : iterationColumns);
    targetColumns.push(...commentColumns);
    return targetColumns;
  };
}
export default BestQualityLists;
