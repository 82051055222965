import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { history } from '@utils';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import reportWebVitals from './reportWebVitals';
import './axios';
// 入口文件
import App from './App';
// 登录页
import Login from './pages/login';
// 新员工注册
import Register from './pages/register';

ReactDOM.render(
  <ConfigProvider locale={ zhCN }>
    <Router history={ history }>
      <Switch>
        <Route exact strict path='/' component={ Login } />
        <Route exact strict path='/register' component={ Register } />
        <Route component={ App } />
      </Switch>
    </Router>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
