import React from 'react';
import {Relax} from 'plume2';
import {Pagination, Table} from 'antd';
import {noop} from "@utils";

const { Column } = Table

@Relax
export default class ProjectList extends React.Component<any, any> {
  static relaxProps = {
    demandOptimizationFlag: 'demandOptimizationFlag',
    customDemandOptimizationFlag: 'customDemandOptimizationFlag',
    tabType: 'tabType',
    mergeData: noop,
    getNewFixList: noop,
    getIsEmpty: noop,

    forNewPageLoading: 'forNewPageLoading',
    forFixPageLoading: 'forFixPageLoading',
    forNewPage: 'forNewPage',
    forFixPage: 'forFixPage',
    newFixScoreType: 'newFixScoreType',
    customNewFixScoreType: 'customNewFixScoreType',
  };

  render() {
    const { getNewFixList } = this.props.relaxProps
    const total = this._getRelevantData('total')
    const current = this._getRelevantData('current') || 1
    const pageSize = this._getRelevantData('size') || 10
    const columns = this._getColumn()
    const loading = this._getLoading()

    return (
        <div className="list">
          <Table
              rowKey="projectName"
              dataSource={this._getData()}
              pagination={false}
              scroll={{ x: 'max-content' }}
              loading={loading}
          >
            {columns?.map?.((v, i) => {
              const fixed = (v?.type == 'total' || v?.type == 'date') ? true : false
              return <Column title={v?.text} dataIndex={v?.type} width={v?.type == 'total' ? '110px' : ''} key={v?.type} align={v?.align || 'center'} render={(value, record) => this._renderColumn(value, record, v?.type)} fixed={fixed}/>
            })}
          </Table>
          <div className="pagination">
            <Pagination
                showQuickJumper={true}
                showSizeChanger={true}
                pageSizeOptions={['10', '20', '50', '100']}
                defaultCurrent={1}
                total={total}
                onChange={(pageNum, pageSize) => {
                  getNewFixList(pageNum, pageSize)
                }}
                showTotal={(total, range) => ` 共${total}条`}
                pageSize={pageSize || 10}
                current={current}
            />
          </div>
        </div>
    );
  }


  _renderColumn = (value, records, type) => {
    if (value == null) {
      return 0
    }
    return value
  }

  _getRelevantData = (type?) => {
    const { forNewPage, forFixPage, newFixScoreType, tabType, customNewFixScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType
    let total;
    if (tab == 1) {
      total = forNewPage?.toJS()?.ipage?.[type]
    } else if (tab == 2) {
      total = forFixPage?.toJS()?.ipage?.[type]
    }
    return total
  }

  _getColumn = () => {
    const { forFixPage, forNewPage, newFixScoreType, tabType, customNewFixScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType
    let columns = []
    if (tab == 1) {
      columns = forNewPage?.toJS()?.titleDatas
    } else if (tab == 2){
      columns = forFixPage?.toJS()?.titleDatas
    }
    let newColumns = []

    if(columns?.length > 0 && tab == 5){
      let employeColumns = { type: "date", text: "日期", fixed: true, width: '120px', align: 'center' }
      newColumns = [employeColumns, ...(columns || [])]
    } else if (columns?.length > 0) {
      let employeColumns = { type: "date", text: "日期", fixed: true, width: '120px', align: 'center' }
      newColumns = [employeColumns, ...(columns || [])]
    }
    return newColumns
  }

  _getLoading = () => {
    const { forNewPageLoading, forFixPageLoading, newFixScoreType, tabType, customNewFixScoreType } = this.props.relaxProps
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType
    let loading;
    if (tab == 1) {
      loading = forNewPageLoading
    } else if (tab == 2) {
      loading = forFixPageLoading
    }
    return loading
  }


  _getData = () => {
    const { forNewPage, forFixPage, newFixScoreType, customNewFixScoreType, tabType } = this.props.relaxProps
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType
    let dataSource = []
    if (tab == 1) {
      dataSource = forNewPage?.toJS()?.ipage?.records;
    } else if (tab == 2) {
      dataSource = forFixPage?.toJS()?.ipage?.records
    }
    dataSource.sort((a,b) => {
      if(a.date > b.date){
        return 1;
      }
      if(a.date < b.date){
        return -1;
      }
      return 0;
    })
    return dataSource
  }
}
