
interface IProps {
    /**
     * 填充颜色
     */
    fillColor?: string;
}

/**
 * 向上箭头
 * @returns 
 */
export default function TopArrowSvg(props: IProps) {
    return (
        <svg width="9px" height="6px" viewBox="0 0 9 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>向上箭头</title>
            <defs>
                <linearGradient x1="-1.11022302e-14%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
                    <stop stopColor="#00C25A" offset="0%"></stop>
                    <stop stopColor="#6BE653" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="9月迭代" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="质量保证-项目列表-基线产品" transform="translate(-952.000000, -755.000000)" 
                    // 默认是渐变绿
                    fill={ props?.fillColor || "url(#linearGradient-1)" }
                >
                    <g id="定制项目-迭代明细" transform="translate(360.000000, 662.000000)">
                        <g id="编组-3" transform="translate(60.000000, 56.000000)">
                            <g id="编组-8" transform="translate(532.000000, 37.000000)">
                                <path d="M0.112430914,5.25783972 L4.18803981,0.146341463 C4.27525492,0.04878 4.38259499,0 4.51006323,0 C4.63753147,0 4.74487154,0.04878 4.83208665,0.146341463 L8.88756909,5.25783972 C9.00832847,5.39721324 9.03180911,5.55574824 8.95801171,5.73344948 C8.88421431,5.91115071 8.7533936,6 8.56554567,6 L0.434454333,6 C0.246606399,6 0.115785693,5.91115071 0.0419882909,5.73344948 C-0.0318091117,5.55574824 -0.00832847215,5.39721324 0.112430914,5.25783972 Z" id="caret-up---anticon"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
