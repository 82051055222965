import React, { Fragment } from "react";
import { StoreProvider } from "plume2";
import { AuthWrapper, OneAuthWrapper } from "@utils";
import AppStore from "./store";
import SearchGroup from "./components/search_group/index";
import EmployeeData from "./components/employee-data/index";
import CalendarData from "./components/calendar-data";
import "./index.less";

/**
 * 资源日历 - 组件
 */
@StoreProvider(AppStore)
export default class ResourceCalendar extends React.Component {
  store: AppStore;
  componentDidMount() {
    //初始化数据
    this.store.init();
  }
  render() {
    return (
      <Fragment>
        {/** 搜索条件 */}
        <OneAuthWrapper functionName="f_resource_calendar_data_summary,f_resource_calendar_effort_summary,f_resource_calendar_effort_detail">
          <SearchGroup />
        </OneAuthWrapper>


        {/** 员工数据汇总 */}
        <AuthWrapper functionName="f_resource_calendar_data_summary">
          <EmployeeData />
        </AuthWrapper>


        {/* 资源日历 */}
        <OneAuthWrapper functionName="f_resource_calendar_effort_summary,f_resource_calendar_effort_detail">
          <CalendarData />
        </OneAuthWrapper>

      </Fragment>
    );
  }
}
