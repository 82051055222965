import React from "react";
import { Relax } from "plume2";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

@Relax
class LineChartItem extends React.Component<any, any> {
  static relaxProps = {
    ownerFixQualityLineData: "ownerFixQualityLineData",
    isShow: 'isShow'
  };
  render() {
    const { ownerFixQualityLineData, isShow } = this.props.relaxProps;
    const fixQualityLineData =
      ownerFixQualityLineData.get("fixQualityLineDataList") ?? [];
    let iterationNameArr = [];
    let onceFixBugRatioArr = [];
    let twiceFixBugRatioArr = [];
    let thriceAndMoreFixBugRatioArr = [];
    fixQualityLineData.forEach((item) => {
      const iterationName = item.get("iterationName") ?? 0;
      const onceFixBugRatio = item.get("onceFixBugRatio") ?? 0;
      const twiceFixBugRatio = item.get("twiceFixBugRatio") ?? 0;
      const thriceAndMoreFixBugRatio =
        item.get("thriceAndMoreFixBugRatio") ?? 0;
      iterationNameArr.push(iterationName);
      onceFixBugRatioArr.push(onceFixBugRatio);
      twiceFixBugRatioArr.push(twiceFixBugRatio);
      thriceAndMoreFixBugRatioArr.push(thriceAndMoreFixBugRatio);
    });
    const option = {
      color: ["#5B8FF9", "#5AD8A6", "#5D7092"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: (params) => {
          let str =
            '<span style="font-size: 10px;font-weight: 400;color: #000000;line-height: 14px;padding: 2px 5px;">' +
            params[0].name +
            "</span>" +
            "<br />";
          params.forEach((item) => {
            str +=
              '<div style="font-size: 10px;font-weight: 400;color: #666666;display: flex;justify-content: space-between;align-items: center;min-width: 160px;padding: 2px 5px;">' +
              '<span style="display: flex;align-items: center;">' +
              '<div style="line-height: 10px;margin-right:6px;border-radius:50%;width:4px;height:4px;background-color:' +
              item.color +
              '">' +
              "</div>" +
              " <span>" +
              item.seriesName +
              "</span>" +
              "</span>" +
              "<span>" +
              item.data +
              "%" +
              "</span>" +
              "</div>";
          });
          return str;
        },
      },
      legend: {
        data: ["一次修复成功率", "二次修复成功率", "三次以上修复成功率"],
        left: "0%",
        icon: "ract",
        itemHeight: 2,
        itemWidth: 12,
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: false, //不展示保存为图片
          },
        },
      },
      grid: {
        left: "0%",
        right: "1%",
        bottom: "1.5%",
        top: "12%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: iterationNameArr,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "一次修复成功率",
          type: "line",
          areaStyle: {
            // 颜色自上而下渐变
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                // 1代表上面
                offset: 0,
                color: "#5B8FF9",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
            opacity: 0.5, // 填充区域透明度
          },
          emphasis: {
            focus: "series",
          },
          data: onceFixBugRatioArr,
        },
        {
          name: "二次修复成功率",
          type: "line",
          areaStyle: {
            // 颜色自上而下渐变
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                // 1代表上面
                offset: 0,
                color: "#5AD8A6",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
            opacity: 0.5, // 填充区域透明度
          },
          emphasis: {
            focus: "series",
          },
          data: twiceFixBugRatioArr,
        },
        {
          name: "三次以上修复成功率",
          type: "line",
          areaStyle: {
            // 颜色自上而下渐变
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                // 1代表上面
                offset: 0,
                color: "#5D7092",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
            opacity: 0.5, // 填充区域透明度
          },
          emphasis: {
            focus: "series",
          },
          data: thriceAndMoreFixBugRatioArr,
        },
      ],
    };
    return (
      isShow && <div className="personal_ranking__line_chart">
        <div className="personal_ranking__line_chart__title">
          修复质量折线图
        </div>
        <div className="personal_ranking__line_chart__chart">
          <ReactEcharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            style={{ width: "100%", minHeight: "300px" }}
          />
        </div>
      </div>
    );
  }
}
export default LineChartItem;
