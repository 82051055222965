import { Store } from "plume2";
import MainActor from "./actor/actor";
import { SUCCESS_CODE } from "@utils";
import * as webapi from "./webapi";

export default class AppStore extends Store {
  bindActor() {
    return [new MainActor()];
  }

  mergeData = (data) => {
    this.dispatch("merge:data", data);
  };

  init() {
    //查询 新增客户数相关
    this.getCustomerIncreaseOverview();
    //查询 累计客户数，活跃客户数，沉默客户数
    this.getCustomerTotalOverview();
    //查询 趋势图
    this.getChartsData();
    //查询 客户列表
    this.getCustomerPage();
  }

  /**
   * 查询新增客户概览
   */
  getCustomerIncreaseOverview = async () => {
    const selectType = this.state().get("selectType");
    const rangeData = this.state().get("rangeData");
    //编辑参数
    const params = {
      selectType: selectType,
      beginDate: "",
      endDate: "",
    };
    //选中自定义
    if (selectType === "4") {
      params.beginDate = rangeData.getIn([0]);
      params.endDate = rangeData.getIn([1]);
    } else {
      delete params.beginDate;
      delete params.endDate;
    }
    const res = await webapi.getCustomerIncreaseOverview(params);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        customerIncreaseData: res?.context || {}, //新增客户数数据
        updateTime: res?.context?.refreshTime || "-", //更新时间
      });
    }
  };

  /**
   * 查询 累计客户数，活跃客户数，沉默客户数
   */
  getCustomerTotalOverview = async () => {
    const res = await webapi.getCustomerTotalOverview();
    if (res.code === SUCCESS_CODE) {
      this.mergeData({
        customerOverviewData: res?.context || {},
      });
    }
  };

  /**
   * 查询趋势图数据
   */
  getChartsData = async () => {
    const selectType = this.state().get("selectType");
    const rangeData = this.state().get("rangeData");
    const tabType = this.state().get("tabType");
    //编辑参数
    const params = {
      selectType: selectType,
      tabType: tabType,
      beginDate: "",
      endDate: "",
    };
    //选中自定义
    if (selectType === "4") {
      params.beginDate = rangeData.getIn([0]);
      params.endDate = rangeData.getIn([1]);
    }
    //选中累计/活跃/沉默客户数
    if (tabType !== "0") {
      delete params.selectType;
      delete params.beginDate;
      delete params.endDate;
    }
    this.getCustomerIncreaseOverview();
    const res = await webapi.getChartsData(params);
    if (res.code === SUCCESS_CODE) {
      this.mergeData({ lineChartsData: res?.context || [] });
    }
  };

  /**
   * 查询客户列表
   */
  getCustomerPage = async () => {
    const selectType = this.state().get("selectType");
    const rangeData = this.state().get("rangeData");
    const tabType = this.state().get("tabType");
    const searchValue = this.state().get("searchValue");
    const listPage = this.state().get("listPage");

    //编辑参数
    const params = {
      selectType: selectType,
      tabType: tabType,
      keyword: searchValue,
      pageNum: listPage?.get("current"),
      pageSize: listPage?.get("size"),
      beginDate: "",
      endDate: "",
    };
    //选中自定义
    if (selectType === "4") {
      delete params.selectType;
      params.beginDate = rangeData[0];
      params.endDate = rangeData[1];
    }
    //选中累计/活跃/沉默客户数
    if (tabType !== "0") {
      delete params.selectType;
      delete params.beginDate;
      delete params.endDate;
    }
    const res = await webapi.getCustomerPage(params);
    if (res.code === SUCCESS_CODE) {
      const replayCustomerVOPage = res?.context?.replayCustomerVOPage || {};
      const listPage = {
        total: replayCustomerVOPage?.total || 0,
        size: replayCustomerVOPage?.size || 10,
        current: replayCustomerVOPage?.current || 1,
      };
      this.mergeData({
        listData: replayCustomerVOPage?.records || [],
        listPage: listPage,
      });
    }
  };

  /**
   * 修改自定义日期选择弹窗开关
   */
  changeRangePickerModalVisible = () => {
    const visible = this.state().get("visible");
    this.mergeData({ visible: !visible });
  };

  /**
   * 修改选择日期范围
   */
  changeRangeData = (startDate, endDate) => {
    //确认后更改日期范围以及选中自定义
    this.mergeData({ rangeData: [startDate, endDate], selectType: "4" });

    //查询趋势图数据
    this.getChartsData();
  };

  /**
   * 修改新增客户数底部范围选择
   */
  changeRange = async (key) => {
    //自定义弹出弹窗
    if (key === "4") {
      this.changeRangePickerModalVisible();
    } else {
      await this.mergeData({ selectType: key });
      //查询趋势图数据
      this.getChartsData();
    }
  };

  /**
   * 修改tag标签
   */
  changeTag = (key) => {
    this.mergeData({ tabType: key });
    this.getChartsData();
  };

  /**
   * 客户列表切换分页
   */
  changeListPage = (pageNum, pageSize) => {
    const listPage = this.state().get("listPage")?.toJS();
    listPage.size = pageSize;
    listPage.current = pageNum;
    this.mergeData({ listPage: listPage });
    this.getCustomerPage();
  };

  /**
   * 修改搜索内容
   */
  changeSearchValue = (value) => {
    const listPage = this.state().get("listPage")?.toJS();
    //搜索从第一页开始
    listPage.current = 1;
    this.mergeData({ searchValue: value, listPage: listPage });
    this.getCustomerPage();
  };
}
