import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import moment from "moment";

import { Select, DatePicker, Button } from 'antd';
const { Option } = Select;

/**
 * 个人视角的时间筛选
 */
@Relax
export default class SearchTime extends React.Component<any, any> {
  static relaxProps = {
    changeData: noop,
    personalRankingSearchByTime: noop,
    personSelectStartYear: 'personSelectStartYear',
    isLeader: 'isLeader'
  };

  render() {
    let { personSelectStartYear, isLeader } = this.props.relaxProps;
    return (
      !isLeader && <div className="search-time-box">
        <DatePicker
          style={{ width: 208, marginRight: 10, borderRadius: 4 }}
          picker="year"
          value={personSelectStartYear ? moment(personSelectStartYear) : null}
          onChange={(e, dateString) => this._handleChange('personSelectStartYear', dateString)}
        />
      </div>
    );
  }

  _handleChange = (type, value) => {
    let { changeData, personalRankingSearchByTime } = this.props.relaxProps;
    changeData(type, value);
    personalRankingSearchByTime()
  }
}
