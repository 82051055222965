import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { Input, Button, Table, Alert } from "antd";
import moment from 'moment';

@Relax
class CodeDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchName: "",
    };
  }

  static relaxProps = {
    thousandCodeDataSource: "thousandCodeDataSource",
    changeThousandCodeParams: noop,
    thousandCodeLoading: "thousandCodeLoading",
    timeFrame: 'timeFrame',
    isShowTimeFrame: 'isShowTimeFrame'
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  titleColumns: any = [
    {
      title: "序号",
      key: "num",
      width: "10%",
      align: "center",
      render: (text, record, index) => ++index,
    },
    {
      title: "姓名",
      dataIndex: "username",
      key: "username",
      align: "center",
      render: (text) => text || "-",
    },
    {
      title: "新增代码行",
      dataIndex: "additions",
      key: "additions",
      align: "center",
      render: (text) => text || "-",
    },
    {
      title: "删除代码行",
      dataIndex: "deletions",
      key: "deletions",
      width: "15%",
      align: "center",
      render: (text) => text || "-",
    },
  ];

  totleColumns: any = [
    {
      title: "合计",
      dataIndex: "title",
      key: "title",
      width: "50%",
      align: "center",
      render: (text) => text,
    },
    {
      title: "总新增代码行",
      dataIndex: "totalAdditions",
      key: "totalAdditions",
      width: "25%",
      align: "center",
      render: (text) => text,
    },
    {
      title: "总删除代码行",
      dataIndex: "totalDeletions",
      key: "totalDeletions",
      width: "25%",
      align: "center",
      render: (text) => text,
    },
  ];

  render() {
    const {
      thousandCodeDataSource,
      changeThousandCodeParams,
      thousandCodeLoading,
      timeFrame,
      isShowTimeFrame
    } = this.props.relaxProps;
    const beginTime = timeFrame?.toJS()?.beginTime == null ? null : moment(timeFrame?.toJS()?.beginTime).format('YYYY/M/D')
    const endTime = timeFrame?.toJS()?.endTime == null ? null : moment(timeFrame?.toJS()?.endTime).format('YYYY/M/D')
    const time = beginTime + ' ～ ' + endTime
    const { searchName } = this.state;
    let list = thousandCodeDataSource.get("list")?.toJS() || [];
    return (
      <div className="thousand_code_CodeDetail">
        <div >
          <Alert
            message=""
            description={
              <div>
                <ul style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
                  <li>
                    1、千行代码数据只查询从需求分析时间至验收测试区间的对应分支的代码改动记录，请确保里程碑计划已正确维护
                  </li>
                  <li>
                    2、Git Project中配置的Webhook事件将会以增量方式进行记录，若配置时间滞后可在管理后台对缺失的部分进行二次初始化
                  </li>
                </ul>
              </div>
            }
            style={{ borderRadius: '4px', border: '1px solid #FF6600', background: '#FFF6F0' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <Input
                className="thousand_code_CodeDetail_input"
                addonBefore="姓名"
                placeholder="请输入姓名"
                allowClear={true}
                value={searchName}
                onChange={(e) => {
                  this.setState({ searchName: e.target.value });
                }}
              />
              <Button
                className="thousand_code_CodeDetail_button"
                type="primary"
                size="middle"
                onClick={() => {
                  const { searchName } = this.state;
                  changeThousandCodeParams({ username: searchName });
                }}
              >
                搜索
              </Button>
            </div>
            {isShowTimeFrame && beginTime && <div className="thousand_code_CodeDetail_statistical-range">
              <span style={{ color: 'rgba(0, 0, 0, 0.4)' }}>统计范围:&nbsp;&nbsp;</span>
              <span>{time}</span>
            </div>}
          </div>

        </div>


        {this.renderCardList()}

        <Table
          columns={this.titleColumns}
          dataSource={list}
          loading={thousandCodeLoading}
          pagination={false}
          // showHeader={false}
        />
      </div>
    );
  }

  /**
   * 渲染投入人数/新增代码行/删除代码行卡片
   */
  renderCardList = () => {
    const { thousandCodeDataSource } = this.props.relaxProps;
    // 编辑参数
    const data = [
      {
        key: 0,
        title: "投入人数",
        value: thousandCodeDataSource.get("list")?.size,
      },
      {
        key: 1,
        title: "新增代码行",
        value: thousandCodeDataSource.get("totalAdditions"),
      },
      {
        key: 2,
        title: "删除代码行",
        value: thousandCodeDataSource.get("totalDeletions"),
      },
    ];
    return (
      <div className="thousand_code_CodeDetail_cardList">
        {data?.map((v) => {
          return (
            <div className="thousand_code_CodeDetail_card" key={v.key}>
              <div className="thousand_code_CodeDetail_card_key">
                {v.title || "-"}
              </div>
              <div className="thousand_code_CodeDetail_card_value">
                {v.value || 0}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  inputInit = () => {
    this.setState({ searchName: "" });
  };
}

export default CodeDetail;
