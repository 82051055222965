import React from "react";
import { Relax } from "plume2";
import { Table, Tooltip, Pagination, Image } from "antd";
import { fromJS } from "immutable";
import { noop } from "@utils";
import first from "./../../img/first.png";
import second from "./../../img/second.png";
import third from "./../../img/third.png";
const { Column, ColumnGroup } = Table;


@Relax
class devEffectList extends React.Component<any, any> {
  static relaxProps = {
    changeSearchData: noop,
    getDevEffectRankList: noop,
    renderOwnerScore: noop,
    changeData: noop,
    mergeData: noop,
    effectListUserId: 'effectListUserId',
    devEffectRankingList: 'devEffectRankingList',
    devEffectRankingLoading: 'devEffectRankingLoading',
    isLeader: 'isLeader'
  };

  componentWillUnmount() {
    // 清空相关数据
    const { mergeData } = this.props.relaxProps
    mergeData({ effectListUserId: null, devEffectRankingList: [], devEffectRankingLoading: true })
  }

  render() {

    const { devEffectRankingList, getDevEffectRankList, renderOwnerScore, devEffectRankingLoading, isLeader } = this.props.relaxProps;
    const dataSource = devEffectRankingList?.toJS()?.records
    const total = devEffectRankingList?.toJS()?.total
    const current = devEffectRankingList?.toJS()?.current

    return (
      isLeader ? <div className="personal_ranking__effect-list">
        <div className="personal_ranking__effect-list__title">
          研发效能排行榜
        </div>
        <div className="personal_ranking__effect-list__table">
          <Table
            dataSource={dataSource}
            pagination={false}
            loading={devEffectRankingLoading}
            onRow={record => {
              return {
                onClick: () => {
                  renderOwnerScore(record)
                  this._scrollToDetail()
                }, // 点击行
              };
            }}
            rowClassName={this._setRowClassName}
          >
            <Column title="排名" dataIndex="rank" key="rank" width="4%" align="center" render={(data) => this._getRank(data) as any} />
            <Column title="姓名" dataIndex="username" key="username" width="8%" align="center" />
            <Column title="所属部门" dataIndex="deptName" key="deptName" width="12%" align="center" />
            <ColumnGroup title="编码进度" >
              <Column title="计划任务" dataIndex="planTaskNum" key="planTaskNum" width="8%" align="center" render={(data) => this._getColumnData(data, 'number')} />
              <Column title="实际完成" dataIndex="finishTaskNum" key="finishTaskNum" width="8%" align="center" render={(data) => this._getColumnData(data, 'number')} />
            </ColumnGroup>
            <ColumnGroup title="编码质量"  >
              <Column title="Sonar缺陷" dataIndex="sonarNum" key="sonarNum" width="8%" align="center" render={(data) => this._getColumnData(data, 'number')} />
            </ColumnGroup>
            <ColumnGroup title="转测质量" >
              <Column title="千行代码" dataIndex="codeLineAdditions" key="codeLineAdditions" width="7%" align="center" render={(text, data) => this._getColumnData(text, 'codeLineAdditions', data)} />
              <Column title="缺陷数" dataIndex="bugNum" key="bugNum" width="7%" align="center" render={(text, data) => this._getColumnData(text, 'bugNum', data)} />
              <Column title="参与修复" dataIndex="participationNum" key="participationNum" width="7%" align="center" render={(text, data) => this._getColumnData(text, 'participationNum', data)} />
              <Column title="修正率" dataIndex="onceFixBugRatio" key="onceFixBugRatio" width="7%" align="center" render={(data) => this._getColumnData(data, 'ratio')} />
              <Column title="缺陷密度" dataIndex="bugDensity" key="bugDensity" width="7%" align="center" render={(data) => this._getColumnData(data)} />
            </ColumnGroup>
            <Column title="总得分" dataIndex="score" key="score" width="8%" align="center" render={(data) => this._getColumnData(data, 'score')} />
          </Table>
        </div>
        <div className="personal_ranking__effect-list__pagintion" id="positon-owner-data">
          <Pagination
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={['10', '20', '50', '100']}
            defaultCurrent={1}
            total={total}
            onChange={(page, pageSize) => { getDevEffectRankList(page, pageSize, true) }}
            showTotal={(total, range) => ` 共${total}条`}
            current={current}
          />
        </div>
      </div> : null
    );
  }

  _getColumnData = (text, type?, data?) => {
    if (text == null) return '-'
    if (type == 'number') return text + '个'
    if (type == 'ratio') return text + '%'
    if (type == 'score') return <span style={{ color: '#333333', fontWeight: '500' }}>{text}</span>
    if (type == 'participationNum') {
      return <Tooltip
        title={() => {
          return (
            <>
              <div>{`参与修复缺陷总数: ${data.participationNum}个`}</div>
              <div>
                {`累计打回数: ${data.participationNum - data.onceFixBugNum}个`}
              </div>
            </>
          );
        }}
      >
        {text + '个'}
      </Tooltip>
    }
    if (type == 'bugNum') {
      return <Tooltip
        title={() => {
          return (
            <>
              <div>{`致命: ${data.fatal ? data.fatal : 0}个`}</div>
              <div>{`严重: ${data.serious ? data.serious : 0}个`}</div>
              <div>{`一般: ${data.normal ? data.normal : 0}个`}</div>
              <div>{`建议: ${data.advice ? data.advice : 0}个`}</div>
              <div>{`提示: ${data.prompt ? data.prompt : 0}个`}</div>
            </>
          );
        }}
      >
        {text + '个'}
      </Tooltip>
    }
    if (type == 'codeLineAdditions') {
      return <Tooltip
        title={() => {
          return (
            <>
              <div>{`新增行数: ${data.codeLineAdditions ? data.codeLineAdditions : 0}行`}</div>
              <div>
                {`删除行数: ${data.codeLineDeletions ? data.codeLineDeletions : 0}行`}
              </div>
            </>
          );
        }}
      >
        {text}
      </Tooltip>
    }
    return text
  }

  _setRowClassName = (record) => {
    const { effectListUserId } = this.props.relaxProps
    return record.userId == effectListUserId ? 'click-row-bgc' : ''
  }

  _getRank = (text) => {
    const images = [first, second, third];
    if (text > 0 && text < 4) {
      return (
        <div style={{ height: 20 }}>
          <Image
            src={images[+text - 1]}
            width={20}
            height={20}
            preview={false}
          />
        </div>
      );
    }
    return text;
  }

  // 页面滚动到个人得分数据
  _scrollToDetail = () => {
    const scrollDom = document.getElementById('positon-owner-data');
    scrollDom.scrollIntoView({ block: "start", behavior: "smooth" });
  }

}
export default devEffectList;
