import React from "react";
import { Relax } from "plume2";
import ProjectModal from './project-modal';
import PersonModal from './person-modal';
import Main from './main';


@Relax
export default class CalendarData extends React.Component<any, any> {
  render() {
    return (
      <div className="calendar-data-contain">
        {/* 标题 */}
        <div className="calendar-data-contain-title">资源日历</div>
        {/* 日历主体 */}
        <Main />
        {/* 部门明细弹窗 */}
        <ProjectModal />
        {/* 人员明细弹窗 */}
        <PersonModal />
      </div>
    );
  }
}
