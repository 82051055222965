import { Axios } from "@utils";

export const login = async (data) => {
  return Axios("/login", "post", data);
};

// 个人信息 - 获取请求
export const getUserInfo = () => {
  return Axios("/user-info-web/get-user-info", "get");
};

// 企业微信扫码登录后，拿到code，调用当前接口，获取当前用户信息 - 获取请求
export const get_user_info_by_auth_cod_wei_xin = (req = {}) => {
  return Axios("/get-wechat-user-info-by-code", "post", req);
};

/**
 * 查询用户的菜单信息
 * 
 */
export const fetchMenus = () => {
  return Axios("/menu-auth/menus", "get");
};

/**
 * 查询用户的功能信息
 *
 */
export const fetchFunctions = () => {
  return Axios("/menu-auth/functions", "get");
};

