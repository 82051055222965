import React from "react";
import { Relax } from "plume2";
import { DatePicker, Table, Row, Image } from "antd";
import { noop, AuthWrapper } from "@utils";
import moment from "moment";
import first from "./../../img/first.png";
import second from "./../../img/second.png";
import third from "./../../img/third.png";

/**
 * 排行列表 质量最佳Top5，缺陷修复Top5，编码得分排行，缺陷得分排行
 */
@Relax
class RankList extends React.Component<any, any> {
  static relaxProps = {
    bestQualityTopFiveData: "bestQualityTopFiveData",
    fixBugTopFiveData: "fixBugTopFiveData",
    codeScoreData: "codeScoreData",
    bugScoreData: "bugScoreData",
    changeDate: noop,
    _bugScore: noop,
    _codingScore: noop,
    begin: "begin",
    end: "end",
    bugScorePageNum: "bugScorePageNum",
    codeScorePageNum: "codeScorePageNum",
    bestQualityTopFiveLoading: "bestQualityTopFiveLoading", //获取质量最佳top5加载中
    fixBugTopFiveLoading: "fixBugTopFiveLoading", //修复缺陷top5加载中
    codeScoreLoading: "codeScoreLoading", //编码得分排行加载中
    bugScoreLoading: "bugScoreLoading", //缺陷得分排行加载中
  };

  render() {
    const {
      bestQualityTopFiveData,
      fixBugTopFiveData,
      codeScoreData,
      bugScoreData,
      bestQualityTopFiveLoading, //获取质量最佳top5加载中
      fixBugTopFiveLoading, //修复缺陷top5加载中
      codeScoreLoading, //编码得分排行加载中
      bugScoreLoading, //缺陷得分排行加载中
    } = this.props.relaxProps;
    const bestQualityTopFiveDataList = bestQualityTopFiveData?.get(
      "bestQualityTopFiveDataList"
    );
    const fixBugTopFiveDataList = fixBugTopFiveData?.get(
      "fixBugTopFiveDataList"
    );

    const defaultData = [
      {
        title: "质量最佳Top5",
        img: "qualityImg",
        dataSource: bestQualityTopFiveDataList?.toJS(),
        columns: this.getColumns("bestQualityTopFive"),
        loading: bestQualityTopFiveLoading,
        funtionName: 'f_quality_optimal_top5'
      },
      {
        title: "缺陷修复Top5",
        img: "fixBugImg",
        dataSource: fixBugTopFiveDataList?.toJS(),
        columns: this.getColumns("fixBugTopFive"),
        loading: fixBugTopFiveLoading,
        funtionName: 'f_fix_bug_top5'
      },
      {
        title: "编码得分排行",
        img: "codeRankImg",
        dataSource: codeScoreData?.toJS(),
        columns: this.getColumns("codingScores"),
        loading: codeScoreLoading,
        funtionName: 'f_code_score_rank'
      },
      {
        title: "缺陷得分排行",
        img: "issueScoreRankImg",
        dataSource: bugScoreData?.toJS(),
        columns: this.getColumns("bugScores"),
        loading: bugScoreLoading,
        funtionName: 'f_bug_score_rank'
      },
    ];
    return (
      <div className="personal_ranking__rank_list">
        {this.renderMonth()}
        <Row justify="space-between">
          {defaultData?.map((v) => {
            return (
              <React.Fragment key={Math.random()}>
                {this.renderTable(v)}
              </React.Fragment>
            );
          })}
        </Row>
      </div>
    );
  }

  /**
   * 渲染月份选择器
   */
  renderMonth = () => {
    const { changeDate } = this.props.relaxProps;
    return (
      <DatePicker
        picker="month"
        dropdownClassName="date_picker_month_dropdown"
        defaultValue={moment(moment().format("YYYY-M"))}
        onChange={(date) => {
          const begin = moment(date)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss");
          const end = moment(date).endOf("month").format("YYYY-MM-DD HH:mm:ss");
          changeDate(begin, end);
        }}
        monthCellRender={(date) => {
          if (moment().format("YYYY-M") === moment(date).format("YYYY-M")) {
            return "本月";
          }
          return `${moment(date).format("M")}月`; // 修改中文显示为数字
        }}
        style={{ width: 208, marginBottom: "4px" }}
        format={(value) =>
          moment(value).format("YYYY-M") === moment().format("YYYY-M")
            ? "本月"
            : moment(value).format("YYYY-MM")
        }
      />
    );
  };

  /**
   * 渲染表单
   */
  renderTable = (defaultData) => {
    return (
      <AuthWrapper functionName={defaultData.funtionName}>
      <div className="list_item">
        <div className="list_item__title">{defaultData.title}</div>
        <div className="list_item__item">
          <Table
            columns={defaultData?.columns}
            rowKey="rowNum"
            dataSource={defaultData?.dataSource}
            pagination={false}
            // showHeader={false}
            loading={defaultData?.loading}
            className={`${!defaultData?.dataSource?.length && "list_item__item__no_data"
              } ${defaultData?.img}`}
          />
        </div>
      </div>
      </AuthWrapper>
    );
  };

  /**
   * 获取列数据
   */
  getColumns = (type) => {
    let columns = [
      {
        title: "排名",
        dataIndex: "rowNum",
        key: "rowNum",
        align: "center",
        width: "15%",
        render: (text, record) => {
          const images = [first, second, third];
          if (text > 0 && text < 4) {
            return (
              <div style={{ height: 20 }}>
                <Image
                  src={images[+text - 1]}
                  width={20}
                  height={20}
                  preview={false}
                />
              </div>
            );
          }
          return this.getMore(text, record?.myself);
        },
      },
      {
        title: "姓名",
        dataIndex: type === "bugScores" ? "fixUser" : "owner",
        key: type === "bugScores" ? "fixUser" : "owner",
        align: "center",
        width: "20%",
        render: (text, record) => this.getMore(text, record?.myself, "owner"),
      },
    ] as any;
    //质量最佳TOP5
    if (type === "bestQualityTopFive") {
      columns = columns.concat([
        {
          title: "缺陷总数",
          dataIndex: "bugNum",
          key: "bugNum",
          align: "center",
          width: "20%",
        },
        {
          title: "加权缺陷数",
          key: "bugWeight",
          dataIndex: "bugWeight",
          align: "center",
          width: "20%",
          render: (text) => this.getMore(`${text || 0}`),
        },
        {
          title: "缺陷密度",
          key: "bugDensity",
          dataIndex: "bugDensity",
          align: "center",
          width: "20%",
        },
      ]);
    }

    //缺陷修复TOP5
    if (type === "fixBugTopFive") {
      columns = columns.concat([
        {
          title: "修复缺陷总数",
          dataIndex: "fixBugNum",
          key: "fixBugNum",
          align: "center",
          width: "25%",
        },
        {
          title: "修复成功率",
          key: "onceFixBugRatio",
          dataIndex: "onceFixBugRatio",
          align: "center",
          width: "25%",
          render: (text) => this.getMore((text || 0) + "%"),
        },
      ]);
    }

    //编码得分排行
    if (type === "codingScores") {
      columns = columns.concat([
        {
          title: "进度得分",
          dataIndex: "progressScore",
          key: "progressScore",
          render: (text, record) =>
            this.getMore(text, record?.myself, "progressScore"),
          align: "center",
          width: "20%",
        },
        {
          title: "质量得分",
          key: "qualityScore",
          dataIndex: "qualityScore",
          render: (text, record) => this.getMore(text, record?.myself),
          align: "center",
          width: "20%",
        },
        {
          title: "总得分", //编码得分分进度得分与质量得分两个纬度，各占50%权重
          key: "totalScore",
          dataIndex: "totalScore",
          align: "center",
          width: "20%",
          render: (text, record) => {
            return this.getMore(text, record?.myself);
          },
        },
      ]);
    }

    //缺陷得分排行榜
    if (type === "bugScores") {
      columns = columns.concat([
        {
          title: "缺陷密度得分",
          dataIndex: "bugDensityScore",
          key: "bugDensityScore",
          render: (text, record) =>
            this.getMore(text, record?.myself, "bugDensityScore"),
          align: "center",
          width: "25%",
        },
        {
          title: "修复质量得分",
          key: "fixBugScore",
          dataIndex: "fixBugScore",
          render: (text, record) => this.getMore(text, record?.myself),
          align: "center",
          width: "25%",
        },
        {
          title: "总得分", //缺陷得分分缺陷密度得分与修复质量得分两个纬度，各占50%权重
          key: "totalScore",
          dataIndex: "totalScore",
          align: "center",
          width: "15%",
          render: (text, record) => {
            return this.getMore(text, record?.myself);
          },
        },
      ]);
    }

    return columns;
  };

  /**
   * 查看更多样式
   */
  getMore = (text: number | string, myself?: boolean, type = "") => {
    const {
      _bugScore,
      _codingScore,
      begin,
      end,
      bugScorePageNum,
      codeScorePageNum,
    } = this.props.relaxProps;
    if (type && type !== "owner" && text === -1) {
      return (
        <div
          className="personal_ranking__rank_list__get_more"
          onClick={() =>
            type === "bugDensityScore"
              ? _bugScore({
                  begin: begin,
                  end: end,
                  pageNum: bugScorePageNum + 1,
                })
              : _codingScore({
                  begin: begin,
                  end: end,
                  pageNum: codeScorePageNum + 1,
                })
          }
        >
          查看更多
        </div>
      );
    }

    //编辑展示内容
    let value = text || "0";
    if (text === -1) {
      value = "...";
    }
    if (type === "owner") {
      value = value || "-";
    }

    return (
      <div style={myself ? { color: "#FF6600" } : {}}>
        {value}
        {type === "owner" && myself && <div>(我)</div>}
      </div>
    );
  };
}
export default RankList;
