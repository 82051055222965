// 首页
import Home from "./../pages/home";
// 修改密码
import ChangePassword from "./../pages/change-password";
// 效能度量
import EffectivenessMeasurement from "../pages/effectiveness-measurement";
// 质量保证
import QualityAssurance from "../pages/quality-assurance";
//运营数据
import OperationalData from "../pages/operational-data";


// 主路由
//noLogin: true 不需要登录
export const MAIN_ROUTERS = [
  {
    path: "/home",
    exact: true,
    component: Home,
  },
  {
    path: "/effectiveness-measurement",
    exact: true,
    component: EffectivenessMeasurement,
  },
  {
    path: "/release-pipeline",
    exact: true,
    component: null,
  },
  {
    path: "/quality-assurance",
    exact: true,
    component: QualityAssurance,
  },
  {
    path: "/performance-appraisal",
    exact: true,
    component: null,
  },
  {
    path: "/operational-data",
    exact: true,
    component: OperationalData,
  },
  {
    path: "/change-password",
    exact: true,
    component: ChangePassword,
    noLogin: true,
  },
];
