export const DownOutlined = () => (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>下拉箭头</title>
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="个人信息01" transform="translate(-1586.000000, -23.000000)" fill="#C9C9C9">
                <g id="编组-20" transform="translate(1449.000000, 10.000000)">
                    <g id="下拉箭头" transform="translate(137.000000, 13.000000)">
                        <path d="M5.81066017,7 L9.78033009,2.46323438 C10.0732233,2.12849928 10.0732233,1.58578644 9.78033009,1.25105133 C9.48743687,0.916316223 9.01256313,0.916316223 8.71966991,1.25105133 L4.21966991,6.39390847 C3.9267767,6.72864358 3.9267767,7.27135642 4.21966991,7.60609153 L8.71966991,12.7489487 C9.01256313,13.0836838 9.48743687,13.0836838 9.78033009,12.7489487 C10.0732233,12.4142136 10.0732233,11.8715007 9.78033009,11.5367656 L5.81066017,7 Z" transform="translate(7.000000, 7.000000) scale(-1, 1) rotate(-90.000000) translate(-7.000000, -7.000000) "></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const RightOutlined = () => (
    <svg width="6px" height="12px" viewBox="0 0 6 12" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Path 2</title>
        <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="个人信息01" transform="translate(-1414.000000, -847.000000)" fill="#C9C9C9">
                <g id="编组-6备份" transform="translate(1140.000000, 781.000000)">
                    <g id="编组-10" transform="translate(0.000000, 50.000000)">
                        <g id="编组" transform="translate(274.000000, 16.000000)">
                            <path d="M1.81066017,6 L5.78033009,1.46323438 C6.0732233,1.12849928 6.0732233,0.585786438 5.78033009,0.25105133 C5.48743687,-0.0836837768 5.01256313,-0.0836837768 4.71966991,0.25105133 L0.219669914,5.39390847 C-0.0732233047,5.72864358 -0.0732233047,6.27135642 0.219669914,6.60609153 L4.71966991,11.7489487 C5.01256313,12.0836838 5.48743687,12.0836838 5.78033009,11.7489487 C6.0732233,11.4142136 6.0732233,10.8715007 5.78033009,10.5367656 L1.81066017,6 Z" id="Path" transform="translate(3.000000, 6.000000) scale(-1, 1) translate(-3.000000, -6.000000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);