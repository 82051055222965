import {Axios} from '@utils';

// 个人信息 - 获取请求
export const getUserInfo = () => {
    return Axios('/user-info-web/get-user-info', 'get');
};

// 更新用户头像 - 获取请求
export const updateUserHeadImg = (req = {}) => {
    return Axios('/user-info-web/update-user-head-img', 'post', req);
};