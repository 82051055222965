import React from "react";
import { Relax } from "plume2";
import { noop, AuthWrapper, cache } from "@utils";
import moment from "moment";
import { Select, DatePicker, Button, TreeSelect } from 'antd';
import {fromJS} from "immutable";
const { Option } = Select;
const { TreeNode } = TreeSelect;


/**
 * 查询条件
 */
@Relax
export default class SearchGroup extends React.Component<any, any> {
  static relaxProps = {
    search: noop,
    changeData: noop,
    selectYear: 'selectYear',
    selectMonth: 'selectMonth',
    belongDepartment: 'belongDepartment',
    projectModlDepartmentId: 'projectModlDepartmentId',
    departmentId: 'departmentId',
    realtimeEffectConditions: 'realtimeEffectConditions',
    realtimeEffectConditionsData: 'realtimeEffectConditionsData',
    belongDepartmentList: 'belongDepartmentList',
    isLeader: 'isLeader',
    userId: 'userId',
    departmentUserList: 'departmentUserList',
    departmentUserInfo: 'departmentUserInfo'
  };

  render() {
    const { search, departmentId, belongDepartmentList, isLeader, userId, departmentUserList } = this.props.relaxProps;
    const LOGIN_FUNCTIONS = JSON.parse(localStorage.getItem?.(cache.LOGIN_FUNCTIONS));
    const isShow = (isLeader || LOGIN_FUNCTIONS?.includes?.('f_resource_calendar_data_department_tree')) ? true : false

    return (
      <div className="search-group-contain">

        {/* 年份 */}
        <DatePicker
          style={{ width: 208, marginRight: 10, borderRadius: 4 }}
          picker="year"
          defaultValue={moment(moment().format("YYYY"))}
          format={(value) => `${moment(value).format("YYYY")}年`}
          onChange={(data) => {
            const selectYear = moment(data).format("YYYY")
            this._handleChange('selectYear', selectYear)
          }}
          allowClear={false}
        />

        {/* 月份 */}
        <DatePicker
          style={{ width: 208, borderRadius: 4 }}
          picker="month"
          dropdownClassName="resource-calendar-select-month"
          defaultValue={moment(moment().format("YYYY-M"))}
          onChange={(data) => {
            const selectMonth = moment(data).format("M")
            this._handleChange('selectMonth', selectMonth)
          }}
          format={(value) => `${moment(value).format("M")}月`}
          allowClear={false}
        />

        {/* 部门 */}
        {isShow && <AuthWrapper functionName="f_resource_calendar_data_department_tree">
          <TreeSelect
            showSearch
            style={{ width: 208, marginLeft: 10, marginRight: 10}}
            placeholder="请选择所属部门"
            onChange={(value, label) => {
              this._handleChange('departmentId', value)
              this._handleChange('departmentName', label?.[0])
            }}
            filterTreeNode={(input, treeNode) =>
              treeNode.props.title
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            defaultValue={departmentId}
            value={departmentId}
            treeDefaultExpandedKeys={[departmentId?.toString()]}
            dropdownClassName="department-select-dropdown"
          >
            {this._loop(belongDepartmentList?.toJS())}
          </TreeSelect>
            <Select
                showSearch
                placeholder="请选择员工"
                style={{ width: 208, marginRight: 10 }}
                defaultValue={null}
                onChange={(value, option) => {
                    this._handleChange('userId', value)
                    this._handleChange('departmentUserInfo', fromJS(option))
                }}
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                    return typeof option.props.children == 'string'
                        ? option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                        : true;
                }}
                value={userId}
                dropdownClassName="user-select-dropdown"
            >
                <Option value={null}>全部</Option>
                {departmentUserList?.toJS()?.map?.(item => <Option value={item.value}>{item.label}</Option>)}
            </Select>
        </AuthWrapper>}
        <Button
          className="search_group_button"
          type="primary"
          onClick={() => search(true)}
          style={{ marginLeft: '10px' }}
        >
          搜索
        </Button>
      </div>
    );
  }

  // 处理所属部门的树形结构数据
  _loop = (data) =>
    data.map((item) => {
      if (item?.children?.length > 0) {
        return (
          <TreeNode
            key={item.id}
            value={item.id}
            title={item.name}
          >
            {this._loop(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          value={item.id}
          title={item.name}
        />
      );
    });

  _handleChange = (type, value) => {
    let { changeData } = this.props.relaxProps;
    changeData(type, value);
  }
}
