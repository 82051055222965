import React from "react";
import { Relax } from "plume2";
import { Image, Tooltip } from "antd";
import { noop } from "@utils";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import TooltipImg from "../img/tooltip.svg";
import TooltipCheckedImg from "../img/tooltip_checked.svg";

/**
 * 趋势图 新增客户数，累计客户数，活跃客户数，沉默客户数
 */
@Relax
class LineCharts extends React.Component<any, any> {
  static relaxProps = {
    selectType: "selectType",
    customerIncreaseData: "customerIncreaseData",
    customerOverviewData: "customerOverviewData",
    lineChartsData: "lineChartsData",
    changeRange: noop,
    tabType: "tabType",
    changeTag: noop,
  };

  render() {
    return (
      <div className="skyeye_pc_operational_data__line_charts">
        {/** 渲染顶部标签 */}
        {this.renderTags()}

        {/** 渲染趋势图 */}
        {this.renderCharts()}
      </div>
    );
  }

  /**
   * 渲染顶部标签
   */
  renderTags = () => {
    const {
      selectType,
      customerIncreaseData,
      customerOverviewData,
      changeRange,
      tabType,
      changeTag,
    } = this.props.relaxProps;
    //标签数据编辑
    const tags = [
      {
        title: "新增客户数",
        data: customerIncreaseData?.get("increaseNum") || 0,
        isAdded: customerIncreaseData?.get("addedNum") > -1, // true: 增加 false: 减少
        count: Math.abs(customerIncreaseData?.get("addedNum") || 0), //增减数量
        key: "0",
        tip: "筛选时间内，新签约成功的客户数量",
      },
      {
        title: "累计客户数",
        data: customerOverviewData?.get("totalNum") || 0,
        key: "1",
        tip: "签约成功的客户数量总和",
      },
      {
        title: "活跃客户数",
        data: customerOverviewData?.get("activeNum") || 0,
        key: "2",
        tip: "近30日内登录系统成功的客户总和数",
      },
      {
        title: "沉默客户数",
        data: customerOverviewData?.get("silenceNum") || 0,
        key: "3",
        tip: "近30日内未登录过系统的客户总和数",
      },
    ];

    const rangePickerData = ["今日", "本周", "本月", "全年", "自定义"];

    let text = "";
    if (selectType === "0") {
      text = "比前一天";
    } else if (selectType === "1") {
      text = "比前一周";
    } else if (selectType === "2") {
      text = "比前一月";
    } else if (selectType === "3") {
      text = "比前一年";
    }

    return (
      <div className="skyeye_pc_operational_data__line_charts__tags">
        {tags.map((tag) => {
          //是否是当前选中的标签
          const isCurrent = tabType === tag.key;
          //是否是新增客户数
          const isFirst = "isAdded" in tag && "count" in tag;
          return (
            <div
              className={`skyeye_pc_operational_data__line_charts__tags__tag ${
                isCurrent &&
                "skyeye_pc_operational_data__line_charts__tags__tag_checked"
              }`}
              key={tag.key}
              onClick={() => {
                if (isCurrent) {
                  return;
                }
                changeTag(tag.key);
              }}
            >
              <div className="skyeye_pc_operational_data__line_charts__tags__tag_title">
                {tag.title}
                <Tooltip title={tag.tip}
                  overlayClassName='skyeye_pc_operational_data__line_charts__tags__tag_title--tooltip'
                >
                  <img src={isCurrent ? TooltipCheckedImg : TooltipImg} alt="icon" />
                </Tooltip>
              </div>
              <div className="skyeye_pc_operational_data__line_charts__tags__tag_main_data">
                {tag.data?.toLocaleString()}
                {isFirst && selectType !== "4" && (
                  <span className="main_data_label">
                    {text}
                    <span
                      className={`main_data_label_title main_data_label_title_${
                        tag?.isAdded ? "red" : "green"
                      }`}
                    >
                      {tag.count}
                    </span>
                    {tag?.isAdded ? (
                      <span className="main_data_label_triangle_red" />
                    ) : (
                      <span className="main_data_label_triangle_green" />
                    )}
                  </span>
                )}
              </div>
              <div className="skyeye_pc_operational_data__line_charts__tags__tag_bottom">
                {isFirst ? (
                  <div className="range_picker">
                    {rangePickerData.map((v, index) => {
                      return (
                        <span
                          className={`${
                            selectType === `${index}` && "checked"
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            changeRange(`${index}`);
                          }}
                          key={Math.random()}
                        >
                          {v}
                        </span>
                      );
                    })}
                  </div>
                ) : (
                  isCurrent && <div className="date">仅展示近30日</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * 渲染趋势图
   */
  renderCharts = () => {
    const { lineChartsData, tabType, selectType } = this.props.relaxProps;
    const increaseNum = []; //新增客户数据
    const activeNum = []; //活跃客户数据
    const silenceNum = []; //沉默客户数据
    const totalNum = []; //累计客户数据
    const date = []; //x轴日期数据

    //编辑数据
    lineChartsData?.forEach((v, index) => {
      increaseNum.push(v?.get("increaseNum"));
      silenceNum.push(v?.get("silenceNum"));
      totalNum.push(v?.get("totalNum"));
      activeNum.push(v?.get("activeNum"));
      // 日期最后一条替换为今日
      date.push(
        index === lineChartsData.size - 1 && selectType !== "4"
          ? "今日"
          : v?.get("date")
      );
    });
    const chartData = [
      {
        title: "新增客户",
        data: increaseNum,
      },
      {
        title: "累计客户",
        data: totalNum,
      },
      {
        title: "活跃客户",
        data: activeNum,
      },
      {
        title: "沉默客户",
        data: silenceNum,
      },
    ];
    //编辑图表
    const option = {
      color: ["#1890FF"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
          },
        },
        formatter: (params) => {
          let str =
            '<span style="font-size: 10px;font-weight: 400;color: #000000;line-height: 14px;">' +
            params[0].name +
            "</span>" +
            "<br />";
          params.forEach((item) => {
            str +=
              '<div style="font-size: 10px;font-weight: 400;color: #666666;display: flex;justify-content: space-between;align-items: center;min-width: 160px">' +
              '<span style="display: flex;align-items: center;">' +
              '<div style="line-height: 10px;margin-right:6px;border-radius:50%;width:4px;height:4px;background-color:' +
              item.color +
              '">' +
              "</div>" +
              " <span>" +
              item.seriesName +
              "</span>" +
              "</span>" +
              "<span>" +
              item.data +
              "</span>" +
              "</div>";
          });
          return str;
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: false, //不展示保存为图片
          },
        },
      },
      grid: {
        left: "0%",
        right: "1%",
        top: "10%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: date,
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: `${chartData[tabType].title}数`,
          type: "line",
          areaStyle: {
            // 颜色自上而下渐变
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                // 1代表上面
                offset: 0,
                color: "#1890FF",
              },
              {
                offset: 1,
                color: "#fff",
              },
            ]),
            opacity: 0.2, // 填充区域透明度
          },
          emphasis: {
            focus: "series",
          },
          data: chartData[tabType].data,
        },
      ],
    };
    return (
      <div className="skyeye_pc_operational_data__line_charts_chart">
        <div className="skyeye_pc_operational_data__line_charts_chart__title">
          {chartData[tabType].title}趋势图
        </div>
        <div className="personal_ranking__line_chart__chart">
          <ReactEcharts
            option={option}
            notMerge={true}
            lazyUpdate={true}
            style={{ width: "100%", minHeight: "300px" }}
          />
        </div>
      </div>
    );
  };
}
export default LineCharts;
