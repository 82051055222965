import React from "react";
import {Relax} from "plume2";
import {List, Radio} from 'antd';
import {noop} from '@utils';
import ModuleList from './module-list'
import BarChart from './bar-chart';


@Relax
class TrendChart extends React.Component<any, any> {
  static relaxProps = {
    newFixScoreType: 'newFixScoreType',
    customNewFixScoreType: 'customNewFixScoreType',
    tabType: 'tabType',
    mergeData: noop,
    getNewFixList: noop,
    getIsEmpty: noop,
    newFixLineData: 'newFixLineData',
    forNewPage: 'forNewPage',
    forFixPage: 'forFixPage',
  };

  render() {
    const {
      newFixScoreType,
      tabType,
      newFixLineData,
      forNewPage,
      forFixPage,
      customNewFixScoreType
    } = this.props.relaxProps;
    if(tabType !== 1){
      return null;
    }
    const chartFlag = newFixLineData && newFixLineData?.toJS()?.length > 0;
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType;
    let listFlag = tab == 1 && forNewPage && forNewPage?.toJS()?.ipage?.records?.length > 0;
     listFlag = listFlag || tab == 2 && forFixPage && forFixPage?.toJS().ipage?.records?.length > 0;
    return (
      <div className="module-chart" >
        <div className="head">
          <div className="title" style={{marginBottom: "10px"}}>新增与修复趋势</div>
        </div>
        {
          chartFlag ? <>
            <BarChart/>
          </> : <div className="empty-data"><List /></div>
        }
        <div className="head">
          <div className="title">{this._getTitle()}</div>
          <Radio.Group
              options={[
                { label: "按新增趋势", value: 1 },
                { label: "按修复趋势", value: 2 },
              ]}
              onChange={(e) => {
                this._changeScoreType(e.target.value)
              }}
              value={tab}
              defaultValue={0}
              optionType="button"
              buttonStyle="solid"
          />
        </div>
        {
          listFlag ? <>
            <ModuleList />
          </> : <div className="empty-data"><List /></div>
        }
      </div>
    );
  }

  _getTitle = () => {
    const {
      newFixScoreType,
      customNewFixScoreType,
      tabType
    } = this.props.relaxProps;
    const tab = tabType == 1 ? newFixScoreType : customNewFixScoreType
    if (tab == 1) {
      return '按新增趋势'
    } else if (tab == 2) {
      return '按修复趋势'
    }
  }

  _changeScoreType = (value) => {
    const { mergeData, getNewFixList, tabType } = this.props.relaxProps;
    if (tabType == 1) {
      mergeData({
        newFixScoreType: value
      })
    } else {
      mergeData({
        customNewFixScoreType: value
      })
    }
    mergeData({
      forNewPage: {}, // 按新增列表
      forFixPage: {}, // 按修复列表
    })
    getNewFixList()
  }



}
export default TrendChart;
