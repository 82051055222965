import React from "react";
import { getQueryVariable } from "@utils";
import { StoreProvider } from "plume2";
import AppStore from "./store";
import {
  SkyeyePcLogo,
  SkyeyePcCopyright,
  SkyeyePcWelcomePage,
} from "@components";
// 二维码 - icon
import code_png from "./img/code.png";
// 账号登录 - icon
import account_png from "./img/account.png";
// 账号登录 - 表单组件
import LoginForm from "./components/login_form";
// 二维码登录 - 扫码组件
import ScanAndLogin from "./components/scan_and_login";
import "./index.less";

// 登录（账号登录 or 扫码登录）
@StoreProvider(AppStore)
class Login extends React.Component<any, any> {
  store: AppStore;

  componentDidMount() {
    const { code } = getQueryVariable("code");
    if (code) {
      this.store.mergeData({ loginType: "code_QR" });
    }
  }

  // 渲染（账号登录 or 扫码登录）下的表单组件
  _renderLoginForm = (loginType) => {
    if (loginType === "account") return <LoginForm {...this.props} />;
    if (loginType === "code_QR") return <ScanAndLogin {...this.props} />;
  };

  // 获取表单右上角icon
  _renderRightTopIcon = (loginType) => {
    if (loginType === "account") return code_png;
    if (loginType === "code_QR") return account_png;
  };

  render() {
    const loginType = this.store.get("loginType");
    return (
      <div className="skyeye_pc_login">
        {/* 数字化平台logo */}
        <SkyeyePcLogo />

        {/* 核心登录表单 */}
        <SkyeyePcWelcomePage>
          <div className="skyeye_pc_login__content">
            <img
              src={this._renderRightTopIcon(loginType)}
              alt="二维码"
              onClick={this.store._changeLoginType}
            />
            {this._renderLoginForm(loginType)}
          </div>
        </SkyeyePcWelcomePage>

        {/* 数字化平台 - 底部版权 */}
        <SkyeyePcCopyright />
      </div>
    );
  }
}
export default Login;
