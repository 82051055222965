import Axios from "./axios";
import config from "./.config.json";
import { cache } from "@utils";
export const LOGIN_DATA = "skyeye-pc@login";

// 接口url前缀
export const baseURL = config.HOST;

// 请求成功cdoe
export const SUCCESS_CODE = "S-000000";

// 获取url参数
export const getQueryVariable = (key, search = window.location.href) => {
  const query = search.split("?")[1];
  const arr = query ? query.split("&") : [];
  const params = {};

  for (let i = 0; i < arr.length; i++) {
    const _result = arr[i].split("=") || [];
    // 单key
    if (!Array.isArray(key)) {
      if (_result[0] === key) {
        params[key] = _result[1];
      }
    } else {
      // 数组key
      key.forEach((item) => {
        if (_result[0] === item) {
          params[item] = _result[1];
        }
      });
    }
  }
  return params;
};

//退出登陆
export const loginout = () => {
  Axios("/logout", "get")
    .then((data) => {
      //清空缓存
      localStorage.clear();
      localStorage.removeItem(LOGIN_DATA);
      localStorage.removeItem(cache.LOGIN_FUNCTIONS);
      localStorage.removeItem(cache.LOGIN_MENUS);
      localStorage.removeItem(cache.LOGIN_URL);
      //跳转页面
      window.location.replace('/');
    })
    .catch((err) => {
      console.error(err);
    });
};

// 转base64
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

/**
 * 版权相关
 */
export const copyright = {
  licence: "苏B2-20200641", //增值电信业务经营许可证
  software_product_number: "苏 RC-2018-A0593", //软件产品编号
  certificate: "016ZB20I20636R1M", //ISO27001证书
  software_enterprise_number: "苏RQ-2017-A0060", //软件企业编号
  high_and_new_tech_enterprises_number: "GR201832006384", //高新技术企业编号
  start_year: "2016",
  end_year: "2023",
  company: "南京万米信息技术有限公司",
  put_on_record_number: "苏ICP备16060232号-1", //备案号
};


// 获取一级菜单的数据
export const firstMenusData = () => {
  let _data = [];
  let login_menus = localStorage.getItem(cache.LOGIN_MENUS);
  if (login_menus?.includes?.("/home")) {
    _data.push({
      title: "首页",
      url: "/home",
    });
  }

  if (login_menus?.includes?.("/effectiveness-measurement")) {
    _data.push({
      title: "效能度量",
      url: "/effectiveness-measurement",
    });
  }

  if (login_menus?.includes?.("/quality-assurance")) {
    _data.push({
      title: "质量保证",
      url: "/quality-assurance",
    });
  }

  if (login_menus?.includes?.("/operational-data")) {
    _data.push({
      title: "运营数据",
      url: "/operational-data",
    });
  }

  if (login_menus?.includes?.("/release-pipeline")) {
    _data.push({
      title: "发布流水线",
      url: "/release-pipeline",
    });
  }

  if (login_menus?.includes?.("/performance-appraisal")) {
    _data.push({
      title: "绩效考核",
      url: "/performance-appraisal",
    });
  }
  return _data;
};;

// 获取二级菜单的数据
export const secondMenusData = (text) => {
  let login_menus = localStorage.getItem(cache.LOGIN_MENUS);
  let secondMenusData =
    "项目列表,质量考核,缺陷修复,个人排行榜,项目排行榜,千行代码,资源日历,实时人效,缺陷分析";
  if (secondMenusData?.includes?.(text)) {
    if (login_menus?.includes?.(text)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
  
};