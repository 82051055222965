import { copyright } from "@utils";
import "./index.less";

/**
 * 数字化平台 - 底部版权
 */
const SkyeyePcCopyright = () => {
  const {
    licence,
    software_product_number,
    certificate,
    software_enterprise_number,
    high_and_new_tech_enterprises_number,
    start_year,
    end_year,
    company,
    put_on_record_number,
  } = copyright;
  return (
    <ul className="skyeye_pc_copyright">
      <li>{`增值电信业务经营许可证：${licence}`}</li>
      <li>{`软件产品编号：${software_product_number} ISO27001证书：${certificate} 软件企业编号：${software_enterprise_number} 高新技术企业编号：${high_and_new_tech_enterprises_number}`}</li>
      <li>{`©Copyright ${start_year}-${end_year} ${company} 版权所有 | ${put_on_record_number}`}</li>
    </ul>
  );
};

export default SkyeyePcCopyright;
