import React from 'react';
import { Relax } from 'plume2';
import { noop, AuthWrapper, checkAuth } from '@utils';
import { Table, Pagination } from 'antd';
import onLine from '../../img/online.png';
import { IterationStatusText } from '@components';

@Relax
export default class Demo extends React.Component<any, any> {
  static relaxProps = {
    realtimeEffectConditions: 'realtimeEffectConditions',
    realtimeEffectDataSource: 'realtimeEffectDataSource',
    realtimeEffectTableType: 'realtimeEffectTableType',
    realtimeEffectLoading: 'realtimeEffectLoading',
    realtime_effect_projectType: 'realtime_effect_projectType',
    changePage: noop,
    changeData: noop,
    showDrawerModal: noop,
  };

  render() {
    const {
      realtimeEffectConditions,
      realtimeEffectDataSource,
      realtimeEffectTableType,
      changePage,
      realtimeEffectLoading,
    } = this.props.relaxProps;
    return (
      <div className="effect_table">
        <table className="effect_table_title">
          <thead>
            <tr>
              {this.getColumns().map((item, index) => {
                return (
                  <th
                    key={Math.random()}
                    className="effect_table_title_th"
                    style={{
                      width: item.width || '',
                      textAlign: index ? 'center' : 'left',
                      padding: item.padding || '',
                    }}
                  >
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
        </table>
        <Table
          rowKey={'id'}
          columns={this.getColumns()}
          dataSource={realtimeEffectDataSource.get('records')?.toJS()}
          pagination={false}
          showHeader={false}
          loading={realtimeEffectLoading}
        />
        <div className="effect_table_pagination">
          <Pagination
            current={realtimeEffectConditions.get('pageNum') || 1}
            total={realtimeEffectDataSource.get('total')}
            pageSize={realtimeEffectConditions.get('pageSize') || 10}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `共 ${total} 条`}
            onChange={(pageNum, pageSize) => {
              changePage(pageNum, pageSize);
            }}
          />
        </div>
      </div>
    );
  }

  getColumns = (): any => {
      const { showDrawerModal, realtime_effect_projectType, } = this.props.relaxProps;

    const columns: any = [
      {
        title: '立项预算',
        align: 'center',
        width: '10%',
        dataIndex: 'budget',
        key: 'budget',
        render: (text) => (text !== null ? text + '人天' : '-'),
      },
      {
        title: '计划投入',
        align: 'center',
        width: '10%',
        dataIndex: 'planEffort',
        key: 'planHumaneEffect',
        render: (text) => (text !== null ? text + '人天' : '-'),
      },
      {
        title: '计划人效',
        align: 'center',
        width: '10%',
        dataIndex: 'planHumaneEffect',
        key: 'planEffort',
        render: (text) => (text !== null ? `${text}%` : '-'),
      },
      {
        title: '当前预计消耗',
        align: 'center',
        width: '14%',
        dataIndex: 'currentConsume',
        key: 'currentConsume',
        render: (text) => (text !== null ? text + '人天' : '-'),
      },
      {
        title: '实际消耗',
        align: 'center',
        width: '12%',
        dataIndex: 'actualConsume',
        key: 'actualConsume',
        render: (text) => (text !== null ? text + '人天' : '-'),
      },
      {
        title: '实际人效',
        align: 'center',
        width: '10%',
        dataIndex: 'actualHumaneEffect',
        key: 'actualHumaneEffect',
        render: (text) => (text !== null ? `${text}%` : '-'),
      },
      {
        title: '操作',
        align: 'center',
        width: '14%',
        dataIndex: 'options',
        key: 'options',
        render: (_, rowInfo) =>
          checkAuth('f_humane_effect_detail') ? (
            <a onClick={() => showDrawerModal(rowInfo)}>投入明细</a>
          ) : (
            '-'
          ),
      },
    ];
    const titleColumn = {
      title: '项目/迭代名称',
      dataIndex: 'name',
      width: '25%',
      key: 'name',
      render: (text, data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{marginRight:5}}>
            <IterationStatusText
                projectType={ data.projectNodeFlag ? 1 : 0}
                status={ data.status }
                placeholder={ null }
            />
          </div>
          <span
              className='hover-cls'
              onClick={() => {
                window.open("/quality-assurance?pageType=0");
                this.cacheSearchParams("project-list", data.projectNodeFlag ? data.id : data.projectId, data.projectNodeFlag ? null : data.id);
              }}
          >{text}</span>
        </div>
      ),
    };
    const newColumn = [titleColumn, ...columns];
    return newColumn;
  };

  /**
   * 缓存 - 搜索条件
   * @param key
   * @returns
   */
  cacheSearchParams = (key, projectId, iterationId) => {
    if(!key) return;
    const {realtimeEffectConditions } = this.props.relaxProps || {}
    const projectType = realtimeEffectConditions?.toJS?.()?.projectType;
    const belongBaseline = realtimeEffectConditions?.toJS?.()?.belongBaseline;
    const startYear = realtimeEffectConditions?.toJS?.()?.startYear;
    const params = {
      projectType: projectType,
      belongBaseline: belongBaseline,
      iterationId: projectType == 1 ? null : iterationId,
      projectId: projectId,
      startYear: startYear,
      activeTabKey: iterationId,
    };

    localStorage.setItem(`${key}-searchParams`, JSON.stringify(params));
  }
}
