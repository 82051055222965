import React from "react";
import Platform from "./components/platform";
import MustReadForNewPeople from "./components/must_read_for_new_people";
import GitCalendarHeatmap from "./components/git_calendar_heatmap";
import Rank from "./components/rank";
import { AuthWrapper } from '@utils'
import "./index.less";

// 首页右侧边栏
export default class Sider extends React.Component<any, any> {
  render() {
    return (
      <div className="skyeye_pc_home_sider">
        {/** 本月排名 月花费工时 */}
        <AuthWrapper functionName="f_year_rank">
          <Rank />
        </AuthWrapper>

        {/* 其他平台入口(如：gitLab、wiki、tapd等) */}
        <AuthWrapper functionName="f_quick_navigate">
          <Platform />
        </AuthWrapper>

        {/* git日历热力图 */}
        <AuthWrapper functionName="f_git_events">
          <GitCalendarHeatmap />
        </AuthWrapper>

        {/* 新人必读 */}
        <AuthWrapper functionName="f_newcomer_required_read">
          <MustReadForNewPeople />
        </AuthWrapper>
      </div>
    );
  }
}
