import React, { Fragment } from "react";
import { Relax } from "plume2";
import { noop, AuthWrapper, OneAuthWrapper } from "@utils";
import TreeControl from "./tree_control";
import CodeDetail from "./code_detail";
import { DatePicker, Tabs } from "antd";
import moment from "moment";
const { TabPane } = Tabs;

/**
 * 千行代码 - 组件
 */
@Relax
export default class ThousandCode extends React.Component<any, any> {
  static relaxProps = {
    thousandCodeProjectType: "thousandCodeProjectType",
    startYear: "startYear",
    mergeData: noop,
    thousandCodeInit: noop,
  };

  $Child;

  inputInit = () => {
    this.$Child.inputInit();
  };

  componentDidMount() {
    const { thousandCodeInit } = this.props.relaxProps
    thousandCodeInit()
  }

  render() {
    const { thousandCodeProjectType, startYear, mergeData } =
      this.props.relaxProps;

    return (
      <Fragment>
        <AuthWrapper functionName="f_code_line_project_tree">
          <div className="thousand_code-date">
            <DatePicker
              picker="year"
              defaultValue={moment(startYear)}
              onChange={(date) => {
                mergeData({ startYear: moment(date).format("YYYY") });
                this._setActiveKey(thousandCodeProjectType.toString());
              }}
              className="thousand_code-date_picker"
            />
          </div>
        </AuthWrapper>

        <OneAuthWrapper functionName="f_code_line_project_tree,f_code_line_query">
          <div className="thousand_code">
            <AuthWrapper functionName="f_code_line_project_tree">
              <Tabs
                defaultActiveKey="1"
                activeKey={thousandCodeProjectType.toString()}
                onChange={(e) => this._setActiveKey(e)}
              >
                <TabPane tab="基线产品" key="0" />
                <TabPane tab="定制项目" key="1" />
              </Tabs>
            </AuthWrapper>

            <div style={{ display: "flex", paddingLeft: 20, paddingRight: 20 }}>
              <AuthWrapper functionName="f_code_line_project_tree">
                <TreeControl />
              </AuthWrapper>
              <AuthWrapper functionName="f_code_line_query">
                <CodeDetail onRef={(ref) => (this.$Child = ref)} />
              </AuthWrapper>
            </div>
          </div>
        </OneAuthWrapper>

      </Fragment>
    );
  }

  _setActiveKey = (key: string) => {
    let { mergeData, thousandCodeInit } = this.props.relaxProps;
    mergeData({
      thousandCodeProjectType: Number(key),
      thousandCodeParams: {},
      selectedKeys: [],
      thousandCodeDataSource: [],
      timeFrame: {}
    });
    this.inputInit();
    thousandCodeInit();
  };
}
