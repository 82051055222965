import React from "react";
import { Relax } from "plume2";
import moment from "moment";
import { Select, Form, Button, DatePicker, } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { OneAuthWrapper, AuthWrapper, noop } from "@utils";
import { IActor } from "../../type";
import AppStore from "../../store";
import './index.less';

interface IComponentProps {
    relaxProps?: Partial<IActor> & AppStore;
}

interface IComponentState {
    /** 是否展示 “项目” */
    isProject: boolean;
}

/**
 * 查询条件
 */
@Relax
export default class SearchGroup extends React.Component<IComponentProps, IComponentState> {
    formRef = React.createRef<FormInstance>();

    static relaxProps = {
        projectTypeList: 'projectTypeList',
        belongBaselineList: 'belongBaselineList',
        projectList: 'projectList',
        iterationList: 'iterationList',
        time: 'time',
        projectType: 'projectType',
        belongBaseLine: 'belongBaseLine',
        isUpdateForm: 'isUpdateForm',
        onValuesChange: noop,
        fetchProjectListPageFn: noop,
        changeLoadingFn: noop,
        iterationId: 'iterationId',
        projectId: 'projectId',
        startYear: 'startYear',
        activeTabKey: 'activeTabKey',
        pageSize: 'pageSize',
        pageNum: 'pageNum',
    };

    constructor(props) {
        super(props);
        this.state = {
            isProject: false,
        }
    }

    componentDidUpdate(prevProps: Readonly<IComponentProps>, prevState: Readonly<any>, snapshot?: any): void {
        const { time, isUpdateForm, ...otherProps } = this.props.relaxProps || {};
        const prevIsUpdateForm = prevProps.relaxProps.isUpdateForm;
        if(isUpdateForm && isUpdateForm !== prevIsUpdateForm) {
            this.formRef.current?.setFieldsValue?.({
                ...otherProps,
                time: moment(time)
            });
        }
    }

    render() {
        const { 
            projectTypeList, belongBaselineList, projectList, iterationList, projectType,
            belongBaseLine, iterationId, projectId, startYear, activeTabKey
        } = this.props.relaxProps || {};
        const { isProject } = this.state;
        return (
            <div className="project_list_search_group">
                <Form 
                    layout="inline"
                    ref={ this.formRef }
                    onValuesChange={ this.onValuesChange }
                    onFinish={ this.onFinish }
                >
                    <Form.Item name="time">
                        <DatePicker
                            className="project_list_search_group__datePicker"
                            picker="year"
                            value={startYear ? moment(startYear) : null}
                            disabledDate={current => current && current > moment().subtract('year')}
                        />
                    </Form.Item>

                    <OneAuthWrapper functionName="f_project_list_project_type_baseline,f_project_list_project_type_customized">
                        <Form.Item name="projectType">
                            <Select placeholder="请选择项目类型"
                                value={projectType}
                            >
                                {
                                    projectTypeList?.map?.(item => {
                                        if(!item?.get?.("label")) return null;

                                        return (
                                            <Select.Option key={ item?.get?.("value") } value={ item?.get?.("value") }>{ item?.get?.("label") }</Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </OneAuthWrapper>

                    <OneAuthWrapper functionName="f_project_list_belong_baseline_sbc,f_project_list_belong_baseline_yxd,f_project_list_belong_baseline_b2b,f_project_list_belong_baseline_jszt,f_project_list_belong_baseline_ywzt">
                        <Form.Item name="belongBaseLine">
                            <Select placeholder="请选择所属基线"
                                value={belongBaseLine}
                            >
                                {
                                    belongBaselineList?.map?.(item => {
                                        if(!item?.get?.("label")) return null;

                                        return (
                                            <Select.Option key={ item?.get?.("value") } value={ item?.get?.("value") }>{ item?.get?.("label") }</Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </OneAuthWrapper>

                    {
                        isProject || projectType == 1? (
                            <OneAuthWrapper functionName="f_project_list_project_select_list_all,f_project_list_project_select_list">
                                <Form.Item name="projectId">
                                    <Select placeholder="请选择项目"
                                    value={projectId}
                                    >
                                        <Select.Option value={ null }>全部</Select.Option>
                                        {
                                            projectList?.map?.(item => {
                                                if(!item?.get?.("label")) return null;
        
                                                return (
                                                    <Select.Option key={ item?.get?.("value") } value={ item?.get?.("value") }>{ item?.get?.("label") }</Select.Option>
                                                );
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </OneAuthWrapper>
                        ) : null
                    }

                    <AuthWrapper functionName="f_project_list_iteration_select_list">
                        <Form.Item name="iterationId">
                            <Select placeholder="请选择迭代"
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return typeof option.props.children == 'string'
                                            ? option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                            : true;
                                    }}
                            >
                                <Select.Option value={ null }>全部</Select.Option>
                                {
                                    iterationList?.map?.(item => {
                                        if(!item?.get?.("label")) return null;

                                        return (
                                            <Select.Option key={ item?.get?.("value") } value={ item?.get?.("value") }>{ item?.get?.("label") }</Select.Option>
                                        );
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </AuthWrapper>

                    <Form.Item>
                        <Button type="primary" htmlType="submit"
                            className="project_list_search_group__button"
                        >搜索</Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    /**
     * 监听 - 表单字段值的变化
     * @param changedValues 
     * @param allValues 
     */
    onValuesChange = (changedValues, allValues) => {
        const { isProject } = this.state;
        const { onValuesChange } = this.props.relaxProps || {};
        if(!changedValues || !Object.keys(changedValues).length) return;
        if(!allValues || !Object.keys(allValues).length) return;

        let params = {};
        if(!('iterationId' in changedValues)) {
            params = {
                iterationId: null,
            };
            if(!('projectId' in changedValues)) {
                params['projectId'] = null;
            }
            
            this.formRef.current?.setFieldsValue?.(params);
        }

        // 当表单字段值的变化时，触发接口请求
        onValuesChange?.(changedValues, {
            ...allValues,
            ...params,
        });

        if(typeof changedValues?.projectType === 'number') {
            const isProject_new = Boolean(changedValues?.projectType);
            if(isProject_new === isProject) return;
  
            this.setState({ 
                isProject: isProject_new, 
            });
        }

    }
    
    /**
     * 搜索 - 操作
     * @param values 
     */
    onFinish = (values) => {
        const { fetchProjectListPageFn, changeLoadingFn, pageSize, pageNum } = this.props.relaxProps || {};
        values['time'] = values['time'] ? moment?.(values['time'])?.format?.("YYYY") : null;

        // 开启loading
        changeLoadingFn?.(true);
        fetchProjectListPageFn?.({
            ...values,
            pageSize: pageSize,
        }, false);
    }

};
