import { Action, Actor } from 'plume2';
import { merge, fromJS } from 'immutable';
import moment from 'moment';

export default class MainActor extends Actor {
  defaultState() {
    return {
      pageType: '0', //类型 0: 个人排行榜， 1: 项目排行榜， 2: 千行代码 3: 资源日历 4:实时人效
      ownerScoreRankData: {}, //当前用户年度数据和排名
      ownerFixQualityLineData: {}, //当前用户年度修复质量折线图数据
      ownerScoreData: {}, //年度个人得分数据 ownerScoreData.ownerScoreDataList：个人得分数据列表数据，ownerScoreData.ownerScoreDataForYear:个人得分年度合计
      begin: moment()
        .month(moment().month())
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss'), //查询开始时间
      end: moment()
        .month(moment().month())
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss'), //查询结束时间
      bestQualityTopFiveData: {}, //获取质量最佳top5
      fixBugTopFiveData: {}, //修复缺陷top5
      codeScoreMyIndex: 0, //编码得分排行当前数据位置
      bugScoreMyIndex: 0, //缺陷得分排行当前数据位置
      codeScoreData: [], //编码得分排行
      bugScoreData: [], //缺陷得分排行
      codeScorePageNum: 1, //编码得分排行页码
      bugScorePageNum: 1, //缺陷得分排行分页

      bestQualityTopFiveForProjectData: {}, //项目质量最佳top5
      bestQualityTopFiveForIterationData: {}, //迭代质量最佳top5
      projectScoreDataSource: [], //项目得分排行-项目 数据
      isShowProjectScoreGetMore: false, //是否展示项目得分排行查看更多
      iterationScoreDataSource: [], //项目得分排行-迭代 数据
      projectScoreType: '0', // 项目得分排行 0: 按项目 1: 按迭代
      projectScorePageNum: 1, //项目得分排行-项目 分页
      iterationScorePageNum: 1, //项目得分排行-迭代 分页
      thousandCodeProjectType: 0, // 千行代码 组织类型
      thousandCodeIterationGroupList: [], //千行代码 组织列表
      thousandCodeParams: {}, //千行代码查询参数
      thousandCodeDataSource: {}, //千行代码 数据
      realtimeEffectConditions: {}, //实时人效 搜索条件
      realtimeEffectConditionsData: {}, //实时人效 条件框数据
      realtimeEffectDataSource: {}, //实时人效 搜索结果
      realtimeEffectTableType: undefined, //实时人效 表格类型(主要用作头部名称的切换)
      realtimeEffectLoading: true, // 实时人效loading

      projectModalVisible: false, // 项目投入明细弹窗
      personModalVisible: false, // 人员任务明细弹窗
      startYear: moment().format('YYYY'), //开始年份
      projectStartYear: moment().format('YYYY'), //项目排行榜 开始年份
      selectedKeys: [], //选中的树

      bestQualityTopFiveLoading: false, //获取质量最佳top5加载中
      fixBugTopFiveLoading: false, //修复缺陷top5加载中
      codeScoreLoading: false, //编码得分排行加载中
      bugScoreLoading: false, //缺陷得分排行加载中
      ownerScoreLoading: false, //个人得分数据加载中
      bestQualityTopFiveForProjectLoading: false, //项目质量最佳top5加载中
      bestQualityTopFiveForIterationLoading: false, //迭代质量最佳top5加载中
      projectScoreLoading: false, //项目得分排行加载中
      thousandCodeLoading: false, //千行代码加载中
      devEffectRankingLoading: true, //研发效能排行榜加载中
      personSelectStartYear: moment().format('YYYY'),

      // 个人排行榜（主管视角相关）
      isLeader: false,
      belongDepartmentList: [], // 所属部门列表
      belongDepartmentInfo: {}, // 所选部门信息
      departmentId: null, //所选部门的id
      departmentUserInfo: {}, // 所选员工信息
      departmentUserList: [], //员工列表
      userId: null, // 所选员工id
      leaderSelectStartYear: moment().format('YYYY'), // 针对与主管视角的开始年份
      devEffectRankingList: {}, // 研发效能排行榜列表
      isShow: false, // 用于隐藏个人数据和折线图
      effectListUserId: null, // 研发效能排行榜的列表用户id
      ranking: 1, // 年度个人排名
      // 时间统计范围
      timeFrame: {},
      isShowTimeFrame: false, // 是否展示统计范围

      // 投入明细
      detailInfo: {},
      drawerVisible: false,
      investmentTotalDetail: {
        dateList: [], //表头
        dataList: [], //表格数据
        totalVoList: [], //每日总计
        baseOfWorkingHours: 0, //工时标准
        totalEffort: 0, //计划总投入人天
        workDateList: [], //所有工作日日期
      },
      investmentPhaseDetail: {
        dateList: [], //每月日期
        dataList: [], //表格数据
        totalVoList: [], //每日总计
        baseOfWorkingHours: 0, //工时标准
        workDateList: [], //所有工作日日期
      },
      drawerActiveKey: '1',
      humaneEffectDetailLoading: false,
      humaneEffectMileStoneLoading: false,
      /** 实时人效 - 项目类型 */
      realtime_effect_projectType: null,
      effectPageNum: 1,
      effectPageSize: 10,
    };
  }

  @Action('merge:data')
  mergeData(state, data) {
    return merge(state, data);
  }

  @Action('change:data')
  change(state, { field, value }) {
    return state.set(field, value);
  }
}
