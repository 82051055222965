import React from "react";
import { Relax } from "plume2";
import { IActor } from "../../type";
import { IterationStatusText } from '@components';
import AppStore from "../../store";
// 进度条 - 组件
import Progress from './../progress';
// 向上箭头 - svg组件
import TopArrowSvg from './imgs/top-arrow-svg';
// 向下箭头 - svg组件
import BottomArrowSvg from './imgs/bottom-arrow-svg';
import './index.less';

interface IComponentProps {
    relaxProps?: Partial<IActor> & AppStore;
    /** 项目类型 */
    projectType?: number;
    /** 基线产品、定制项目 - 迭代明细 */
    iterationDetailsTitle?: string;
    /** 迭代明细 - 数据 */
    iterationDetailsMap?: {
      [key: string]: any;
    };
}

/**
 * 迭代明细
 */
@Relax
export default class IterationDetails extends React.Component<IComponentProps, any> {

    static relaxProps = {
        iterationDetailsTitle: 'iterationDetailsTitle',
        iterationDetailsMap: 'iterationDetailsMap',
        fetchProjectListPageParams: 'fetchProjectListPageParams',
    };

    render() {
        let { 
            iterationDetailsTitle,
        } = this.props.relaxProps || {};
        iterationDetailsTitle = this.props.iterationDetailsTitle ?? iterationDetailsTitle;

        return (
            <div className="project_list_iteration_details">
                {
                    iterationDetailsTitle ? (
                        <div className="project_list_iteration_details__title">{ iterationDetailsTitle }-迭代明细</div>
                    ) : null
                }

                {/* 渲染 - 迭代明细 - 组件 */}
                { this.renderIterationDetails() }
            </div>
        );
    }

    /**
     * 渲染 - 迭代明细 - 组件
     * @returns 
     */
    renderIterationDetails = () => {
        let { fetchProjectListPageParams, iterationDetailsMap, } = this.props.relaxProps || {};
        iterationDetailsMap = this.props.iterationDetailsMap ?? iterationDetailsMap;
        iterationDetailsMap = iterationDetailsMap?.toJS?.() || {};
        if(!iterationDetailsMap || !Object.keys(iterationDetailsMap).length) return null;

        let { projectType } = fetchProjectListPageParams?.toJS?.() || {};
        projectType = this.props?.projectType ?? projectType;
        
        if(![0, 1].includes(projectType)) return null;

        if(projectType === 0 ) {
            return this.renderIterationDetails_0(projectType, iterationDetailsMap);
        }

        return this.renderIterationDetails_1(projectType, iterationDetailsMap);
    }

    /**
     * 渲染 - 基线产品-迭代明细 - 组件
     * @param projectType 
     * @param iterationDetailsMap 
     * @returns 
     */
    renderIterationDetails_0 = (projectType, iterationDetailsMap) => {
        const { 
            startDate, endDate, progressRate, status, workload, 
            actualHumaneEffect, bugDensity, dealTimelyRate,
            returnTimelyRate, repairQuality, qualityScore,
            actualHumaneEffectColor, actualHumaneEffectArrow, bugDensityColor,
            bugDensityArrow,
        } = iterationDetailsMap || {};

        return (
            <ul className="project_list_iteration_details__list">
                <li>
                    <span>迭代周期：</span>
                    <div>{ (startDate || endDate) ? `${ startDate } ~ ${ endDate }` : '-' }</div>
                </li>
                <li>
                    <span>迭代进度：</span>
                    <div>
                        {/* 渲染 - 进度条 - 组件 */}
                        { this.renderProgress(progressRate) }
                    </div>
                </li>
                <li>
                    <span>迭代状态：</span>
                    {/* 渲染 - 迭代状态 - 组件 */}
                    { this.renderIterationStatus(status, projectType) }
                </li>
                <li>
                    <span>迭代工作量：</span>
                    {/* 渲染 - 工作量 - 组件 */}
                    { this.renderWorkload(workload) }
                </li>
                <li>
                    <span>迭代人效：</span>
                    {/* 渲染 - 人效 - 组件 */}
                    { this.renderHumanEfficiency(actualHumaneEffect, actualHumaneEffectColor, actualHumaneEffectArrow) }
                </li>
                <li>
                    <span>缺陷密度：</span>
                    {/* 渲染 - 缺陷密度 - 组件 */}
                    { this.renderBugDensity(bugDensity, bugDensityColor, bugDensityArrow) }
                </li>
                <li>
                    <span>修复及时率：</span>
                    {/* 渲染 - 下划线 - 组件 */}
                    { this.renderUnderlineComponent(dealTimelyRate) }
                </li>
                <li>
                    <span>回归及时率：</span>
                    {/* 渲染 - 下划线 - 组件 */}
                    { this.renderUnderlineComponent(returnTimelyRate) }
                </li>
                <li>
                    <span>修复质量：</span>
                    {/* 渲染 - 下划线 - 组件 */}
                    { this.renderUnderlineComponent(repairQuality) }
                </li>
                <li>
                    <span>质量考核得分：</span>
                    {/* 渲染 - 下划线 - 组件 */}
                    { this.renderUnderlineComponent(qualityScore, "分", "quality_inspection") }
                </li>
            </ul>
        );
    }

    /**
     * 渲染 - 下划线 - 组件
     * @param val 
     */
    renderUnderlineComponent = (val, unit = "%", key = 'bug_fixes') => {
        if(typeof val !== 'number') {
            return (
                <div>-</div>
            );
        }

        return (
            <div 
                className="project_list_iteration_details__list--underline"
                onClick={() => {
                    const index = {
                        quality_inspection: '1',
                        bug_fixes: '2',
                    }[key];
                    if(!index) return;

                    window.open(`/quality-assurance?pageType=${ index }`);
                    this.cacheSearchParams(key);
                }}
            >{ val }{ unit }</div>
        );
    }

    /**
     * 渲染 - 定制项目-迭代明细 - 组件
     * @param projectType 
     * @param iterationDetailsMap 
     * @returns 
     */
    renderIterationDetails_1 = (projectType, iterationDetailsMap) => {
        const { 
            projectDate, startDate, endDate, progressRate, status, projectManagerName, 
            implementationManagerName, preSalesManagerName, salesManagerName,
            devLeadName, contractWorkload, workload, finishWorkload,
            actualHumaneEffect, bugDensity, actualHumaneEffectColor, actualHumaneEffectArrow,
            bugDensityColor, bugDensityArrow,
        } = iterationDetailsMap || {};

        return (
            <ul className="project_list_iteration_details__list02">
                <li>
                    <span>立项时间：</span>
                    <div>{ projectDate || '-' }</div>
                </li>
                <li>
                    <span>开始时间：</span>
                    <div>{ startDate || '-' }</div>
                </li>
                <li>
                    <span>完成时间：</span>
                    <div>{ this.getEndDateFn(status, projectType, endDate) }</div>
                </li>
                <li>
                    <span>当前进度：</span>
                    <div>
                        {/* 渲染 - 进度条 - 组件 */}
                        { this.renderProgress(progressRate) }
                    </div>
                </li>
                <li>
                    <span>当前状态：</span>
                    {/* 渲染 - 迭代状态 - 组件 */}
                    { this.renderIterationStatus(status, projectType) }
                </li>
                <li>
                    <span>项目经理：</span>
                    <div>{ projectManagerName || '-' }</div>
                </li>
                <li>
                    <span>实施产品：</span>
                    <div>{ implementationManagerName || '-' }</div>
                </li>
                <li>
                    <span>售前产品：</span>
                    <div>{ preSalesManagerName || '-' }</div>
                </li>
                <li>
                    <span>销售经理：</span>
                    <div>{ salesManagerName || '-' }</div>
                </li>
                <li>
                    <span>开发组长：</span>
                    <div>{ devLeadName || '-' }</div>
                </li>
                <li>
                    <span>合同工作量：</span>
                    {/* 渲染 - 工作量 - 组件 */}
                    { this.renderWorkload(contractWorkload) }
                </li>
                <li>
                    <span>立项工作量：</span>
                    {/* 渲染 - 工作量 - 组件 */}
                    { this.renderWorkload(workload) }
                </li>
                <li>
                    <span>已投入工作量：</span>
                    {/* 渲染 - 工作量 - 组件 */}
                    { this.renderWorkload(finishWorkload) }
                </li>
                <li>
                    <span>项目人效：</span>
                    {/* 渲染 - 人效 - 组件 */}
                    { this.renderHumanEfficiency(actualHumaneEffect, actualHumaneEffectColor, actualHumaneEffectArrow) }
                </li>
                <li>
                    <span>缺陷密度：</span>
                    {/* 渲染 - 缺陷密度 - 组件 */}
                    { this.renderBugDensity(bugDensity, bugDensityColor, bugDensityArrow) }
                </li>
            </ul>
        );
    }

    /**
     * 获取 - 完成时间
     * @param status 
     * @param projectType 
     * @param endDate 
     */
    getEndDateFn = (status, projectType, endDate) => {
        if(!endDate) return "-";

        if(status === 0 || (projectType === 1 && status === 1)) {
            return `计划：${ endDate }`;
        }

        return endDate;
    }

    /**
     * 渲染 - 进度条 - 组件
     * @param val 
     */
    renderProgress = (val) => {
        if(typeof val !== 'number') {
            return (
                <div>-</div>
            );
        }

        return (
            <Progress percent={ val } width={ 120 } />
        );
    }

    /**
     * 渲染 - 工作量 - 组件
     * @param val 
     */
    renderWorkload = (val) => {
        return (
            <div>{ typeof val === 'number' ? `${ val }人天` : '-' }</div>
        );
    }

    /**
     * 渲染 - 缺陷密度 - 组件
     * @param val 
     * @param colorNum 0绿色，1红色
     * @param arrowNum 0向上，1向下
     * @returns 
     */
    renderBugDensity = (val, colorNum: 0 | 1, arrowNum: 0 | 1) => {
        if(typeof val !== 'number') {
            return (
                <div>-</div>
            );
        }

        const fillColor = colorNum === 1 ? '#F53731' : null;
        return (
            <div 
                className="project_list_iteration_details__list--arrow"
                onClick={() => {
                    window.open("/quality-assurance?pageType=1");
                    this.cacheSearchParams("quality_inspection");
                }}
            >
                { val }(个/KL)
                {
                    [0, 1].includes(arrowNum) ? (
                        <div>
                            {
                                arrowNum === 0 ? (
                                    <TopArrowSvg fillColor={ fillColor } />
                                ) : (
                                    <BottomArrowSvg fillColor={ fillColor } />
                                )
                            }
                        </div>
                    ) : null
                }
            </div>
        );
    }

    /**
     * 渲染 - 人效 - 组件
     * @param val 
     * @param colorNum 0绿色，1红色
     * @param arrowNum 0向上，1向下
     * @returns 
     */
    renderHumanEfficiency = (val, colorNum: 0 | 1, arrowNum: 0 | 1) => {

        if(typeof val !== 'number') {
            return (
                <div>-</div>
            );
        }

        const fillColor = colorNum === 1 ? '#F53731' : null;
        return (
            <div 
                className="project_list_iteration_details__list--arrow"
                onClick={() => {
                    window.open("/effectiveness-measurement?pageType=4");
                    this.cacheSearchParams("effectiveness-measurement");
                }}
            >
                { val }%
                {
                    [0, 1].includes(arrowNum) ? (
                        <div>
                            {
                                arrowNum === 0 ? (
                                    <TopArrowSvg fillColor={ fillColor } />
                                ) : (
                                    <BottomArrowSvg fillColor={ fillColor } />
                                )
                            }
                        </div>
                    ) : null
                }
            </div>
        );
    }

    /**
     * 渲染 - 迭代状态 - 组件
     * @param status 
     * @param projectType 
     * @returns 
     */
    renderIterationStatus = (status, projectType) => {
        if(![0, 1].includes(projectType)) return "-";
        if(typeof status !== 'number') return "-";

        return (
            <IterationStatusText 
                projectType={ projectType } 
                status={ status } 
            />
        );
    }

    /**
     * 缓存 - 搜索条件
     * @param key 
     * @returns 
     */
    cacheSearchParams = (key) => {
        if(!key) return;

        let { fetchProjectListPageParams, iterationDetailsMap, } = this.props.relaxProps || {};
        iterationDetailsMap = this.props.iterationDetailsMap ?? iterationDetailsMap;
        const { id, projectId, } = iterationDetailsMap?.toJS?.() || {};
        const { 
            pageNum, pageSize, iterationId,
            belongBaseLine, projectType, 
            ...restParams
        } = fetchProjectListPageParams?.toJS?.();

        const iteration = (projectType === 0 ? id : null) ?? null;
        const project = projectType === 0 ? null : projectId;
        const params = {
            ...restParams,
            projectType,
            belongBaseline: belongBaseLine,
            iteration,
            iterationId: iteration,
            project,
            projectId: project,
            startYear: restParams?.time,
        };
        
        localStorage.setItem(`${key}-searchParams`, JSON.stringify(params));
    }

};
