import React from 'react';
import { SkyeyePcLogo, SkyeyePcCopyright, SkyeyePcWelcomePage } from '@components';
// 新员工注册 - 表单
import RegisterForm from './components/register_form';
import './index.less';

// 新员工注册
export default class Register extends React.Component<any, any> {
    render() {
        return (
            <div className='skyeye_pc_register'>
                {/* 数字化平台logo */}
                <SkyeyePcLogo />

                {/* 核心登录表单 */}
                <SkyeyePcWelcomePage pathname='/register'>
                    <div className='skyeye_pc_register__content'>
                        <RegisterForm {...this.props} />
                    </div>
                </SkyeyePcWelcomePage>

                {/* 数字化平台 - 底部版权 */}
                <SkyeyePcCopyright />
            </div>
        );
    }
}