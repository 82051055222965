import React from 'react';
import { Relax } from 'plume2';
import { Table, Tooltip, Pagination, Checkbox } from 'antd';
import { fromJS } from 'immutable';
import { noop } from '@utils';
import {IterationStatusText} from "@components";

@Relax
class BugData extends React.Component<any, any> {
  static relaxProps = {
    getQualityInspectionBugData: noop,
    mergeData: noop,
    getProjectInfoWithList: noop,
    qualityInspectionListInfo: 'qualityInspectionListInfo',
    projectType: 'projectType',
    qualityInspectionListLoading: 'qualityInspectionListLoading',
    qualityBugDataCheckStatus: 'qualityBugDataCheckStatus',
    qualityInspectionConditions: 'qualityInspectionConditions',
    qualityInspectionRowId: 'qualityInspectionRowId',
  };

  componentWillUnmount() {
    // 清空相关数据
    const { mergeData } = this.props.relaxProps;
    mergeData({
      qualityInspectionListInfo: [],
      qualityInspectionListLoading: true,
    });
  }

  render() {
    const {
      qualityInspectionListInfo,
      getQualityInspectionBugData,
      projectType,
      qualityInspectionListLoading,
      qualityBugDataCheckStatus,
      getProjectInfoWithList,
      qualityInspectionConditions,
    } = this.props.relaxProps;
    const dataSource = qualityInspectionListInfo?.toJS()?.records;
    const total = qualityInspectionListInfo?.toJS()?.total;
    const current = qualityInspectionListInfo?.toJS()?.current;
    const options = [
      { label: '验收测试', value: '验收测试' },
      { label: '生产问题', value: '生产问题' },
    ];
    const defaultCheck = qualityBugDataCheckStatus?.toJS();

    return (
      <div className="bug_data_list">
        <div className="bug_data_list_title">
          <div className="bug_data_list_title_words">
            {`${projectType == 0 ? '基线产品' : '定制项目'}-缺陷数据`}
          </div>
          <div>
            <Checkbox disabled checked>
              内部测试
            </Checkbox>
            <Checkbox.Group
              options={options}
              value={defaultCheck}
              onChange={(e) => this._onChangeCheck(e)}
            />
          </div>
        </div>

        <div className="bug_data_list_table">
          <Table
            dataSource={dataSource}
            pagination={false}
            loading={qualityInspectionListLoading}
            columns={
              projectType == 0
                ? this._baselineColumn()
                : (this._customMadeColumn() as any)
            }
            childrenColumnName="children"
            rowKey="id"
            onRow={(record) => {
              return {
                onClick: () => {
                  if (projectType == 0) return;
                  this._columsClick(record);
                }, // 点击行
              };
            }}
            rowClassName={this._setRowClassName}
          ></Table>
        </div>
        <div className="bug_data_list_pagintion">
          <Pagination
            showQuickJumper={true}
            showSizeChanger={true}
            pageSizeOptions={['10', '20', '50', '100']}
            defaultCurrent={1}
            total={total}
            onChange={(page, pageNumber) => {
              getQualityInspectionBugData(true, page, pageNumber);
            }}
            showTotal={(total, range) => ` 共${total}条`}
            current={current}
          />
        </div>
      </div>
    );
  }

  _columsClick = (record) => {
    const { mergeData, getProjectInfoWithList } = this.props.relaxProps;
    mergeData({
      qualityInspectionRowId: record.id,
    });
    getProjectInfoWithList(record, true);
  };

  _setRowClassName = (record) => {
    const { qualityInspectionRowId } = this.props.relaxProps || {};
    const id = record?.id;
    return id === qualityInspectionRowId ? 'click-row-bgc' : '';
  };

  _onChangeCheck = (e) => {
    const { mergeData } = this.props.relaxProps;
    mergeData({
      qualityBugDataCheckStatus: e,
    });
  };

  _baselineColumn = () => {
    const { qualityBugDataCheckStatus, projectType } = this.props.relaxProps;
    let checkValue = qualityBugDataCheckStatus?.toJS()
    let column1 = [{
      title: "迭代/项目名称",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (text, data) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{marginRight:5}}>
              <IterationStatusText
                  projectType={ projectType }
                  status={ data.status }
                  placeholder={ null }
              />
            </div>
            <span
                className='hover-cls'
                onClick={() => {
                  window.open("/quality-assurance?pageType=0");
                  this.cacheSearchParams("project-list", null, data.id)
                }}
            >{text}</span>
          </div>
      )
    },
      {
        title: '总得分',
        dataIndex: 'totalScore',
        key: 'totalScore',
        align: 'center',
        render: (value, record) => this._getColumnValue(value, record, 'score'),
      },
      {
        title: '内部测试',
        children: [
          {
            title: '开发质量(分)',
            dataIndex: 'devQualityScore',
            key: 'devQualityScore',
            align: 'center',
            render: (value, record) =>
              this._getColumnValue(value, record, 'score'),
          },
          {
            title: '缺陷密度',
            dataIndex: 'devBugDensity',
            key: 'devBugDensity',
            align: 'center',
            render: (value, record) => (
              <span
                style={{
                  color: record.devIsBugDensityExceed ? '#F53731' : '#323233',
                }}
              >
                {value !== null ? value : '-'}
              </span>
            ),
          },
          {
            title: '代码行',
            dataIndex: 'codeLine',
            key: 'codeLine',
            align: 'center',
            render: (value, record) => this._getColumnValue(value, record),
          },
          {
            title: '缺陷数',
            dataIndex: 'devBugNum',
            key: 'devBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'dev'),
          },
        ],
      },
    ];
    let column = [
      {
        title: '验收测试',
        children: [
          // {
          //   title: "缺陷率",
          //   dataIndex: "bugRate",
          //   key: "bugRate",
          //   align: "center",
          //   render: (value, record) => this._getDefectRateColumn(value, record, 'uat')
          // },
          {
            title: '缺陷数',
            dataIndex: 'uatBugNum',
            key: 'uatBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'uat'),
          },
        ],
      },
      {
        title: '生产问题',
        children: [
          {
            title: '漏测得分',
            dataIndex: 'missScore',
            key: 'missScore',
            align: 'center',
            render: (value, record) =>
              this._getColumnValue(value, record, 'score'),
          },
          {
            title: '漏测率',
            dataIndex: 'missRate',
            key: 'missRate',
            align: 'center',
            render: (value, record) =>
              this._getLeakageRate(value, record, 'prod'),
          },
          {
            title: '生产质量(分)',
            dataIndex: 'prodQualityScore',
            key: 'prodQualityScore',
            align: 'center',
            render: (value, record) =>
              this._getColumnValue(value, record, 'score'),
          },
          {
            title: '缺陷数',
            dataIndex: 'prodBugNum',
            key: 'prodBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'prod'),
          },
        ],
      },
    ];
    let column2 = column.filter((v) => checkValue.includes(v.title));
    let newColumn = [...column1, ...column2];

    // 1.全勾选
    newColumn[0]['width'] = '21%';
    // 2.验收测试 生产问题均未勾选
    if (column2.length === 0) {
      newColumn[0]['width'] = '340px';
    }
    // 3.仅勾选一种
    if (column2.length == 1) {
      newColumn[0]['width'] = '25%';
    }
    return newColumn;
  };

  _customMadeColumn = () => {
    const { qualityBugDataCheckStatus, projectType } = this.props.relaxProps;
    let checkValue = qualityBugDataCheckStatus?.toJS();
    let column1 = [
      {
        title: "迭代/项目名称",
        dataIndex: "name",
        key: "name",
        align: "left",
        render: (text, data) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{marginRight:5}}>
                <IterationStatusText
                    projectType={ data.projectNodeFlag ? 1 : 0}
                    status={ data.status }
                    placeholder={ null }
                />
              </div>
              <span
                  className='hover-cls'
                  onClick={() => {
                    window.open("/quality-assurance?pageType=0");
                    this.cacheSearchParams("project-list", data.projectNodeFlag ? data.id : data.projectId, data.projectNodeFlag ? null : data.id)
                  }}
              >{text}</span>
            </div>
        )
      },
      {
        title: '总得分',
        dataIndex: 'totalScore',
        key: 'totalScore',
        align: 'center',
        render: (value, record) => this._getColumnValue(value, record, 'score'),
      },
      {
        title: '内部测试',
        children: [
          {
            title: '开发质量(分)',
            dataIndex: 'devQualityScore',
            key: 'devQualityScore',
            align: 'center',
            render: (value, record) =>
              this._getColumnValue(value, record, 'score'),
          },
          {
            title: '缺陷密度',
            dataIndex: 'devBugDensity',
            key: 'devBugDensity',
            align: 'center',
            render: (value, record) => (
              <span
                style={{
                  color: record.devIsBugDensityExceed ? '#F53731' : '#323233',
                }}
              >
                {value !== null ? value : '-'}
              </span>
            ),
          },
          {
            title: '代码行',
            dataIndex: 'codeLine',
            key: 'codeLine',
            align: 'center',
          },
          {
            title: '缺陷数',
            dataIndex: 'devBugNum',
            key: 'devBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'dev'),
          },
        ],
      },
    ];
    let column = [
      {
        title: '验收测试',
        children: [
          {
            title: 'UAT质量(分)',
            dataIndex: 'uatQualityScore',
            key: 'uatQualityScore',
            align: 'center',
          },
          // {
          //   title: "缺陷率",
          //   dataIndex: "bugRate",
          //   key: "bugRate",
          //   align: "center",
          //   render: (value, record) => this._getDefectRateColumn(value, record, 'uat')
          // },
          {
            title: '缺陷密度',
            dataIndex: 'uatBugDensity',
            key: 'uatBugDensity',
            align: 'center',
            render: (value, record) => (
              <span
                style={{
                  color: record.uatIsBugDensityExceed ? '#F53731' : '#323233',
                }}
              >
                {value !== null ? value : '-'}
              </span>
            ),
          },
          {
            title: '缺陷数',
            dataIndex: 'uatBugNum',
            key: 'uatBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'uat'),
          },
        ],
      },
      {
        title: '生产问题',
        children: [
          {
            title: '生产质量(分)',
            dataIndex: 'prodQualityScore',
            key: 'prodQualityScore',
            align: 'center',
          },
          {
            title: '缺陷数',
            dataIndex: 'prodBugNum',
            key: 'prodBugNum',
            align: 'center',
            render: (value, record) =>
              this._getBugNumColumn(value, record, 'prod'),
          },
          {
            title: '漏测率',
            dataIndex: 'missRate',
            key: 'missRate',
            align: 'center',
            render: (value, record) =>
              this._getLeakageRate(value, record, 'prod'),
          },
          {
            title: '漏测得分',
            dataIndex: 'missScore',
            key: 'missScore',
            align: 'center',
          },
        ],
      },
    ];
    let column2 = column.filter((v) => checkValue.includes(v.title));
    let newColumn = [...column1, ...column2];
    // 1.全勾选
    newColumn[0]['width'] = '18%';
    // 2.验收测试 生产问题均未勾选
    if (column2.length === 0) {
      newColumn[0]['width'] = '340px';
    }
    // 3.仅勾选一种
    if (column2.length == 1) {
      newColumn[0]['width'] = '22%';
    }
    return newColumn;
  };

  _getColumnValue = (value, record, unit?) => {
    if (value == null) {
      return '-';
    }
    if (unit == 'scroe') {
      return value + '分';
    }
    if (unit == 'rate') {
      return value + '%';
    }

    if (unit == 'rate') {
      return value + '%';
    }
    return value;
  };

  // 缺陷数 悬浮
  _getBugNumColumn = (value, records, type?) => {
    if (value == null) return '-';
    return (
      <Tooltip
        color="#FFFFFF"
        overlayClassName="quality-inspection-bug-data-tooltip"
        placement="rightTop"
        title={() => {
          return (
            <div style={{ color: '#333333' }}>
              <div>
                致命: <span>{`${records[`${type}Fatal`]}个`}</span>
              </div>
              <div>
                严重: <span>{`${records[`${type}Serious`]}个`}</span>
              </div>
              <div>
                一般: <span>{`${records[`${type}Normal`]}个`}</span>
              </div>
              <div>
                提示: <span>{`${records[`${type}Prompt`]}个`}</span>{' '}
              </div>
              <div>
                建议: <span>{`${records[`${type}Advice`]}个`}</span>
              </div>
              <div>
                需求/优化: <span>{`${records[`${type}Optimize`]}个`}</span>
              </div>
              <div>
                加权数: <span>{`${records[`${type}BugWeight`]}个`}</span>
              </div>
            </div>
          );
        }}
      >
        <span>{value}</span>
      </Tooltip>
    );
  };

  // 缺陷率 悬浮
  _getDefectRateColumn = (value, records, type?) => {
    if (value == null) return '-';
    return (
      <Tooltip
        color="#FFFFFF"
        overlayClassName="quality-inspection-bug-data-tooltip"
        placement="rightTop"
        title={() => {
          return (
            <div style={{ color: '#333333' }}>
              <div>
                内部测试: <span>{`${records.devBugNum || 0}个`}</span>
              </div>
              <div>
                验收测试: <span>{`${records.uatBugNum || 0}个`}</span>
              </div>
              <div>
                需求/优化: <span>{`${records[`${type}Optimize`] || 0}个`}</span>
              </div>
              <div>
                内部测试加权: <span>{`${records.devBugWeight || 0}个`}</span>
              </div>
              <div>
                验收测试加权: <span>{`${records.uatBugWeight || 0}个`}</span>
              </div>
            </div>
          );
        }}
      >
        <span
          style={{ color: records.uatIsBugRateExceed ? '#F53731' : '#323233' }}
        >
          {value + '%'}
        </span>
      </Tooltip>
    );
  };

  // 漏测率 悬浮
  _getLeakageRate = (value, records, type?) => {
    if (value == null) return '-';
    return (
      <Tooltip
        color="#FFFFFF"
        overlayClassName="quality-inspection-bug-data-tooltip"
        placement="rightTop"
        title={() => {
          return (
            <div style={{ color: '#333333' }}>
              <div>
                内部测试: <span>{`${records.devBugNum || 0}个`}</span>
              </div>
              <div>
                验收测试: <span>{`${records.uatBugNum || 0}个`}</span>
              </div>
              <div>
                生产问题: <span>{`${records.prodBugNum || 0}个`}</span>
              </div>
              <div>
                需求/优化: <span>{`${records[`${type}Optimize`] || 0}个`}</span>
              </div>
              <div>
                内部测试加权: <span>{`${records.devBugWeight || 0}个`}</span>
              </div>
              <div>
                内测含优化加权: <span>{`${records.devBugWeightForMiss || 0}个`}</span>
              </div>
              <div>
                验收测试加权: <span>{`${records.uatBugWeight || 0}个`}</span>
              </div>
              <div>
                验收含优化加权: <span>{`${records.uatBugWeightForMiss || 0}个`}</span>
              </div>
              <div>
                生产问题加权: <span>{`${records.prodBugWeight || 0}个`}</span>
              </div>
            </div>
          );
        }}
      >
        <span
          style={{ color: records.isMissRateExceed ? '#F53731' : '#323233' }}
        >
          {value + '%'}
        </span>
      </Tooltip>
    );
  };

  /**
   * 缓存 - 搜索条件
   * @param key
   * @returns
   */
  cacheSearchParams = (key, projectId, iterationId) => {
    if(!key) return;

    let { qualityInspectionConditions } = this.props.relaxProps || {};
    const {
      projectType, belongBaseline, startYear
    } = qualityInspectionConditions?.toJS?.();
    const params = {
      projectType: projectType,
      belongBaseline: belongBaseline,
      iterationId: projectType == 1 ? null : iterationId,
      projectId: projectId,
      startYear: startYear,
      activeTabKey: iterationId,
    };

    localStorage.setItem(`${key}-searchParams`, JSON.stringify(params));
  }
}
export default BugData;
