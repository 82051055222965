import React from "react";
import { Relax } from "plume2";
import { noop } from "@utils";
import { fromJS } from "immutable";
import { Tree } from "antd";

import project from "./../../img/project.png";
import project_select from "./../../img/project_select.png";
import branch from "./../../img/branch.png";
import branch_select from "./../../img/branch_select.png";

@Relax
export default class TreeControl extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedKeys: "",
    };
  }

  static relaxProps = {
    thousandCodeIterationGroupList: "thousandCodeIterationGroupList",
    thousandCodeProjectType: "thousandCodeProjectType",
    changeThousandCodeParams: noop,
    clearData: noop,
    mergeData: noop,
  };

  componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    if (nextProps.relaxProps.thousandCodeIterationGroupList.size) {
      let key = [
        nextProps.relaxProps.thousandCodeIterationGroupList.get(0).get("id"),
      ];
      this.setState({
        selectedKeys: JSON.stringify(key),
      });
    }
  }

  render() {
    const { thousandCodeIterationGroupList } = this.props.relaxProps;
    const { selectedKeys } = this.state;
    return (
      <div className="thousand_code_TreeControl">
        <Tree
          selectedKeys={[selectedKeys]}
          onSelect={(e) => this._onSelect(e)}
          treeData={this._dataList(thousandCodeIterationGroupList)}
        />
      </div>
    );
  }

  _dataList = (data, parent?) => {
    return (data.size = 0)
      ? []
      : data.toJS().map((item) => {
          let itemKey = [];
          if(!item.type&&item.type!==0){
            return {title: '',key:'',children:[]}
          }
          // 当前节点的key，分支取name作为key值
          itemKey.push(item.type === 2 ? item.name : item.id);
          // 拼接父节点key值
          if (parent) {
            itemKey.unshift(...parent);
          }
          return {
            title: () => {
              let { selectedKeys } = this.state;
              let flag = JSON.stringify(itemKey) == selectedKeys;
              let img;
              if (item.type === 2) {
                img = flag ? branch_select : branch;
              } else {
                img = flag ? project_select : project;
              }
              return (
                <div className="thousand_code_TreeImg">
                  <img src={img} alt="" width="16" height="16" />
                  {item.name}
                </div>
              );
            },
            key: JSON.stringify(itemKey),
            children:
              item.children.length > 0
                ? this._dataList(fromJS(item.children), itemKey)
                : [],
          };
        });
  };

  _onSelect = (ids) => {
    //取消选中
    if (!ids.length) {
      return null;
    }
    const { changeThousandCodeParams, thousandCodeProjectType, clearData, mergeData } =
      this.props.relaxProps;
    const idArray = JSON.parse(ids);
    this.setState({ selectedKeys: ids[0] });
    //记录选中
    mergeData({selectedKeys: ids})

    if (Number(thousandCodeProjectType)) {
      // 定制项目
      const projectId = idArray[0];
      const iterationId = idArray[1] || "";
      const branch = idArray[2] || "";
      changeThousandCodeParams({ projectId, iterationId, branch });
    } else {
      // 基线产品
      const projectId = idArray[0]; // 所属基线
      const iterationId = idArray[1]; // 迭代
      const branch = idArray[2] || ""; // 分支
      // 选中所属基线清空值
      if (idArray?.length === 1) {
        clearData();
        mergeData({ isShowTimeFrame: false })
      } else {
        changeThousandCodeParams({ iterationId, branch });
      }
    }
  };
}
